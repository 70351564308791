import React, {useEffect, useRef, useState} from "react";
import Interval from "../../../../components/Interval/Interval";
import client, {getApiHost} from "../../../../lib/client";
import {Link, useRouteMatch} from "react-router-dom";
import styles from "./Monitoring.module.scss";
import Query from "../../../../components/Query/Query";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../components/Loading/Loading";
import Label from "../../../../components/Label/Label";
import Select from "../../../../components/Select/Select";
import {arr, selectDefaultFilter} from "../../../../lib/helpers/renderhelper";
import {ErrorTypeNotFound, ErrorTypeUnauthorized} from "../../../../lib/result";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
const Option = Select.Option;

interface GrafanaProps {
    url: string;
}

const injectedGrafanaCss = `
.custom-scrollbar .view {
    overflow: auto !important;
}      

.sidemenu {
    display: none !important;
}     

.main-view {
    background-color: white !important;
}      
`;

function Grafana(props: GrafanaProps) {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [iframeVisible, setIframeVisible] = useState(false);
    const [iframeHeight, setIframeHeight] = useState(6000);
    const [waitLimit, setWaitLimit] = useState(10000);
    const intervalTime = 100;

    return <Interval interval={intervalTime} fn={() => {
        if (iframeRef?.current) {
            // inject css if not present
            const element = iframeRef.current.contentDocument?.getElementById("loft-css");
            if (iframeRef.current.contentDocument && !element) {
                const style = document.createElement('style');
                style.id = "loft-css";
                style.textContent = injectedGrafanaCss;
                if (iframeRef.current.contentDocument?.head) {
                    iframeRef.current.contentDocument?.head.append(style);
                }
            }

            // resize iframe if necessary
            const navbar = iframeRef.current.contentDocument?.getElementsByClassName("navbar") || [];
            const dashboard = iframeRef.current.contentDocument?.getElementsByClassName("dashboard-content") || [];
            if ([...navbar].length === 1 && [...dashboard].length === 1 && dashboard[0].firstChild && dashboard[0].lastChild) {
                const scrollHeightFirst = (dashboard[0].firstChild as any).scrollHeight;
                const scrollHeightLast = (dashboard[0].lastChild as any).scrollHeight;
                const scrollHeightNavbar = (navbar[0] as any).scrollHeight;

                // 32 is the padding for top and bottom
                setIframeHeight(scrollHeightFirst + scrollHeightLast + scrollHeightNavbar + 32);
                setIframeVisible(true);
            }
            
            // this is a little bit hacky because we cannot use scope variables here
            // because the function is not refreshed in the underlying component
            setIframeVisible(iframeVisible => {
                setWaitLimit(waitLimit => {
                    if (!iframeVisible) {
                        if (waitLimit > 0) {
                            return waitLimit - intervalTime;
                        } else {
                            setIframeHeight(1000);
                            iframeVisible = true;
                        }
                    }
                    
                    return waitLimit;
                })
                
                return iframeVisible;
            })
        }
    }}>
        {!iframeVisible && <Loading />}
        <iframe style={{"display": iframeVisible ? "block" : "none"}} className={styles["grafana"]} height={iframeHeight} src={getApiHost() + props.url + "?theme=light"} ref={iframeRef} />
    </Interval>
}

interface Dashboard {
    id?: number;
    title?: string;
    url?: string;
    tags?: string[];
}

interface MonitoringProps {

}

export default function Monitoring(props: MonitoringProps) {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    const [nextDashboard, setNextDashboard] = useState<Dashboard | undefined>(undefined);
    const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | undefined>(undefined);
    useEffect(() => {
        if (nextDashboard) {
            setNextDashboard(undefined);
            window.setTimeout(() => setSelectedDashboard(nextDashboard), 100);
        }
    }, [nextDashboard])

    return <div className={styles["admin-wrapper"]}>
        <ClusterHeader />
        <Query query={async () => await client.doRaw<Array<Dashboard>>(`/grafana/${cluster}/api/search?query=&starred=false&skipRecent=true&skipStarred=true&folderIds=0&layout=folders`)}>
            {
                result => {
                    if (result.error) {
                        if (result.error.val?.type === ErrorTypeNotFound) {
                            return <div className={styles["not-found"]}>
                                <Tooltip title={result.error.val.message}>
                                    <span>loft couldn't find a running grafana pod.</span>
                                </Tooltip>
                                &nbsp;Please make sure you have installed the recommended app <Link to={`/clusters/details/${cluster}/cluster`}>kube-prometheus-stack</Link> into this cluster. For a complete walkthrough, please take a look at the <a target={"_blank"} href={"https://loft.sh/docs"}>monitoring guide</a>
                            </div>
                        } else if (result.error.val?.type === ErrorTypeUnauthorized && result.error.val.reason === "Unknown") {
                            return <div>
                                <div className={"color-error"}>Failed to authenticate with Grafana. This could have several reasons:</div>
                                <ul className={styles["auth-error"]}>
                                    <li className={"color-error"}>You have several grafana instances running in the same cluster. You can verify this by running <b><i>kubectl get po --selector=app.kubernetes.io/name=grafana --all-namespaces</i></b> in the cluster. To tell loft to use a specific grafana instance, please add the annotation <b><i>grafana.loft.sh/namespace: grafana-namespace</i></b> to the clusters metadata (Clusters &gt; Actions &gt; Show Yaml)</li>
                                    <li className={"color-error"}>You have not configured the prometheus-stack release correctly. Please make sure you have <b><i>auth.proxy</i></b> under <b><i>grafana.ini</i></b> in the helm release values configured. For a complete guide, please take a look at the <a href="https://loft.sh/docs/clusters/guides/monitoring" target="_blank">loft docs</a>.</li>
                                </ul>
                            </div>;
                        }

                        return <ErrorMessage error={result.error} />;
                    } else if (result.loading && !result.data) {
                        return <Loading />;
                    }

                    return <div>
                        <div className={styles["dashboard-select"]}>
                            <Label>Select a grafana dashboard to display below</Label>
                            <Select showSearch
                                    className={styles["select"]}
                                    placeholder={"Select a dashboard"}
                                    filterOption={selectDefaultFilter}
                                    value={selectedDashboard ? selectedDashboard.id+"" : undefined}
                                    onChange={value => {
                                        setSelectedDashboard(undefined);
                                        if (!value) {
                                            return;
                                        }

                                        setNextDashboard(arr(result.data).find(dashboard => ((dashboard.id+"") === value)));
                                    }}>
                                {arr(result.data).sort((a: Dashboard, b: Dashboard) => {
                                    if (arr(a.tags).includes("loft-grafana-dashboard") && !arr(b.tags).includes("loft-grafana-dashboard")) {
                                        return -1;
                                    } else if (!arr(a.tags).includes("loft-grafana-dashboard") && arr(b.tags).includes("loft-grafana-dashboard")) {
                                        return 1;
                                    }
                                    if (arr(a.tags).includes("kubernetes-mixin") && !arr(b.tags).includes("kubernetes-mixin")) {
                                        return -1;
                                    } else if (!arr(a.tags).includes("kubernetes-mixin") && arr(b.tags).includes("kubernetes-mixin")) {
                                        return 1;
                                    }
                                    if (a!.title! < b!.title!) {
                                        return -1;
                                    } else if (a!.title! > b!.title!) {
                                        return 1;
                                    }
                                    return 0;
                                }).map(dashboard => {
                                    return <Option key={dashboard.id} value={dashboard.id + ""}>{dashboard.title}</Option>
                                })}
                            </Select>
                        </div>
                        {
                            selectedDashboard?.url && <div className={styles["grafana-wrapper"]}>
                                <Grafana url={selectedDashboard.url} />
                            </div>
                        }
                    </div>
                }
            }
        </Query>
    </div>
}