import React from "react";
import AuthLayout from "../../../components/AuthLayout/AuthLayout";
import client from "../../../lib/client";
import {Button, Input} from "antd";
import Label from "../../../components/Label/Label";
import styles from "./CreateAccount.module.scss";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import {ResultError, Return} from "../../../lib/result";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {NewResource, Resources} from "../../../lib/resources";
import {useErrorLoading} from "../../../lib/messageHook";
import {useUser} from "../../../contexts/UserContext/UserContext";
import {arr} from "../../../lib/helpers/renderhelper";
import constants from "../../../constants/constants";
import {LOFT_PROFILE_INFO} from "../CompleteProfile/CompleteProfile";

interface Props extends RouteComponentProps {
}

export async function deleteResetAccessKey(): Promise<ResultError> {
    const userResult = await client.getUser();
    if (userResult.err) {
        return userResult;
    }
    
    // fetch access keys for user
    const accessKeysResult = await client.management(Resources.ManagementV1UserAccessKeys).Get(userResult.val!);
    if (accessKeysResult.err) {
        return accessKeysResult;
    }
    
    // delete all keys
    for (let i = 0; i < arr(accessKeysResult.val.accessKeys).length; i++) {
        if (accessKeysResult.val.accessKeys![i].spec?.type !== "Reset") {
            continue
        }
        
        const deleteResult = await client.management(Resources.ManagementV1OwnedAccessKey).Delete(accessKeysResult.val.accessKeys![i].metadata?.name!);
        if (deleteResult.err) {
            return deleteResult;
        }
    }

    return Return.Ok()
}

function CreateAccount(props: Props) {
    const user = useUser();
    const [email, setEmail] = React.useState<string>(user && user.spec && user.spec.email ? user.spec.email : "");
    const [username, setUsername] = React.useState<string>(user && user.spec && user.spec.username ? user.spec.username : "");
    const [password, setPassword] = React.useState<string>("");
    const [errorLoading, setLoading, setError] = useErrorLoading();

    // reroute if not logged in
    if (!client.isLoggedIn()) {
        props.history.push("/");
        return null;
    }

    return <AuthLayout title="Create a new account" description="to access your spaces" onSubmit={async () => {
        // validate inputs
        if (!email) {
            setError(Return.Failed("Please specify an email"));
            return;
        }
        if (!username) {
            setError(Return.Failed("Please specify an username"));
            return;
        }
        if (!password) {
            setError(Return.Failed("Please specify a password"));
            return;
        }
        if (password.length < 7) {
            setError(Return.Failed("Please choose a password that has more than 6 characters"));
            return;
        }

        // set loading
        setLoading(true);

        // create a new UserProfile resource
        const userProfile = NewResource(Resources.ManagementV1UserProfile, "profile", {
            username,
            email,
            password,
            "custom": localStorage.getItem(LOFT_PROFILE_INFO) || "",
        });

        // create the resource
        const response = await client.management(Resources.ManagementV1UserProfile).Name(user?.metadata?.name!).Create(userProfile);
        if (response.err) {
            setError(response);
            return;
        }

        // login to gain a new access key
        const loginResult = await client.login(username, password);
        if (loginResult.err) {
            setError(loginResult);
            return;
        }

        // now delete the current access key
        const result = await deleteResetAccessKey();
        if (result.err) {
            setError(result);
            return;
        }

        // redirect
        window.location.href = "/spaces";
    }}>
        <Label>Email address</Label>
        <Input placeholder={"name@company.tld"} value={email} onChange={(e) => setEmail(e.target.value)} />
        <Label>Choose a username</Label>
        <Input placeholder={"username"} value={username} onChange={(e) => setUsername(e.target.value)} />
        <div className={styles["small-text"]}>Allowed characters: [a-z] [0-9]</div>
        <Label>Choose a password</Label>
        <Input.Password className={styles["password"]} placeholder={"*************"} value={password} onChange={(e) => setPassword(e.target.value)} />
        <div className={styles["small-text"]}>More than 6 characters</div>
        <ErrorMessage className={styles["error"]} error={errorLoading.error} />
        <div className={styles["finish-wrapper"]}>
            <Button loading={errorLoading.loading} type={"primary"} htmlType={"submit"}>Finish</Button>
        </div>
        <div className={styles["legal-hint"]}>
            By creating an account, you agree to the <a href={"https://loft.sh/terms"}>Terms of Service</a> and the <a href={"https://loft.sh/privacy-policy"}>Privacy Policy</a> of Loft.
        </div>
    </AuthLayout>
}

export default withRouter(CreateAccount);
