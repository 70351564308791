import React from "react";
import styles from "./AuthLayout.module.scss";
import LoftLogoBig from "../../images/loft-logo.svg";
import Icon from "@ant-design/icons";

interface AuthLayoutProps {
    title: string;
    description: string;

    onSubmit?: () => void;
}

export default function AuthLayout(props: React.PropsWithChildren<AuthLayoutProps>) {
    return <div className={styles["auth-layout"]} style={{ minWidth: "512px" }}>
        <div className={styles["container"]}>
            <div className={styles["logo"]}>
                <Icon component={LoftLogoBig as any} className={styles["logo-svg"]} />
            </div>
            <div className={styles["header"]}>
                <div className={styles["title"]}>{props.title}</div>
                <div className={styles["description"]}>{props.description}</div>
            </div>
            <form onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();

                if (props.onSubmit) {
                    props.onSubmit();
                }
            }}>
                {props.children}
            </form>
        </div>
    </div>
}