import React from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {Return} from "../../../../lib/result";
import client, {GroupVersionResource, RequestOptionsVCluster} from "../../../../lib/client";
import {DrawerDispatch, useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import {Unstructured} from "../../../../lib/types"
import {parseApiVersion} from "../../../../lib/helpers/kubehelper"
import Description from "../../../../components/Description/Description";
import Label from "../../../../components/Label/Label";
import YAMLEditor from "../../../../components/YAMLEditor/YAMLEditor";
import jsyaml from "js-yaml";
import {Result} from "../../../../lib/result";

export interface ResourcesDrawerProps extends SectionProps {
  cluster?: string;
  vCluster?: RequestOptionsVCluster;

  namespace?: string;
  placeholder?: string;
  defaultResourceYaml?: string;

  apiResources?: GroupVersionResource<Unstructured>[];
  selectedResource?: GroupVersionResource<any>;

  refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<ResourcesDrawerProps, "mode"> & {config?: string, drawer?: DrawerDispatch};

async function onCreate({cluster, vCluster, namespace, selectedResource, apiResources, config}: ChangeFunctionProps) {
    let newResource: any = undefined;
    try {
        newResource = jsyaml.load(config!);
    } catch(err: any) {
        return Return.Failed(err + "");
    }
    if (!newResource) {
        return Return.Failed("Resource is empty");
    } else if (!newResource.apiVersion) {
        return Return.Failed("apiVersion is missing");
    } else if (!newResource.kind) {
        return Return.Failed("kind is missing");
    }

    const groupVersion = parseApiVersion(newResource.apiVersion);
    if (groupVersion.err) {
        return Return.Error(groupVersion.val);
    }

    const resource = selectedResource ? selectedResource : apiResources?.find((resource) => resource.kind === newResource.kind && resource.group === groupVersion.val[0] && resource.version === groupVersion.val[1]);
    if (!resource) {
        return Return.Failed("Resource " + newResource.kind + " not found in cluster " + cluster);
    }

    let result : Result<Unstructured>;
    if (namespace) {
        result = await client.auto(cluster, vCluster, resource).Namespace(namespace).Create(newResource);
    } else if (resource.namespaced && newResource.metadata?.namespace) {
        result = await client.auto(cluster, vCluster, resource).Namespace(newResource.metadata.namespace).Create(newResource);
    } else if (resource.namespaced) {
        result = await client.auto(cluster, vCluster, resource).Namespace("default").Create(newResource);
    } else {
        result = await client.auto(cluster, vCluster, resource).Create(newResource);
    }

    if (result.err) {
        return Return.Error(result.val);
    }
    return Return.Ok();
}

export default function ResourcesDrawer(props: ResourcesDrawerProps) {
  const {cluster, vCluster} = props;
  const [textContent, setTextContent] = React.useState(props.defaultResourceYaml);
  const drawer = useDrawerDispatcher();

  return <ItemDrawer okButtonText={"Create"} onOkAsync={async () => {
      if (!cluster && !vCluster) {
        ClientMessage.Error(Return.Failed("no cluster or vcluster selected"));
        return;
      }
    
      const message = ClientMessage.Loading(cluster, vCluster);

      // execute the create / update / batch logic
      const result = await onCreate({cluster: cluster, vCluster: vCluster, selectedResource: props.selectedResource, namespace: props.namespace, apiResources: props.apiResources, config: textContent, refetch: props.refetch});

      // check if there was an error
      if (result?.err) {
        message.ErrorAuto(result, cluster, vCluster);
        return;
      }

      // refetch
      await props.refetch();
      message.DoneAuto(cluster, vCluster);

      // close drawer
      drawer({});
  }}>
   <React.Fragment>
      <Label>Kubernetes Resource Config</Label>
      <YAMLEditor placeholder={props.placeholder}
                  value={textContent}
                  minLines={20}
                  maxLines={100}
                  onChange={val => setTextContent(val)} />
      <Description>Configure the new resource</Description>
    </React.Fragment>
  </ItemDrawer>
}