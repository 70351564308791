import React from "react";
import {MouseEventHandler, useState} from "react";
import {without} from "../../helpers/renderhelper";

export interface ResetProps<T> {
    resetable?: boolean;

    value?: any;
    readOnly?: boolean;
    onChangedStatus?: (changed: boolean) => void;

    changed?: boolean;
    initial?: T;
}

export function filterResetProps(props: any) {
    return without(props, ["resetable", "onChangedStatus", "changed", "initial"])
}

interface Changed<T> {
    changed: boolean;
    initial?: T;
}

export function useReset<T>(props: ResetProps<T>, onChange?: (value: T) => void) {
    const [changed, setChanged] = useState<Changed<T>>({changed: false});
    const onSelect = () => {
        if (props.resetable && !props.readOnly && !changed.changed && !props.changed) {
            setChanged({
                changed: true,
                initial: props.value
            });

            if (props.onChangedStatus) {
                props.onChangedStatus(true);
            }
        }
    };

    const onReset = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        e.preventDefault();
        if (onChange) {
            if (props.changed) {
                onChange(props.initial!);
            } else {
                onChange(changed.initial!);
            }
        }

        setChanged({
            changed: false
        });

        if (props.onChangedStatus) {
            props.onChangedStatus(false);
        }
    };

    return {
        changed: props.resetable && (changed.changed || props.changed),
        onSelect,
        onReset
    }
}