import React from "react";
import { SectionProps } from "../../../../../components/Drawer/ItemDrawer";
import Select from "../../../../../components/Select/Select";
import {ResultError, Return} from "../../../../../lib/result";
import Query from "../../../../../components/Query/Query";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../../components/Loading/Loading";
import Description from "../../../../../components/Description/Description";
import Section from "../../../../../components/Drawer/Section/Section";
import Label from "../../../../../components/Label/Label";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import {displayName} from "../../../../../lib/helper";
import {ClusterV1Space} from "../../../../../../gen/models/clusterV1Space";
import {Link} from "react-router-dom";
const { Option } = Select;

const SpaceConstraintsLabel = "loft.sh/space-constraints";

interface SpaceConstraintsState {
    spaceConstraints: string | undefined;
    spaceConstraintsChanged: boolean;
}

interface SpaceConstraintsProps extends SectionProps {
    space: ClusterV1Space | undefined;
}

export default class SpaceConstraints extends React.PureComponent<SpaceConstraintsProps, SpaceConstraintsState> {
    state: SpaceConstraintsState = {
        spaceConstraints: this.props.space?.status?.spaceConstraint?.name,
        spaceConstraintsChanged: false,
    };

    update = (space: ClusterV1Space): ResultError => {
        if (this.state.spaceConstraintsChanged) {
            if (!space.metadata) {
                space.metadata = {};
            }
            if (!space.metadata.labels) {
                space.metadata.labels = {};
            }
            if (this.state.spaceConstraints) {
                space.metadata.labels[SpaceConstraintsLabel] = this.state.spaceConstraints;
            } else {
                delete space.metadata.labels[SpaceConstraintsLabel];
            }
        }
        
        return Return.Ok();
    };
    
    batch = (spaces: ClusterV1Space[]): ResultError => {
        for (let i = 0; i < spaces.length; i++) {
            const result = this.update(spaces[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok()
    }

    render() {
        if (["create"].includes(this.props.mode)) {
            return null;
        }

        return <Section title={`Space Constraints`} foldable={true} defaultFolded={true}>
            <Query query={async () => await client.management(Resources.ManagementV1SpaceConstraint).List()}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading) {
                            return <Loading />
                        }

                        return <React.Fragment>
                            <Label>Space Constraints</Label>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Select Space Constraints"
                                style={{ width: '100%' }}
                                resetable={true}
                                onChangedStatus={status => this.setState({spaceConstraintsChanged: status})}
                                value={this.state.spaceConstraints}
                                onChange={value => {
                                    this.setState({
                                        spaceConstraints: value,
                                    });
                                }}
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {arr(result.data?.items).map(constraints => {
                                    return <Option key={constraints.metadata?.name} value={constraints.metadata?.name!}>
                                        {displayName(constraints)}
                                    </Option>
                                })}
                            </Select>
                            <Description><Link to={"/clusters/spaceconstraints"}>Space Constraints</Link> are resources, permissions or namespace metadata that is applied and synced automatically into the space. This is useful to ensure certain Kubernetes objects are present in each namespace to provide namespace isolation or to ensure certain labels or annotations are set on the namespace of the user.</Description>
                        </React.Fragment>
                    }
                }
            </Query>
        </Section>
    }
}