import React from "react";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {DrawerDispatch, useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../../../components/Table/Table";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {ApiOutlined, DeleteOutlined, SettingOutlined} from "@ant-design/icons/lib";
import {alert, deleteConfirm} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import useQuery from "../../../../lib/Query/Query";
import styles from "./Templates.module.scss";
import { useRouteMatch } from "react-router-dom";
import {Button, Space} from "antd";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import {ConfigV1alpha1Template} from "../../../../../gen/models/configV1alpha1Template";
import {arr} from "../../../../lib/helpers/renderhelper";
import TemplateDrawer from "./TemplateDrawer/TemplateDrawer";
import {creationTimestampSorter, nameSorter} from "../../../../lib/helpers/sorthelper";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import TemplateDeletePopup from "./TemplateDeletePopup";
import FixedText from "../../../../components/FixedText/FixedText";
import copy from "copy-to-clipboard";
import Code from "../../../../components/Code/Code";

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, cluster: string, allTemplates: ConfigV1alpha1Template[]) {
    const editTemplate = (template: ConfigV1alpha1Template) => {
        drawerDispatcher({
            title: "Edit Template: " + template.metadata?.name!,
            content: <TemplateDrawer mode={"update"} cluster={cluster} template={template} allTemplates={allTemplates} refetch={refetch} />
        })
    };

    return [
        {
            title: "Template Name",
            sorter: (a: ConfigV1alpha1Template, b: ConfigV1alpha1Template) => nameSorter(a, b),
            render: (template: ConfigV1alpha1Template) => {
                return <FixedText className={styles["clickable"]} onClick={() => editTemplate(template)} text={template.metadata?.name!} />
            },
        },
        {
            title: 'Type',
            render: (template: ConfigV1alpha1Template) => {
                const types = [];
                if (template.resources?.helm) {
                    types.push("helm");
                }
                if (template.resources?.manifests && template.resources.manifests.length) {
                    types.push("kubectl");
                }
                if (template.resources?.remoteManifests && template.resources.remoteManifests.length) {
                    types.push("remote manifests");
                }

                if (!types.length) {
                    return "none";
                }

                return types.join(", ");
            }
        },
        {
            title: 'Helm Chart',
            render: (template: ConfigV1alpha1Template) => {
                const helmChart = template.resources?.helm?.chart?.repository?.name;
                return helmChart ? <FixedText text={helmChart} /> : "";
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ConfigV1alpha1Template, b: ConfigV1alpha1Template) => creationTimestampSorter(a, b),
            render: (template: ConfigV1alpha1Template) => {
                return <DynamicTime timestamp={template.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (template: ConfigV1alpha1Template) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editTemplate(template)} />
                    </Tooltip>
                    <ShowYamlPopup className={styles["setting"]} object={template} cluster={cluster} resource={Resources.ConfigV1alpha1Template} name={template.metadata?.name!} refetch={refetch} />
                    <TemplateDeletePopup templates={[template.metadata?.name!]} cluster={cluster} refetch={refetch} />
                </TableActions>;
            }
        },
    ];
}

function filter(item: ConfigV1alpha1Template, value: string) {
    return !!(item.metadata?.name?.includes(value))
}

export default function Templates() {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    // get templates
    const {loading, error, data, refetch} = useQuery(async () => await client.cluster(cluster, Resources.ConfigV1alpha1Template).List());
    return <div>
        <Table loading={loading} columns={getTableColumns(refetch, drawerDispatcher, cluster, arr(data?.items))} dataSource={data ? arr(data.items).map(template => { return {...template, key: template.metadata!.name!}}) : undefined} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <ClusterHeader />,
            right: <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Create Template",
                    content: <TemplateDrawer mode={"create"} cluster={cluster} allTemplates={data!.items} refetch={refetch} />
                })
            }}>Create Template</Button>,
            selectedActions: <React.Fragment>
                <Tooltip title={"edit"}>
                    <SettingOutlined className={styles["setting-batch"]} onClick={() =>
                    {
                        const templates: ConfigV1alpha1Template[] = [];
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                            const name = selectedRowKeys[i];
                            const template = data!.items.find(template => template.metadata?.name === name);
                            if (!template) {
                                continue;
                            }

                            templates.push(template);
                        }

                        if (templates.length === 0) {
                            return;
                        }

                        if (templates.length === 1) {
                            const template = templates[0];
                            drawerDispatcher({
                                title: "Edit Template: " + template.metadata?.name!,
                                content: <TemplateDrawer mode={"update"} cluster={cluster} template={template} allTemplates={data!.items} refetch={refetch} />
                            })
                        } else {
                            drawerDispatcher({
                                title: "Bulk Edit Selected Templates",
                                content: <TemplateDrawer mode={"batch"} cluster={cluster} templates={templates} allTemplates={data!.items} refetch={refetch} />
                            })
                        }

                        setSelectedRowKeys([]);
                    }} />
                </Tooltip>
                <TemplateDeletePopup className={styles["delete-batch"]} templates={selectedRowKeys.map(text => text.toString())} cluster={cluster} refetch={() => {
                  setSelectedRowKeys([]);
                  return refetch();
                }} />
            </React.Fragment>
        }} />
    </div>
};