import React from "react";
import Select from "../../../../components/Select/Select";
import {ResultError, Return} from "../../../../lib/result";
import Section from "../../../../components/Drawer/Section/Section";
import Description from "../../../../components/Description/Description";
import Label from "../../../../components/Label/Label";
import YAMLEditor from "../../../../components/YAMLEditor/YAMLEditor";
import {SectionProps} from "../../ItemDrawer";
import {ManagementV1Task} from "../../../../../gen/models/managementV1Task";
const { Option } = Select;

interface ManifestsState {
    manifests: string | undefined
}

interface ManifestsProps extends SectionProps {
    
}

export default class Manifests extends React.PureComponent<ManifestsProps, ManifestsState> {
    state: ManifestsState = {
        manifests: undefined,
    };

    create = (task: ManagementV1Task): ResultError => {
        if (!this.state.manifests) {
            return Return.Failed("Please specify manifests to apply")
        }
        if (!task.spec) {
            task.spec = {};
        }
        if (!task.spec.task) {
            task.spec.task = {}
        }
        if (!task.spec.task.apply) {
            task.spec.task.apply = {};
        }

        task.spec.task.apply.manifests = this.state.manifests;
        return Return.Ok();
    };

    render() {
        if (this.props.mode !== "create") {
            return null;
        }

        return <Section title={`Manifests`}>
            <Label>Manifests</Label>
            <YAMLEditor value={this.state.manifests}
                        onChange={(value) => this.setState({manifests: value})}
                        minLines={20}
                        maxLines={100}
                        placeholder={`apiVersion: v1
kind: Pod
metadata:
  name: my-pod
  namespace: my-namespace
spec:
...`} />
            <Description>Manifests that you want to apply to the cluster.</Description>
        </Section>
    }
}