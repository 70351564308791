import React from "react";
import {LockOutlined, SecurityScanOutlined} from "@ant-design/icons/lib";
import {useHistory, useRouteMatch} from "react-router-dom";
import Header from "../../../../components/Header/Header";
import {SolutionOutlined} from "@ant-design/icons";
import {useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {getActions} from "../../Users";
import {ManagementV1User} from "../../../../../gen/models/managementV1User";

interface UserDetailsHeaderProps {

}

export default function UserDetailsHeader(props: React.PropsWithChildren<UserDetailsHeaderProps>) {
    const routeMatch = useRouteMatch<{user: string}>();
    const user = routeMatch.params.user;
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1] === ":user" ? "access-keys" : path[path.length - 1];

    const history = useHistory();
    const drawerDispatcher = useItemDrawer();
    // we refresh the object here, otherwise it can be possible that we get a conflict error if the space has changed meanwhile
    const {data:userDetails, refetch:usersRefetch} = useQuery(async () => await client.management(Resources.ManagementV1User).Get(user));
    const userObj : ManagementV1User = userDetails?userDetails:{}
    const refetch = () => {
        return usersRefetch();
    };

    return <Header title={user} actions={getActions(userObj, drawerDispatcher, history, refetch)} selectedKey={selectedKey} items={[
        {
            key: "access-keys",
            url: `/users/details/${user}/access-keys`,
            content: <React.Fragment>
                <LockOutlined />Access Keys
            </React.Fragment>
        },
        {
            key: "activity",
            url: `/users/details/${user}/activity`,
            content: <React.Fragment>
                <SolutionOutlined />Activity
            </React.Fragment>
        },
        {
            key: "permissions",
            url: `/users/details/${user}/permissions`,
            content: <React.Fragment>
                <SecurityScanOutlined />Assigned Cluster Roles
            </React.Fragment>
        },
    ]}>
        {props.children}
    </Header>
}