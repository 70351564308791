import React from "react";
import {ResultError, Return} from "../../../../../lib/result";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import Description from "../../../../../components/Description/Description";
import Label from "../../../../../components/Label/Label";
import AutoComplete, {defaultAutoCompleteFilter} from "../../../../../components/AutoComplete/AutoComplete";
import {StorageV1VirtualClusterAccess} from "../../../../../../gen/models/agentstorageV1VirtualClusterAccess";
import {arr, selectOwnerFilter} from "../../../../../lib/helpers/renderhelper";
import styles from "./VClusterAccessRules.module.scss";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {ClusterV1EntityInfo} from "../../../../../../gen/models/clusterV1EntityInfo";
import {StorageV1VirtualClusterAccessRule} from "../../../../../../gen/models/agentstorageV1VirtualClusterAccessRule";
import Select from "../../../../../components/Select/Select";
import {alert} from "../../../../../lib/Modal/Modal";
import Owner from "../../../../../components/Owner/Owner";
import {ManagementV1VirtualClusterTemplate} from "../../../../../../gen/models/managementV1VirtualClusterTemplate";
import InfoAlert from "../../../../../components/InfoAlert/InfoAlert";
import {Link} from "react-router-dom";
const Option = Select.Option;

const clusterRoles = [{value: "cluster-admin"}, {value: "admin"}, {value: "edit"}, {value: "view"}];

interface VClusterAccessRulesState {
    selectedUserOrTeam: string | undefined;
    defaultClusterRole: string | undefined;

    rules: Array<StorageV1VirtualClusterAccessRule>;
}

interface AccessObject {
    access?: StorageV1VirtualClusterAccess | undefined;
}

interface VClusterAccessRulesProps {
    vCluster: AccessObject | undefined;

    teams: ClusterV1EntityInfo[];
    users: ClusterV1EntityInfo[];
}

interface VClusterAccessRuleProps {
    selectedUsers: string[] | undefined;
    selectedTeams: string[] | undefined;

    teams: ClusterV1EntityInfo[];
    users: ClusterV1EntityInfo[];

    clusterRole: string | undefined;

    onChange: (e: any) => void;
    onDelete: () => void;
}

function VClusterAccessRule(props: VClusterAccessRuleProps) {
    let user = props.selectedUsers?.length ? props.users.find(u => props.selectedUsers?.[0] === u.name) : props.selectedTeams?.length ? props.teams.find(u => props.selectedTeams?.[0] === u.name) : undefined;
    if (!user) {
        return null;
    }

    return <div className={styles["rule-row"]}>
        <div className={styles["cluster"]}>
            <span className={styles["user"]}>
                <Owner displayName={user.displayName} username={user.username} kubeName={user.name} isTeam={!props.selectedUsers?.length} type={"none"} />
            </span>
        </div>
        <div>
            <AutoComplete placeholder={"Cluster Role..."}
                          value={props.clusterRole}
                          filterOption={() => true}
                          showArrow={true}
                          allowClear={true}
                          onChange={(val) => props.onChange(val)}
                          options={clusterRoles.map(cr => ({label: cr.value, value: cr.value}))} />
        </div>
        <div>
            <DeleteOutlined className={styles["delete-btn"]} onClick={() => props.onDelete()} />
        </div>
    </div>
}

export default class VClusterAccessRules extends React.PureComponent<VClusterAccessRulesProps, VClusterAccessRulesState> {
    state: VClusterAccessRulesState = {
        defaultClusterRole: this.props.vCluster?.access?.defaultClusterRole,
        rules: arr(this.props.vCluster?.access?.rules),

        selectedUserOrTeam: undefined,
    };

    create = async (vCluster: AccessObject): Promise<ResultError> => {
        if (!vCluster.access) {
            vCluster.access = {};
        }
        vCluster.access.defaultClusterRole = this.state.defaultClusterRole;
        vCluster.access.rules = this.state.rules;
        return Return.Ok();
    };

    update = async (vCluster: AccessObject): Promise<ResultError> => {
        if (!vCluster.access) {
            vCluster.access = {};
        }
        vCluster.access.defaultClusterRole = this.state.defaultClusterRole;
        vCluster.access.rules = this.state.rules;
        return Return.Ok();
    };

    selectTemplate = (vClusterTemplate: ManagementV1VirtualClusterTemplate | undefined) => {
        if (!vClusterTemplate) {
            this.setState({
                defaultClusterRole: undefined,
                rules: [],
            });
            return;
        }

        this.setState({
            defaultClusterRole: vClusterTemplate.spec?.template?.access?.defaultClusterRole,
            rules: arr(vClusterTemplate.spec?.template?.access?.rules)
        })
    }

    render() {
        return <React.Fragment>
                <Description>Access rules for this virtual cluster can be defined below. If an access rule matches the user or team, the user will gain all cluster roles that matches the user or the teams the user is part of. If the user got access through an RBAC role and no rule matches, the user will get the fallback cluster role assigned below.</Description>
                <Label>Add Permission to:</Label>
                <div className={styles["cluster-select-wrapper"]}>
                    <Select showSearch
                            allowClear={true}
                            className={styles["select"]}
                            filterOption={selectOwnerFilter}
                            value={this.state.selectedUserOrTeam}
                            placeholder={"User or team"}
                            onChange={value => this.setState({selectedUserOrTeam: value})}>
                        {this.props.users.map(user => <Option key={"user:"+user.name} value={"user:"+user.name}><Owner displayName={user.displayName} username={user.username} kubeName={user.name} className={styles["option"]} isTeam={false} type={"small"} /></Option>)}
                        {this.props.teams.map(team => <Option key={"team:"+team.name} value={"team:"+team.name}><Owner displayName={team.displayName} username={team.username} kubeName={team.name} isTeam={true} className={styles["option"]} type={"small"} /></Option>)}
                    </Select>
                    <PlusOutlined className={styles["plus-btn"]} onClick={async () => {
                        if (!this.state.selectedUserOrTeam) {
                            alert({
                                title: `Please select a User or Team`,
                                content: `Please select a User or Team to add a new rule`,
                            });
                            return;
                        }

                        if (this.state.selectedUserOrTeam.indexOf("user:") === 0) {
                            this.setState({
                                rules: [...this.state.rules, {
                                    users: [this.state.selectedUserOrTeam.substring(5)],
                                    clusterRole: "cluster-admin"
                                }]
                            })
                        } else {
                            this.setState({
                                rules: [...this.state.rules, {
                                    teams: [this.state.selectedUserOrTeam.substring(5)],
                                    clusterRole: "cluster-admin"
                                }]
                            })
                        }
                    }} />
                </div>
                <InfoAlert message={<span>If you cannot find the desired user here, please make sure the user has appropriate <Link to={"/clusters/clusteraccess"}>cluster access</Link></span>} />
                {this.state.rules.length > 0 && <React.Fragment>
                    <div className={styles["rules-header"]}>
                        <div>User</div>
                        <div>Cluster Role</div>
                        <div>&nbsp;</div>
                    </div>
                    <div className={styles["rules-wrapper"]}>
                        {this.state.rules.map((rule, index) => <VClusterAccessRule key={index}
                                                                                   selectedUsers={rule.users}
                                                                                   selectedTeams={rule.teams}
                                                                                   teams={this.props.teams}
                                                                                   users={this.props.users}
                                                                                   clusterRole={rule.clusterRole}
                                                                                    onDelete={() => {
                                                                                        const newRules = [...this.state.rules];
                                                                                        newRules.splice(index, 1);
                                                                                        this.setState({rules: newRules});
                                                                                    }}
                                                                                    onChange={val => {
                                                                                        const newRules = [...this.state.rules];
                                                                                        newRules[index] = {...newRules[index], clusterRole: val}
                                                                                        this.setState({rules: newRules});
                                                                                    }} />)}
                    </div>
                </React.Fragment>}
                <SectionExpander name={"Fallback Cluster Role"}>
                    <Label>Fallback Cluster Role</Label>
                    <AutoComplete placeholder={"cluster-admin"}
                                  value={this.state.defaultClusterRole}
                                  filterOption={() => true}
                                  showArrow={true}
                                  allowClear={true}
                                  onChange={(val) => this.setState({defaultClusterRole: val})}
                                  options={clusterRoles.map(cr => ({label: cr.value, value: cr.value}))} />
                    <Description>The cluster role that will be assigned to the user or team if no other rule matches, but the user or team still got access via RBAC.</Description>
                </SectionExpander>
        </React.Fragment>
    }
}