import React from "react";
import * as Ant from "antd";
import styles from "./Checkbox.module.scss";
import * as AntCheckboxProps from "antd/lib/checkbox";
import {filterResetProps, ResetProps, useReset} from "../../lib/hooks/Reset/reset";
import {without} from "../../lib/helpers/renderhelper";

export interface CheckboxProps extends AntCheckboxProps.CheckboxProps, Omit<ResetProps<boolean>, "value"> {
}

export default function Checkbox(props: CheckboxProps) {
    const {changed, onSelect, onReset} = useReset<boolean>(props, (val) => {
        if (props.onChange) {
            // dirty hack but mostly works
            props.onChange({
                target: {
                    checked: val,
                }
            } as any);
        }
    });
    const classNames = [styles["checkbox"]];
    if (props.className) {
        classNames.push(props.className);
    }
    if (changed) {
        classNames.push(styles["changed"]);
    }

    return <span className={classNames.join(" ")}>
        <Ant.Checkbox {...filterResetProps(without(props, ["className"]))} className={classNames.join(" ")} onSelect={onSelect} onClick={onSelect} />
        {changed && <span className={styles["text"]} onClick={(e) => onReset(e)}>Reset</span>}
    </span>
}
