import React from "react";
import {
    CoffeeOutlined, LockOutlined
} from "@ant-design/icons/lib";
import { useRouteMatch, useHistory } from "react-router-dom";
import Header from "../../../components/Header/Header";

interface Props {
    
}

export default function ProfileHeader(props: React.PropsWithChildren<Props>) {
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1];

    return <Header title={"Profile"} selectedKey={selectedKey} items={[
            {
                key: "profile",
                url: `/profile`,
                    content: <React.Fragment>
                    <CoffeeOutlined />Profile
                </React.Fragment>
            },
            {
                key: "access-keys",
                url: `/profile/access-keys`,
                    content: <React.Fragment>
                    <LockOutlined />Access Keys
                </React.Fragment>
            }
    ]}>{props.children}</Header>
}