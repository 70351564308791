import React from "react";
import {DrawerDispatch, useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import {UserContextState, useUser} from "../../../contexts/UserContext/UserContext";
import styles from "./QuotaTable.module.scss";
import {ToggleColumn, ToggleColumnContent} from "../../../contexts/ToggleColumn/ToggleColumn";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import Owner from "../../../components/Owner/Owner";
import Table, {TableActions} from "../../../components/Table/Table";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {alert, deleteConfirm} from "../../../lib/Modal/Modal";
import ClientMessage from "../../../lib/Message/ClientMessage";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons/lib";
import {arr, getDisplayName} from "../../../lib/helpers/renderhelper";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {ResultError} from "../../../lib/result";
import {ColumnType} from "antd/lib/table";
import QuotaDrawer from "../QuotaDrawer/QuotaDrawer";
import {creationTimestampSorter, stringSorter} from "../../../lib/helpers/sorthelper";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import {ColumnFilterItem} from "antd/es/table/interface";
import {cpuParser, memoryParser} from "../../../lib/helpers/resourcehelper";
import FixedText from "../../../components/FixedText/FixedText";
import {StorageV1ClusterQuota} from "../../../../gen/models/agentstorageV1ClusterQuota";
import {ClusterV1EntityInfo} from "../../../../gen/models/clusterV1EntityInfo";
import {displayName} from "../../../lib/helper";
import {ManagementV1Cluster} from "../../../../gen/models/managementV1Cluster";
import {ClusterV1ClusterQuota} from "../../../../gen/models/clusterV1ClusterQuota";
import {ClusterObject} from "../../Spaces/Spaces/Spaces";

function getTableColumns(refetch: () => Promise<void>, 
                         drawerDispatcher: DrawerDispatch, 
                         user: UserContextState, 
                         isClusterView: boolean, 
                         quotas: ClusterObject<ClusterV1ClusterQuota>[] | undefined,
                         clusters: Array<ManagementV1Cluster> | undefined): Array<ColumnType<ClusterObject<ClusterV1ClusterQuota>>> {
    const editQuota = (quota: ClusterObject<ClusterV1ClusterQuota>) => {
        drawerDispatcher({
            title: "Edit Quota: " + quota.object?.metadata?.name!,
            content: <QuotaDrawer mode={"update"} cluster={quota.cluster!} quota={quota.object} allQuotas={quotas?.map(quota => quota.object!)!} refetch={refetch} />
        })
    };

    const ownerColumn: ColumnType<ClusterObject<ClusterV1ClusterQuota>> = {
        title: <ToggleColumn id={"owner"} columns={['Owner', 'Kubernetes (ID)']}/>,
        fixed: 'left',
        showSorterTooltip: false,
        sorter: (a: ClusterObject<ClusterV1ClusterQuota>, b: ClusterObject<ClusterV1ClusterQuota>) => {
            const aOwner = a.object?.status?.owner;
            const bOwner = b.object?.status?.owner;
            const aValue = aOwner?.user ? getDisplayName(aOwner.user.displayName, aOwner.user.username, aOwner.user.name) : aOwner?.team ? getDisplayName(aOwner.team.displayName, aOwner.team.username, aOwner.team.name) : "none";
            const bValue = bOwner?.user ? getDisplayName(bOwner.user.displayName, bOwner.user.username, bOwner.user.name) : bOwner?.team ? getDisplayName(bOwner.team.displayName, bOwner.team.username, bOwner.team.name) : "none";
            return stringSorter(aValue, bValue);
        },
        render: (quota: ClusterObject<ClusterV1ClusterQuota>) => {
            return <ToggleColumnContent id={"owner"} columns={[
                () => {
                    let owner = quota.object?.status?.owner;
                    if (owner) {
                        if (owner.user) {
                            if (isDisabled(quota)) {
                                return <Tooltip title={"This quota was generated by a Cluster Access object"}>
                                    <span><Owner displayName={owner.user.displayName} username={owner.user.username} kubeName={owner.user.name!} /></span>
                                </Tooltip>;
                            }
                            
                            return <span onClick={() => editQuota(quota)}>
                                <Owner displayName={owner.user.displayName} username={owner.user.username} kubeName={owner.user.name!} type={"clickable"} />
                            </span>;
                        } else if (owner.team) {
                            if (isDisabled(quota)) {
                                return <Tooltip title={"This quota was generated by a Cluster Access object"}>
                                    <span><Owner displayName={owner.team.displayName} username={owner.team.username} kubeName={owner.team.name!} isTeam={true} /></span>
                                </Tooltip>;
                            }
                            
                            return <span onClick={() => editQuota(quota)}>
                                <Owner displayName={owner.team.displayName} username={owner.team.username} kubeName={owner.team.name!} type={"clickable"} isTeam={true} />
                            </span>;
                        }
                    }

                    return <span className={styles["clickable"]} onClick={() => editQuota(quota)}><FixedText maxWidth={130} text={quota.object?.metadata?.name} /></span>;
                },
                () => {
                    if (isDisabled(quota)) {
                        return <Tooltip title={"This quota was generated by a Cluster Access object"}>
                            <span><FixedText maxWidth={130} text={quota.object?.metadata?.name} /></span>
                        </Tooltip>;
                    }
                
                    return <span className={styles["clickable"]} onClick={() => editQuota(quota)}><FixedText maxWidth={130} text={quota.object?.metadata?.name} /></span>;
                }
            ]}/>
        }
    };
    const displayNameCluster = (quota: ClusterObject<ClusterV1ClusterQuota>) => {
        const cluster = arr(clusters).find(cluster => cluster.metadata?.name === quota.cluster);
        return cluster ? displayName(cluster) : quota.cluster;
    }
    const clusterColumn: ColumnType<ClusterObject<ClusterV1ClusterQuota>> = {
        title: 'Cluster',
        filters: !isClusterView ? arr(quotas).map(quota => {
            const cluster = displayNameCluster(quota);
            return {
                text: cluster,
                value: cluster
            };
        }).reduce((self: any, c) => {
            if (arr(self as ColumnFilterItem[]).find(s => s.value === c.value)) {
                return self;
            }

            return [...self, c];
        }, []) : undefined,
        onFilter: !isClusterView ? (value, record: ClusterObject<ClusterV1ClusterQuota>) => displayNameCluster(record) === value : undefined,
        sorter: (a, b) => stringSorter(displayNameCluster(a), displayNameCluster(b)),
        render: (quota: ClusterObject<ClusterV1ClusterQuota>) => {
            return <Link to={`/clusters/details/${quota.cluster!}`} className={styles["cluster"]}><FixedText maxWidth={130} text={displayNameCluster(quota)} /></Link>;
        }
    };
    const createdColumn: ColumnType<ClusterObject<ClusterV1ClusterQuota>> = {
        title: 'Created',
        width: "180px",
        sorter: (a, b) => creationTimestampSorter(a.object, b.object),
        render: (quota: ClusterObject<ClusterV1ClusterQuota>) => {
            return <DynamicTime timestamp={quota.object?.metadata?.creationTimestamp} useTooltip={true}/>
        }
    };
    const actionsColumn: ColumnType<ClusterObject<ClusterV1ClusterQuota>> = {
        title: 'Actions',
        width: "160px",
        fixed: 'right',
        render: (quota: ClusterObject<ClusterV1ClusterQuota>) => {
            if (isDisabled(quota)) {
                return <TableActions>
                    <ShowYamlPopup className={"blue-btn"} canUpdate={false} object={quota.object} cluster={quota.cluster!} resource={Resources.StorageV1ClusterQuota} name={quota.object?.metadata?.name!} refetch={refetch} />
                </TableActions>;
            }
            
            return <TableActions className={styles["actions"]}>
                <Tooltip title="edit">
                    <SettingOutlined className={styles["setting"]} onClick={() => editQuota(quota)} />
                </Tooltip>
                <ShowYamlPopup className={styles["setting"]} object={quota.object} cluster={quota.cluster!} resource={Resources.StorageV1ClusterQuota} name={quota.object?.metadata?.name!} refetch={refetch} />
                <Tooltip title="delete">
                    <DeleteOutlined className={styles["delete"]} onClick={() => {
                        deleteConfirm({
                            title: `Delete Quota: ${quota.object?.metadata?.name}`,
                            content: `Are you sure you want to delete the account quota ${quota.object?.metadata?.name}?`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading(quota.cluster);
                                const result = await client.cluster(quota.cluster!, Resources.StorageV1ClusterQuota).Delete(quota.object?.metadata?.name!);
                                message.Result(result);
                                await refetch();
                            },
                        });
                    }} />
                </Tooltip>
            </TableActions>;
        }
    };

    // generate limits columns based on all limits
    const limitKeys: string[] = [];
    if (quotas?.length) {
        quotas.forEach(quota => {
            if (quota.object?.spec?.quota?.hard) {
                Object.keys(quota.object?.spec?.quota?.hard).forEach(key => limitKeys.indexOf(key) === -1 && limitKeys.push(key));
            }
        });
    }

    limitKeys.sort();
    const limitColumns = limitKeys.map((name, index) => {
        return {
            title: name,
            key: index + "",
            render: (quota: ClusterObject<ClusterV1ClusterQuota>) => {
                if (quota.object?.spec?.quota?.hard && quota.object?.spec?.quota?.hard[name]) {
                    const used = quota.object?.status?.total?.used?.[name] || "0";
                    if (name === "limits.cpu" || name === "requests.cpu") {
                        return <span>{cpuParser(used).toFixed(2)} / {cpuParser(quota.object?.spec?.quota?.hard[name]).toFixed(2)}</span>
                    } else if (name === "limits.memory" || name === "requests.memory") {
                        return <span>{(memoryParser(used) / 1024 / 1024 / 1024).toFixed(2)} / {(memoryParser(quota.object?.spec?.quota?.hard[name]) / 1024 / 1024 / 1024).toFixed(2)} GB</span>
                    }

                    return <span>{used} / {quota.object?.spec?.quota?.hard[name]}</span>;
                }

                return <span>-</span>;
            }
        }
    });

    if (isClusterView) {
        return [
            ownerColumn,
            ...limitColumns,
            createdColumn,
            actionsColumn,
        ];
    }

    return [
        ownerColumn,
        clusterColumn,
        ...limitColumns,
        createdColumn,
        actionsColumn,
    ];
}

function filterOwner(entity: ClusterV1EntityInfo, value: string) {
    return entity.name?.includes(value) || (entity.username && entity.username.includes(value)) || (entity.displayName && entity.displayName.includes(value)) || (entity.email && entity.email.includes(value))
}

function filter(item: ClusterObject<ClusterV1ClusterQuota>, value: string) {
    return (item.object?.metadata?.name?.includes(value) || 
        item.cluster?.includes(value) || 
        (item.object?.status?.owner && item.object?.status?.owner.team && filterOwner(item.object?.status?.owner.team, value)) || 
        (item.object?.status?.owner && item.object?.status?.owner.user && filterOwner(item.object?.status?.owner.user, value)) || 
        item.object?.spec?.user?.includes(value) || item.object?.spec?.team?.includes(value));
}

export interface QuotaTableProps {
    cluster?: string;
    left?: React.ReactNode;
    top?: React.ReactNode;

    error?: ResultError;
    loading: boolean;
    quotas: Array<ClusterObject<ClusterV1ClusterQuota>> | undefined;
    clusters?: Array<ManagementV1Cluster> | undefined;
    refetch: () => Promise<void>;
}

function isDisabled(quota: ClusterObject<ClusterV1ClusterQuota>) {
    return !!quota.object?.metadata?.ownerReferences?.length;
}

export function QuotaTable(props: QuotaTableProps) {
    const userContextState = useUser();
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
        getCheckboxProps: (quota: ClusterObject<ClusterV1ClusterQuota>) => ({
            disabled: isDisabled(quota),
        }),
    };

    const columns = getTableColumns(props.refetch, drawerDispatcher, userContextState, !!props.cluster, props.quotas, props.clusters);
    return <Table className={styles["table"]} loading={props.loading} scroll={columns.length > 6 ? { x: 1600 } : undefined} columns={columns} dataSource={props.quotas ? arr(props.quotas).map(quota => { return {...quota, key: quota.cluster+"/"+quota.object!.metadata!.name!}}) : undefined} error={props.error} rowSelection={rowSelection} filter={filter as any} refetch={props.refetch} header={{
        top: props.top,
        left: props.left,
        right: props.cluster ? <Button type={"primary"} onClick={() => {
            drawerDispatcher({
                title: "Create Quota",
                content: <QuotaDrawer mode={"create"} cluster={props.cluster!} allQuotas={arr(props.quotas).map(quota => quota.object!)} refetch={props.refetch} />
            })
        }}>Create Quota</Button> : undefined,
        selectedActions: <React.Fragment>
            <Tooltip title={"edit"}>
                <SettingOutlined className={styles["setting-batch"]} onClick={() =>
                {
                    let cluster = "";
                    const quotas: StorageV1ClusterQuota[] = [];
                    for (let i = 0; i < selectedRowKeys.length; i++) {
                        const quotaName = selectedRowKeys[i].toString().split("/");
                        const quota = props.quotas!.find(quota => quota.cluster == quotaName[0] && quota.object?.metadata?.name === quotaName[1]);
                        if (!quota) {
                            continue;
                        } else if (!cluster) {
                            cluster = quota.cluster!;
                        } else if (cluster !== quota.cluster) {
                            alert({
                                title: "Alert",
                                content: "Please only select quotas that are in the same cluster!"
                            });

                            return;
                        }

                        quotas.push(quota.object!);
                    }

                    if (quotas.length === 0) {
                        return;
                    }

                    if (quotas.length === 1) {
                        const quota = quotas[0];
                        drawerDispatcher({
                            title: "Edit Quota: " + quota?.metadata?.name!,
                            content: <QuotaDrawer mode={"update"} cluster={cluster} quota={quota} allQuotas={arr(props.quotas).map(quota => quota.object!)} refetch={props.refetch} />
                        })
                    } else {
                        drawerDispatcher({
                            title: "Bulk Edit Selected Quotas",
                            content: <QuotaDrawer mode={"batch"} cluster={cluster} quotas={quotas} allQuotas={arr(props.quotas).map(quota => quota.object!)} refetch={props.refetch} />
                        })
                    }

                    setSelectedRowKeys([]);
                }} />
            </Tooltip>
            <Tooltip title={"delete"}>
                <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                {
                    deleteConfirm({
                        title: `Delete Quotas`,
                        content: `Are you sure you want to delete the quotas ${selectedRowKeys.join(", ")}?`,
                        onOkAsync: async () => {
                            let message: ClientMessage | undefined = undefined;
                            let lastCluster: string = "";

                            for (let i = 0; i < selectedRowKeys.length; i++) {
                                const quotaName = selectedRowKeys[i].toString().split("/");
                                const quota = props.quotas!.find(quota => quota.cluster == quotaName[0] && quota.object?.metadata?.name === quotaName[1]);
                                if (!quota) {
                                    continue;
                                } else if (!message) {
                                    message = ClientMessage.Loading(quota.cluster);
                                } else {
                                    message.Loading(quota.cluster);
                                }

                                const result = await client.cluster(quota.cluster!, Resources.StorageV1ClusterQuota).Delete(quota.object?.metadata?.name!);
                                if (result.err) {
                                    message.Error(result);
                                    return;
                                }

                                lastCluster = quota.cluster!;
                            }

                            message?.DoneCluster(lastCluster);
                            await props.refetch();
                            setSelectedRowKeys([]);
                        },
                    });
                }} />
            </Tooltip>
        </React.Fragment>
    }} />
}
