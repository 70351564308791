

export const PodYaml = `apiVersion: v1
kind: Pod
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  containers:
  - name: nginx
    image: nginx
    ports:
    - containerPort: 80`;
export const DeploymentYaml = `apiVersion: apps/v1
kind: Deployment
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
  labels:
    app: nginx
spec:
  replicas: 1
  selector:
    matchLabels:
      app: nginx
  template:
    metadata:
      labels:
        app: nginx
    spec:
      containers:
      - name: nginx
        image: nginx
        ports:
        - containerPort: 80`;
export const ServiceYaml = `apiVersion: v1
kind: Service
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  selector:
    app: nginx
  ports:
    - protocol: TCP
      port: 80
      targetPort: 80`;
export const ServiceAccountYaml = `apiVersion: v1
kind: ServiceAccount
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}`;
export const SecretYaml = `apiVersion: v1
data:
  username: YWRtaW4=
  password: YWRtaW4=
kind: Secret
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
type: Opaque`;
export const ConfigMapYaml = `apiVersion: v1
kind: ConfigMap
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
data:
  player_initial_lives: "3"`;
export const LimitRangeYaml = `apiVersion: v1
kind: LimitRange
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  limits:
  - max:
      cpu: "800m"
    min:
      cpu: "200m"
    type: Container`;
export const EndpointsYaml = `apiVersion: v1
kind: Endpoints
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
subsets:
  - addresses:
      - ip: 192.0.2.42
    ports:
      - port: 9376`;
export const PersistentVolumeClaimYaml = `apiVersion: v1
kind: PersistentVolumeClaim
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  storageClassName: manual
  accessModes:
    - ReadWriteOnce
  resources:
    requests:
      storage: 1Gi`;
export const StatefulSetYaml = `apiVersion: apps/v1
kind: StatefulSet
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  selector:
    matchLabels:
      app: nginx 
  serviceName: "nginx"
  replicas: 1
  minReadySeconds: 10 
  template:
    metadata:
      labels:
        app: nginx
    spec:
      terminationGracePeriodSeconds: 10
      containers:
      - name: nginx
        image: nginx
        ports:
        - containerPort: 80
          name: web
        volumeMounts:
        - name: www
          mountPath: /usr/share/nginx/html
  volumeClaimTemplates:
  - metadata:
      name: www
    spec:
      accessModes: [ "ReadWriteOnce" ]
      storageClassName: "standard"
      resources:
        requests:
          storage: 1Gi`;
export const IngressYaml = `apiVersion: networking.k8s.io/v1
kind: Ingress
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
  annotations:
    nginx.ingress.kubernetes.io/rewrite-target: /
spec:
  ingressClassName: nginx-example
  rules:
  - http:
      paths:
      - path: /testpath
        pathType: Prefix
        backend:
          service:
            name: test
            port:
              number: 80`;
export const ReplicationControllerYaml = `apiVersion: v1
kind: ReplicationController
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  replicas: 1
  selector:
    app: nginx
  template:
    metadata:
      name: nginx
      labels:
        app: nginx
    spec:
      containers:
      - name: nginx
        image: nginx
        ports:
        - containerPort: 80`;
export const DaemonSetYaml = `apiVersion: apps/v1
kind: DaemonSet
metadata:
  name: {RESOURCE_NAME}
  namespace: kube-system
  labels:
    k8s-app: fluentd-logging
spec:
  selector:
    matchLabels:
      name: fluentd-elasticsearch
  template:
    metadata:
      labels:
        name: fluentd-elasticsearch
    spec:
      tolerations:
      # these tolerations are to have the daemonset runnable on control plane nodes
      # remove them if your control plane nodes should not run pods
      - key: node-role.kubernetes.io/control-plane
        operator: Exists
        effect: NoSchedule
      - key: node-role.kubernetes.io/master
        operator: Exists
        effect: NoSchedule
      containers:
      - name: fluentd-elasticsearch
        image: quay.io/fluentd_elasticsearch/fluentd:v2.5.2
        resources:
          limits:
            memory: 200Mi
          requests:
            cpu: 100m
            memory: 200Mi
        volumeMounts:
        - name: varlog
          mountPath: /var/log
        - name: varlibdockercontainers
          mountPath: /var/lib/docker/containers
          readOnly: true
      terminationGracePeriodSeconds: 30
      volumes:
      - name: varlog
        hostPath:
          path: /var/log
      - name: varlibdockercontainers
        hostPath:
          path: /var/lib/docker/containers`;
export const ResourceQuotaYaml = `apiVersion: v1
kind: ResourceQuota
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  hard:
    requests.cpu: "1"
    requests.memory: 1Gi
    limits.cpu: "2"
    limits.memory: 2Gi`;
export const NetworkPolicyYaml = `apiVersion: networking.k8s.io/v1
kind: NetworkPolicy
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  podSelector:
    matchLabels:
      role: db
  policyTypes:
    - Ingress
    - Egress
  ingress:
    - from:
        - ipBlock:
            cidr: 172.17.0.0/16
            except:
              - 172.17.1.0/24
        - namespaceSelector:
            matchLabels:
              project: myproject
        - podSelector:
            matchLabels:
              role: frontend
      ports:
        - protocol: TCP
          port: 6379
  egress:
    - to:
        - ipBlock:
            cidr: 10.0.0.0/24
      ports:
        - protocol: TCP
          port: 5978`;
export const PodDisruptionBudgetYaml = `apiVersion: policy/v1
kind: PodDisruptionBudget
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  minAvailable: 2
  selector:
    matchLabels:
      app: zookeeper`;
export const RoleYaml = `apiVersion: rbac.authorization.k8s.io/v1
kind: Role
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
rules:
- apiGroups: [""] # "" indicates the core API group
  resources: ["pods"]
  verbs: ["get"]`;
export const RoleBindingYaml = `apiVersion: rbac.authorization.k8s.io/v1
# This role binding allows "jane" to read pods in the "default" namespace.
# You need to already have a Role named "pod-reader" in that namespace.
kind: RoleBinding
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
subjects:
# You can specify more than one "subject"
- kind: User
  name: jane # "name" is case sensitive
  apiGroup: rbac.authorization.k8s.io
roleRef:
  # "roleRef" specifies the binding to a Role / ClusterRole
  kind: Role #this must be Role or ClusterRole
  name: pod-reader # this must match the name of the Role or ClusterRole you wish to bind to
  apiGroup: rbac.authorization.k8s.io`;
export const ReplicaSetYaml = `apiVersion: apps/v1
kind: ReplicaSet
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
  labels:
    app: guestbook
    tier: nginx
spec:
  # modify replicas according to your case
  replicas: 1
  selector:
    matchLabels:
      tier: nginx
  template:
    metadata:
      labels:
        tier: nginx
    spec:
      containers:
      - name: nginx
        image: nginx`;
export const CronJobYaml = `apiVersion: batch/v1
kind: CronJob
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  schedule: "* * * * *"
  jobTemplate:
    spec:
      template:
        spec:
          containers:
          - name: hello
            image: busybox:1.28
            imagePullPolicy: IfNotPresent
            command:
            - /bin/sh
            - -c
            - date; echo Hello from the Kubernetes cluster
          restartPolicy: OnFailure`;
export const JobYaml = `apiVersion: batch/v1
kind: Job
metadata:
  name: {RESOURCE_NAME}
  namespace: {RESOURCE_NAMESPACE}
spec:
  template:
    spec:
      containers:
      - name: pi
        image: perl
        command: ["perl",  "-Mbignum=bpi", "-wle", "print bpi(2000)"]
      restartPolicy: Never
  backoffLimit: 4
`;
export const GenericYaml = `apiVersion: {RESOURCE_VERSION}
kind: {RESOURCE_KIND}
metadata:
  name: {RESOURCE_NAME}
...`;