import React from "react";
import styles from "./Item.module.scss";
import {Link} from "react-router-dom";

export interface ItemProps {
    className?: string;

    type?: "link";
    to?: string;
}

export function Item(props: React.PropsWithChildren<ItemProps>) {
    const classNames = [styles["item"]];
    if (props.className) {
        classNames.push(props.className);
    }
    if (props.type === "link" && props.to) {
        return <Link className={classNames.join(" ")} to={props.to}>{props.children}</Link>
    }
    
    if (typeof props.children !== "string") {
        return props.children as any;
    }
    
    return <span className={classNames.join(" ")}>{props.children}</span>
}