import Icon, { AppstoreAddOutlined } from '@ant-design/icons';
import {
    BlockOutlined,
    CloseOutlined,
    CloudOutlined,
    CoffeeOutlined,
    LockOutlined,
    SearchOutlined,
    ToolOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons/lib';
import { Avatar, Button, Layout, Popover, Tooltip } from 'antd';
import React, {useContext, useState} from 'react';
import {Link, RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import AppRoutes from '../../AppRoutes';
import constants from '../../constants/constants';
import DrawerProvider, { SetDrawer } from '../../contexts/drawer/DrawerContext';
import { useUser } from '../../contexts/UserContext/UserContext';
import LoftLogo from '../../images/loft-logo.svg';
import client from '../../lib/client';
import {arr, getDisplayName, selectDefaultFilter} from '../../lib/helpers/renderhelper';
import { Resources } from '../../lib/resources';
import { Dock } from '../Dock/Dock';
import DockContextProvider from '../Dock/DockContext';
import Announcement from '../Announcement/Announcement';
import AsyncButton from '../AsyncButton/AsyncButton';
import ApplyManifestsDrawer from '../Drawer/ApplyManifestsDrawer/ApplyManifestsDrawer';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import ControlFree from '../Icons/ControlFree';
import VCluster from '../Icons/VCluster';
import Query from '../Query/Query';
import SideMenu from '../SideMenu/SideMenu';
import styles from './BaseLayout.module.scss';
import * as LoftButton from '../Button/Button';
import {
    addFavouriteRoute,
    Breadcrumb,
    FavouritesContext,
    FavouritesProvider,
    removeFavouriteRoute
} from "../Breadcrumb/Breadcrumb";
import * as H from "history";
import Select from "../Select/Select";
import {stringSorter} from "../../lib/helpers/sorthelper";
const {Option} = Select;

const { Header, Sider, Content, Footer } = Layout;

interface FavouriteRouteSelect {
    location: H.Location<any>;
}

function FavouriteRouteSelect(props: FavouriteRouteSelect) {
    const history = useHistory();
    const favouriteRoutes = useContext(FavouritesContext);
    const favouriteRoute = favouriteRoutes?.state.favouriteRoutes.find(route => route.key === props.location.pathname);
    return <Select showSearch
                   placeholder={"Jump to favourite route..."}
                   className={styles["favourite-route-select"]}
                   dropdownClassName={styles["favourite-route-overlay"]}
                   optionFilterProp="children"
                   filterOption={selectDefaultFilter}
                   value={favouriteRoute?.key || undefined}
                   onChange={value => {
                       const route = favouriteRoutes?.state.favouriteRoutes.find(route => route.key === value);
                       if (route) {
                           history.push(route.url);
                       }
                   }}>
        {arr(favouriteRoutes?.state.favouriteRoutes).sort((a, b) => stringSorter(a.name, b.name)).map(route => {
            return <Option key={route.key} value={route.key} className={styles["favourite-route-option"]}>{route.name}</Option>
        })}
        {favouriteRoute ? 
            <Option key={"deselect"} value={"deselect"} disabled={true} className={styles["favourite-route-select-mark-wrapper"]}>
                <LoftButton.default type={"primary"}
                                    className={styles["favourite-route-select-mark"]} 
                                    onClick={() => removeFavouriteRoute(favouriteRoutes, props.location.pathname)}>Unmark Current Location as Favourite</LoftButton.default>
            </Option> 
            : 
            <Option key={"select"} value={"select"} disabled={true} className={styles["favourite-route-select-mark-wrapper"]}>
                <LoftButton.default type={"primary"}
                                    className={styles["favourite-route-select-mark"]} 
                                    onClick={() => addFavouriteRoute(favouriteRoutes, props.location.pathname)}>Mark Current Location as Favourite</LoftButton.default>
            </Option>}
    </Select>
}

interface Props {}

interface ImpersonationBannerProps {
    name: string;
}

function ImpersonationBanner(props: ImpersonationBannerProps) {
    return <div className={styles["impersonation-banner"]}>
        <span className={styles["impersonation-text"]}>
            <UserSwitchOutlined className={styles["impersonation-icon"]} />You are impersonating <span className={styles["impersonation-name"]}>{props.name}</span>
        </span>
        <span className={styles["impersonation-link"]} onClick={() => {
            client.impersonate();
            window.location.reload();
        }}>Stop Impersonation <CloseOutlined /></span>
    </div>
}

function HeaderAvatar() {
    const user = useUser();
    const [visible, setVisible] = useState<boolean>(false);

    return <div className={styles["avatar"]}>
        <Popover placement="bottomRight" trigger="click" visible={visible} onVisibleChange={v => setVisible(v)} content={<div className={styles["popover"]}>
            <div className={styles["signed-in-as-wrapper"]}>
                <div>Signed in as</div>
                <div className={styles["signed-in-as"]}>
                    <div>{getDisplayName(user?.spec?.displayName, user?.spec?.username, user?.metadata?.name)} ({user?.spec?.username})</div>
                </div>
            </div>
            <div>
                <Link to={"/profile"}>
                    <Button className={styles["popover-btn"]} onClick={() => {
                        setVisible(false);
                    }}>Profile</Button>
                </Link>
            </div>
            <div className={styles["popover-last-btn"]}>
                <a href={"https://loft.sh/docs/introduction"} target="_blank">
                    <Button className={styles["popover-btn"]} onClick={() => {
                        setVisible(false);
                    }}>Docs</Button>
                </a>
            </div>
            <div className={styles["popover-last-btn"]}>
                <AsyncButton className={styles["popover-btn"]} onClickAsync={async () => {
                    if (client.impersonatedUser()) {
                        client.impersonate();
                        window.location.reload();
                    } else {
                        await client.logout();
                        window.location.href = "/";
                    }
                }} type={"primary"}>Logout</AsyncButton>
            </div>
        </div>}>
            {user?.spec?.icon ? <Avatar shape="square" size={42} src={user.spec.icon} /> : <Avatar shape="square" size={42} icon={<UserOutlined />} />}
        </Popover>
    </div>
}

function BaseLayout(props: RouteComponentProps<Props>) {
    const user = useUser();
    return <Layout style={{ minWidth: "1300px" }}>
        {
            client.impersonatedUser() && <ImpersonationBanner name={client.impersonatedUser()?.name!} />
        }
        <Header className={styles["header"]}>
            <div className={styles["logo-wrapper"]}>
                <Icon component={LoftLogo as any} className={styles["logo"]} />
                <Query query={async () => {
                    const versionResult = await client.loftVersion();
                    if (versionResult.err) {
                        return versionResult;
                    }

                    const version = versionResult.val;
                    if (version.instance && version.kubeVersion) {
                        const w = window as any;
                        if (!w.loft) {
                            w.loft = {}
                        }

                        w.loft.version = version.version;
                        w.loft.instance = version.instance;
                        w.loft.kubeVersion = version.kubeVersion;
                        w.loft.user = user;
                    }

                    return versionResult;
                }}>
                    {
                        result => {
                            if (result.error) {
                                return null;
                            } else if (result.loading) {
                                return null;
                            } else if (result.data?.shouldUpgrade) {
                                return <React.Fragment>
                                    <Tooltip title={result.data?.version}>
                                        <div className={styles["version"] + " " + styles["upgrade"]}>v{result.data?.major}.{result.data?.minor}</div>
                                    </Tooltip>
                                    <Query query={async () => await client.management(Resources.ManagementV1LoftUpgrade).CanI("create")}>
                                        {
                                            canIResult => {
                                                if (canIResult.loading || canIResult.error || !canIResult.data) {
                                                    return <a href={`https://loft.sh/upgrade/${result.data?.newerVersion}`} target={"_blank"} className={styles["upgrade-now"]}>Upgrade now</a>;
                                                }

                                                return <Link to={`/admin/upgrade`} className={styles["upgrade-now"]}>Upgrade now</Link>
                                            }
                                        }
                                    </Query>
                                </React.Fragment>
                            }

                            return <Tooltip title={result.data?.version}>
                                <div className={styles["version"]}>v{result.data?.major}.{result.data?.minor}</div>
                            </Tooltip>
                        }
                    }
                </Query>
            </div>
            <Announcement />
            <HeaderAvatar />
        </Header>
        <Layout className={styles["base"]}>
            <Sider className={styles["sider"]} theme={'light'} width={170}>
                <SideMenu mapping={{ "/vclustertemplates": "/vclusters", "/spacetemplates": "/spaces" }} items={[
                    {
                        icon: BlockOutlined,
                        link: "/spaces",
                        name: "Spaces"
                    },
                    {
                        icon: VCluster,
                        link: "/vclusters",
                        name: "Virtual Clusters"
                    },
                    {
                        icon: CloudOutlined,
                        link: "/clusters",
                        name: "Clusters"
                    },
                    {
                        icon: AppstoreAddOutlined,
                        link: "/apps",
                        name: "Apps"
                    },
                    {
                        icon: LockOutlined,
                        link: "/secrets",
                        name: "Secrets"
                    },
                    {
                        icon: ControlFree as any,
                        link: "/quotas",
                        name: "Quotas"
                    },
                    {
                        icon: UserOutlined,
                        link: "/users",
                        name: "Users"
                    },
                    {
                        icon: SearchOutlined,
                        link: "/audit",
                        name: "Audit"
                    },
                    {
                        icon: ToolOutlined,
                        link: "/admin",
                        name: "Admin",
                        admin: true
                    },
                    {
                        icon: CoffeeOutlined,
                        link: "/profile",
                        name: "Profile"
                    },
                ]} />
            </Sider>
            <Layout className={styles["content-wrapper"]}>
                <DockContextProvider>
                    <Dock>
                        <DrawerProvider>
                            <Layout id={constants.Content} className={styles["content"]} style={{ padding: '0 24px 24px' }}>
                                <div className={styles["breadcrumb-wrapper"]}>
                                    <FavouritesProvider>
                                        <Breadcrumb location={props.location} />
                                        <div className={styles["apply-manifests-wrapper"]}>
                                            <FavouriteRouteSelect location={props.location} />
                                            <Button type={"primary"} className={styles["apply-manifests"]} onClick={() => {
                                                SetDrawer({
                                                    title: "Apply Manifests",
                                                    content: <ApplyManifestsDrawer mode={"create"} />,
                                                    noPadding: true
                                                });
                                            }}>Apply Manifests</Button>
                                        </div>
                                    </FavouritesProvider>
                                </div>
                                <Content
                                    className="site-layout-background"
                                    style={{ margin: 0 }}>
                                    <ErrorBoundary>
                                        <AppRoutes />
                                    </ErrorBoundary>
                                </Content>
                            </Layout>
                        </DrawerProvider>
                    </Dock>
                </DockContextProvider>
            </Layout>
        </Layout>
    </Layout>
}

export default withRouter(BaseLayout);