import React from "react";
import {StorageV1Access} from "../../../../../gen/models/storageV1Access";
import {SectionProps} from "../../ItemDrawer";
import Select from "../../../Select/Select";
import {arr, selectDefaultFilter, selectOwnerFilter} from "../../../../lib/helpers/renderhelper";
import {ResultError, Return} from "../../../../lib/result";
import Section from "../../Section/Section";
import Query from "../../../Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {ErrorMessage} from "../../../ErrorMessage/ErrorMessage";
import Loading from "../../../Loading/Loading";
import styles from "./Access.module.scss";
import Owner from "../../../Owner/Owner";
import {ManagementV1Team} from "../../../../../gen/models/managementV1Team";
import {ManagementV1User} from "../../../../../gen/models/managementV1User";
import Label from "../../../Label/Label";
import Button from "../../../Button/Button";
import Description from "../../../Description/Description";
import {PlusOutlined} from "@ant-design/icons";
import {ClusterV1EntityInfo} from "../../../../../gen/models/clusterV1EntityInfo";
import {V1ObjectMeta} from "../../../../../gen/models/V1ObjectMeta";
const { Option } = Select;

const defaultVerbMapping: VerbMapping[] = [
    {
        key: "*",
        text: "All"
    },
    {
        key: "get",
        text: "view"
    },
    {
        key: "update",
        text: "update"
    },
    {
        key: "delete",
        text: "delete"
    },
    {
        key: "bind",
        text: "bind"
    },
]

export interface VerbMapping {
    key: string;
    text: string;
}

interface UserOrTeamProps {
    selectedUsers: string[] | undefined;
    selectedTeams: string[] | undefined;
    teams: ClusterV1EntityInfo[];
    users: ClusterV1EntityInfo[];

    onChange: (entities: string[]) => void;
}

export function UserOrTeam(props: UserOrTeamProps) {
    return <Select showSearch
            className={styles["select"]}
            filterOption={selectOwnerFilter}
            value={[...arr(props.selectedUsers).map(u => u === "*" ? "*" : "user:"+u), ...arr(props.selectedTeams).map(t => t === "*" ? "*" : "team:"+t)]}
            mode={"multiple"}
            placeholder={"Users or teams"}
            onChange={value => props.onChange(value)}>
        <Option key={"*"} value={"*"}><Owner displayName={"Everybody"} username={"Everybody"} kubeName={"Everybody"} hideIcon={false} isTeam={true} className={styles["option"]} type={"small"} /></Option>
        {props.users.map(user => <Option key={"user:"+user.name} value={"user:"+user.name}><Owner displayName={user.displayName} username={user.username} kubeName={user.name} className={styles["option"]} isTeam={false} type={"small"} /></Option>)}
        {props.teams.map(team => <Option key={"team:"+team.name} value={"team:"+team.name}><Owner displayName={team.displayName} username={team.username} kubeName={team.name} isTeam={true} className={styles["option"]} type={"small"} /></Option>)}
    </Select>
}

export function toEntity(obj: ManagementV1User | ManagementV1Team): ClusterV1EntityInfo {
    return {
        'displayName': obj.spec?.displayName,
        'email': (obj as any).spec?.email,
        'name': obj.metadata?.name,
        'subject': (obj as any).spec?.subject,
        'username': obj.spec?.username
    }
}

interface AccessRuleProps {
    index: number;
    rule: StorageV1Access;
    
    allowedVerbs: VerbMapping[];
    teams: ManagementV1Team[];
    users: ManagementV1User[];

    onChange: (entities: string[]) => void;
    onChangeVerbs: (verb: string[]) => void;
}

function AccessRule(props: AccessRuleProps) {
    return <div className={styles["access-rule"]}>
        <UserOrTeam selectedUsers={props.rule.users}
                    selectedTeams={props.rule.teams}
                    teams={props.teams.map(t => toEntity(t))}
                    users={props.users.map(t => toEntity(t))}
                    onChange={props.onChange} />
        <Select showSearch
                className={styles["select"]}
                filterOption={selectDefaultFilter}
                value={arr(props.rule.verbs)}
                mode={"multiple"}
                placeholder={"Allowed verbs"}
                onChange={value => props.onChangeVerbs(value)}>
            {props.allowedVerbs.map(verb => <Option key={verb.key} value={verb.key}>{verb.text}</Option>)}
        </Select>
    </div>
}

interface AccessState {
    rules: StorageV1Access[];
}

interface AccessProps extends SectionProps {
    user: ManagementV1User | undefined;
    kind: string;
    
    access?: AccessObject;
    
    defaultRules?: StorageV1Access[];
    allowedVerbs?: VerbMapping[];
}

interface AccessObject {
    spec?: {
        access?: Array<StorageV1Access> | undefined;
    }
}

const defaultRules = (props: AccessProps) => {
    if (arr(props.access?.spec?.access).length > 0) {
        return props.access?.spec?.access!;
    } else if (props.defaultRules) {
        return props.defaultRules;
    } else if (props.mode === "create") {
        return [{users: [props.user?.metadata?.name!], verbs: ["*"], subresources: ["*"]}];
    }
    
    return [{verbs: [], users: [], teams: [], subresources: ["*"]}];
}

export default class Access extends React.PureComponent<AccessProps, AccessState> {
    state: AccessState = {
        rules: defaultRules(this.props)
    };

    create = async (access: AccessObject): Promise<ResultError> => {
        return this.update(access);
    };

    update = async (access: AccessObject): Promise<ResultError> => {
        if (!access.spec) {
            access.spec = {}
        }
        access.spec.access = arr(this.state.rules).filter(rule => arr(rule.verbs).length > 0 && (arr(rule.users).length > 0 || arr(rule.teams).length));
        return Return.Ok();
    };

    batch = async (accessObjects: Array<AccessObject>): Promise<ResultError> => {
        for (let i = 0; i < accessObjects.length; i++) {
            const result = await this.update(accessObjects[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    };

    render() {
        const verbMapping = this.props.allowedVerbs || defaultVerbMapping;
        return <Section title={`Configure Access To This ${this.props.kind}`} defaultFolded={true} foldable={true}>
            <Query query={async () => {
                const userResult = await client.management(Resources.ManagementV1User).List();
                if (userResult.err) {
                    return userResult;
                }
                
                const teamResult = await client.management(Resources.ManagementV1Team).List();
                if (teamResult.err) {
                    return teamResult;
                }

                return Return.Value({
                    users: arr(userResult.val.items),
                    teams: arr(teamResult.val.items)
                });
            }}>
                {
                    queryResult => {
                        if (queryResult.error) {
                            return <ErrorMessage error={queryResult.error} />;
                        } else if (queryResult.loading) {
                            return <Loading />;
                        }

                        return <div className={styles["access-rules"]}>
                            <Label>Who can view and modify the values of the form fields shown above and have access to the underlying {this.props.kind} object?</Label>
                            <div className={styles["access-rule-header"]}>
                                <div>Users & Teams</div>
                                <div>Permissions</div>
                            </div>
                            {this.state.rules.map((rule, index) => <AccessRule key={index} 
                                                                               index={index} 
                                                                               rule={rule} 
                                                                               users={arr(queryResult.data?.users)}
                                                                               teams={arr(queryResult.data?.teams)}
                                                                               allowedVerbs={verbMapping}
                                                                               onChange={entities => {
                                                                                   const newRules = [...this.state.rules];
                                                                                   let users = [];
                                                                                   let teams = [];
                                                                                   for (let i = 0; i < arr(entities).length; i++) {
                                                                                       if (entities[i] === "*") {
                                                                                           if (newRules[index].users?.find(u => u === "*") || newRules[index].teams?.find(u => u === "*")) {
                                                                                               continue;
                                                                                           } else {
                                                                                               users = ["*"];
                                                                                               teams = [];
                                                                                               break;
                                                                                           }
                                                                                       }
                                                                                       
                                                                                       const splitted = entities[i].split(":");
                                                                                       if (splitted.length !== 2) {
                                                                                           continue
                                                                                       }
                                                                                       
                                                                                       if (splitted[0] === "user") {
                                                                                           users.push(splitted[1]);
                                                                                       } else {
                                                                                           teams.push(splitted[1]);
                                                                                       }
                                                                                   }

                                                                                   newRules[index] = {...newRules[index], users, teams};
                                                                                   this.setState({rules: newRules});
                                                                               }}
                                                                               onChangeVerbs={newVerbs => {
                                                                                   const newRules = [...this.state.rules];
                                                                                   let verbs = [];
                                                                                   for (let i = 0; i < arr(newVerbs).length; i++) {
                                                                                       if (newVerbs[i] === "*") {
                                                                                           if (newRules[index].verbs?.find(u => u === "*")) {
                                                                                               continue;
                                                                                           } else {
                                                                                               verbs = ["*"];
                                                                                               break;
                                                                                           }
                                                                                       }
                                                                                       verbs.push(newVerbs[i]);
                                                                                   }
                                                                                   
                                                                                   newRules[index] = {...newRules[index], verbs};
                                                                                   this.setState({rules: newRules});
                                                                               }} />)}
                            <Button type={"primary"} onClick={() => this.setState({rules: [...this.state.rules, {subresources: ["*"]} as any]})}><PlusOutlined />Rule</Button>
                            <Description>Each rule defines a user / team mapping to what is allowed on this {this.props.kind} object. Leave users or verbs empty to delete a rule. For more information please take a look at the <a href={"https://loft.sh/docs"} target={"_blank"}>loft documenation</a>.</Description>
                        </div>
                    }
                }
            </Query>
        </Section>
    }
}