import React from "react";
import {MessageType} from "antd/es/message";
import {Err, Failed, Result} from "../result";
import Message from "./Message";
import {ClusterBasePath, ManagementBasePath, RequestOptionsVCluster, VClusterBasePath} from "../client";
import styles from "./ClientMessage.module.scss";

class ClientMessage {
    public static IsClientError(result: Err<Failed>): boolean {
        return result.extra && result.extra.basePath;
    }

    public static Loading(cluster?: string, vCluster?: RequestOptionsVCluster): ClientMessage {
        let message = new ClientMessage();
        if (vCluster) {
            message.LoadingVCluster(vCluster.name);
        } else {
            message.Loading(cluster);
        }
        return message;
    }

    public static LoadingVCluster(vCluster?: string): ClientMessage {
        let message = new ClientMessage();
        message.LoadingVCluster(vCluster);
        return message;
    }

    public static Error(result: Err<Failed>): ClientMessage {
        let message = new ClientMessage();
        message.Error(result);
        return message;
    }

    private readonly key: number;
    constructor(key = Math.random() * 1000000) {
        this.key = key;
    }

    public Loading(cluster?: string): MessageType {
        const msg = !cluster ? "Updating state in loft" : <React.Fragment>Updating state in cluster <span style={{fontWeight: 500}}>{cluster}</span></React.Fragment>;
        return new Message(this.key).Loading(msg);
    }

    public LoadingVCluster(vCluster?: string): MessageType {
        const msg = !vCluster ? "Updating state in loft" : <React.Fragment>Updating state in vcluster <span style={{fontWeight: 500}}>{vCluster}</span></React.Fragment>;
        return new Message(this.key).Loading(msg);
    }

    public ResultAuto<T>(result: Result<T>, cluster: string | undefined, vCluster: RequestOptionsVCluster | undefined) {
        if (result.err) {
            this.ErrorAuto(result, cluster, vCluster);
        } else {
            this.DoneAuto(cluster, vCluster);
        }
    }

    public Result<T>(result: Result<T>) {
        if (result.err) {
            this.Error(result);
        } else {
            this.Done(result);
        }
    }

    public DoneAuto(cluster: string | undefined, vCluster: RequestOptionsVCluster | undefined) {
        return cluster ? this.DoneCluster(cluster) : vCluster ? this.DoneVCluster(vCluster) : this.DoneManagement();
    }

    public DoneVCluster(vCluster: RequestOptionsVCluster) {
        return new Message(this.key).Info(<React.Fragment>State saved in vCluster <span style={{fontWeight: 600}}>{vCluster.name}</span></React.Fragment>);
    }

    public DoneCluster(cluster: string) {
        return new Message(this.key).Info(<React.Fragment>State saved in cluster <span style={{fontWeight: 600}}>{cluster}</span></React.Fragment>);
    }

    public DoneManagement() {
        return new Message(this.key).Info("State saved in loft");
    }

    public Done<T>(result: Result<T>) {
        let msg: React.ReactNode = "State saved in loft";
        if (result.extra && result.extra.basePath) {
            if (result.extra.basePath !== ManagementBasePath) {
                msg = <React.Fragment>State saved in cluster <span style={{fontWeight: 600}}>{result.extra.basePath.substring(ClusterBasePath.length)}</span></React.Fragment>
            }
        }

        return new Message(this.key).Info(msg);
    }

    public ErrorAuto(result: Err<Failed>, cluster: string | undefined, vCluster: RequestOptionsVCluster | undefined) {
        return cluster ? this.ErrorCluster(result, cluster) : vCluster ? this.ErrorVCluster(result, vCluster) : this.ErrorManagement(result);
    }

    public ErrorVCluster(result: Err<Failed>, vCluster: RequestOptionsVCluster) {
        const msg = <React.Fragment>
            Failed to save state in vCluster <span style={{fontWeight: 600}}>{vCluster.name}</span><span className={styles["show-details"]}>(show details)</span>
        </React.Fragment>;
        return new Message(this.key).Error(msg, result.val, <React.Fragment>Failed to save state in vCluster <span style={{fontWeight: 600}}>{vCluster.name}</span></React.Fragment>);
    }

    public ErrorCluster(result: Err<Failed>, cluster: string) {
        const msg = <React.Fragment>
            Failed to save state in cluster <span style={{fontWeight: 600}}>{cluster}</span><span className={styles["show-details"]}>(show details)</span>
        </React.Fragment>;
        return new Message(this.key).Error(msg, result.val, <React.Fragment>Failed to save state in cluster <span style={{fontWeight: 600}}>{cluster}</span></React.Fragment>);
    }

    public ErrorManagement(result: Err<Failed>) {
        const msg = <React.Fragment>
            Failed to save state in loft <span className={styles["show-details"]}>(show details)</span>
        </React.Fragment>;
        return new Message(this.key).Error(msg, result.val, "Failed to save state in loft");
    }

    public Error(result: Err<Failed>) {
        if (result.extra && result.extra.basePath) {
            if (result.extra.basePath !== ManagementBasePath) {
                return this.ErrorCluster(result, result.extra.basePath.substring(ClusterBasePath.length));
            }
        }

        return this.ErrorManagement(result);
    }
}

export default ClientMessage;