import React, {ErrorInfo} from "react";
import styles from "./ErrorBoundary.module.scss";
import {Alert} from "antd";

interface Props {
    children: React.ReactNode;
}

interface State {
    error?: any;
}

class ErrorBoundary extends React.Component<Props, State> {
    state: State = {};

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.error) {
            return <Alert message={"An error has occurred: " + this.state.error} className={styles["error"]} type="error" showIcon />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;