import React, {useState} from "react";
import { useRouteMatch } from "react-router-dom";
import AppsTable, {useApps} from "../../../Clusters/Details/Apps/AppsTable/AppsTable";
import Label from "../../../../components/Label/Label";
import AppsList from "../../../Clusters/Details/Overview/AppsList/AppsList";
import styles from "../../../Clusters/Details/Overview/Overview.module.scss";
import VClusterHeader from "../VClusterHeader/VClusterHeader";

export default function Apps() {
    const match = useRouteMatch();
    const {cluster, namespace, vcluster} = match.params as any;
    const vCluster = {
        cluster,
        namespace,
        name: vcluster
    };

    const appsResult = useApps(cluster, namespace, vCluster);
    return <AppsTable query={appsResult} cluster={cluster} namespace={namespace} vCluster={vCluster} header={<VClusterHeader>
        <Label.Bold>Apps</Label.Bold>
        <div>Click on an app to install or upgrade it.</div>
        <AppsList type={"virtualcluster"} query={appsResult} className={styles["apps-list"]} cluster={cluster} namespace={namespace} vCluster={vCluster} />
    </VClusterHeader>} />
};