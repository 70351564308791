import React from "react";
import {ManagementV1PolicyViolation} from "../../../../gen/models/managementV1PolicyViolation";
import {stringSorter} from "../../../lib/helpers/sorthelper";
import FixedText from "../../../components/FixedText/FixedText";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import {arr, getDisplayName, removeDuplicateFilters} from "../../../lib/helpers/renderhelper";
import {Link} from "react-router-dom";
import Table, {TableActions} from "../../../components/Table/Table";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import {Resources} from "../../../lib/resources";
import Owner from "../../../components/Owner/Owner";
import {ToggleColumn, ToggleColumnContent} from "../../../contexts/ToggleColumn/ToggleColumn";
import useQuery from "../../../lib/Query/Query";
import client from "../../../lib/client";
import styles from "./PolicyViolations.module.scss";
import AuditHeader from "../AuditHeader/AuditHeader";

interface Props {}

function webhookName(w: ManagementV1PolicyViolation) {
    return w.status?.policy + ""
}

function getTableColumns(refetch: () => Promise<void>, violations: ManagementV1PolicyViolation[] | undefined) {
    return [
        {
            title: "Policy",
            sorter: (a: ManagementV1PolicyViolation, b: ManagementV1PolicyViolation) => stringSorter(webhookName(a), webhookName(b)),
            filters: removeDuplicateFilters(arr(violations).map(violation => ({
                text: webhookName(violation),
                value: webhookName(violation) + ""
            }))),
            onFilter: (value: any, record: ManagementV1PolicyViolation) => webhookName(record) === value,
            render: (violation: ManagementV1PolicyViolation) => {
                return <Link to={`/clusters/details/${violation.status?.cluster}/policies`} className={styles["clickable"]}><FixedText text={webhookName(violation)} /></Link>
            },
        },
        {
            title: <ToggleColumn id={"user"} columns={['User', 'User (Kubernetes)']}/>,
            showSorterTooltip: false,
            sorter: (a: ManagementV1PolicyViolation, b: ManagementV1PolicyViolation) => {
                const aValue = a.status?.user ? getDisplayName(a.status.user.displayName, a.status.user.username, a.status.user.name) : a.status?.violation?.userInfo?.username ? a.status?.violation?.userInfo?.username : "none";
                const bValue = b.status?.user ? getDisplayName(b.status.user.displayName, b.status.user.username, b.status.user.name) : b.status?.violation?.userInfo?.username ? b.status?.violation?.userInfo?.username : "none";
                return stringSorter(aValue, bValue);
            },
            filters: removeDuplicateFilters(arr(violations).map(violation => {
                const v = violation.status?.user ? getDisplayName(violation.status.user.displayName, violation.status.user.username, violation.status.user.name) : violation.status?.violation?.userInfo?.username ? violation.status?.violation?.userInfo?.username : "none";
                return {
                    text: v,
                    value: v + ""
                }
            })),
            onFilter: (value: any, violation: ManagementV1PolicyViolation) => {
                const v = violation.status?.user ? getDisplayName(violation.status.user.displayName, violation.status.user.username, violation.status.user.name) : violation.status?.violation?.userInfo?.username ? violation.status?.violation?.userInfo?.username : "none";
                return v === value;
            },
            render: (violation: ManagementV1PolicyViolation) => {
                return <ToggleColumnContent id={"user"} columns={[
                    () => {
                        if (violation.status?.user) {
                            return <Owner displayName={violation.status?.user.displayName} username={violation.status?.user.username}
                                          kubeName={violation.status?.user.name!} />;
                        } else if (violation.status?.violation?.userInfo?.username) {
                            return <FixedText text={violation.status?.violation?.userInfo?.username} />
                        }

                        return <Owner/>;
                    },
                    () => {
                        if (violation.status?.user) {
                            return <Owner kubeName={violation.status?.user.name!} />;
                        } else if (violation.status?.violation?.userInfo?.username) {
                            return <FixedText text={violation.status?.violation?.userInfo?.username} />
                        }

                        return <Owner/>;
                    },
                ]}/>
            }
        },
        {
            title: "Operation",
            sorter: (a: ManagementV1PolicyViolation, b: ManagementV1PolicyViolation) => stringSorter(a.status?.violation?.requestInfo?.operation, b.status?.violation?.requestInfo?.operation),
            filters: removeDuplicateFilters(arr(violations).map(violation => ({
                text: violation.status?.violation?.requestInfo?.operation,
                value: violation.status?.violation?.requestInfo?.operation + ""
            }))),
            onFilter: (value: any, record: ManagementV1PolicyViolation) => record.status?.violation?.requestInfo?.operation === value,
            render: (violation: ManagementV1PolicyViolation) => {
                return <FixedText text={violation.status?.violation?.requestInfo?.operation} />
            },
        },
        {
            title: "Action",
            sorter: (a: ManagementV1PolicyViolation, b: ManagementV1PolicyViolation) => stringSorter(a.status?.violation?.action, b.status?.violation?.action),
            filters: removeDuplicateFilters(arr(violations).map(violation => ({
                text: violation.status?.violation?.action,
                value: violation.status?.violation?.action + ""
            }))),
            onFilter: (value: any, record: ManagementV1PolicyViolation) => record.status?.violation?.action === value,
            render: (violation: ManagementV1PolicyViolation) => {
                return <FixedText text={violation.status?.violation?.action} />
            },
        },
        {
            title: "Message",
            sorter: (a: ManagementV1PolicyViolation, b: ManagementV1PolicyViolation) => stringSorter(a.status?.violation?.message, b.status?.violation?.message),
            render: (violation: ManagementV1PolicyViolation) => <FixedText maxWidth={400} text={violation.status?.violation?.message} />
        },
        {
            title: 'Cluster',
            sorter: (a: ManagementV1PolicyViolation, b: ManagementV1PolicyViolation) => stringSorter(a.status?.cluster, b.status?.cluster),
            filters: removeDuplicateFilters(arr(violations).map(violation => ({
                text: violation.status?.cluster,
                value: violation.status?.cluster + ""
            }))),
            onFilter: (value: any, record: ManagementV1PolicyViolation) => record.status?.cluster === value,
            render: (violation: ManagementV1PolicyViolation) => {
                return <Link to={`/clusters/details/${violation.status?.cluster!}`} className={styles["cluster"]}><FixedText text={violation.status?.cluster!} /></Link>;
            }
        },
        {
            title: 'Namespace',
            sorter: (a: ManagementV1PolicyViolation, b: ManagementV1PolicyViolation) => stringSorter(a.status?.violation?.requestInfo?.namespace, b.status?.violation?.requestInfo?.namespace),
            filters: removeDuplicateFilters(arr(violations).map(violation => ({
                text: violation.status?.violation?.requestInfo?.namespace,
                value: violation.status?.violation?.requestInfo?.namespace + ""
            }))),
            onFilter: (value: any, record: ManagementV1PolicyViolation) => record.status?.violation?.requestInfo?.namespace === value,
            render: (violation: ManagementV1PolicyViolation) => {
                return <Link to={`/spaces/${violation.status?.cluster!}/${violation.status?.violation?.requestInfo?.namespace}`} className={styles["cluster"]}><FixedText text={violation.status?.violation?.requestInfo?.namespace} /></Link>;
            }
        },
        {
            title: 'Time',
            render: (violation: ManagementV1PolicyViolation) => {
                return <DynamicTime timestamp={violation.status?.violation?.timestamp} />
            }
        },
        {
            title: 'Actions',
            width: "150px",
            render: (violation: ManagementV1PolicyViolation) => {
                return <TableActions className={styles["actions"]}>
                    <ShowYamlPopup className={styles["setting"]} object={violation} resource={Resources.ManagementV1PolicyViolation} name={violation.status?.policy} refetch={refetch} canUpdate={false} />
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1PolicyViolation, value: string) {
    return !!(item.status?.cluster?.includes(value) || item.status?.policy?.includes(value) || item.status?.violation?.message?.includes(value));
}

function PolicyViolations(props: Props) {
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1PolicyViolation).List());
    return <div>
        <Table className={styles["table"]} loading={loading} columns={getTableColumns(refetch, data?.items)} dataSource={data ? arr(data.items).map(violation => { return {...violation, key: webhookName(violation) + "/" + violation.status?.violation?.timestamp}}) : undefined} error={error} filter={filter} refetch={refetch} header={{
            top: <AuditHeader />,
        }} />
    </div>
}

export default PolicyViolations;
