import React, {useRef} from "react";
import {ManagementV1SpaceTemplate} from "../../../../../gen/models/managementV1SpaceTemplate";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import Metadata from "../../../../components/Drawer/Sections/Metadata/Metadata";
import {ResultError, Return} from "../../../../lib/result";
import {NewResource, Resources} from "../../../../lib/resources";
import client from "../../../../lib/client";
import {useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import Access from "../../../../components/Drawer/Sections/Access/Access";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import SpaceTemplateConfiguration from "./Sections/SpaceTemplateConfiguration";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import Query from "../../../../components/Query/Query";

export interface SpaceTemplateDrawerProps extends SectionProps {
    spaceTemplate?: ManagementV1SpaceTemplate;
    allSpaceTemplates: ManagementV1SpaceTemplate[];
    
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<SpaceTemplateDrawerProps, "mode"|"allSpaceTemplates"|"refetch"> & {spaceTemplateConfigurationRef: SpaceTemplateConfiguration | null, accessRef: Access | null, metadataRef: Metadata};

async function onCreate({metadataRef, spaceTemplateConfigurationRef, accessRef}: ChangeFunctionProps): Promise<ResultError> {
    // make sure we have an object
    let spaceTemplate = NewResource(Resources.ManagementV1SpaceTemplate, undefined, {spec: {}});

    // apply metadata
    const applyMetadataResult = await metadataRef.create(spaceTemplate,async metadata => {
        metadata.generateName = "space-template-";
        return Return.Ok();
    });
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }
    
    // apply space configuration
    const confResult = spaceTemplateConfigurationRef?.create(spaceTemplate);
    if (confResult?.err) {
        return confResult;
    }

    // apply access
    const accessResult = await accessRef?.create(spaceTemplate);
    if (accessResult?.err) {
        return accessResult;
    }

    // create
    const createResult = await client.management(Resources.ManagementV1SpaceTemplate).Create(spaceTemplate);
    if (createResult.err) {
        return createResult;
    }

    return Return.Ok();
}

async function onUpdate({spaceTemplate: originalSpaceTemplate, spaceTemplateConfigurationRef, accessRef, metadataRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalSpaceTemplate) {
        return Return.Ok();
    }

    // make sure the object is up to date
    const getResult = await client.management(Resources.ManagementV1SpaceTemplate).Get(originalSpaceTemplate.metadata?.name!);
    if (getResult.err) {
        return getResult;
    }

    // set object
    let spaceTemplate = getResult.val;

    // apply metadata
    const applyMetadataResult = await metadataRef.update(spaceTemplate);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply space configuration
    const confResult = spaceTemplateConfigurationRef?.update(spaceTemplate);
    if (confResult?.err) {
        return confResult;
    }

    // apply access
    const accessResult = await accessRef?.update(spaceTemplate);
    if (accessResult?.err) {
        return accessResult;
    }

    // update
    const updateResult = await client.management(Resources.ManagementV1SpaceTemplate).Update(spaceTemplate.metadata?.name!, spaceTemplate!);
    if (updateResult.err) {
        return updateResult;
    }

    return Return.Ok();
}

export default function SpaceTemplateDrawer(props: SpaceTemplateDrawerProps) {
    const drawer = useItemDrawer();
    const user = useUser();
    const metadataRef = useRef<Metadata>(null);
    const spaceTemplateConfigurationRef = useRef<SpaceTemplateConfiguration>(null);
    const accessRef = useRef<Access>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({spaceTemplateConfigurationRef: spaceTemplateConfigurationRef.current!, accessRef: accessRef.current!, metadataRef: metadataRef.current!});
        } else if (props.mode === "update") {
            result = await onUpdate({spaceTemplateConfigurationRef: spaceTemplateConfigurationRef.current!, accessRef: accessRef.current!, spaceTemplate: props.spaceTemplate, metadataRef: metadataRef.current!});
        }

        // check if there was an error
        if (result?.err) {
            message.Error(result);
            return;
        }

        // refetch
        props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Space Template"} obj={props.spaceTemplate} showDisplayName={true} showDescription={true} noMargin ref={metadataRef} />
        <SpaceTemplateConfiguration mode={props.mode} spaceTemplate={props.spaceTemplate} ref={spaceTemplateConfigurationRef} />
        <Query query={async () => await client.management(Resources.ManagementV1User).CanI("bind")} skip={!!props.spaceTemplate}>
            {
                result => {
                    if (result.error) {
                        return <ErrorMessage error={result.error} />
                    } else if (result.loading) {
                        return null;
                    }

                    return <Access mode={props.mode} kind={"Space Template"} user={user} defaultRules={result.data ? [
                        {
                            users: [user?.metadata?.name!],
                            subresources: ["*"],
                            verbs: ["*"]
                        },
                        {
                            users: ["*"],
                            subresources: ["*"],
                            verbs: ["get"]
                        }
                    ] : undefined} access={props.spaceTemplate} ref={accessRef} />
                }
            }
        </Query>
    </ItemDrawer>
}
