import React from "react";
import {Err, Failed, Result, ResultError, Return} from "./result";
import Message from "./Message/Message";
import {MessageType} from "antd/es/message";
import {ClusterBasePath, ManagementBasePath} from "./client";

interface errorLoading {
    error: ResultError,
    loading: boolean
}

interface message {
    key: number;
    hide: MessageType | undefined;
    error: ResultError;
    loading: boolean;
}

export function useClientMessage() {
    const {loading, error, setLoading, setError, setDone, setHidden} = useMessage();

    return {
        loading,
        error,
        setLoading,
        setLoadingManagement() {
            setLoading("Updating state in loft")
        },
        setLoadingCluster(cluster: string) {
            setLoading(<React.Fragment>Updating state in cluster <span style={{fontWeight: 600}}>{cluster}</span></React.Fragment>)
        },
        setErrorResult<T>(result: Err<Failed>) {
            let msg: React.ReactNode = "Failed to save state in loft";
            if (result.extra && result.extra.basePath) {
                if (result.extra.basePath !== ManagementBasePath) {
                    msg = <React.Fragment>Failed to save state in cluster <span style={{fontWeight: 600}}>{result.extra.basePath.substring(ClusterBasePath.length)}</span></React.Fragment>
                }
            }

            setError(msg, result, msg);
        },
        setErrorResultRetrieve<T>(result: Err<Failed>, retrieve?: boolean) {
            let msg: React.ReactNode = "Failed to retrieve data from loft";
            if (result.extra && result.extra.basePath) {
                if (result.extra.basePath !== ManagementBasePath) {
                    msg = <React.Fragment>Failed to retrieve data from cluster <span style={{fontWeight: 600}}>{result.extra.basePath.substring(ClusterBasePath.length)}</span></React.Fragment>
                }
            }

            setError(msg, result, msg);
        },
        setDoneResult<T>(result: Result<T>) {
            let msg: React.ReactNode = "State saved in loft";
            if (result.extra && result.extra.basePath) {
                if (result.extra.basePath !== ManagementBasePath) {
                    msg = <React.Fragment>State saved in cluster <span style={{fontWeight: 600}}>{result.extra.basePath.substring(ClusterBasePath.length)}</span></React.Fragment>
                }
            }

            setDone(msg);
        },
        setHidden
    }
}

export function useMessage() {
    const [message, setMessage] = React.useState<message>({
        key: Math.random() * 1000000,
        hide: undefined,
        error: Return.Ok(),
        loading: false,
    });

    return {
        loading: message.loading,
        error: message.error,
        setLoading: (msg?: React.ReactNode) => {
            let hide = new Message(message.key).Loading(msg);
            setMessage({
                key: message.key,
                hide,
                loading: true,
                error: message.error
            })
        },
        setError: (msg: React.ReactNode, err: Err<Failed>, title?: React.ReactNode) => {
            let hide = new Message(message.key).Error(msg, err.val, title);
            setMessage({
                key: message.key,
                hide,
                loading: false,
                error: err
            })
        },
        setDone: (msg?: React.ReactNode) => {
            let hide = new Message(message.key).Info(msg);
            setMessage({
                key: message.key,
                hide,
                loading: false,
                error: Return.Ok()
            })
        },
        setHidden: () => {
            if (message.hide) {
                message.hide()
            }
            setMessage({
                key: message.key,
                hide: undefined,
                loading: false,
                error: Return.Ok(),
            })
        }
    }
}

export function useErrorLoading(initialLoading?: boolean): any[] {
    const [errorLoading, setErrorLoading] = React.useState<errorLoading>({
        error: Return.Ok(),
        loading: !!initialLoading
    });

    return [
        errorLoading,
        (loading: boolean) => {
            setErrorLoading({
                error: Return.Ok(),
                loading,
            });
        },
        (error: ResultError) => {
            setErrorLoading({
                error,
                loading: false,
            });
        }
    ];
}