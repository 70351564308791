import {arr} from "../../lib/helpers/renderhelper";
import {Item, ItemProps} from "../Item/Item";
import {Popover} from "../Popover/Popover";
import styles from "./MultiItem.module.scss";
import React from "react";

export interface MultiItemProps {
    className?: string;
    maxItems?: number;
    
    items: Array<React.PropsWithChildren<ItemProps>>;
}

export function MultiItem(props: MultiItemProps) {
    const maxItems = props.maxItems || 4;
    const items = arr(props?.items);
    if (items.length === 0) {
        return <span>None</span>;
    } else if (items.length <= maxItems) {
        return <span>{items.map(item => <Item {...item} />)}</span>
    }
    
    const classNames = [styles["multi-item-tooltip"]];
    if (props.className) {
        classNames.push(props.className)
    }

    return <Popover content={<div className={classNames.join(" ")}>{items.map(item => <Item {...item} />)}</div>}>
        <span className={styles["multi-item-number"]}>{items.length.toString()}</span>
    </Popover>
}