import React from "react";
import {useRouteMatch} from "react-router-dom";
import useQuery from "../../../../../lib/Query/Query";
import {Resources} from "../../../../../lib/resources";
import client from "../../../../../lib/client";
import {stringSorter} from "../../../../../lib/helpers/sorthelper";
import Table, {TableActions} from "../../../../../components/Table/Table";
import PoliciesHeader from "../PoliciesHeader/PoliciesHeader";
import FixedText from "../../../../../components/FixedText/FixedText";
import DynamicTime from "../../../../../components/DynamicTime/DynamicTime";
import styles from "./Violations.module.scss";
import ShowYamlPopup from "../../../../../components/ShowYamlPopup/ShowYamlPopup";
import {PolicyV1beta1PolicyViolation} from "../../../../../../gen/models/policyV1beta1PolicyViolation";
import {ValuePopup} from "../Policies";
import {confirm} from "../../../../../lib/Modal/Modal";

function getTableColumns() {
    return [
        {
            title: "Action",
            sorter: (a: PolicyV1beta1PolicyViolation, b: PolicyV1beta1PolicyViolation) => stringSorter(a.action, b.action),
            render: (violation: PolicyV1beta1PolicyViolation) => <FixedText text={violation.action} />
        },
        {
            title: 'User Subject',
            render: (violation: PolicyV1beta1PolicyViolation) => {
                return <FixedText text={violation.userInfo?.username} />;
            }
        },
        {
            title: "Message",
            sorter: (a: PolicyV1beta1PolicyViolation, b: PolicyV1beta1PolicyViolation) => stringSorter(a.message, b.message),
            render: (violation: PolicyV1beta1PolicyViolation) => <FixedText maxWidth={600} text={violation.message} onClick={() => confirm({
                title: "Violation",
                content: <ValuePopup value={violation.message + ""} />,
                okText: "Close",
                width: 900,
                hideCancel: true,
                onOkAsync: async _ => undefined
            })} />
        },
        {
            title: 'Namespace',
            sorter: (a: PolicyV1beta1PolicyViolation, b: PolicyV1beta1PolicyViolation) => stringSorter(a.requestInfo?.namespace, b.requestInfo?.namespace),
            render: (violation: PolicyV1beta1PolicyViolation) => {
                return <FixedText text={violation.requestInfo?.namespace} />
            }
        },
        {
            title: 'Time',
            render: (violation: PolicyV1beta1PolicyViolation) => {
                return <DynamicTime timestamp={violation.timestamp} />
            }
        },
        {
            title: 'Actions',
            width: "150px",
            render: (violation: PolicyV1beta1PolicyViolation) => {
                return <TableActions className={styles["actions"]}>
                    <ShowYamlPopup className={styles["setting"]} object={violation} canUpdate={false} />
                </TableActions>;
            }
        },
    ];
}

function filter(item: PolicyV1beta1PolicyViolation, value: string) {
    return !!(item.message?.includes(value) || item.action?.includes(value) || item.requestInfo?.kind?.includes(value) || item.requestInfo?.namespace?.includes(value));
}

function Violations() {
    const match = useRouteMatch();
    const {cluster, policy} = match.params as any;
    let {loading, error, data, refetch} = useQuery(async () => await client.cluster(cluster, Resources.PolicyV1beta1JsPolicyViolations).Get(policy));

    return <div>
        <Table loading={loading} columns={getTableColumns()} dataSource={data?.status?.violations?.map(v => ({...v, key: v.message + (v.timestamp + "")})).reverse()} error={error} filter={filter} refetch={refetch} header={{
            top: <PoliciesHeader />,
        }} />
    </div>
}

export default Violations;