import React from "react";
import styles from './UpgradeLicenseLink.module.scss';
import {useLicense} from "../../contexts/LicenseContext/LicenseContext";
import {openLicenseLink} from "../../views/Admin/License/License";

interface Props {
    className?: string;
}

export default function UpgradeLicenseLink(props: React.PropsWithChildren<Props>) {
    const license = useLicense();
    const classNames = [styles["link"]];
    const clickable = license?.license && license.token;
    if (clickable) {
        classNames.push(styles["clickable"]);
    }
    if (props.className) {
        classNames.push(props.className);
    }

    return <span className={classNames.join(" ")} onClick={clickable ? () => openLicenseLink(license?.license!, "switchPlan", license?.token?.status?.token!) : undefined}>{props.children}</span>
}