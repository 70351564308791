import React from "react";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import styles from "./Profile.module.scss";
import Label from "../../../components/Label/Label";
import {useUser, useUserRefetch} from "../../../contexts/UserContext/UserContext";
import Button from "../../../components/Button/Button";
import {ToolOutlined} from "@ant-design/icons/lib";
import {useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import ProfileDrawer from "./ProfileDrawer/ProfileDrawer";
import {Avatar, Space} from "antd";
import LabeledValue from "../../../components/LabeledValue/LabeledValue";
import FixedText from "../../../components/FixedText/FixedText";

export default function Profile() {
    const user = useUser();
    const userDispatch = useUserRefetch();
    const refetch = async () => userDispatch();
    const drawerDispatcher = useItemDrawer();

    return <div className={styles["profile-wrapper"]}>
        <ProfileHeader />
        <Space size={40} direction={"vertical"}>
            <div>
                <Label.Bold>Basics</Label.Bold>
                <Space size={10} align={"start"}>
                    <div>
                        <LabeledValue label={"Email Address"} className={styles["margin-bottom"]}>
                            {<FixedText text={user?.spec?.email} /> || "none"}
                        </LabeledValue>
                        <div>
                            <Button type={"primary"} onClick={() => {
                                drawerDispatcher({
                                    title: "Change Email Address",
                                    content: <ProfileDrawer mode={"create"} type={"email"} user={user!} refetch={refetch} />
                                })
                            }}><ToolOutlined />Change Email Address</Button>
                        </div>
                    </div>
                    <div>
                        <LabeledValue label={"Name"} className={styles["margin-bottom"]}>
                            {<FixedText text={user?.spec?.displayName} /> || "none"}
                        </LabeledValue>
                        <div>
                            <Button onClick={() => {
                                drawerDispatcher({
                                    title: "Change Name",
                                    content: <ProfileDrawer mode={"create"} type={"username"} user={user!} refetch={refetch} />
                                })
                            }}><ToolOutlined />Change Name</Button>
                        </div>
                    </div>
                    <div>
                        <LabeledValue label={"Username"}>
                            {<FixedText text={user?.spec?.username} /> || "none"}
                        </LabeledValue>
                    </div>

                </Space>
                <div style={{marginTop: "15px"}}>
                    <LabeledValue label={"Profile Picture"} className={styles["margin-bottom"]}>
                        {user?.spec?.icon ? <Avatar shape="square" size={64} src={user.spec.icon} /> : "none"}
                    </LabeledValue>
                    <div>
                        <Button onClick={() => {
                            drawerDispatcher({
                                title: "Change Profile Picture",
                                content: <ProfileDrawer mode={"create"} type={"icon"} user={user!} refetch={refetch} />
                            })
                        }}><ToolOutlined />Change Profile Picture</Button>
                    </div>
                </div>
            </div>
            <div>
                <Label.Bold>Authentication</Label.Bold>
                <LabeledValue label={"Method"} className={styles["margin-bottom"]}>
                    {user?.spec?.passwordRef ? "Password" : "None"}
                </LabeledValue>
                <Space size={10}>
                    <Button type={"primary"} className={styles["change-password"]} onClick={() => {
                        drawerDispatcher({
                            title: "Change Password",
                            content: <ProfileDrawer mode={"create"} type={"password"} user={user!} refetch={refetch} />
                        })
                    }}><ToolOutlined />Change Password</Button>
                </Space>
            </div>
        </Space>
    </div>
}