import React, {useState} from "react";
import AceEditor from "react-ace";
import styles from "./YAMLEditor.module.scss";

import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-eclipse";
import {randomString} from "../../lib/helper";
import {Ace} from "ace-builds";
import {ResetProps, useReset} from "../../lib/hooks/Reset/reset";
import * as AntInput from "antd/lib/input/Input";

export interface YAMLEditorProps extends Omit<ResetProps<string>, "value"> {
    value: string | undefined;
    onChange?: (value: string) => void;
    onLoad?: (editor: Ace.Editor) => void;
    
    className?: string;

    placeholder?: string;
    readOnly?: boolean;
    
    language?: "yaml"|"javascript"|"sh"|"markdown";
    
    height?: string;
    width?: string;
    
    minLines?: number;
    maxLines?: number;
}

export default function YAMLEditor(props: YAMLEditorProps) {
    const {changed, onSelect, onReset} = useReset<string>(props, (val) => {
        if (props.onChange) {
            // dirty hack but mostly works
            props.onChange(val);
        }
    });
    const classNames = [styles["editor"]];
    if (props.className) {
        classNames.push(props.className);
    }
    if (changed) {
        classNames.push(styles["changed"]);
    }

    const [name, _] = useState(randomString(16));
    return <div className={classNames.join(" ")}>
           <AceEditor value={props.value || ""}
                      name={name}
                      width={props.width || "100%"}
                      height={props.height || "600px"}
                      placeholder={props.placeholder}
                      readOnly={props.readOnly}
                      onChange={props.onChange}
                      onFocus={onSelect}
                      onLoad={props.onLoad}
                      mode={props.language || "yaml"}
                      setOptions={{
                          showPrintMargin: false,
                          highlightActiveLine: !props.readOnly,
                          fontSize: "12px",
                          fontFamily: "'Source Code Pro', monospace",
                          tabSize: 2,
                      }}
                      minLines={props.minLines}
                      maxLines={props.maxLines}
                      theme={"eclipse"} />
          {changed && <span className={styles["text"]} onClick={(e) => onReset(e)}>Reset</span>}
    </div>
};