import React from "react";
import styles from "./AppCard.module.scss";
import { Popover } from "../../../../../components/Popover/Popover";
import {isSystemApp} from "./AppsList";
import {HelmRelease} from "../../Apps/AppsTable/AppsTable";

interface AppCardProps {
    icon?: React.ReactNode;
    title?: string;
    className?: string;

    onClick?: () => void;
    release?: HelmRelease;
    status?: "error"|"warning"|"okay";
    statusIcon?: React.ReactNode;

    transparent?: boolean;
}

export default function AppCard(props: AppCardProps): JSX.Element {
    const classNames = [styles["app-card"]];
    if (props.className) {
        classNames.push(props.className);
    }
    if (props.transparent) {
        classNames.push(styles["transparent"]);
    }

    const returnVal = <div className={classNames.join(" ")} onClick={props.onClick}>
      <div className={styles["app-card-icon-wrapper"]}>
        {props.icon}
        {props.status && <div className={[styles["status"], styles[props.status]].join(" ")}>
            {props.statusIcon}
        </div>}
      </div>
      <div className={styles["app-card-title"]} title={props.title}>
        {props.title}
      </div>
    </div>;

    if (props.release && !isSystemApp(props.release.release) && props.release.chart && props.release.chart.metadata?.version !== props.release.release.spec?.chart?.version) {
      return <Popover content={<span>
            There is a newer chart version ({props.release.chart.metadata?.version}) available.
        </span>}>
          {returnVal}
      </Popover>;
    }
    return returnVal;
}