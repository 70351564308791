import React from "react";
import ActivityTable from "../../../Audit/Activity/ActivityTable";
import {useRouteMatch} from "react-router-dom";
import SpaceHeader from "../SpaceHeader/SpaceHeader";
import Description from "../../../../components/Description/Description";

export default function SpaceActivity() {
    const match: any = useRouteMatch();
    const {cluster, space} = match.params as any;
    return <ActivityTable top={<SpaceHeader>
        <Description.Table>Shows the user and team activity inside this space depending on your audit configuration.</Description.Table>
    </SpaceHeader>} hideTarget={true} target={`cluster:${cluster}`} hideNamespace={true} namespace={space} />
}