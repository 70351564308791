import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {useItemDrawer} from "../../../../../contexts/drawer/DrawerContext";
import Metadata from "../../../../../components/Drawer/Sections/Metadata/Metadata";
import {ConfigV1alpha1TemplateInstance} from "../../../../../../gen/models/configV1alpha1TemplateInstance";
import TemplateInstanceSpec from "./Sections/TemplateInstanceSpec";
import ClientMessage from "../../../../../lib/Message/ClientMessage";
import {ResultError, Return} from "../../../../../lib/result";
import {NewResource, Resources} from "../../../../../lib/resources";
import client from "../../../../../lib/client";

export interface TemplateInstanceDrawerProps extends SectionProps {
    cluster: string;

    namespace?: string;
    templateInstance?: ConfigV1alpha1TemplateInstance;
    templateInstances?: ConfigV1alpha1TemplateInstance[];

    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<TemplateInstanceDrawerProps, "mode"|"refetch"> & {metadataRef: Metadata, templateInstanceSpecRef: TemplateInstanceSpec};

async function onCreate({cluster, metadataRef, templateInstanceSpecRef}: ChangeFunctionProps): Promise<ResultError> {
    // make sure we have an object
    let templateinstance = NewResource(Resources.ConfigV1alpha1TemplateInstance, undefined, {});

    // apply metadata
    const applyMetadataResult = await metadataRef.create(templateinstance, async metadata => {
        metadata.generateName = "templateinstance-";
        return Return.Ok();
    });
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply spec
    templateinstance.spec = {
      template: ""
    }
    const result = await templateInstanceSpecRef.create(templateinstance.spec!)
    if (result.err) {
        return result;
    }

    // create
    const createResult = await client.cluster(cluster, Resources.ConfigV1alpha1TemplateInstance).Namespace(templateinstance.metadata?.namespace).Create(templateinstance);
    if (createResult.err) {
        return createResult;
    }

    return Return.Ok();
}

async function onUpdate({templateInstance: originalTemplateInstance, cluster, metadataRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalTemplateInstance) {
        return Return.Ok();
    }

    // make sure the object is up to date
    const getResult = await client.cluster(cluster, Resources.ConfigV1alpha1TemplateInstance).Namespace(originalTemplateInstance.metadata?.namespace).Get(originalTemplateInstance.metadata?.name!);
    if (getResult.err) {
        return getResult;
    }

    // set object
    let template = getResult.val;

    // apply metadata
    const applyMetadataResult = await metadataRef.update(template);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // update
    const updateResult = await client.cluster(cluster, Resources.ConfigV1alpha1TemplateInstance).Namespace(originalTemplateInstance.metadata?.namespace).Update(template.metadata?.name!, template!);
    if (updateResult.err) {
        return updateResult;
    }

    return Return.Ok();
}

async function onBatch({templateInstances: originalTemplateInstances, cluster, metadataRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalTemplateInstances) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the team has changed meanwhile
    const listResult = await client.cluster(cluster, Resources.ConfigV1alpha1TemplateInstance).List();
    if (listResult.err) {
        return listResult;
    }

    // assign
    const templateInstances = listResult.val.items.filter(newObj => originalTemplateInstances!.find(old => old.metadata?.name === newObj.metadata?.name && old.metadata?.namespace === newObj.metadata?.namespace));

    // apply metadata
    const applyMetadataResult = await metadataRef.batch(templateInstances);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // update objects
    for (let i = 0; i < templateInstances.length; i++) {
        const obj = templateInstances[i];
        const updateResult = await client.cluster(cluster, Resources.ConfigV1alpha1TemplateInstance).Namespace(obj.metadata?.namespace).Update(obj.metadata?.name!, obj!);
        if (updateResult.err) {
            return updateResult;
        }

        templateInstances[i] = updateResult.val;
    }

    return Return.Ok();
}

export default function TemplateInstanceDrawer(props: TemplateInstanceDrawerProps) {
    const drawer = useItemDrawer();
    const metadataRef = useRef<Metadata>(null);
    const templateInstanceSpecRef = useRef<TemplateInstanceSpec>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading(props.cluster);

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({cluster: props.cluster, metadataRef: metadataRef.current!, templateInstanceSpecRef: templateInstanceSpecRef.current!});
        } else if (props.mode === "update") {
            result = await onUpdate({templateInstance: props.templateInstance, cluster: props.cluster, metadataRef: metadataRef.current!, templateInstanceSpecRef: templateInstanceSpecRef.current!});
        } else if (props.mode === "batch") {
            result = await onBatch({templateInstances: props.templateInstances, cluster: props.cluster, metadataRef: metadataRef.current!, templateInstanceSpecRef: templateInstanceSpecRef.current!});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorCluster(result, props.cluster);
            return;
        }

        // refetch
        await props.refetch();
        message.DoneCluster(props.cluster);

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} namespaced={true} cluster={props.cluster} type={"Template Instance"} obj={props.templateInstance ? props.templateInstance : props.namespace ? {metadata: {namespace: props.namespace}} : {}} forceNamespace={!!props.namespace} noMargin ref={metadataRef} />
        <TemplateInstanceSpec mode={props.mode} cluster={props.cluster} ref={templateInstanceSpecRef} />
    </ItemDrawer>
}
