import React, {useEffect} from "react";
import styles from "./Policies.module.scss";
import {DeleteOutlined, SettingOutlined, WarningOutlined} from "@ant-design/icons/lib";
import {Button} from "antd";
import {Link, useRouteMatch} from "react-router-dom";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import useQuery from "../../../../lib/Query/Query";
import {DrawerDispatch, useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import {ErrorTypeNotFound, Return} from "../../../../lib/result";
import {arr, removeDuplicateFilters} from "../../../../lib/helpers/renderhelper";
import Table, {TableActions} from "../../../../components/Table/Table";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {alert, confirm, deleteConfirm} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {
    creationTimestampSorter,
    nameSorter,
    numberSorter,
    stringSorter
} from "../../../../lib/helpers/sorthelper";
import FixedText from "../../../../components/FixedText/FixedText";
import {MultiItem} from "../../../../components/MultiItem/MultiItem";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import PoliciesDrawer from "./PoliciesDrawer/PoliciesDrawer";
import {PolicyV1beta1JsPolicy} from "../../../../../gen/models/policyV1beta1JsPolicy";
import TextArea from "../../../../components/TextArea/TextArea";
import {PolicyV1beta1JsPolicyViolations} from "../../../../../gen/models/policyV1beta1JsPolicyViolations";

export function ValuePopup(props: {value: string}) {
    return <div className={styles["popup"]}>
        <TextArea readOnly value={props.value} />
    </div>
}

function getViolations(policy: PolicyV1beta1JsPolicy, policyViolations: PolicyV1beta1JsPolicyViolations[]) {
    return arr(arr(policyViolations).find(v => v.metadata?.name === policy.metadata?.name)?.status?.violations).length
}

export function policyType(policy: PolicyV1beta1JsPolicy) {
    return policy.spec?.type || "Validating";
}

function getTableColumns(refetch: () => Promise<void>, cluster: string, policies: PolicyV1beta1JsPolicy[], policyViolations: PolicyV1beta1JsPolicyViolations[], drawerDispatcher: DrawerDispatch) {
    const editPolicy = (policy: PolicyV1beta1JsPolicy) => {
        drawerDispatcher({
            title: "Edit Policy: " + policy.metadata?.name!,
            width: 900,
            content: <PoliciesDrawer mode={"update"} cluster={cluster} policy={policy} refetch={refetch} />
        })
    };

    return [
        {
            title: 'Name',
            sorter: (a: PolicyV1beta1JsPolicy, b: PolicyV1beta1JsPolicy) => nameSorter(a, b),
            render: (policy: PolicyV1beta1JsPolicy) => {
                return <FixedText className={styles["clickable"]} maxWidth={400} onClick={() => editPolicy(policy)} text={policy.metadata?.name} />;
            }
        },
        {
            title: 'Status',
            render: (policy: PolicyV1beta1JsPolicy) => {
                if (policy.status?.phase === "Failed") {
                    return <span className={styles["cluster-error"]} onClick={() => alert({
                        title: `Error in Policy (${policy.status?.reason})`,
                        content: <TextArea value={policy.status?.message} readOnly={true} />,
                    })}><WarningOutlined />Error</span>
                } else if (!policy.status?.phase) {
                    return <FixedText text={"Initializing"} />
                }
                
                return <FixedText text={"Synced"} />;
            }
        },
        {
            title: 'Type',
            sorter: (a: PolicyV1beta1JsPolicy, b: PolicyV1beta1JsPolicy) => stringSorter(policyType(a), policyType(b)),
            filters: removeDuplicateFilters(arr(policies).map(policy => ({
                text: policyType(policy),
                value: policyType(policy) + ""
            }))),
            onFilter: (value: any, record: PolicyV1beta1JsPolicy) => policyType(record) === value,
            render: (policy: PolicyV1beta1JsPolicy) => {
                return policyType(policy);
            }
        },
        {
            title: 'Resources',
            render: (policy: PolicyV1beta1JsPolicy) => {
                const resources = arr(policy.spec?.resources);
                return <MultiItem items={resources.map(resource => ({key: resource, children: resource}))} maxItems={5} />;
            }
        },
        {
            title: 'Violations',
            sorter: (a: PolicyV1beta1JsPolicy, b: PolicyV1beta1JsPolicy) => numberSorter(getViolations(a, policyViolations), getViolations(b, policyViolations)),
            render: (policy: PolicyV1beta1JsPolicy) => {
                const violations = getViolations(policy, policyViolations);
                if (violations === 0) {
                    return 0;
                }
                return <Link
                    to={`/clusters/details/${cluster}/policies/${policy.metadata?.name}`}
                    className={styles["clickable"]}>{violations}</Link>;
            },
        },
        {
            title: 'Javascript',
            render: (policy: PolicyV1beta1JsPolicy) => {
                return <FixedText className={styles["clickable"]} onClick={() => {
                    confirm({
                        title: "Javascript of " + policy.metadata?.name,
                        content: <ValuePopup value={policy.spec?.javascript + ""} />,
                        okText: "Close",
                        width: 900,
                        hideCancel: true,
                        onOkAsync: async _ => undefined
                    });
                }} text="Show" />
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: PolicyV1beta1JsPolicy, b: PolicyV1beta1JsPolicy) => creationTimestampSorter(a, b),
            render: (policy: PolicyV1beta1JsPolicy) => {
                return <DynamicTime timestamp={policy.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (policy: PolicyV1beta1JsPolicy) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editPolicy(policy)} />
                    </Tooltip>
                    <ShowYamlPopup className={styles["setting"]} cluster={cluster} object={policy} resource={Resources.PolicyV1beta1JsPolicy} name={policy.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={styles["delete"]} onClick={() => {
                            deleteConfirm({
                                title: `Delete Policy: ${policy.metadata?.name}`,
                                content: `Are you sure you want to delete the ${policyType(policy)} policy ${policy.metadata?.name}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading(cluster);
                                    const result = await client.cluster(cluster, Resources.PolicyV1beta1JsPolicy).Delete(policy.metadata?.name!);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: PolicyV1beta1JsPolicy, value: string) {
    return !!(item.metadata?.name?.includes(value));
}

function Policies() {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    const drawerDispatcher = useItemDrawer();

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data: combinedData, refetch} = useQuery(async () => {
        const result = await client.cluster(cluster, Resources.PolicyV1beta1JsPolicy).List();
        if (result.err) {
            return result;
        }

        const violationsResult = await client.cluster(cluster, Resources.PolicyV1beta1JsPolicyViolations).List();
        if (violationsResult.err) {
            return violationsResult;
        }
        
        return Return.Value({policies: result.val, violations: violationsResult.val});
    });
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 4000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };
    if (error && error.val?.type === ErrorTypeNotFound) {
        return <div className={styles["admin-wrapper"]}>
            <ClusterHeader />
            <div className={styles["not-found"]}>
                Seems like <Link to={`/clusters/details/${cluster}/cluster`}>jspolicy</Link> is not installed. Please make sure you have installed the recommended app <Link to={`/clusters/details/${cluster}/cluster`}>jspolicy</Link> into this cluster. 
            </div>
        </div>
    }
    
    const data = combinedData?.policies;
    const violations = combinedData?.violations;
    return <div>
        <Table className={styles["table"]} loading={!data && loading} columns={getTableColumns(refetch, cluster, arr(data?.items), arr(violations?.items), drawerDispatcher)} dataSource={data ? arr(data.items).map(policy => { return {...policy, key: policy.metadata?.name}}) : undefined} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <ClusterHeader />,
            right: <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Add Policy",
                    width: 900,
                    content: <PoliciesDrawer cluster={cluster} mode={"create"} refetch={refetch} />,
                });
            }}>Add Policy</Button>,
            selectedActions: <React.Fragment>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Policies`,
                            content: `Are you sure you want to delete the policies ${selectedRowKeys.join(", ")}? This action CANNOT be reverted! This will delete all policies with all keys inside them.`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading(cluster);
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const result = await client.cluster(cluster, Resources.PolicyV1beta1JsPolicy).Delete(selectedRowKeys[i] as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneCluster(cluster);
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}

export default Policies;