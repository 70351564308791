import React from "react";
import styles from "./UserInvitePopup.module.scss";
import TextArea from "../../components/TextArea/TextArea";
import {confirm} from "../../lib/Modal/Modal";
import copy from "copy-to-clipboard";

interface UserInvitePopupProps {
    accessKey: string;

    isReset?: boolean;
}

function UserInvitePopup(props: UserInvitePopupProps) {
    return <div className={styles["popup"]}>
        <div className={styles["text"]}>You can send the following link to the user. This link allows the user to set a new password and sign in to loft.</div>
        <TextArea readOnly value={getInviteLink(props)} />
        <div className={styles["red-text"]}>You will only see this link once. To generate a new link, use the "Reset Password" button.</div>
    </div>
}

function getInviteLink(props: UserInvitePopupProps): string {
  return `${window.location.protocol}//${window.location.host}/${props.isReset ? "reset-password" : "invite"}#token=${props.accessKey}`
}

function getResetLink(props: UserInvitePopupProps): string {
    return `${window.location.protocol}//${window.location.host}/reset-password#token=${props.accessKey}`
}

export function openUserResetPasswordPopup(props: UserInvitePopupProps) {
    confirm({
        title: "Reset Password Link",
        content: <UserInvitePopup accessKey={props.accessKey} isReset />,
        okText: "Copy & Close",
        width: 900,
        hideCancel: true,
        onOkAsync: async _ => copy(getResetLink(props))
    });
}

export function openUserInvitePopup(props: UserInvitePopupProps) {
    confirm({
        title: "Invite Link for User",
        content: <UserInvitePopup accessKey={props.accessKey} />,
        okText: "Copy & Close",
        width: 900,
        hideCancel: true,
        onOkAsync: async _ => copy(getInviteLink(props))
    });
}