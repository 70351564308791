import React, {useEffect, useState} from "react";
import {Err, ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {StorageV1VirtualCluster} from "../../../../../../gen/models/agentstorageV1VirtualCluster";
import {SelectCluster} from "../../../../Spaces/Spaces/SpaceDrawer/Sections/SpaceOwner";
import useQuery from "../../../../../lib/Query/Query";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Label from "../../../../../components/Label/Label";
import Select from "../../../../../components/Select/Select";
import Description from "../../../../../components/Description/Description";
import {ManagementV1Cluster} from "../../../../../../gen/models/managementV1Cluster";
import {ManagementV1VirtualClusterTemplate} from "../../../../../../gen/models/managementV1VirtualClusterTemplate";
import Loading from "../../../../../components/Loading/Loading";
import {displayName} from "../../../../../lib/helper";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import {ClusterV1Space} from "../../../../../../gen/models/clusterV1Space";
import constants from "../../../../../constants/constants";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
const Option = Select.Option;

interface SelectTemplateProps {
    isCreate: boolean;
    cluster: string | undefined;
    template: string | undefined;

    syncAlways: boolean | undefined;
    setSyncAlways: (val: boolean) => void;

    onError: (err: Err<any>) => void;
    onChangeTemplate: (template: ManagementV1VirtualClusterTemplate | undefined) => void;
    onChangeValues: (values: string | undefined, version: string | undefined) => void;
}

interface SelectTemplateState {
    selectedTemplate?: string;
    selectedCluster?: string;
}

export function SelectTemplate(props: SelectTemplateProps) {
    const [state, setState] = useState<SelectTemplateState>({
        selectedTemplate: props.template,
        selectedCluster: props.cluster,
    });
    const {loading, error, data} = useQuery(async () => client.management(Resources.ManagementV1VirtualClusterTemplate).List());
    useEffect( () => {
        (async () => {
            if (props.isCreate) {
                if (props.cluster && props.cluster !== state.selectedCluster) {
                    const result = await client.management(Resources.ManagementV1ClusterVirtualClusterDefaults).Get(props.cluster);
                    if (result.err) {
                        props.onError(Return.Failed(`Error retrieving default values for cluster ${props.cluster}: ${result.val.message}`));
                        return;
                    }

                    const defaultTemplate = result.val.defaultTemplate;
                    setState({
                        selectedCluster: props.cluster,
                        selectedTemplate: defaultTemplate?.metadata?.name
                    });
                    if (defaultTemplate) {
                        if (defaultTemplate.metadata?.name !== state.selectedTemplate) {
                            props.onChangeTemplate(defaultTemplate);
                        }
                    } else if (result.val.values) {
                        props.onChangeValues(result.val.values, result.val.latestVersion);
                    }
                }
            }
        })();
    }, [props.cluster]);
    if (error && error.err) {
        return <ErrorMessage error={error} />
    } else if (loading) {
        return <Loading />;
    } else if (!arr(data?.items).length) {
        return null;
    }

    return <SectionExpander name={"Template"}>
        <Label>Virtual Cluster Template</Label>
        <Select
            showSearch
            allowClear={true}
            loading={loading}
            style={{ width: "100%" }}
            placeholder="Select a template"
            value={state.selectedTemplate}
            onChange={(v) => {
                props.onChangeTemplate(arr(data?.items).find(t => t.metadata?.name === v));
                setState({...state, selectedTemplate: v});
            }}
            optionFilterProp="children"
            filterOption={selectDefaultFilter}
        >
            {data ? arr(data.items).map(template => <Option key={template?.metadata?.name!} value={template?.metadata?.name!}>{displayName(template)}</Option>) : undefined}
        </Select>
        <Description>Select a template to create the virtual cluster from. You can also tell Loft to keep this virtual cluster in sync with the template. If the template is configured to be synced automatically with all virtual clusters you can also exclude this virtual cluster from automatic syncing.</Description>
        <div>
            <Checkbox checked={props.syncAlways} onChange={e => props.setSyncAlways(e.target.checked)}>Sync automatically with template</Checkbox>
        </div>
    </SectionExpander>
}

interface VClusterLocationState {
    syncAlways: boolean | undefined;
}

interface VClusterLocationProps extends SectionProps {
    // The cluster the virtual cluster is in
    cluster?: string;
    
    // If cluster select should be shown
    showClusterSelect?: boolean;
    
    // A function to set an error
    onError: (err: Err<any>) => void;

    // A function to set the cluster
    onSelectCluster: (cluster: ManagementV1Cluster | undefined, isOnlyOne?: boolean) => void;
    
    // A function to set the virtual cluster template
    onSelectVirtualClusterTemplate: (template: ManagementV1VirtualClusterTemplate | undefined) => void;
    
    // A function to set the virtual cluster default values and version
    onSelectVirtualClusterValues: (version: string | undefined, values: string | undefined) => void;

    // If the space is modified this will be non empty
    vCluster: StorageV1VirtualCluster | undefined;
}

export default class VClusterLocation extends React.PureComponent<VClusterLocationProps, VClusterLocationState> {
    state: VClusterLocationState = {
        syncAlways: this.props.vCluster?.metadata?.annotations?.[constants.LoftTriggerSyncAnnotation] === "always",
    };

    create = (obj: {vCluster: StorageV1VirtualCluster, cluster: string}): ResultError => {
        if (!this.props.cluster) {
            return Return.Failed("Please select a cluster to create the virtual cluster in");
        }

        obj.cluster = this.props.cluster;
        this.setTemplateAnnotations(obj.vCluster);
        return Return.Ok();
    };

    update = (obj: StorageV1VirtualCluster): ResultError => {
        this.setTemplateAnnotations(obj);
        return Return.Ok();
    }

    setTemplateAnnotations = (vCluster: StorageV1VirtualCluster) => {
        if (!vCluster.metadata) {
            vCluster.metadata = {}
        }
        if (!vCluster.metadata.annotations) {
            vCluster.metadata.annotations = {}
        }
        if (this.state.syncAlways) {
            vCluster.metadata.annotations[constants.LoftTriggerSyncAnnotation] = "always"
        } else {
            delete(vCluster.metadata.annotations[constants.LoftTriggerSyncAnnotation])
        }
    }

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <div>
            {this.props.mode === "create" && !this.props.showClusterSelect && <SelectCluster cluster={this.props.cluster} onChange={(value, isOnlyOne) => {
                this.props.onSelectCluster(value, isOnlyOne);
            }} />}
            <SelectTemplate isCreate={this.props.mode === "create"}
                            syncAlways={this.state.syncAlways}
                            setSyncAlways={(val) => this.setState({syncAlways: val})}
                            onError={err => this.props.onError(err)}
                            onChangeValues={(values, version) => this.props.onSelectVirtualClusterValues(values, version)} 
                            onChangeTemplate={(template) => this.props.onSelectVirtualClusterTemplate(template)}
                            template={this.props.vCluster?.metadata?.annotations?.[constants.LoftVirtualClusterTemplate]}
                            cluster={this.props.cluster} />
        </div>
    }
}