import React from "react";
import {ConfigV1alpha1TemplateInstanceSpec} from "../../../../../../../gen/models/configV1alpha1TemplateInstanceSpec";
import client from "../../../../../../lib/client";
import {Resources} from "../../../../../../lib/resources";
import {ResultError, Return} from "../../../../../../lib/result";
import Checkbox from "../../../../../../components/Checkbox/Checkbox";
import {SectionProps} from "../../../../../../components/Drawer/ItemDrawer";
import Section from "../../../../../../components/Drawer/Section/Section";
import Label from "../../../../../../components/Label/Label";
import Query from "../../../../../../components/Query/Query";
import {ErrorMessage} from "../../../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../../../components/Loading/Loading";
import {arr, selectDefaultFilter, selectOwnerFilter} from "../../../../../../lib/helpers/renderhelper";
import Select from "../../../../../../components/Select/Select";
import Description from "../../../../../../components/Description/Description";
const { Option } = Select;

interface TemplateInstanceSpecState {
    template: string | undefined;
    sync: boolean;
}

interface TemplateInstanceSpecProps extends SectionProps {
    cluster: string;
}

export default class TemplateInstanceSpec extends React.PureComponent<TemplateInstanceSpecProps, TemplateInstanceSpecState> {
    state: TemplateInstanceSpecState = {
        template: undefined,
        sync: true
    };

    create = async (spec: ConfigV1alpha1TemplateInstanceSpec): Promise<ResultError> => {
        if(!this.state.template){
            return Return.Failed("Please select a template");
        }

        spec.template = this.state.template;
        spec.sync = this.state.sync;
        return Return.Ok();
    };

    render() {
        if (["batch", "update"].includes(this.props.mode)) {
            return null;
        }

        return <Query query={async () => await client.cluster(this.props.cluster, Resources.ConfigV1alpha1Template).List()}>
          {
            result => {
              if (result.error) {
                  return <ErrorMessage error={result.error} />;
              } else if (result.loading) {
                  return <Loading />;
              }

              return <Section title={`Template`}>
                <React.Fragment>
                    <Label>Template</Label>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Select a Template"
                        style={{ width: '100%' }}
                        value={this.state.template}
                        onChange={value => {
                            this.setState({
                                template: value,
                            });
                        }}
                        optionFilterProp="children"
                        filterOption={selectDefaultFilter}
                    >
                        {arr(result.data?.items).map(template => {
                            return <Option key={template.metadata?.name} value={template.metadata?.name!}>
                                {template.metadata?.name}
                            </Option>
                        })}
                    </Select>
                    <Description>The instance will deploy this template</Description>
                    <Label>Sync Template Instance</Label>
                    <Checkbox onChange={e => this.setState({
                        sync: e.target.checked
                    })} checked={this.state.sync}>
                        Sync Enabled
                    </Checkbox>
                    <Description>If enabled, any change to the template will trigger a redeploy of the template instance</Description>
                </React.Fragment>
              </Section>
            }
          }
        </Query>
    }
}