import CodePrefix from "../../images/code-prefix.svg";
import React from "react";
import "./Code.scss";
import Icon from "@ant-design/icons";

interface Props {
    text: string | React.ReactNode;
    
    hideIcon?: boolean;
}

export default function Code(props: Props) {
    return <div className="code">
        {!props.hideIcon && <Icon className={"code-icon"} component={CodePrefix as any} />}
        <span className={"code-text"}>{props.text}</span>
    </div>
}