import React, {useState} from "react";
import styles from "./ConfigApplyPopup.module.scss";
import {Modal} from "../../../lib/Modal/Modal";
import client from "../../../lib/client";
import {NewResource, Resources} from "../../../lib/resources";
import ClientMessage from "../../../../src/lib/Message/ClientMessage";
import Button from "../../../components/Button/Button";
import {Return} from "../../../lib/result";
import {Alert} from "antd";

interface Props {
    config: string;
    refetch: () => Promise<void>;
}

export default function ConfigApplyPopup(props: Props) {
    const [visible, setVisible] = useState<boolean>(false);
    return <React.Fragment>
        <Button type={"primary"} onClick={() => setVisible(true)}>Apply</Button>
        <Modal title={`Apply Loft Config`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
            const message = ClientMessage.Loading();
            try {
                const newConfig = NewResource(Resources.ManagementV1Config, "loft-config", {spec: {
                    raw: btoa(props.config),
                }});
                const createResult = await client.management(Resources.ManagementV1Config).Create(newConfig);
                if (createResult.err) {
                    message.Error(createResult);
                    return;
                }

                await props.refetch();
                message.DoneManagement();
            } catch(err) {
                message.Error(Return.Failed(err + ""))
            }
        }}>
            <div>
                Are you sure you want to save the loft config?
            </div>
            <div className={styles["checkbox"]}>
                <Alert className={styles["info"]} message={"As soon as you update the config, Loft will restart to apply the config changes. Restarting loft might take several minutes"} type="warning" showIcon />
            </div>
        </Modal>
    </React.Fragment>
}