import React from "react";
import {WarningOutlined, PartitionOutlined} from "@ant-design/icons/lib";
import { useRouteMatch } from "react-router-dom";
import Header from "../../../../../components/Header/Header";

interface PoliciesHeaderProps {

}

export default function PoliciesHeader(props: React.PropsWithChildren<PoliciesHeaderProps>) {
    const routeMatch = useRouteMatch();
    const {cluster, type, policy} = routeMatch.params as any;
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1] === ":policy" ? "violations" : path[path.length - 1];

    return <Header title={policy} selectedKey={selectedKey} items={[
        {
            key: "violations",
            url: `/clusters/details/${cluster}/policies/${type}/${policy}`,
            content: <React.Fragment>
                <WarningOutlined />Violations
            </React.Fragment>
        }
    ]}>
        {props.children}
    </Header>
}