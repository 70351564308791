import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../contexts/drawer/DrawerContext";
import {ManagementV1User} from "../../../../gen/models/managementV1User";
import UserMetadata from "./Sections/UserMetadata";
import {Result, ResultError, Return} from "../../../lib/result";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {NewResource, Resources} from "../../../lib/resources";
import client from "../../../lib/client";
import constants from "../../../constants/constants";
import {openUserInvitePopup} from "../UserInvitePopup";
import UserClusterRoles from "./Sections/UserClusterRoles";
import UserTeamMemberships from "./Sections/UserTeamMemberships";
import ClusterAccountTemplates from "./Sections/ClusterAccountTemplates";
import {useUser} from "../../../contexts/UserContext/UserContext";
import Access, {VerbMapping} from "../../../components/Drawer/Sections/Access/Access";

export const defaultVerbMappingUser: VerbMapping[] = [
    {
        key: "*",
        text: "All"
    },
    {
        key: "create",
        text: "create"
    },
    {
        key: "get",
        text: "view"
    },
    {
        key: "update",
        text: "update"
    },
    {
        key: "delete",
        text: "delete"
    },
    {
        key: "bind",
        text: "bind"
    },
    {
        key: "createkey",
        text: "Create Access Keys"
    },
    {
        key: "updatekey",
        text: "Update Access Keys"
    },
    {
        key: "getkey",
        text: "List Access Keys"
    },
    {
        key: "deletekey",
        text: "Delete Access Keys"
    },
]

export interface UserDrawerProps extends SectionProps {
    user?: ManagementV1User;
    users?: ManagementV1User[];
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<UserDrawerProps, "mode"|"refetch"> & {
    accessRef: Access | null, 
    userMetadataRef: UserMetadata | null, 
    userClusterRoles?: UserClusterRoles | null, 
    clusterAccountTemplates?: ClusterAccountTemplates | null, 
    userTeamMemberships?: UserTeamMemberships | null
};

export async function createResetAccessKey(user: ManagementV1User): Promise<Result<string>> {
    // create new access key
    const accessKey = NewResource(Resources.ManagementV1ResetAccessKey, "reset");
    if (!accessKey.spec) {
        accessKey.spec = {};
    }
    accessKey.spec.displayName = "Reset Password";
    accessKey.spec.user = user.metadata?.name;

    // activate the access key
    const accessKeyResult = await client.management(Resources.ManagementV1ResetAccessKey).Create(accessKey);
    if (accessKeyResult.err) {
        return accessKeyResult;
    }

    // return the key
    return Return.Value(accessKeyResult.val.spec?.key!);
}

async function onCreate({accessRef, userMetadataRef, userClusterRoles, clusterAccountTemplates, userTeamMemberships}: ChangeFunctionProps) {
    // make sure we have a user object
    let user = NewResource(Resources.ManagementV1User, undefined, {spec: {}});

    // apply user metadata
    const result = await userMetadataRef?.create(user);
    if (result?.err) {
        return result;
    }
    
    // create cluster roles
    const clusterRoleResult = await userClusterRoles?.create(user.spec!);
    if (clusterRoleResult?.err) {
        return clusterRoleResult;
    }

    // apply cluster account templates
    const clusterAccountResults = clusterAccountTemplates?.create(user.spec!);
    if (clusterAccountResults?.err) {
        return clusterAccountResults;
    }
    
    // apply access
    const accessResult = await accessRef?.create(user);
    if (accessResult?.err) {
        return accessResult;
    }

    // set show organization to false
    if (!user.metadata?.annotations) {
        user.metadata!.annotations = {}
    }
    user.metadata!.annotations[constants.LoftUserCustomDataAnnotation] = btoa(JSON.stringify({
        skipOrganization: true
    }));

    // create user
    const userResult = await client.management(Resources.ManagementV1User).Create(user);
    if (userResult.err) {
        return userResult;
    }

    // reassign user
    user = userResult.val;

    // create password
    const passwordResult = await userMetadataRef?.updatePassword(user);
    if (passwordResult?.err) {
        return passwordResult;
    }

    const accessKeyResult = await createResetAccessKey(user);
    if (accessKeyResult.err) {
        return accessKeyResult;
    }

    // create team memberships
    const teamMembershipsResult = await userTeamMemberships!.create(user);
    if (teamMembershipsResult.err) {
        return teamMembershipsResult;
    }

    // open link popup
    openUserInvitePopup({
        accessKey: accessKeyResult.val,
    });

    return Return.Ok();
}

async function onUpdate({user, accessRef, userMetadataRef, userClusterRoles, userTeamMemberships, clusterAccountTemplates}: ChangeFunctionProps) {
    if (!user) {
        return Return.Ok();
    }

    // make sure the user object is up to date
    const userGetResult = await client.management(Resources.ManagementV1User).Get(user.metadata?.name!);
    if (userGetResult.err) {
        return userGetResult;
    }

    user = userGetResult.val;

    // apply user metadata
    const result = await userMetadataRef?.update(user);
    if (result?.err) {
        return result;
    }
    
    // apply cluster account templates
    const clusterAccountResults = await clusterAccountTemplates?.update(user.spec!);
    if (clusterAccountResults?.err) {
        return clusterAccountResults;
    }
    
    // apply access
    const accessResult = await accessRef?.update(user);
    if (accessResult?.err) {
        return accessResult;
    }

    // create cluster roles
    const clusterRoleResult = userClusterRoles?.update(user.spec!);
    if (clusterRoleResult?.err) {
        return clusterRoleResult;
    }

    // update user
    const userResult = await client.management(Resources.ManagementV1User).Update(user.metadata?.name!, user);
    if (userResult.err) {
        return userResult;
    }

    // reassign user
    user = userResult.val;

    // create password
    const passwordResult = await userMetadataRef?.updatePassword(user);
    if (passwordResult?.err) {
        return passwordResult;
    }

    // create team memberships
    const teamMembershipsResult = await userTeamMemberships!.update(user);
    if (teamMembershipsResult.err) {
        return teamMembershipsResult;
    }

    return Return.Ok();
}

async function onBatch({users, accessRef, userMetadataRef, userClusterRoles, clusterAccountTemplates}: ChangeFunctionProps) {
    if (!users) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the user has changed meanwhile
    const usersResult = await client.management(Resources.ManagementV1User).List();
    if (usersResult.err) {
        return usersResult;
    }

    // assign the new spaces
    users = usersResult.val.items.filter(user => users!.find(oldUser => oldUser.metadata?.name === user.metadata?.name));

    // update metadata
    const metadataError = userMetadataRef!.batch(users);
    if (metadataError.err) {
        return metadataError;
    }

    // apply cluster account templates
    const clusterAccountResults = await clusterAccountTemplates?.batch(users.map(user => user.spec!));
    if (clusterAccountResults?.err) {
        return clusterAccountResults;
    }

    // apply access
    const accessResult = await accessRef?.batch(users);
    if (accessResult?.err) {
        return accessResult;
    }
    
    // update cluster roles
    const clusterRoleResult = userClusterRoles?.batch(users.map(user => user.spec!));
    if (clusterRoleResult?.err) {
        return clusterRoleResult;
    }

    // update users
    for (let i = 0; i < users.length; i++) {
        const userResult = await client.management(Resources.ManagementV1User).Update(users[i].metadata?.name!, users[i]);
        if (userResult.err) {
            return userResult;
        }

        users[i] = userResult.val;
    }

    return Return.Ok();
}

export default function UserDrawer(props: UserDrawerProps) {
    const user = useUser();
    const drawer = useDrawerDispatcher();
    const userMetadataRef = useRef<UserMetadata>(null);
    const clusterAccountTemplates = useRef<ClusterAccountTemplates>(null);
    const userClusterRoles = useRef<UserClusterRoles>(null);
    const userTeamMembershipsRef = useRef<UserTeamMemberships>(null);
    const accessRef = useRef<Access>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({accessRef: accessRef.current!, userMetadataRef: userMetadataRef.current, userClusterRoles: userClusterRoles?.current, clusterAccountTemplates: clusterAccountTemplates.current, userTeamMemberships: userTeamMembershipsRef.current});
        } else if (props.mode === "update") {
            result = await onUpdate({accessRef: accessRef.current!,  user: props.user, userMetadataRef: userMetadataRef.current, userClusterRoles: userClusterRoles?.current, clusterAccountTemplates: clusterAccountTemplates.current, userTeamMemberships: userTeamMembershipsRef.current});
        } else if (props.mode === "batch") {
            result = await onBatch({accessRef: accessRef.current!, users: props.users, userMetadataRef: userMetadataRef.current, userClusterRoles: userClusterRoles?.current, clusterAccountTemplates: clusterAccountTemplates.current, userTeamMemberships: userTeamMembershipsRef.current});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorManagement(result);
            return;
        }

        // refetch
        await props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <UserMetadata mode={props.mode} user={props.user} ref={userMetadataRef} filterAnnotations={[constants.LoftUserCustomDataAnnotation]} />
        <ClusterAccountTemplates mode={props.mode} clusterAccountTemplatesSpec={props.user?.spec} clusterAccountTemplatesSpecs={props.users ? props.users.map(user => user?.spec!) : undefined} ref={clusterAccountTemplates} />
        <UserTeamMemberships mode={props.mode} user={props.user} ref={userTeamMembershipsRef} />
        <UserClusterRoles mode={props.mode} 
                          clusterRoles={props.user?.spec?.clusterRoles} 
                          imagePullSecrets={props.user?.spec?.imagePullSecrets}
                          groups={props.user?.spec?.groups}
                          hideGroups={false}
                          title={"Advanced Options"} 
                          label={"Cluster Roles for User"} 
                          description={"Cluster roles allow the user to do certain things in loft"} 
                          ref={userClusterRoles} />
        <Access mode={props.mode} kind={"User"} user={user} access={props.user} allowedVerbs={defaultVerbMappingUser} ref={accessRef} />
    </ItemDrawer>
}