import React, {RefObject} from "react";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../../components/Loading/Loading";
import Query from "../../../../../components/Query/Query";
import Section from "../../../../../components/Drawer/Section/Section";
import {arr} from "../../../../../lib/helpers/renderhelper";
import {StorageV1VirtualClusterTemplate} from "../../../../../../gen/models/storageV1VirtualClusterTemplate";
import VClusterAccessRules from "../../../VClusters/VClusterDrawer/Sections/VClusterAccessRules";
import {toEntity} from "../../../../../components/Drawer/Sections/Access/Access";

interface VClusterTemplateAccessState {
    defaultClusterRole: string | undefined;
}

interface VClusterTemplateAccessProps extends SectionProps {
    vClusterTemplate: StorageV1VirtualClusterTemplate | undefined;
}

export default class VClusterTemplateAccess extends React.PureComponent<VClusterTemplateAccessProps, VClusterTemplateAccessState> {
    vClusterAccessRulesRef?: RefObject<VClusterAccessRules>;

    state: VClusterTemplateAccessState = {
        defaultClusterRole: this.props.vClusterTemplate?.spec?.template?.access?.defaultClusterRole,
    };

    constructor(props: VClusterTemplateAccessProps) {
        super(props);

        this.vClusterAccessRulesRef = React.createRef<VClusterAccessRules>();
    }

    create = async (vCluster: StorageV1VirtualClusterTemplate): Promise<ResultError> => {
        if (!vCluster.spec) {
            vCluster.spec = {};
        }
        if (!vCluster.spec.template) {
            vCluster.spec.template = {};
        }

        const result = await this.vClusterAccessRulesRef?.current?.create(vCluster.spec.template);
        if (result?.err) {
            return result;
        }

        return Return.Ok();
    };

    update = async (vCluster: StorageV1VirtualClusterTemplate): Promise<ResultError> => {
        if (!vCluster.spec) {
            vCluster.spec = {};
        }
        if (!vCluster.spec.template) {
            vCluster.spec.template = {};
        }

        const result = await this.vClusterAccessRulesRef?.current?.update(vCluster.spec.template);
        if (result?.err) {
            return result;
        }

        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Query query={async () => {
            const userResult = await client.management(Resources.ManagementV1User).List();
            if (userResult.err) {
                return userResult;
            }

            const teamResult = await client.management(Resources.ManagementV1Team).List();
            if (teamResult.err) {
                return teamResult;
            }

            return Return.Value({
                users: arr(userResult.val.items),
                teams: arr(teamResult.val.items)
            });
        }}>
            {
                result => {
                    if (result.error) {
                        return <ErrorMessage error={result.error}/>;
                    } else if (result.loading) {
                        return <Loading/>;
                    }

                    return <Section title={"Virtual Cluster Access"} defaultFolded={true} foldable={true}>
                        <VClusterAccessRules vCluster={this.props.vClusterTemplate?.spec?.template}
                                             users={arr(result.data?.users).map(t => toEntity(t))}
                                             teams={arr(result.data?.teams).map(t => toEntity(t))}
                                             ref={this.vClusterAccessRulesRef} />
                    </Section>
                }
            }
        </Query>
    }
}