import React from "react";
import ActivityTable from "../../../Audit/Activity/ActivityTable";
import {useRouteMatch} from "react-router-dom";
import TeamHeader from "../TeamHeader/TeamHeader";
import Description from "../../../../components/Description/Description";

export default function TeamActivity() {
    const match: any = useRouteMatch();
    const team = match.params?.team;
    return <ActivityTable top={<TeamHeader>
        <Description.Table>Shows the team activity across the management instance, connected clusters and virtual clusters depending on your audit configuration.</Description.Table>
    </TeamHeader>} hideUser={true} user={"loft:team:"+team} />
}