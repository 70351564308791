import React, {RefObject} from "react";
import Section from "../../../../../components/Drawer/Section/Section";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Label from "../../../../../components/Label/Label";
import Description from "../../../../../components/Description/Description";
import {Result, ResultError, Return} from "../../../../../lib/result";
import {ManagementV1ClusterAccountTemplate} from "../../../../../../gen/models/managementV1ClusterAccountTemplate";
import {V1LabelSelector} from "@kubernetes/client-node";
import Query from "../../../../../components/Query/Query";
import client from "../../../../../lib/client";
import Loading from "../../../../../components/Loading/Loading";
import {Resources} from "../../../../../lib/resources";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import styles from "../../../../Spaces/Spaces/SpaceDrawer/Sections/SpaceAccess.module.scss";
import Select from "../../../../../components/Select/Select";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import Input from "../../../../../components/Input/Input";
const { Option } = Select;

interface ClusterSelectorProps extends SectionProps {
    clusterAccountTemplates?: ManagementV1ClusterAccountTemplate[];
    clusterAccountTemplate?: ManagementV1ClusterAccountTemplate;
}

interface ClusterSelectorState {
    clusters: string[];
    clustersChanged: boolean;

    clusterSelector: string | undefined;
    clusterSelectorChanged: boolean;
}

export function convertLabelSelectorToString(selector?: V1LabelSelector): string {
    return selector?.matchLabels ? Object.entries(selector.matchLabels)
        .map(([k, v]) => `${k}=${v}`)
        .join(",") : ""
}

export function convertStringToLabelSelector(selector?: string): Result<V1LabelSelector> {
    if (!selector) {
        return Return.Value({});
    }

    let retSelector: V1LabelSelector = {};
    const splitted = selector.split(",");
    for (let i = 0; i < splitted.length; i++) {
        const kvSplitted = splitted[i].split("=");
        if (kvSplitted.length != 2) {
            return Return.Failed("Not a correct label selector: " + splitted[i]);
        }
        if (!retSelector.matchLabels) {
            retSelector.matchLabels = {};
        }

        retSelector.matchLabels[kvSplitted[0].trim()] = kvSplitted[1].trim();
    }


    return Return.Value(retSelector);
}

export default class ClusterSelector extends React.PureComponent<ClusterSelectorProps, ClusterSelectorState> {
    state: ClusterSelectorState = {
        clusters: arr(this.props.clusterAccountTemplate?.spec?.clusters).length > 0 ? arr(this.props.clusterAccountTemplate?.spec?.clusters) : this.props.mode === "create" ? ["*"] : [],
        clustersChanged: false,

        clusterSelector: this.props.clusterAccountTemplate ? convertLabelSelectorToString(this.props.clusterAccountTemplate?.spec?.clusterSelector) : undefined,
        clusterSelectorChanged: false,
    };

    create(clusterAccountTemplate: ManagementV1ClusterAccountTemplate): ResultError {
        if (!clusterAccountTemplate.spec) {
            clusterAccountTemplate.spec = {};
        }

        clusterAccountTemplate.spec.clusters = this.state.clusters;
        if (this.state.clusterSelector) {
            const result = convertStringToLabelSelector(this.state.clusterSelector);
            if (result.err) {
                return result;
            }

            clusterAccountTemplate.spec.clusterSelector = result.val;
        }
        
        return Return.Ok();
    }

    update(clusterAccountTemplate: ManagementV1ClusterAccountTemplate): ResultError {
        if (this.state.clustersChanged) {
            if (!clusterAccountTemplate.spec) {
                clusterAccountTemplate.spec = {};
            }

            clusterAccountTemplate.spec.clusters = this.state.clusters;
        }

        if (this.state.clusterSelectorChanged) {
            if (!clusterAccountTemplate.spec) {
                clusterAccountTemplate.spec = {};
            }

            const result = convertStringToLabelSelector(this.state.clusterSelector);
            if (result.err) {
                return result;
            }

            clusterAccountTemplate.spec.clusterSelector = result.val;
        }

        return Return.Ok();
    }

    batch(clusterAccountTemplates: ManagementV1ClusterAccountTemplate[]): ResultError {
        for (let i = 0; i < clusterAccountTemplates.length; i++) {
            const result = this.update(clusterAccountTemplates[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    }

    render() {
        return <Section title={"1. Select Clusters"} foldable={true} defaultFolded={true}>
            <Label>Select target clusters by name</Label>
            <Query query={async () => await client.management(Resources.ManagementV1Cluster).List()}>
                {
                    result => {
                        if (result.loading) {
                            return <Loading />
                        } else if (result.error) {
                            return <ErrorMessage error={result.error} />
                        }
                        
                        const clusters = [...arr(result.data?.items).map(cluster => cluster.metadata?.name)];
                        return <Select
                            resetable={this.props.mode !== "create"}
                            className={styles["select"]}
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select clusters this template should apply to"
                            value={this.state.clusters}
                            onChangedStatus={changed => this.setState({clustersChanged: changed})}
                            onChange={(value) => {
                                const allBefore = !!this.state.clusters.find(c => c === "*");
                                const allAfter = !!value.find(c => c === "*");
                                
                                if (allBefore && allAfter) {
                                    this.setState({clusters: ["*"]});
                                } else if (allBefore) { 
                                    this.setState({clusters: value.filter(c => c !== "*")});
                                } else if (allAfter) {
                                    this.setState({clusters: ["*"]});
                                } else {
                                    this.setState({clusters: value})
                                }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={selectDefaultFilter}
                        >
                            <Option key={"*"} value={"*"}>All Clusters</Option>
                            {clusters.map(cluster => <Option key={cluster} value={cluster!}>
                                {cluster}
                            </Option>)}
                        </Select>
                    }
                }
            </Query>
            <Description>Select clusters to create accounts and resources in. Select wildcard to apply to all clusters.</Description>
            <SectionExpander name={"Select Clusters by Label"}>
                <Label>Select target clusters by label</Label>
                <Input resetable={this.props.mode !== "create"}
                       onChangedStatus={status => this.setState({clusterSelectorChanged: status})}
                       value={this.state.clusterSelector}
                       placeholder={"label1=value1,label2=value2"}
                       onChange={(e) => this.setState({clusterSelector: e.target.value})} />
                <Description>Select clusters by label to create accounts and resources in.</Description>
            </SectionExpander>
        </Section>
    }
}