import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../components/Drawer/ItemDrawer";
import {ManagementV1Cluster} from "../../../../gen/models/managementV1Cluster";
import Metadata from "../../../components/Drawer/Sections/Metadata/Metadata";
import {ResultError, Return} from "../../../lib/result";
import {Resources} from "../../../lib/resources";
import {useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../lib/Message/ClientMessage";
import Access from "../../../components/Drawer/Sections/Access/Access";
import {useUser} from "../../../contexts/UserContext/UserContext";
import DefaultTemplates from "./Sections/DefaultTemplates";
import {genericOnUpdate} from "../../../lib/helpers/drawerhelper";
import DirectClusterEndpoint from "./Sections/DirectClusterEndpoint";
import Agent from "./Sections/Agent";

export interface ClusterDrawerProps extends SectionProps {
    cluster?: ManagementV1Cluster;
    
    refetch: () => Promise<void>;
}

export default function ClusterDrawer(props: ClusterDrawerProps) {
    const drawer = useItemDrawer();
    const user = useUser();
    const metadataRef = useRef<Metadata>(null);
    const accessRef = useRef<Access>(null);
    const defaultTemplatesRef = useRef<DefaultTemplates>(null);
    const directClusterEndpointRef = useRef<DirectClusterEndpoint>(null);
    const agentRef = useRef<Agent>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "update") {
            result = await genericOnUpdate<ManagementV1Cluster>({
                existing: props.cluster!,
                resource: Resources.ManagementV1Cluster,
                sections: [
                    metadataRef.current!.update,
                    defaultTemplatesRef.current!.update,
                    directClusterEndpointRef.current!.update,
                    agentRef.current!.update,
                    accessRef.current!.update,
                ],
            });
        }

        // check if there was an error
        if (result?.err) {
            message.Error(result);
            return;
        }

        // refetch
        props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Cluster"} obj={props.cluster} showDisplayName={true} showDescription={true} noMargin ref={metadataRef} />
        <DefaultTemplates mode={props.mode} cluster={props.cluster} ref={defaultTemplatesRef} />
        <Agent mode={props.mode} cluster={props.cluster} ref={agentRef} />
        <DirectClusterEndpoint mode={props.mode} cluster={props.cluster} ref={directClusterEndpointRef} />
        <Access mode={props.mode} kind={"Cluster"} user={user} access={props.cluster} ref={accessRef} />
    </ItemDrawer>
}
