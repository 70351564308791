import React from "react";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {Result, ResultError, Return} from "../../../../../lib/result";
import Section from "../../../../../components/Drawer/Section/Section";
import Label from "../../../../../components/Label/Label";
import Query from "../../../../../components/Query/Query";
import Loading from "../../../../../components/Loading/Loading";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Select from "../../../../../components/Select/Select";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import {displayName, randomNumber} from "../../../../../lib/helper";
import Description from "../../../../../components/Description/Description";
import {StorageV1ClusterRoleRef} from "../../../../../../gen/models/agentstorageV1ClusterRoleRef";
import {ManagementV1ClusterRoleTemplate} from "../../../../../../gen/models/managementV1ClusterRoleTemplate";
import {V1ClusterRole} from "@kubernetes/client-node";
import {StorageV1AccessQuota} from "../../../../../../gen/models/agentstorageV1AccessQuota";
import {convertToMap, parseMap} from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import {ManagementV1SpaceConstraint} from "../../../../../../gen/models/managementV1SpaceConstraint";
import {StorageV1LocalSpaceConstraint} from "../../../../../../gen/models/agentstorageV1LocalSpaceConstraint";
import Input from "../../../../../components/Input/Input";
import TextArea from "../../../../../components/TextArea/TextArea";
import {Link} from "react-router-dom";
import constants from "../../../../../constants/constants";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
const {Option} = Select;

interface ClusterAccessConfigState {
    clusterRoles: Array<string>;
    clusterRolesChanged: boolean;

    disableSpaceCreation: boolean;

    priority: number | undefined;
    priorityChanged: boolean;

    quota: string | undefined;
    quotaChanged: boolean;
    
    spaceConstraint: string | undefined;
    spaceConstraintChanged: boolean;
}

interface ClusterAccessObject {
    metadata?: {
        annotations?: { [key: string]: string; };
    }

    spec?: {
        clusterRoles?: Array<StorageV1ClusterRoleRef>;
        priority?: number;
        quota?: StorageV1AccessQuota;
        spaceConstraintsRef?: string;
    }
}

interface ClusterAccessConfigProps extends SectionProps {
    obj?: ClusterAccessObject;

    clusterRoleQuery: () => Promise<Result<Array<ManagementV1ClusterRoleTemplate | V1ClusterRole>>>;
    spaceConstraintsQuery: () => Promise<Result<Array<ManagementV1SpaceConstraint | StorageV1LocalSpaceConstraint>>>;
}

export default class ClusterAccessConfig extends React.PureComponent<ClusterAccessConfigProps, ClusterAccessConfigState> {
    state: ClusterAccessConfigState = {
        disableSpaceCreation: this.props.obj?.metadata?.annotations?.[constants.DisableSpaceCreationAnnotation] === "true",

        clusterRoles: this.props.obj?.spec?.clusterRoles?.map(clusterRole => clusterRole.name!) || [],
        clusterRolesChanged: false,

        priority: this.props.obj?.spec?.priority,
        priorityChanged: false,
        
        quota: this.props.obj ? (parseMap(this.props.obj?.spec?.quota?.hard) || undefined) : "spaces: 3\nvirtualclusters: 3\npods: 20",
        quotaChanged: false,
        
        spaceConstraint: this.props.obj?.spec?.spaceConstraintsRef,
        spaceConstraintChanged: false,
    };

    create = async (obj: ClusterAccessObject): Promise<ResultError> => {
        if (!obj.spec) {
            obj.spec = {};
        }

        obj.spec.clusterRoles = this.state.clusterRoles.map(clusterRole => ({name: clusterRole}));
        if (!this.state.priority) {
            obj.spec.priority = randomNumber(1000, 100000) * 100;
        } else {
            obj.spec.priority = this.state.priority;
        }
        if (this.state.quota) {
            const result = convertToMap(this.state.quota);
            if (result.err) {
                return result;
            }

            obj.spec.quota = {
                hard: result.val
            }
        } else {
            delete(obj.spec.quota);
        }
        obj.spec.spaceConstraintsRef = this.state.spaceConstraint;
        if (!obj.metadata) {
            obj.metadata = {}
        }
        if (!obj.metadata.annotations) {
            obj.metadata.annotations = {};
        }
        if (this.state.disableSpaceCreation) {
            obj.metadata.annotations[constants.DisableSpaceCreationAnnotation] = "true";
        }
        return Return.Ok();
    };

    update = async (obj: ClusterAccessObject): Promise<ResultError> => {
        if (!obj.spec) {
            obj.spec = {};
        }
        if (this.state.clusterRolesChanged) {
            obj.spec.clusterRoles = this.state.clusterRoles.map(clusterRole => ({name: clusterRole}));
        }
        if (this.state.priorityChanged) {
            if (!this.state.priority) {
                obj.spec.priority = randomNumber(100, 1000) * 100;
            } else {
                obj.spec.priority = this.state.priority;
            }
        }
        if (this.state.quotaChanged) {
            if (this.state.quota) {
                const result = convertToMap(this.state.quota);
                if (result.err) {
                    return result;
                }

                if (Object.keys(result.val).length > 0) {
                    obj.spec.quota = {
                        hard: result.val
                    }
                } else {
                    delete(obj.spec.quota);
                }
            } else {
                delete(obj.spec.quota);
            }
        }
        if (this.state.spaceConstraintChanged) {
            obj.spec.spaceConstraintsRef = this.state.spaceConstraint;
        }
        if (!obj.metadata) {
            obj.metadata = {}
        }
        if (!obj.metadata.annotations) {
            obj.metadata.annotations = {};
        }
        if (this.state.disableSpaceCreation) {
            obj.metadata.annotations[constants.DisableSpaceCreationAnnotation] = "true";
        } else {
            delete(obj.metadata.annotations[constants.DisableSpaceCreationAnnotation])
        }
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <React.Fragment>
            <Section title={`Restrictions`} defaultFolded={true} foldable={true}>
                <Label>Enforce Space Constraints</Label>
                <Query query={async () => {
                    const result = await this.props.spaceConstraintsQuery();
                    if (result.err) {
                        return result;
                    } else if (this.props.mode === "create") {
                        const defaultSpaceConstraints = result.val.find(spaceConstraints => spaceConstraints.metadata?.labels?.[constants.LoftDefaultSpaceConstraints] === "true");
                        if (defaultSpaceConstraints) {
                            this.setState({spaceConstraint: defaultSpaceConstraints.metadata?.name});
                        }
                    }
                    
                    return result;
                }}>
                    {
                        result => {
                            if (result.loading) {
                                return <Loading />
                            } else if (result.error) {
                                return <ErrorMessage error={result.error} />
                            }

                            return <Select
                                resetable={this.props.mode !== "create"}
                                allowClear={true}
                                style={{ width: '100%' }}
                                placeholder="No Space Constraints"
                                value={this.state.spaceConstraint}
                                onChangedStatus={changed => this.setState({spaceConstraintChanged: changed})}
                                onChange={(value) => this.setState({spaceConstraint: value})}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {arr(result.data).map(spaceConstraints => <Option key={spaceConstraints.metadata?.name} value={spaceConstraints.metadata?.name!}>
                                    {displayName(spaceConstraints)}
                                </Option>)}
                            </Select>
                        }
                    }
                </Query>
                <Description><Link to={"/clusters/spaceconstraints"}>Space Constraints</Link> define what should automatically be enforced for new spaces, e.g. labels and annotations for the underlying namespace or manifests to be applied to the namespace (e.g. a NetworkPolicy or a LimitRange). If a Space Constraint changes, the changes will automatically applied to all spaces that enforce this constraint.
                </Description>
                <Label>Enforce Quotas</Label>
                <TextArea resetable={this.props.mode !== "create"}
                        changed={this.state.quotaChanged}
                        value={this.state.quota}
                        style={{minHeight: "98px"}}
                        onChangedStatus={(changed) => this.setState({quotaChanged: changed})}
                        onChange={(e) => this.setState({quota: e.target.value})}
                        placeholder={`spaces: 5
virtualclusters: 10
limits.cpu: 2
limits.memory: 4Gi
requests.cpu: 1
requests.memory: 1Gi`}
                />
                <Description>Quotas are aggregated limits that will be applied for each user or team. Quotas are enforced per cluster and across all namespaces of the respective user or team. A quota could, for example, enforce a maximum number of 5 spaces and additionally set a maximum number of 10Gi of memory across all spaces that belong to a certain user or team.</Description>
                <Label>Disable Space Creation</Label>
                <Checkbox checked={this.state.disableSpaceCreation} onChange={e => this.setState({disableSpaceCreation:e.target.checked})}>
                    Disable Space Creation
                </Checkbox>
                <Description>By default every user and team that has access to a cluster can create spaces. If you don't want that the user can create spaces, check this box.</Description>
            </Section>
            <Section title={"Advanced Options"} foldable={true} defaultFolded={true}>
                <Label>Priority For This Cluster Access</Label>
                <Input type={"number"}
                    resetable={this.props.mode !== "create"}
                    allowClear={true}
                    value={this.state.priority}
                    onChangedStatus={changed => this.setState({priorityChanged: changed})}
                    onChange={(e) => this.setState({priority: parseInt(e.target.value)})}
                    placeholder={"Empty to generate priority"} />
                <Description>Defining a priority is useful in case a user accesses a cluster and multiple Cluster Access objects would match this user, e.g. one Cluster Access directly selecting the user and another Cluster Access selecting each user of the team Admins. In this case, Loft will use the Cluster Access with the higher priority.</Description>
                <Label>Extra Cluster Roles (creates ClusterRoleBindings)</Label>
                <Query query={async () => await this.props.clusterRoleQuery()}>
                    {
                        result => {
                            if (result.loading) {
                                return <Loading />
                            } else if (result.error) {
                                return <ErrorMessage error={result.error} />
                            }

                            return <Select
                                resetable={this.props.mode !== "create"}
                                mode={"multiple"}
                                style={{ width: '100%' }}
                                placeholder="No Extra Cluster Roles"
                                value={this.state.clusterRoles}
                                onChangedStatus={changed => this.setState({clusterRolesChanged: changed})}
                                onChange={(value) => this.setState({clusterRoles: value})}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {arr(result.data).filter(clusterRole => clusterRole.metadata?.annotations?.[constants.LoftIgnoreAnnotation] !== "true").map(clusterRole => <Option key={clusterRole.metadata?.name} value={clusterRole.metadata?.name!}>
                                    {displayName(clusterRole)}
                                </Option>)}
                            </Select>
                        }
                    }
                </Query>
                <Description><Link to={"/clusters/clusterroles"}>Cluster Roles</Link> that should be assigned to the respective user or team. By default, a user matching this Cluster Access will automatically have the "Default Cluster Role".</Description>
            </Section>
        </React.Fragment>
    }
}