import { CodeOutlined } from '@ant-design/icons/lib';
import React, { useContext } from 'react';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import { RequestOptionsVCluster } from '../../../../lib/client';
import { DockContext, DockTabActions, DockTabKind } from '../../../../components/Dock/DockContext';

interface PodExecPopupProps<T> {
    className?: string;
    onClose: () => void;
    initialContainer?: string;

    vCluster?: RequestOptionsVCluster;
    cluster?: string;

    pod: string;
    namespace: string;
}

export function IconPodExecPopup<T>(props: Omit<PodExecPopupProps<T>, "onClose">) {
    const { cluster, namespace, pod, vCluster } = props;
    const { dispatch } = useContext(DockContext);
    const onClick = () => {
        dispatch({
            type: DockTabActions.OPEN,
            payload: {
                kind: DockTabKind.EXEC,
                cluster,
                vCluster,
                namespace,
                pod,
            }
        })
    }

    return <React.Fragment>
        <Tooltip title={"shell"}>
            <CodeOutlined className={props.className} onClick={onClick} />
        </Tooltip>
    </React.Fragment>
}