import React from "react";
import {
    BlockOutlined,
    CloudOutlined,
    TeamOutlined,
    CopyOutlined,
    AppstoreAddOutlined,
    DatabaseOutlined,
    AreaChartOutlined, LoginOutlined,
    ApartmentOutlined, ContainerOutlined, SolutionOutlined
} from "@ant-design/icons/lib";
import { useRouteMatch, useHistory } from "react-router-dom";
import ControlFree from "../../../../components/Icons/ControlFree";
import Header from "../../../../components/Header/Header";
import VCluster from "../../../../components/Icons/VCluster";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {arr} from "../../../../lib/helpers/renderhelper";

interface ClusterHeaderProps {

}

export default function ClusterHeader(props: React.PropsWithChildren<ClusterHeaderProps>) {
    const routeMatch = useRouteMatch<{cluster: string}>();
    const cluster = routeMatch.params.cluster;
    const {data} = useQuery(async () => client.cluster(cluster, Resources.ConfigV1alpha1Account).List());
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1] === ":cluster" ? "cluster" : path[path.length -2] === "resources" ? "resources" : path[path.length - 1];
    
    const items = [
        {
            key: "cluster",
            url: `/clusters/details/${cluster}/cluster`,
            content: <React.Fragment>
                <CloudOutlined />Cluster
            </React.Fragment>
        },
        {
            key: "clusteraccess",
            url: `/clusters/details/${cluster}/clusteraccess`,
            content: <React.Fragment>
                <LoginOutlined />Cluster Access
            </React.Fragment>
        },
        {
            key: "spaces",
            url: `/clusters/details/${cluster}/spaces`,
            content: <React.Fragment>
                <BlockOutlined />Spaces
            </React.Fragment>
        },
        {
            key: "vclusters",
            url: `/clusters/details/${cluster}/vclusters`,
            content: <React.Fragment>
                <VCluster />Virtual Clusters
            </React.Fragment>
        },
        {
            key: "apps",
            url: `/clusters/details/${cluster}/apps`,
            content: <React.Fragment>
                <AppstoreAddOutlined />Apps
            </React.Fragment>
        },
        {
            key: "activity",
            url: `/clusters/details/${cluster}/activity`,
            content: <React.Fragment>
                <SolutionOutlined />Activity
            </React.Fragment>
        },
        {
            key: "quotas",
            url: `/clusters/details/${cluster}/quotas`,
            content: <React.Fragment>
                <ControlFree />Quotas
            </React.Fragment>
        },
        {
            key: "policies",
            url: `/clusters/details/${cluster}/policies`,
            content: <React.Fragment>
                <ContainerOutlined />Policies
            </React.Fragment>
        },
        {
            key: "monitoring",
            url: `/clusters/details/${cluster}/monitoring`,
            content: <React.Fragment>
                <AreaChartOutlined />Monitoring
            </React.Fragment>
        },
    ]
    if (arr(data?.items).length > 0) {
        items.push({
            key: "accounts",
            url: `/clusters/details/${cluster}/accounts`,
            content: <React.Fragment>
                <TeamOutlined />Accounts<span className={"color-warning"}>(Deprecated)</span>
            </React.Fragment>
        },
        {
            key: "templates",
            url: `/clusters/details/${cluster}/templates`,
            content: <React.Fragment>
                <CopyOutlined />Security Templates<span className={"color-warning"}>(Deprecated)</span>
            </React.Fragment>
        },
        {
            key: "templateinstances",
            url: `/clusters/details/${cluster}/templateinstances`,
            content: <React.Fragment>
                <ApartmentOutlined />Security Template Instances<span className={"color-warning"}>(Deprecated)</span>
            </React.Fragment>
        })
    }
    items.push({
            key: "resources",
            url: `/clusters/details/${cluster}/resources`,
            content: <React.Fragment>
                <DatabaseOutlined />Resources
            </React.Fragment>
    });
    return <Header title={"Cluster Management"} selectedKey={selectedKey} items={items}>
        {props.children}
    </Header>
}