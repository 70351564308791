import React, {useEffect, useState} from "react";
import {useEffectOnce} from "../../lib/helper";

export interface IntervalProps {
    interval: number;
    immediate?: boolean;
    fn: () => void;
}

export default function Interval(props: React.PropsWithChildren<IntervalProps>): JSX.Element {
    useEffectOnce(() => {
        const timer = setInterval(props.fn, props.interval);
        if (props.immediate) {
            props.fn();
        }
        return () => {
            clearInterval(timer);
        }
    });

    return props.children as JSX.Element;
}

export interface IntervalRenderProps {
    interval: number;
    children: () => string | JSX.Element | undefined;
}

export function IntervalRender(props: IntervalRenderProps): JSX.Element {
    const [children, setChildren] = useState<string | JSX.Element | undefined>(props.children())
    useEffect(() => {
        setChildren(props.children());
        const timer = setInterval(() => setChildren(props.children()), props.interval)
        return () => {
            clearInterval(timer);
        }
    }, [props.children]);

    return children as JSX.Element;
}