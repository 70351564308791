import React from "react";
import { useRouteMatch } from "react-router-dom";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import AppsTable, {useApps} from "./AppsTable/AppsTable";
import Label from "../../../../components/Label/Label";
import AppsList from "../Overview/AppsList/AppsList";
import styles from "../Overview/Overview.module.scss";

export default function Apps() {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    const appsResult = useApps(cluster);
    return <AppsTable query={appsResult} cluster={cluster} header={<ClusterHeader>
        <Label.Bold>Apps</Label.Bold>
        <div>Click on an app to install or upgrade it.</div>
        <AppsList type="cluster" query={appsResult} className={styles["apps-list"]} cluster={cluster} />
    </ClusterHeader>} />
};