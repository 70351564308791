import React from "react";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import useQuery from "../../../../lib/Query/Query";
import {useRouteMatch} from "react-router-dom";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import {QuotaTable} from "../../../Quotas/QuotaTable/QuotaTable";
import {ClusterObject} from "../../../Spaces/Spaces/Spaces";
import {ClusterV1ClusterQuota} from "../../../../../gen/models/clusterV1ClusterQuota";

function buildClusterQuotas(cluster: string, quotas: Array<ClusterV1ClusterQuota>): Array<ClusterObject<ClusterV1ClusterQuota>> {
    return quotas.map(quota => {
        return {
            cluster,
            object: quota,
        }
    });
}

export default function Quotas() {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    const quotaQuery = useQuery(async () => await client.cluster(cluster, Resources.ClusterV1ClusterQuota).List());
    
    return <QuotaTable cluster={cluster}
                       top={<ClusterHeader />}
                       error={quotaQuery.error}
                       loading={quotaQuery.loading}
                       quotas={quotaQuery.data ? buildClusterQuotas(cluster, quotaQuery.data.items) : undefined}
                       refetch={quotaQuery.refetch} />
};