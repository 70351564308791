import {useHistory, useRouteMatch} from "react-router-dom";
import React, {useEffect, useState} from "react";
import client, {GroupVersionResource} from "../../../../lib/client";
import {Unstructured} from "../../../../lib/types";
import useQuery, {QueryResult} from "../../../../lib/Query/Query";
import {arr, selectDefaultFilter} from "../../../../lib/helpers/renderhelper";
import styles from "./Resources.module.scss";
import Label from "../../../../components/Label/Label";
import Select from "../../../../components/Select/Select";
import {fromResourceString, toResourceString} from "../../../../lib/helpers/kubehelper";
import {ResourceTable} from "../../../../components/ResourceTable/ResourceTable";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import {Err, Failed, Ok, ResultError} from "../../../../lib/result";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import {stringCompare, stringSorter, versionCompare} from "../../../../lib/helpers/sorthelper";
import * as H from "history";
const Option = Select.Option;

const clusterCache: {[cluster: string]: GroupVersionResource<Unstructured>[]} = {};



export function useApiResourceClusterQuery({cluster, removeDuplicates, includeSubResources}: {cluster: string, removeDuplicates: boolean, includeSubResources: boolean}) {
    let {loading, error, data: apiResourcesRaw} = useQuery(async () => {
        const result = await client.clusterNonResource(cluster).ApiResources(false, true)
        if (result.err) {
            return result;
        }

        clusterCache[cluster] = result.val;
        return result;
    });
    if (!apiResourcesRaw) {
        apiResourcesRaw = clusterCache[cluster];
    }
    
    if (apiResourcesRaw && apiResourcesRaw.length > 0) {
        if (!includeSubResources) {
            apiResourcesRaw = apiResourcesRaw.filter(resource => !resource.subResource);
        }
        if (removeDuplicates) {
            const newApiResourcesRaw: GroupVersionResource<Unstructured>[] = [];
            for (let i = 0; i < apiResourcesRaw.length; i++) {
                const index = newApiResourcesRaw.findIndex(other => other.resource === apiResourcesRaw?.[i]?.resource && other.group === apiResourcesRaw?.[i]?.group);
                if (index !== -1) {
                    const result = versionCompare(apiResourcesRaw?.[i]?.version, newApiResourcesRaw[index].version)
                    if (result > 0) {
                        newApiResourcesRaw[index] = apiResourcesRaw[i];
                    }
                } else {
                    newApiResourcesRaw.push(apiResourcesRaw[i]);
                }
            }
            
            apiResourcesRaw = newApiResourcesRaw;
        }
        
        apiResourcesRaw.sort((a, b) => {
            if (a.group == b.group) {
                if (a.resource == b.resource) {
                    return versionCompare(a.version, b.version);
                }

                return stringCompare(a.resource, b.resource);
            }
            if (!a.group) {
                return -1;
            } else if (!b.group) {
                return 1;
            }
            
            return stringCompare(a.group, b.group);
        })
    }
    
    return {
        loading,
        error,
        apiResourcesRaw
    }
}

export interface ApiResourcesProps {
    selectedResource: GroupVersionResource<Unstructured> | undefined,
    setSelectedResource: (s: GroupVersionResource<Unstructured> | undefined) => void,
    apiResources: Array<GroupVersionResource<Unstructured>>,
    loading: boolean,
    error?: Ok<undefined> | Err<Failed> |  undefined,
    defaultResource?: string,
    baseUrl?: string,
    history?: H.History<H.LocationState>
} 

export function ApiResources(props: ApiResourcesProps) {
    const history = useHistory();
    useEffect(() => {
        if (!props.selectedResource && props.apiResources && props.defaultResource) {
            const parsedGVR = fromResourceString(props.defaultResource);
            const resource = props.apiResources?.find(resource => resource.resource === parsedGVR.resource && resource.group === parsedGVR.group);
            if (resource) {
                props.setSelectedResource(resource);
            }
        }
    }, [props.apiResources])
    
    if (props.error) {
        return <ErrorMessage error={props.error} />
    }

    const loading = props.loading && !arr(props.apiResources).length;
    return <div className={styles["wrapper"]}>
        <Label>Select a resource to display in the table below</Label>
        <Select showSearch
                loading={loading}
                className={styles["select"]}
                placeholder={loading ? "Loading Resources..." : "Select a resource"}
                filterOption={selectDefaultFilter}
                value={props.selectedResource ? toResourceString(props.selectedResource) : props.defaultResource}
                onChange={value => {
                    if (!value) {
                        props.setSelectedResource(undefined);
                        if (props.baseUrl) {
                            history?.push(props.baseUrl);
                        }
                        return;
                    }

                    const parsedGVR = fromResourceString(value);
                    props.setSelectedResource(props.apiResources?.find(resource => resource.resource === parsedGVR.resource && resource.group === parsedGVR.group));
                    if (props.baseUrl) {
                        history?.push(props.baseUrl+"/"+value);
                    }
                }}>
            {arr(props.apiResources).map(apiResource => {
                const key = toResourceString(apiResource);
                return <Option key={key} value={key}>{key}</Option>
            })}
        </Select>
    </div>
}

export default function Other() {
    const match = useRouteMatch();
    const {cluster, resource} = match.params as any;

    // get api resources
    const [selectedResource, setSelectedResource] = useState<GroupVersionResource<Unstructured> | undefined>(undefined);
    const {loading, error, apiResourcesRaw} = useApiResourceClusterQuery({cluster, removeDuplicates: true, includeSubResources: false});
    const apiResources = arr(apiResourcesRaw).filter(r => arr(r.verbs).includes("list") && arr(r.verbs).includes("get"))
    return <div>
        <ResourceTable cluster={cluster}
                       addNamespaceColumn={selectedResource?.namespaced}
                       resource={selectedResource}
                       top={<ClusterHeader>
                           <ApiResources selectedResource={selectedResource}
                                         setSelectedResource={setSelectedResource} 
                                         apiResources={apiResources}
                                         loading={loading}
                                         error={error}
                                         defaultResource={resource}
                                         baseUrl={`/clusters/details/${cluster}/resources`} />
                       </ClusterHeader>} />
    </div>
};