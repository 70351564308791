import React, {useRef, useState} from "react";
import SpaceOwner from "./Sections/SpaceOwner";
import SpaceSleepMode from "./Sections/SpaceSleepMode";
import SpaceAccess from "./Sections/SpaceAccess";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {NewResource, Resources} from "../../../../lib/resources";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {Err, ResultError, Return} from "../../../../lib/result";
import client from "../../../../lib/client";
import {useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import Query from "../../../../components/Query/Query";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../components/Loading/Loading";
import SpaceApps from "./Sections/SpaceApps";
import {arr} from "../../../../lib/helpers/renderhelper";
import {ProgressPopup} from "../../../../components/ProgressPopup/ProgressPopup";
import {Wait} from "../../../../lib/helper";
import {ClusterV1Space} from "../../../../../gen/models/clusterV1Space";
import {ManagementV1User} from "../../../../../gen/models/managementV1User";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import {ManagementV1Task} from "../../../../../gen/models/managementV1Task";
import AppParametersDrawer, {AppWithParameters} from "../../../../components/Drawer/AppParametersDrawer/AppParametersDrawer";
import {ManagementV1App} from "../../../../../gen/models/managementV1App";
import constants from "../../../../constants/constants";
import SpaceConstraints from "./Sections/SpaceConstraints";
import SpaceMetadata from "./Sections/SpaceMetadata";

export interface SpaceDrawerProps extends SectionProps {
    cluster?: string;

    space?: ClusterV1Space;
    spaces?: ClusterV1Space[];
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<SpaceDrawerProps, "mode"> & {
    apps: AppWithParameters[] | undefined, 
    user: ManagementV1User, 
    progressPopupRef: ProgressPopup | null, 
    spaceAppsRef: SpaceApps | null, 
    spaceOwnerRef: SpaceOwner | null,
    metadataRef: SpaceMetadata | null,
    spaceSleepModeRef: SpaceSleepMode | null, 
    spaceAccessRef: SpaceAccess | null,
    spaceConstraintsRef: SpaceConstraints | null,
};

export async function streamTask(user: ManagementV1User, 
                                 task: ManagementV1Task, 
                                 progressPopupRef: ProgressPopup | undefined | null,
                                 deleteOnAbort: boolean,
                                 closeImmediately?: boolean): Promise<ResultError> {
    if (!task.metadata) {
        task.metadata = {};
    }
    task.metadata!.generateName = "task-";
    if (!task.spec) {
        task.spec = {};
    }
    task.spec.access = [
        {
            users: [user.metadata?.name!],
            verbs: ["*"],
            subresources: ["*"]
        }
    ]

    const taskCreateResult = await client.management(Resources.ManagementV1Task).Create(task);
    if (taskCreateResult.err) {
        return taskCreateResult;
    }

    const taskName = taskCreateResult.val.metadata?.name!;
    progressPopupRef?.clear();
    await progressPopupRef?.setVisible(true, true);
    progressPopupRef?.writeln(`Waiting for task to start...`);

    let isReady = false;
    for (let i = 0; i < 100; i++) {
        const taskResult = await client.management(Resources.ManagementV1Task).Get(taskName);
        if (taskResult.err) {
            return taskResult;
        } else if (taskResult.val.status?.podPhase+"" === "Running" || taskResult.val.status?.podPhase+"" === "Succeeded" || taskResult.val.status?.podPhase+"" === "Failed") {
            isReady = true;
            break;
        } else if (!progressPopupRef?.isVisible()) {
            if (deleteOnAbort) {
                await client.management(Resources.ManagementV1Task).Delete(taskName!);
            }
            return Return.Failed("Aborted by user");
        }

        await Wait(2000);
    }
    if (!isReady) {
        progressPopupRef?.writeln(`Timed out waiting for task to become ready...`);
        return Return.Failed("Timed out waiting for task to become ready")
    }

    // stream logs
    const taskLogResult = await client.management(Resources.ManagementV1TaskLog).TaskLogs(taskName!, {
        follow: true,
    })
    if (taskLogResult.err) {
        progressPopupRef?.writeln(`Error streaming task log: ${taskLogResult.val.message}`);
        return taskLogResult;
    }

    const reader = taskLogResult.val;
    try {
        while(progressPopupRef?.isVisible()) {
            const result = reader.read();
            let resultValue: ReadableStreamDefaultReadResult<Uint8Array> | undefined = undefined; 
            while(true) {
                const resultAll = await Promise.race([result, Wait(2000)])
                if (!resultAll) {
                    if (!progressPopupRef?.isVisible()) {
                        break;
                    }
                    
                    continue;
                }
                resultValue = resultAll;
                break;
            }
            
            if (resultValue?.value) {
                progressPopupRef?.write(new TextDecoder().decode(resultValue?.value));
            }
            if (resultValue?.done) {
                break;
            }
        }
        if (!progressPopupRef?.isVisible()) {
            if (deleteOnAbort) {
                await client.management(Resources.ManagementV1Task).Delete(taskName!);
            }
            reader.cancel();
            return Return.Failed("Aborted by user");
        }
    } catch(e) {
        progressPopupRef?.writeln(`Error streaming task log: ${e}`);
        return Return.Failed(`Error streaming task log: ${e}`);
    }
    
    while(true) {
        if (!progressPopupRef?.isVisible()) {
            return Return.Ok();
        }
        
        const taskResult = await client.management(Resources.ManagementV1Task).Get(taskName!);
        if (taskResult.err) {
            return taskResult;
        } else if (taskResult.val.status?.podPhase+"" === "Failed") {
            progressPopupRef?.writeln(`Task Failed...`);
            return Return.Failed("Task Failed");
        } else if (taskResult.val.status?.podPhase+"" === "Running") {
            await Wait(1000);
            continue;
        }
        
        break;
    }

    if (!closeImmediately) {
        progressPopupRef?.writeln(`Closing automatically in 5 seconds...`);
        for(let i = 0; i < 5 && progressPopupRef?.isVisible(); i++) {
            await Wait(1000);
        }
    }
    await progressPopupRef?.setVisible(false, false);
    return Return.Ok();
}

async function onCreate({apps, user, cluster, spaceAppsRef, progressPopupRef, spaceOwnerRef, metadataRef, spaceSleepModeRef, spaceAccessRef}: ChangeFunctionProps) {
    if (!cluster) {
        return Return.Ok();
    }

    // make sure we have a space object
    const space = NewResource(Resources.ClusterV1Space);

    // apply metadata
    let result = await metadataRef?.create(space);
    if (result?.err) {
        return result;
    }

    // apply space owner
    result = spaceOwnerRef?.create({space, cluster});
    if (result?.err) {
        return result;
    }

    // apply sleep mode
    result = spaceSleepModeRef?.create(space);
    if (result?.err) {
        return result;
    }

    // apply obejcts
    result = spaceAppsRef?.create(space);
    if (result?.err) {
        return result;
    }
    
    // apply apps
    if (arr(apps).length > 0) {
        // create space creation task
        const task = NewResource(Resources.ManagementV1Task);
        task.spec = {
            displayName: "Create Space "+(space.metadata?.name || space.metadata?.generateName),
            target: {
                cluster: {
                    cluster: cluster,
                }
            },
            task: {
                spaceCreation: {
                    metadata: space.metadata,
                    owner: {
                        user: space.spec?.user,
                        team: space.spec?.team
                    },
                    objects: space.spec?.objects,
                    apps: apps?.map(app => ({name: app.app?.metadata?.name, parameters: app.parameters}))
                }  
            },
        }
        
        const streamTaskResult = await streamTask(user, task, progressPopupRef, true);
        if (streamTaskResult.err) {
            return streamTaskResult;
        }
    } else {
        // create space
        const spaceResult = await client.cluster(cluster, Resources.ClusterV1Space).Create(space);
        if (spaceResult.err) {
            return spaceResult;
        }
    }

    // apply roles
    result = await spaceAccessRef?.create(space.metadata!.name!);
    if (result?.err) {
        return result;
    }
    
    return Return.Ok();
}

async function onUpdate({space, spaceConstraintsRef, spaceAppsRef, cluster, spaceOwnerRef, metadataRef, spaceSleepModeRef, spaceAccessRef}: ChangeFunctionProps) {
    if (!cluster || !space) {
        return Return.Ok();
    }

    // we refresh the object here, otherwise it can be possible that we get a conflict error if the space has changed meanwhile
    let spaceResult = await client.cluster(cluster, Resources.ClusterV1Space).Get(space.metadata?.name!);
    if (spaceResult.err) {
        return spaceResult;
    }
    space = spaceResult.val;

    // apply metadata
    let result = metadataRef!.update(space);
    if (result?.err) {
        return result;
    }

    // only update if we have rights to do it
    if (spaceOwnerRef) {
        // apply space owner
        let result = spaceOwnerRef!.update(space);
        if (result?.err) {
            return result;
        }
    }

    // apply sleep mode
    result = spaceSleepModeRef!.update(space);
    if (result?.err) {
        return result;
    }

    // apply objects
    result = spaceAppsRef!.update(space);
    if (result?.err) {
        return result;
    }

    // apply space constraints
    const spaceConstraintsResult = spaceConstraintsRef?.update(space);
    if (spaceConstraintsResult?.err) {
        return spaceConstraintsResult;
    }

    // update space
    spaceResult = await client.cluster(cluster, Resources.ClusterV1Space).Update(space.metadata?.name!, space);
    if (spaceResult.err) {
        return spaceResult;
    }

    space = spaceResult.val;

    // only update role bindings if we are allowed to
    if (spaceAccessRef) {
        // apply roles
        let result = await spaceAccessRef.update(space.metadata?.name!);
        if (result?.err) {
            return result;
        }
    }

    return Return.Ok();
}

async function onBatch({spaces, spaceConstraintsRef, cluster, metadataRef, spaceSleepModeRef, spaceAccessRef}: ChangeFunctionProps) {
    if (!cluster || !spaces) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the space has changed meanwhile
    const spacesResult = await client.cluster(cluster, Resources.ClusterV1Space).List();
    if (spacesResult.err) {
        return spacesResult;
    }

    // assign the new spaces
    spaces = spacesResult.val.items.filter(space => spaces!.find(oldSpace => oldSpace.metadata?.name === space.metadata?.name));

    // only update if we have rights to do it
    if (metadataRef) {
        // apply metadata
        let result = metadataRef?.batch(spaces);
        if (result?.err) {
            return result;
        }

        // apply sleep mode
        result = spaceSleepModeRef!.batch(spaces);
        if (result?.err) {
            return result;
        }
        
        // apply space constraints
        const spaceConstraintsResult = spaceConstraintsRef?.batch(spaces);
        if (spaceConstraintsResult?.err) {
            return spaceConstraintsResult;
        }

        // update spaces
        for (let i = 0; i < spaces.length; i++) {
            const spaceResult = await client.cluster(cluster, Resources.ClusterV1Space).Update(spaces[i].metadata?.name!, spaces[i]);
            if (spaceResult.err) {
                return spaceResult;
            }

            spaces[i] = spaceResult.val;
        }
    }

    // only update role bindings if we are allowed to
    if (spaceAccessRef) {
        // apply roles
        let result = await spaceAccessRef.batch(arr(spaces).map(space => space.metadata?.name!));
        if (result?.err) {
            return result;
        }
    }

    return Return.Ok();
}

export default function SpaceDrawer(props: SpaceDrawerProps) {
    const user = useUser();
    const [cluster, setCluster] = useState<string | undefined>(props.cluster);
    const [error, setError] = useState<Err<any> | undefined>(undefined);
    const drawer = useDrawerDispatcher();
    const spaceOwnerRef = useRef<SpaceOwner>(null);
    const metadataRef = useRef<SpaceMetadata>(null);
    const spaceSleepModeRef = useRef<SpaceSleepMode>(null);
    const spaceAccessRef = useRef<SpaceAccess>(null);
    const spaceAppsRef = useRef<SpaceApps>(null);
    const progressPopupRef = useRef<ProgressPopup>(null);
    const spaceConstraintsRef = useRef<SpaceConstraints>(null);
    
    // apps
    const [apps, setApps] = useState<ManagementV1App[]>([]);
    return <React.Fragment>
        {apps.length > 0 && <AppParametersDrawer drawerDispatcher={drawer}
                                                               apps={apps.map(app => ({app: app}))}
                                                               onCreate={async (apps) => {
                                                                   const message = ClientMessage.Loading(cluster!);
                                                                   const result = await onCreate({spaceConstraintsRef: spaceConstraintsRef.current!, apps, user: user!, progressPopupRef: progressPopupRef.current!, spaceAppsRef: spaceAppsRef.current!, space: props.space, cluster: cluster!, refetch: props.refetch, spaceOwnerRef: spaceOwnerRef.current, metadataRef: metadataRef.current, spaceSleepModeRef: spaceSleepModeRef.current, spaceAccessRef: spaceAccessRef.current});
                                                                   if (result?.err) {
                                                                       message.ErrorCluster(result, cluster!);
                                                                       return;
                                                                   }

                                                                   // refetch
                                                                   await props.refetch();
                                                                   message.DoneCluster(cluster!);

                                                                   // close drawer
                                                                   drawer({});
                                                               }}
                                                               onClose={() => {
                                                                   setApps([]);
                                                                   drawer({
                                                                       title: "Create Space",
                                                                       update: true,
                                                                   })
                                                               }} />}
        <ItemDrawer className={apps.length > 0 ? "hidden" : undefined} okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
            if (!cluster) {
                ClientMessage.Error(Return.Failed("no cluster selected"));
                return;
            }
            if (error) {
                ClientMessage.Error(error);
                return;
            }

            // get apps
            let apps: AppWithParameters[] = [];
            if (props.mode === "create") {
                const appsResult = spaceAppsRef?.current?.getApps();
                if (appsResult?.err) {
                    ClientMessage.Error(appsResult);
                    return;
                } else if (!!appsResult?.val.find(app => arr(app.spec?.parameters).length > 0)) {
                    setApps(appsResult?.val);
                    return;
                }
                
                apps = appsResult?.val.map(app => ({app, parameters: undefined})) || [];
            }

            // execute the create / update / batch logic
            const message = ClientMessage.Loading(cluster!);
            let result: ResultError | undefined = undefined;
            if (props.mode === "create") {
                result = await onCreate({spaceConstraintsRef: spaceConstraintsRef.current!, apps, user: user!, progressPopupRef: progressPopupRef.current!, spaceAppsRef: spaceAppsRef.current!, space: props.space, cluster: cluster!, refetch: props.refetch, spaceOwnerRef: spaceOwnerRef.current, metadataRef: metadataRef.current, spaceSleepModeRef: spaceSleepModeRef.current, spaceAccessRef: spaceAccessRef.current});
            } else if (props.mode === "update") {
                result = await onUpdate({spaceConstraintsRef: spaceConstraintsRef.current!, apps: undefined, user: user!, progressPopupRef: progressPopupRef.current!, spaceAppsRef: spaceAppsRef.current!, space: props.space, cluster: cluster!, refetch: props.refetch, spaceOwnerRef: spaceOwnerRef.current, metadataRef: metadataRef.current, spaceSleepModeRef: spaceSleepModeRef.current, spaceAccessRef: spaceAccessRef.current});
            } else if (props.mode === "batch") {
                result = await onBatch({spaceConstraintsRef: spaceConstraintsRef.current!, apps: undefined, user: user!, progressPopupRef: progressPopupRef.current!, spaceAppsRef: spaceAppsRef.current!, spaces: props.spaces, cluster: cluster!, refetch: props.refetch, spaceOwnerRef: spaceOwnerRef.current, metadataRef: metadataRef.current, spaceSleepModeRef: spaceSleepModeRef.current, spaceAccessRef: spaceAccessRef.current});
            }

            // check if there was an error
            if (result?.err) {
                message.ErrorCluster(result, cluster!);
                return;
            }

            // refetch
            await props.refetch();
            message.DoneCluster(cluster!);

            // close drawer
            drawer({});
        }}>
            <Query query={async () => {
                const namespaceUpdateResult = await client.cluster(props.cluster!, Resources.V1Namespace).Namespace(props.space?.metadata?.name).CanI("update");
                if (namespaceUpdateResult.err) {
                    return namespaceUpdateResult;
                }

                const rolebindingCreateResult = await client.cluster(props.cluster!, Resources.V1RoleBinding).Namespace(props.space?.metadata?.name).CanI("create");
                if (rolebindingCreateResult.err) {
                    return rolebindingCreateResult;
                }

                return Return.Value({
                    updateNamespace: namespaceUpdateResult.val,
                    createRoleBinding: rolebindingCreateResult.val
                })
            }} refetch={[props.cluster]} skip={props.mode === "create"}>
                {
                    rbacResult => {
                        if (rbacResult.error) {
                            return <ErrorMessage error={rbacResult.error} />
                        } else if (props.mode !== "create") {
                            if (rbacResult.loading) {
                                return <Loading />
                            } else if (!rbacResult.data?.createRoleBinding && !rbacResult.data?.updateNamespace) {
                                return <ErrorMessage error={Return.Failed("You have no permissions to edit this space(s)")} />;
                            }
                        }

                        return <Query query={async () => await client.management(Resources.ManagementV1ClusterMembers).Get(cluster!)} refetch={[cluster]} skip={!cluster}>
                            {
                                clusterMembersResult => {
                                    if (clusterMembersResult.error) {
                                        return <ErrorMessage error={clusterMembersResult.error} />;
                                    }

                                    const clusterMembers = clusterMembersResult.data;
                                    return <React.Fragment>
                                        {props.mode === "create" && <ProgressPopup title={"Creating Space"} ref={progressPopupRef} />}
                                        {(props.mode === "create" || rbacResult.data?.updateNamespace) && <SpaceOwner showClusterSelect={props.mode === "create" && !props.cluster}
                                                        mode={props.mode}
                                                        space={props.space}
                                                        cluster={cluster}
                                                        clusterMembers={clusterMembers}
                                                        onError={err => setError(err)}
                                                        onTemplateSelect={template => {
                                                            let metadata = template?.spec?.template?.metadata;
                                                            if (template?.metadata?.name) {
                                                                if (!metadata) {
                                                                    metadata = {}
                                                                }
                                                                if (!metadata.annotations) {
                                                                    metadata.annotations = {}
                                                                }
                                                                metadata.annotations[constants.LoftSpaceTemplate] = template.metadata.name
                                                            }

                                                            metadataRef.current?.labelsSectionRef?.current?.setMetadata(metadata);
                                                            spaceSleepModeRef.current?.selectTemplate(template);
                                                            spaceAppsRef.current?.selectTemplate(template);
                                                        }}
                                                        onClusterSelect={cluster => {
                                                            setError(undefined);
                                                            setCluster(cluster);
                                                        }}
                                                        ref={spaceOwnerRef} />}
                                        {error && <ErrorMessage error={error} />}
                                        <SpaceMetadata mode={props.mode} space={props.space} ref={metadataRef} noMargin={props.mode === "batch" || (props.mode !== "create" && !rbacResult.data?.updateNamespace)} />
                                        <SpaceSleepMode mode={props.mode} ref={spaceSleepModeRef} space={props.space} />
                                        <SpaceApps mode={props.mode} space={props.space} ref={spaceAppsRef} />
                                        <SpaceConstraints space={props.space} mode={props.mode} ref={spaceConstraintsRef} />
                                        {(props.mode === "create" || rbacResult.data?.createRoleBinding) && cluster && clusterMembers && <SpaceAccess mode={props.mode}
                                                                                                                                                      kind={"Space"}
                                                                                                                                                      cluster={cluster}
                                                                                                                                                      clusterMembers={clusterMembers}
                                                                                                                                                      defaultClusterRole={"loft-cluster-space-admin"}
                                                                                                                                                      space={props.space?.metadata?.name!}
                                                                                                                                                      ref={spaceAccessRef} />}
                                    </React.Fragment>
                                }
                            }
                        </Query>
                    }
                }
            </Query>
        </ItemDrawer>
    </React.Fragment>
}