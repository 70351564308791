import React from "react";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {DrawerDispatch, useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../../../components/Table/Table";
import useQuery from "../../../../lib/Query/Query";
import { useRouteMatch, useHistory } from "react-router-dom";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import {arr} from "../../../../lib/helpers/renderhelper";
import {V1Node} from "@kubernetes/client-node";
import {Popover} from "../../../../components/Popover/Popover";
import {cpuParser, memoryParser} from "../../../../lib/helpers/resourcehelper";
import Progress from "../../../../components/Progress/Progress";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import styles from "./Overview.module.scss";
import AppsList from "./AppsList/AppsList";
import Label from "../../../../components/Label/Label";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import ClusterSubdomain from "./ClusterSubdomain/ClusterSubdomain";
import {ErrorTypeForbidden} from "../../../../lib/result";
import FixedText from "../../../../components/FixedText/FixedText";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import {NodeStats, NodeStatsContext, NodeStatsProvider} from "../../../../contexts/NodeStats/NodeStats";
import {useApps} from "../Apps/AppsTable/AppsTable";

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, cluster: string) {
    return [
        {
            title: "Node Name",
            render: (node: V1Node) => {
                return <FixedText text={node.metadata?.name} />;
            },
        },
        {
            title: 'Status',
            render: (node: V1Node) => {
                let condition: React.ReactNode = 'Active';
                if (node.status?.conditions?.length) {
                    for (let i = 0; i < node.status?.conditions?.length; i++) {
                        const nodeCondition = node.status.conditions[i];
                        if (nodeCondition.type !== 'Ready' && nodeCondition.status !== 'False') {
                            condition = (
                                <Popover title={<span>{nodeCondition.type} - {nodeCondition.reason}</span>} content={<span>{nodeCondition.message}</span>}>
                                    <span className={"color-warning"}>{nodeCondition.type}</span>
                                </Popover>
                            );
                            break;
                        }
                    }
                }

                return condition;
            }
        },
        {
            title: 'Pods',
            render: (node: V1Node) => {
                return <NodeStats cluster={cluster} node={node.metadata!.name!}>
                    {
                        result => {
                            if (result.loading) {
                                return <Progress percent={0} />;
                            } else if (result.error) {
                                return <ErrorMessage error={result.error} />;
                            }

                            const podsUsed = arr(result.data?.pods).length;
                            const podsAvailable = node.status?.capacity?.pods ? parseInt(node.status?.capacity?.pods) : 0;
                            return <Tooltip title={podsUsed + " / " + podsAvailable}>
                                <Progress  className={styles["progress"]} percent={Math.round((podsUsed / podsAvailable) * 100)} />
                            </Tooltip>
                        }
                    }
                </NodeStats>;
            }
        },
        {
            title: 'Cores',
            render: (node: V1Node) => {
                return <NodeStats cluster={cluster} node={node.metadata!.name!}>
                    {
                        result => {
                            if (result.loading) {
                                return <Progress percent={0} />;
                            } else if (result.error) {
                                return <ErrorMessage error={result.error} />;
                            }
                            
                            const cpuUsed = cpuParser((result.data?.node?.cpu?.usageNanoCores || 0) + "n");
                            const cpuAvailable = cpuParser(node.status?.capacity?.cpu ? node.status?.capacity?.cpu : "1");
                            const percentage = Math.round((cpuUsed / cpuAvailable) * 100);
                            return <Tooltip title={cpuUsed.toFixed(2) + " / " + cpuAvailable.toFixed(2)}>
                                <Progress  className={styles["progress"]} percent={percentage} />
                            </Tooltip>
                        }
                    }
                </NodeStats> ;
            }
        },
        {
            title: 'Memory',
            render: (node: V1Node) => {
                return <NodeStats cluster={cluster} node={node.metadata!.name!}>
                    {
                        result => {
                            if (result.loading) {
                                return <Progress percent={0} />;
                            } else if (result.error) {
                                return <ErrorMessage error={result.error} />;
                            }
                            
                            const memoryUsed = memoryParser((result.data?.node?.memory?.usageBytes || 0) + "") / 1024 / 1024 / 1024;
                            const memoryAvailable = (memoryParser((result.data?.node?.memory?.availableBytes || 1) + "") / 1024 / 1024 / 1024) + memoryUsed;
                            const percentage = Math.round((memoryUsed / memoryAvailable) * 100);
                            return <Tooltip title={memoryUsed.toFixed(1) + " / " + memoryAvailable.toFixed(1) + " GB"}>
                                <Progress className={styles["progress"]} percent={percentage} />
                            </Tooltip>;
                        }
                    }
                </NodeStats>
            }
        },
        {
            title: 'Node Storage',
            render: (node: V1Node) => {
                return <NodeStats cluster={cluster} node={node.metadata!.name!}>
                    {
                        result => {
                            if (result.loading) {
                                return <Progress percent={0}/>;
                            } else if (result.error) {
                                return <ErrorMessage error={result.error}/>;
                            }

                            const nodeStorageUsed = memoryParser((result.data?.node?.fs?.usedBytes || 0) + "") / 1024 / 1024 / 1024;
                            const nodeStorageAvailable = memoryParser((result.data?.node?.fs?.capacityBytes || 1) + "") / 1024 / 1024 / 1024;
                            const percentage = Math.round((nodeStorageUsed / nodeStorageAvailable) * 100);
                            return <Tooltip title={nodeStorageUsed.toFixed(1) + " / " + nodeStorageAvailable.toFixed(1) + " GB"}>
                                <Progress  className={styles["progress"]} percent={percentage} />
                            </Tooltip>;
                        }
                    }
                </NodeStats>
            }
        },
        {
            title: 'Created',
            render: (node: V1Node) => {
                return <DynamicTime timestamp={node.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "100px",
            render: (node: V1Node) => {
                return <TableActions className={styles["actions"]}>
                    <ShowYamlPopup className={"blue-btn"} object={node} cluster={cluster} resource={Resources.V1Node} name={node.metadata?.name!} refetch={refetch} />
                </TableActions>;
            }
        }
    ];
}

function filter(item: V1Node, value: string) {
    return !!(item.metadata?.name?.includes(value))
}

export default function Overview() {
    const match = useRouteMatch();
    const history = useHistory();
    const {cluster} = match.params as any;
    const drawerDispatcher = useItemDrawer();
    const appsResult = useApps(cluster);

    // get releases
    const {loading, error, data, refetch} = useQuery(async () => await client.cluster(cluster, Resources.V1Node).List());
    if (error && error.val?.type === ErrorTypeForbidden) {
        // redirect to cluster overview
        history.push("/clusters");
        return null;
    }

    return <div>
        <NodeStatsProvider>
            <NodeStatsContext.Consumer>
                {
                    value => {
                        const newRefetch = () => {
                            value?.dispatch({refresh: true});
                            return refetch();
                        }
                        
                        return <Table className={styles["table"]} loading={loading} columns={getTableColumns(newRefetch, drawerDispatcher, cluster)} dataSource={data ? arr(data.items).map(node => { return {...node, key: node.metadata!.name!}}) : undefined} error={error} filter={filter} refetch={newRefetch} header={{
                            top: <ClusterHeader>
                                <Label.Bold>Apps</Label.Bold>
                                <div>Click on an app to install or upgrade it.</div>
                                <AppsList query={appsResult} type={"cluster"} className={styles["apps-list"]} cluster={cluster} />
                                <ClusterSubdomain cluster={cluster} />
                                <Label.Bold className={styles["nodes"]}>Nodes</Label.Bold>
                            </ClusterHeader>,
                        }} />
                    }
                }
            </NodeStatsContext.Consumer>
        </NodeStatsProvider>
    </div>
};