import {
    APIExtensionsGroup,
    APIExtensionsVersion,
    LoftSchemeGroup,
    LoftSchemeGroupCluster, LoftSchemeGroupVirtualCluster,
    LoftSchemeVersion,
    LoftSchemeVersionCluster, LoftSchemeVersionVirtualCluster,
    V1Beta1PodMetrics
} from "./types";
import {GroupVersionResource} from "./client";
import {
    V1Ingress,
    NetworkingV1beta1Ingress,
    V1ClusterRole,
    V1ClusterRoleBinding, V1ConfigMap, V1CustomResourceDefinition, V1Deployment, CoreV1Event,
    V1Namespace, V1Node,
    V1Pod, V1Role,
    V1RoleBinding, V1Secret,
    V1SelfSubjectAccessReview, V1Service, V1StatefulSet
} from "@kubernetes/client-node";
import {ConfigV1alpha1Template} from "../../gen/models/configV1alpha1Template";
import {ConfigV1alpha1TemplateInstance} from "../../gen/models/configV1alpha1TemplateInstance";
import {ManagementV1License} from "../../gen/models/managementV1License";
import {ManagementV1User} from "../../gen/models/managementV1User";
import {ManagementV1UserProfile} from "../../gen/models/managementV1UserProfile";
import {ManagementV1Team} from "../../gen/models/managementV1Team";
import {ManagementV1Cluster} from "../../gen/models/managementV1Cluster";
import {ManagementV1ClusterReset} from "../../gen/models/managementV1ClusterReset";
import {ManagementV1ClusterConnect} from "../../gen/models/managementV1ClusterConnect";
import {ManagementV1SelfSubjectAccessReview} from "../../gen/models/managementV1SelfSubjectAccessReview";
import {ClusterV1SleepModeConfig} from "../../gen/models/clusterV1SleepModeConfig";
import {ManagementV1ClusterMembers} from "../../gen/models/managementV1ClusterMembers";
import {ManagementV1UserClusters} from "../../gen/models/managementV1UserClusters";
import {ClusterV1HelmRelease} from "../../gen/models/clusterV1HelmRelease";
import {ManagementV1Feature} from "../../gen/models/managementV1Feature";
import {ManagementV1Announcement} from "../../gen/models/managementV1Announcement";
import {ManagementV1LicenseToken} from "../../gen/models/managementV1LicenseToken";
import { ConfigV1alpha1Account } from "../../gen/models/configV1alpha1Account";
import {StorageV1VirtualCluster} from "../../gen/models/agentstorageV1VirtualCluster";
import {ManagementV1ClusterVirtualClusterDefaults} from "../../gen/models/managementV1ClusterVirtualClusterDefaults";
import {ManagementV1Config} from "../../gen/models/managementV1Config";
import {ManagementV1LoftUpgrade} from "../../gen/models/managementV1LoftUpgrade";
import {ManagementV1ClusterDomain} from "../../gen/models/managementV1ClusterDomain";
import {ManagementV1ClusterAccountTemplate} from "../../gen/models/managementV1ClusterAccountTemplate";
import {ManagementV1SharedSecret} from "../../gen/models/managementV1SharedSecret";
import {ManagementV1ClusterCharts} from "../../gen/models/managementV1ClusterCharts";
import {ManagementV1OwnedAccessKey} from "../../gen/models/managementV1OwnedAccessKey";
import {ManagementV1ResetAccessKey} from "../../gen/models/managementV1ResetAccessKey";
import {ManagementV1PolicyViolation} from "../../gen/models/managementV1PolicyViolation";
import {ManagementV1Task} from "../../gen/models/managementV1Task";
import {ManagementV1TaskLog} from "../../gen/models/managementV1TaskLog";
import {ManagementV1Self} from "../../gen/models/managementV1Self";
import {PolicyV1beta1JsPolicy} from "../../gen/models/policyV1beta1JsPolicy";
import {PolicyV1beta1JsPolicyViolations} from "../../gen/models/policyV1beta1JsPolicyViolations";
import {ManagementV1TeamClusters} from "../../gen/models/managementV1TeamClusters";
import {ManagementV1UserAccessKeys} from "../../gen/models/managementV1UserAccessKeys";
import {ManagementV1TeamAccessKeys} from "../../gen/models/managementV1TeamAccessKeys";
import {ManagementV1VirtualClusterTemplate} from "../../gen/models/managementV1VirtualClusterTemplate";
import {ManagementV1App} from "../../gen/models/managementV1App";
import {VirtualclusterV1HelmRelease} from "../../gen/models/virtualclusterV1HelmRelease";
import {ManagementV1SpaceTemplate} from "../../gen/models/managementV1SpaceTemplate";
import {ClusterV1VirtualCluster} from "../../gen/models/clusterV1VirtualCluster";
import {ClusterV1Space} from "../../gen/models/clusterV1Space";
import {ManagementV1ClusterMemberAccess} from "../../gen/models/managementV1ClusterMemberAccess";
import {StorageV1ClusterQuota} from "../../gen/models/agentstorageV1ClusterQuota";
import {StorageV1LocalUser} from "../../gen/models/agentstorageV1LocalUser";
import {ManagementV1ClusterAccess} from "../../gen/models/managementV1ClusterAccess";
import {ManagementV1SpaceConstraint} from "../../gen/models/managementV1SpaceConstraint";
import {ManagementV1ClusterRoleTemplate} from "../../gen/models/managementV1ClusterRoleTemplate";
import {ClusterV1LocalClusterAccess} from "../../gen/models/clusterV1LocalClusterAccess";
import {StorageV1LocalSpaceConstraint} from "../../gen/models/agentstorageV1LocalSpaceConstraint";
import { ClusterV1ClusterQuota } from "../../gen/models/clusterV1ClusterQuota";
import {ManagementV1Event} from "../../gen/models/managementV1Event";
import {ClusterV1ChartInfo} from "../../gen/models/clusterV1ChartInfo";
import {ManagementV1IngressAuthToken} from "../../gen/models/managementV1IngressAuthToken";

export const Resources: {
    ClusterV1SleepModeConfig: GroupVersionResource<ClusterV1SleepModeConfig>,
    ClusterV1HelmRelease: GroupVersionResource<ClusterV1HelmRelease>,
    ClusterV1VirtualCluster: GroupVersionResource<ClusterV1VirtualCluster>,
    ClusterV1Space: GroupVersionResource<ClusterV1Space>,
    ClusterV1ClusterQuota: GroupVersionResource<ClusterV1ClusterQuota>,
    ClusterV1LocalClusterAccess: GroupVersionResource<ClusterV1LocalClusterAccess>,
    ClusterV1ChartInfo: GroupVersionResource<ClusterV1ChartInfo>,
    VirtualclusterV1HelmRelease: GroupVersionResource<VirtualclusterV1HelmRelease>,
    CustomResourceDefinition: GroupVersionResource<V1CustomResourceDefinition>,
    ManagementV1App: GroupVersionResource<ManagementV1App>,
    ManagementV1PolicyViolation: GroupVersionResource<ManagementV1PolicyViolation>,
    ManagementV1ResetAccessKey: GroupVersionResource<ManagementV1ResetAccessKey>,
    ManagementV1License: GroupVersionResource<ManagementV1License>,
    ManagementV1LicenseToken: GroupVersionResource<ManagementV1LicenseToken>,
    ManagementV1Self: GroupVersionResource<ManagementV1Self>,
    ManagementV1SharedSecret: GroupVersionResource<ManagementV1SharedSecret>,
    ManagementV1OwnedAccessKey: GroupVersionResource<ManagementV1OwnedAccessKey>,
    ManagementV1User: GroupVersionResource<ManagementV1User>,
    ManagementV1IngressAuthToken: GroupVersionResource<ManagementV1IngressAuthToken>,
    ManagementV1UserClusters: GroupVersionResource<ManagementV1UserClusters>,
    ManagementV1UserProfile: GroupVersionResource<ManagementV1UserProfile>,
    ManagementV1UserAccessKeys: GroupVersionResource<ManagementV1UserAccessKeys>,
    ManagementV1Team: GroupVersionResource<ManagementV1Team>,
    ManagementV1TeamClusters: GroupVersionResource<ManagementV1TeamClusters>,
    ManagementV1TeamAccessKeys: GroupVersionResource<ManagementV1TeamAccessKeys>,
    ManagementV1ClusterAccountTemplate: GroupVersionResource<ManagementV1ClusterAccountTemplate>,
    ManagementV1ClusterConnect: GroupVersionResource<ManagementV1ClusterConnect>,
    ManagementV1Cluster: GroupVersionResource<ManagementV1Cluster>,
    ManagementV1ClusterCharts: GroupVersionResource<ManagementV1ClusterCharts>,
    ManagementV1ClusterReset: GroupVersionResource<ManagementV1ClusterReset>,
    ManagementV1ClusterMembers: GroupVersionResource<ManagementV1ClusterMembers>,
    ManagementV1ClusterVirtualClusterDefaults: GroupVersionResource<ManagementV1ClusterVirtualClusterDefaults>,
    ManagementV1ClusterDomain: GroupVersionResource<ManagementV1ClusterDomain>,
    ManagementV1ClusterMemberAccess: GroupVersionResource<ManagementV1ClusterMemberAccess>,
    ManagementV1SelfSubjectAccessReview: GroupVersionResource<ManagementV1SelfSubjectAccessReview>,
    ManagementV1VirtualClusterTemplate: GroupVersionResource<ManagementV1VirtualClusterTemplate>,
    ManagementV1SpaceTemplate: GroupVersionResource<ManagementV1SpaceTemplate>,
    ManagementV1Feature: GroupVersionResource<ManagementV1Feature>,
    ManagementV1Config: GroupVersionResource<ManagementV1Config>,
    ManagementV1LoftUpgrade: GroupVersionResource<ManagementV1LoftUpgrade>,
    ManagementV1Announcement: GroupVersionResource<ManagementV1Announcement>,
    ManagementV1ClusterAccess: GroupVersionResource<ManagementV1ClusterAccess>,
    ManagementV1SpaceConstraint: GroupVersionResource<ManagementV1SpaceConstraint>,
    ManagementV1ClusterRoleTemplate: GroupVersionResource<ManagementV1ClusterRoleTemplate>,
    ManagementV1Task: GroupVersionResource<ManagementV1Task>,
    ManagementV1TaskLog: GroupVersionResource<ManagementV1TaskLog>,
    ManagementV1Event: GroupVersionResource<ManagementV1Event>,
    PolicyV1beta1JsPolicy: GroupVersionResource<PolicyV1beta1JsPolicy>,
    PolicyV1beta1JsPolicyViolations: GroupVersionResource<PolicyV1beta1JsPolicyViolations>,
    ConfigV1alpha1Account: GroupVersionResource<ConfigV1alpha1Account>,
    ConfigV1alpha1Template: GroupVersionResource<ConfigV1alpha1Template>,
    ConfigV1alpha1TemplateInstance: GroupVersionResource<ConfigV1alpha1TemplateInstance>,
    StorageV1LocalUser: GroupVersionResource<StorageV1LocalUser>,
    StorageV1VirtualCluster: GroupVersionResource<StorageV1VirtualCluster>,
    StorageV1ClusterQuota: GroupVersionResource<StorageV1ClusterQuota>,
    StorageV1LocalSpaceConstraint: GroupVersionResource<StorageV1LocalSpaceConstraint>,
    NetworkingV1Ingress: GroupVersionResource<V1Ingress>,
    NetworkingV1beta1Ingress: GroupVersionResource<NetworkingV1beta1Ingress>,
    V1StatefulSet: GroupVersionResource<V1StatefulSet>,
    V1Deployment: GroupVersionResource<V1Deployment>,
    V1Event: GroupVersionResource<CoreV1Event>,
    V1Pod: GroupVersionResource<V1Pod>,
    V1Service: GroupVersionResource<V1Service>,
    V1Node: GroupVersionResource<V1Node>,
    V1Namespace: GroupVersionResource<V1Namespace>,
    V1SelfSubjectAccessReview: GroupVersionResource<V1SelfSubjectAccessReview>,
    V1ClusterRole: GroupVersionResource<V1ClusterRole>,
    V1ClusterRoleBinding: GroupVersionResource<V1ClusterRoleBinding>,
    V1Role: GroupVersionResource<V1Role>,
    V1RoleBinding: GroupVersionResource<V1RoleBinding>,
    V1Secret: GroupVersionResource<V1Secret>,
    V1ConfigMap: GroupVersionResource<V1ConfigMap>,
    V1User: GroupVersionResource<any>,
    V1Beta1PodMetrics: GroupVersionResource<V1Beta1PodMetrics>
} = {
    "ClusterV1SleepModeConfig": {group: LoftSchemeGroupCluster, version: LoftSchemeVersionCluster, resource: "sleepmodeconfigs", kind: "SleepModeConfig", namespaced: true},
    "ClusterV1Space": {group: LoftSchemeGroupCluster, version: LoftSchemeVersionCluster, resource: "spaces", kind: "Space"},
    "ClusterV1ClusterQuota": {group: LoftSchemeGroupCluster, version: LoftSchemeVersionCluster, resource: "clusterquotas", kind: "ClusterQuota"},
    "ClusterV1HelmRelease": {group: LoftSchemeGroupCluster, version: LoftSchemeVersionCluster, resource: "helmreleases", kind: "HelmRelease", namespaced: true},
    "ClusterV1VirtualCluster": {group: LoftSchemeGroupCluster, version: LoftSchemeVersionCluster, resource: "virtualclusters", kind: "VirtualCluster", namespaced: true},
    "ClusterV1LocalClusterAccess": {group: LoftSchemeGroupCluster, version: LoftSchemeVersionCluster, resource: "localclusteraccesses", kind: "LocalClusterAccess"},
    "ClusterV1ChartInfo": {group: LoftSchemeGroupCluster, version: LoftSchemeVersionCluster, resource: "chartinfos", kind: "ChartInfo"},
    "VirtualclusterV1HelmRelease": {group: LoftSchemeGroupVirtualCluster, version: LoftSchemeVersionVirtualCluster, resource: "helmreleases", kind: "HelmRelease", namespaced: true},
    "CustomResourceDefinition": {group: APIExtensionsGroup, version: APIExtensionsVersion, resource: "customresourcedefinitions", kind: "CustomResourceDefinition"},
    "ManagementV1App": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "apps", kind: "App"},
    "ManagementV1PolicyViolation": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "policyviolations", kind: "PolicyViolation"},
    "ManagementV1ResetAccessKey": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "resetaccesskeys", kind: "ResetAccessKey"},
    "ManagementV1License": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "licenses", kind: "License"},
    "ManagementV1LicenseToken": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "licensetokens", kind: "LicenseToken"},
    "ManagementV1Self": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "selves", kind: "Self"},
    "ManagementV1SharedSecret": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "sharedsecrets", kind: "SharedSecret", namespaced: true},
    "ManagementV1OwnedAccessKey": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "ownedaccesskeys", kind: "OwnedAccessKey"},
    "ManagementV1User": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "users", kind: "User"},
    "ManagementV1IngressAuthToken": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "ingressauthtokens", kind: "IngressAuthToken"},
    "ManagementV1UserClusters": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "users", subResource: "clusters", kind: "UserClusters"},
    "ManagementV1UserProfile": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "users", subResource: "profile", kind: "UserProfile"},
    "ManagementV1UserAccessKeys": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "users", subResource: "accesskeys", kind: "UserAccessKeys"},
    "ManagementV1Team": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "teams", kind: "Team"},
    "ManagementV1TeamClusters": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "teams", subResource: "clusters", kind: "TeamClusters"},
    "ManagementV1TeamAccessKeys": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "teams", subResource: "accesskeys", kind: "TeamAccessKeys"},
    "ManagementV1ClusterAccountTemplate": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusteraccounttemplates", kind: "ClusterAccountTemplate"},
    "ManagementV1ClusterConnect": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusterconnect", kind: "ClusterConnect"},
    "ManagementV1Cluster": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusters", kind: "Cluster"},
    "ManagementV1ClusterCharts": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusters", subResource: "charts", kind: "ClusterCharts"},
    "ManagementV1ClusterReset": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusters", subResource: "reset", kind: "ClusterReset"},
    "ManagementV1ClusterMembers": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusters", subResource: "members", kind: "ClusterMembers"},
    "ManagementV1ClusterVirtualClusterDefaults": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusters", subResource: "virtualclusterdefaults", kind: "ClusterVirtualClusterDefaults"},
    "ManagementV1ClusterDomain": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusters", subResource: "domain", kind: "ClusterDomain"},
    "ManagementV1ClusterMemberAccess": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusters", subResource: "memberaccess", kind: "ClusterMemberAccess"},
    "ManagementV1SelfSubjectAccessReview": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "selfsubjectaccessreviews", kind: "SelfSubjectAccessReview"},
    "ManagementV1VirtualClusterTemplate": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "virtualclustertemplates", kind: "VirtualClusterTemplate"},
    "ManagementV1SpaceTemplate": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "spacetemplates", kind: "SpaceTemplate"},
    "ManagementV1Feature": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "features", kind: "Feature"},
    "ManagementV1Config": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "configs", kind: "Config"},
    "ManagementV1LoftUpgrade": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "loftupgrades", kind: "LoftUpgrade"},
    "ManagementV1Announcement": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "announcements", kind: "Announcement"},
    "ManagementV1ClusterAccess": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusteraccesses", kind: "ClusterAccess"},
    "ManagementV1SpaceConstraint": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "spaceconstraints", kind: "SpaceConstraint"},
    "ManagementV1ClusterRoleTemplate": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "clusterroletemplates", kind: "ClusterRoleTemplate"},
    "ManagementV1Task": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "tasks", kind: "Task"},
    "ManagementV1TaskLog": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "tasks", subResource: "log", kind: "TaskLog"},
    "ManagementV1Event": {group: LoftSchemeGroup, version: LoftSchemeVersion, resource: "events", kind: "Event"},
    "PolicyV1beta1JsPolicy": {group: "policy.jspolicy.com", version: "v1beta1", resource: "jspolicies", kind: "JsPolicy"},
    "PolicyV1beta1JsPolicyViolations": {group: "policy.jspolicy.com", version: "v1beta1", resource: "jspolicyviolations", kind: "JsPolicyViolations"},
    "ConfigV1alpha1Account": {group: "config.kiosk.sh", version: "v1alpha1", resource: "accounts", kind: "Account"},
    "ConfigV1alpha1Template": {group: "config.kiosk.sh", version: "v1alpha1", resource: "templates", kind: "Template"},
    "ConfigV1alpha1TemplateInstance": {group: "config.kiosk.sh", version: "v1alpha1", resource: "templateinstances", kind: "TemplateInstance", namespaced: true},
    "StorageV1VirtualCluster": {group: "storage.loft.sh", version: LoftSchemeVersion, resource: "virtualclusters", kind: "VirtualCluster", namespaced: true},
    "StorageV1ClusterQuota": {group: "storage.loft.sh", version: LoftSchemeVersion, resource: "clusterquotas", kind: "ClusterQuota"},
    "StorageV1LocalUser": {group: "storage.loft.sh", version: LoftSchemeVersion, resource: "localusers", kind: "LocalUser"},
    "StorageV1LocalSpaceConstraint": {group: "storage.loft.sh", version: LoftSchemeVersion, resource: "localspaceconstraints", kind: "LocalSpaceConstraint"},
    "NetworkingV1beta1Ingress": {group: "networking.k8s.io", version:"v1beta1", resource: "ingresses", kind: "Ingress", namespaced: true},
    "NetworkingV1Ingress": {group: "networking.k8s.io", version:"v1", resource: "ingresses", kind: "Ingress", namespaced: true},
    "V1StatefulSet": {group: "apps", version:"v1", resource: "statefulsets", kind: "StatefulSet", namespaced: true},
    "V1Deployment": {group: "apps", version:"v1", resource: "deployments", kind: "Deployment", namespaced: true},
    "V1Event": {group: "", version:"v1", resource: "events", kind: "Event", namespaced: true},
    "V1Pod": {group: "", version:"v1", resource: "pods", kind: "Pod", namespaced: true},
    "V1Node": {group: "", version:"v1", resource: "nodes", kind: "Node"},
    "V1Namespace": {group: "", version:"v1", resource: "namespaces", kind: "Namespace"},
    "V1SelfSubjectAccessReview": {group: "authorization.k8s.io", version: "v1", resource: "selfsubjectaccessreviews", kind: "SelfSubjectAccessReview"},
    "V1ClusterRole": {group: "rbac.authorization.k8s.io", version: "v1", resource: "clusterroles", kind: "ClusterRole"},
    "V1ClusterRoleBinding": {group: "rbac.authorization.k8s.io", version: "v1", resource: "clusterrolebindings", kind: "ClusterRoleBinding"},    
    "V1Role": {group: "rbac.authorization.k8s.io", version: "v1", resource: "roles", kind: "Role", namespaced: true},
    "V1RoleBinding": {group: "rbac.authorization.k8s.io", version: "v1", resource: "rolebindings", kind: "RoleBinding", namespaced: true},
    "V1Secret": {group: "", version:"v1", resource: "secrets", kind: "Secret", namespaced: true},
    "V1ConfigMap": {group: "", version:"v1", resource: "configmaps", kind: "ConfigMap", namespaced: true},
    "V1Service": {group: "", version:"v1", resource: "services", kind: "Service", namespaced: true},
    "V1User": {group: "", version:"v1", resource: "users", kind: "User", namespaced: false},
    "V1Beta1PodMetrics": {group: "metrics.k8s.io", version:"v1beta1", resource: "pods", kind: "PodMetrics", namespaced: true}
};

export function NewResource<T>(groupVersionResource: GroupVersionResource<T>, name?: string, data?: T): T {
    return {...{
        "apiVersion": groupVersionResource.group ? groupVersionResource.group + "/" + groupVersionResource.version : groupVersionResource.version,
        "kind": groupVersionResource.kind,
        "metadata": {
            "name": name,
        }
    }, ...data} as any
}

