import { IDisposable, ITerminalAddon, Terminal } from 'xterm';

export interface IStreamAddonOptions {
    onClose?(): void;
}

export class StreamAddon implements ITerminalAddon {
    private _disposables: IDisposable[] = [];
    private _stream: ReadableStreamDefaultReader<Uint8Array>;
    private _closed: boolean = false;
    private _onClose: () => void = () => { };

    constructor(stream: ReadableStreamDefaultReader<Uint8Array>, options?: IStreamAddonOptions) {
        this._stream = stream;
        if (options?.onClose != null) {
            this._onClose = options.onClose
        }
        (async () => {
            await stream.closed
            this._closed = true;
            this._onClose();
        })()
    }

    public activate(terminal: Terminal): void {
        (async () => {
            for await (const chunk of streamAsyncIterator(this._stream)) {
                terminal.write(chunk!);
            }
        })()
    }

    public dispose(): void {
        if (!this._closed) {
            this._stream.cancel();
        }

        for (const d of this._disposables) {
            d.dispose();
        }
    }
}

function streamAsyncIterator(reader: ReadableStreamDefaultReader<Uint8Array>): AsyncIterableIterator<Uint8Array> {
    return {
        async next(): Promise<IteratorResult<Uint8Array>> {
            const { done, value } = await reader.read();
            return { done: done!, value: value! }
        },
        async return(): Promise<IteratorResult<Uint8Array>> {
            await reader.releaseLock();
            return { done: true, value: undefined }
        },
        [Symbol.asyncIterator]() {
            return this;
        }
    }
}