import React, {useState} from "react";
import {MinusSquareOutlined, PlusSquareOutlined} from "@ant-design/icons/lib";
import styles from "./SectionExpander.module.scss";

interface SectionExpanderProps {
    name: React.ReactNode;
    
    className?: string;
}

export default function SectionExpander(props: React.PropsWithChildren<SectionExpanderProps>) {
    const [expanded, setExpanded] = useState<boolean>(false);
    return <div className={props.className}>
        <div className={styles["title"]} onClick={() => setExpanded(!expanded)}>
            {expanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />}{props.name}
        </div>
        <div className={!expanded ? styles["content"] + " " + styles["hidden"] : styles["content"] }>
            {props.children}
        </div>
    </div>
}
