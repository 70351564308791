import React from "react";
import AuthLayout from "../../../components/AuthLayout/AuthLayout";
import client from "../../../lib/client";
import {Button, Input, Popover} from "antd";
import Label from "../../../components/Label/Label";
import styles from "./Login.module.scss";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import {ResultError, Return} from "../../../lib/result";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {findGetParameter, findHashParameter, randomString} from "../../../lib/helper";
import Query from "../../../components/Query/Query";
import Loading from "../../../components/Loading/Loading";
import {V1Info, V1OIDCRedirect, V1OIDCToken} from "../../../lib/types";
import AsyncButton from "../../../components/AsyncButton/AsyncButton";
import {GitlabOutlined, GithubOutlined, LoginOutlined, GoogleOutlined} from "@ant-design/icons/lib";
import {NewResource, Resources} from "../../../lib/resources";

export const LOFT_REDIRECT_ON_LOGIN_IDENTIFIER = "loft_redirect_on_login";

const LOFT_CLI_LOGIN_IDENTIFIER = "loft_cli_login";

const LOFT_HOST_IDENTIFIER = "loft_ingress_host";
const LOFT_PATH_IDENTIFIER = "loft_ingress_path";
const LOFT_SIGNATURE_IDENTIFIER = "loft_ingress_signature";

const host = findGetParameter("host");
const path = findGetParameter("path");
const signature = findGetParameter("signature");
if (host && path && signature) {
    localStorage.setItem(LOFT_HOST_IDENTIFIER, host);
    localStorage.setItem(LOFT_PATH_IDENTIFIER, path);
    localStorage.setItem(LOFT_SIGNATURE_IDENTIFIER, signature);
}

interface Props extends RouteComponentProps {

}

async function ssoLogin(loginEndpoint: string | undefined, setError: (err: ResultError) => void) {
    const result = await client.doRaw<V1OIDCRedirect>(loginEndpoint!);
    if (result.err) {
        setError(result);
        return;
    }

    const cli = findGetParameter("cli");
    if (cli) {
        localStorage.setItem(LOFT_CLI_LOGIN_IDENTIFIER, "true")
    }
    
    if (result.val.samlId) {
        const form = document.createElement("form");
        const element1 = document.createElement("input");
        const element2 = document.createElement("input");
        form.method = "POST";
        form.action = result.val.redirect+"";
        element1.value=result.val.samlData+"";
        element1.name="SAMLRequest";
        form.appendChild(element1);
        element2.value=result.val.samlId+"";
        element2.name="RelayState";
        form.appendChild(element2);
        document.body.appendChild(form);
        form.submit();
    } else {
        window.location.href = result.val.redirect!;
    }
}

async function redirect(props: Props, setError: (err: ResultError) => void) {
    const cli = findGetParameter("cli") === "true" || findHashParameter("cli") === "true" || localStorage.getItem(LOFT_CLI_LOGIN_IDENTIFIER) === "true";
    if (!cli) {
        // get ingress & signature if specified
        const host = localStorage.getItem(LOFT_HOST_IDENTIFIER);
        const path = localStorage.getItem(LOFT_PATH_IDENTIFIER);
        const signature = localStorage.getItem(LOFT_SIGNATURE_IDENTIFIER);
        if (host && path && signature) {
            localStorage.removeItem(LOFT_HOST_IDENTIFIER);
            localStorage.removeItem(LOFT_PATH_IDENTIFIER);
            localStorage.removeItem(LOFT_SIGNATURE_IDENTIFIER);

            // make an api request to create a token
            const result = await client.management(Resources.ManagementV1IngressAuthToken).Create({
                spec: {
                    host,
                    signature
                }
            })
            if (result.err) {
                setError(result);
                return
            }

            window.location.href = "http://"+host+"/loft/callback?token="+encodeURIComponent(result.val.status!.token!)+"&path="+encodeURIComponent(path)
        } else {
            const redirectUri = localStorage.getItem(LOFT_REDIRECT_ON_LOGIN_IDENTIFIER);
            if (redirectUri) {
                localStorage.removeItem(LOFT_REDIRECT_ON_LOGIN_IDENTIFIER);
                window.location.href = redirectUri;
                return;
            }

            props.history.push("/spaces");
        }

        return;
    }
    
    // remove cli login identifier
    localStorage.removeItem(LOFT_CLI_LOGIN_IDENTIFIER);

    // find out the current user
    const userResult = await client.getUser();
    if (userResult.err) {
        setError(userResult);
        return;
    }

    // create a new access key for the cli. name doesn't matter since it will be ignored
    const now = new Date();
    const newAccessKey = NewResource(Resources.ManagementV1OwnedAccessKey, "cli", {
        spec: {
            user: userResult.val!,
            displayName: "CLI Login  - " + getMonth(now.getMonth()) + " " + now.getDate() + ", " + now.getFullYear(),
            type: "Login",
            ttl: 3600 * 24 * 30,
            ttlAfterLastActivity: true,
        }
    })

    const result = await client.management(Resources.ManagementV1OwnedAccessKey).Create(newAccessKey);
    if (result.err) {
        setError(result);
        return;
    }

    // directly redirect if we are logged in with username and password
    window.location.href = "http://localhost:25843/login?username=" + encodeURIComponent("youareusinganoutdatedcli") + "&key=" + encodeURIComponent(result.val.spec?.key!);
    return;
}

function getMonth(month: number) {
    switch (month){
        case 0:
            return "January";
        case 1:
            return "February";
        case 2:
            return "March";
        case 3:
            return "April";
        case 4:
            return "May";
        case 5:
            return "June";
        case 6:
            return "July";
        case 7:
            return "August";
        case 8:
            return "September";
        case 9:
            return "October";
        case 10:
            return "November";
        case 11:
            return "December";
    }
    
    return month + "";
}

function renderSSO(type: string | undefined, loginEndpoint: string | undefined, error: ResultError, setError: (err: ResultError) => void) {
    let buttonContent = <React.Fragment>
        <LoginOutlined />Sign in with SSO
    </React.Fragment>;
    if (type === "github") {
        buttonContent = <React.Fragment>
            <GithubOutlined />Sign in with GitHub
        </React.Fragment>;
    }
    if (type === "gitlab") {
        buttonContent = <React.Fragment>
            <GitlabOutlined />Sign in with Gitlab
        </React.Fragment>;
    }
    if (type === "microsoft") {
        buttonContent = <React.Fragment>
            <LoginOutlined />Sign in with Microsoft
        </React.Fragment>;
    }
    if (type === "google") {
        buttonContent = <React.Fragment>
            <GoogleOutlined />Sign in with Google
        </React.Fragment>;
    }

    return <div className={styles["oidc-wrapper"]}>
        <AsyncButton onClickAsync={() => ssoLogin(loginEndpoint, setError)}>
            {buttonContent}
        </AsyncButton>
        <ErrorMessage className={styles["error"]} error={error} />
    </div>
}

function renderPasswordLogin(username: string, password: string, setUsername: (a: string) => void, setPassword: (a: string) => void, error: ResultError) {
    return <React.Fragment>
        <Label>Email address or username</Label>
        <Input tabIndex={1} placeholder={"name@company.tld"} value={username} onChange={(e) => setUsername(e.target.value)} />
        <Label className={styles["password"]}>
            Password
            <Popover content={<div className={styles["reset-password"]}>
                <div>Please contact an administrator to reset your password.</div> 
                <div>If you are the administrator, run <b><i>loft reset password --user {username || "admin"}</i></b></div>
            </div>} trigger="click">
                <a href={"javascript:void"}>Forgot password?</a>
            </Popover>
        </Label>
        <Input.Password tabIndex={2} placeholder={"*************"} value={password} onChange={(e) => setPassword(e.target.value)} />
        <ErrorMessage className={styles["error"]} error={error} />
        <div className={styles["sign-in-wrapper"]}>
            <Button tabIndex={3} type={"primary"} htmlType={"submit"}>Sign in</Button>
        </div>
    </React.Fragment>
}

function Login(props: Props) {
    const [username, setUsername] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [oidcError, setOidcError] = React.useState<ResultError>(Return.Ok());
    const [error, setError] = React.useState<ResultError>(Return.Ok());
    const [done, setDone] = React.useState<boolean>(false);
    if (done) {
        if (oidcError) {
            return <ErrorMessage error={oidcError} />;
        }
        
        return null;
    }

    // check if we got a access_key
    const accessKey = findHashParameter("access_key");
    if (accessKey) {
        (async () => {
            const result = await client.loginWithAccessKey(accessKey);
            if (result.err) {
                console.error(result);
                setOidcError(result);
                return;
            }

            redirect(props, setOidcError);
        })()
        setDone(true);
        return null;
    }

    // directly redirect if we are already logged in
    if (client.isLoggedIn()) {
        redirect(props, setOidcError);
        setDone(true);
        return null;
    }

    return <AuthLayout title="Sign in to your account" description="to access your spaces" onSubmit={async () => {
        if (!username) {
            setError(Return.Failed("Please specify an username"));
            return false;
        } else if (!password) {
            setError(Return.Failed("Please specify a password"));
            return false;
        }

        const response = await client.login(username, password);
        if (response.err) {
            setError(response);
            return false;
        }

        redirect(props, setOidcError);
        setDone(true);
        return false;
    }}>
        <Query query={async () => await client.doRaw<V1Info>("/auth/info")}>
            {
                result => {
                    if (result.loading) {
                        return <Loading />;
                    } else if (result.error) {
                        return <ErrorMessage error={result.error} />;
                    }

                    const passwordEnabled = !!result.data?.methods?.password?.enabled;
                    const ssoEnabled = !!result.data?.methods?.sso?.enabled;
                    return <React.Fragment>
                        {ssoEnabled && renderSSO(result.data?.methods?.sso?.type, result.data?.methods?.sso?.loginEndpoint, oidcError, setOidcError)}
                        {ssoEnabled && passwordEnabled && <div className={styles["or"]}>
                            <div className={styles["text"]}>OR</div>
                            <div className={styles["line"]} />
                        </div>}
                        {passwordEnabled && renderPasswordLogin(username, password, setUsername, setPassword, error)}
                        {!ssoEnabled && !passwordEnabled && <div className={styles["no-login"]}>Login is currently not possible</div>}
                        <div className={styles["legal-hint"]}>
                            By signing in, you agree to the <a href={"https://loft.sh/terms"}>Terms of Service</a> and the <a href={"https://loft.sh/privacy-policy"}>Privacy Policy</a> of Loft.
                        </div>
                    </React.Fragment>
                }
            }
        </Query>
    </AuthLayout>
}


export default withRouter(Login);