import React from "react";
import { ManagementV1App } from "../../../../gen/models/managementV1App";
import {SectionProps} from "../ItemDrawer";
import {Result, ResultError, Return} from "../../../lib/result";
import jsyaml from "js-yaml";
import {getDeepValue, setDeepValue} from "../../../lib/helper";
import {StorageV1AppParameter} from "../../../../gen/models/storageV1AppParameter";
import {arr, selectDefaultFilter} from "../../../lib/helpers/renderhelper";
import Label from "../../Label/Label";
import Description from "../../Description/Description";
import Section from "../Section/Section";
import Input from "../../Input/Input";
import Select from "../../Select/Select";
import Checkbox from "../../Checkbox/Checkbox";
import TextArea from "../../TextArea/TextArea";
const {Option} = Select;

interface AppParametersState {
    parameters: string;
}

interface AppParametersProps extends SectionProps {
    parameterDefinitions: StorageV1AppParameter[] | undefined;
    
    parameters?: string;
}

function validate(label: string, value: any, param: StorageV1AppParameter): ResultError {
    if (!param.type || param.type === "string" || param.type === "password" || param.type === "multiline") {
        if (typeof value !== "string") {
            return Return.Failed(`${label} has wrong type ${typeof value}, but expected string`)
        }
        if (arr(param.options).length > 0 && !param.options!.includes(value)) {
            return Return.Failed(`${label} has invalid value, allowed values are: ${param.options!.join(" ")}`)
        }
        if (param.validation) {
            const regEx = new RegExp(param.validation);
            if (!regEx.test(value)) {
                return Return.Failed(`${label} has invalid value, must match regex ${param.validation}`)
            }
        }
        if (param.invalidation) {
            const regEx = new RegExp(param.invalidation);
            if (regEx.test(value)) {
                return Return.Failed(`${label} has invalid value, cannot match regex ${param.invalidation}`)
            }
        }
        
        return Return.Ok();
    } else if (param.type === "number") {
        if (typeof value !== "number") {
            return Return.Failed(`${label} has wrong type ${typeof value}, but expected number`)
        }
        if (param.min !== undefined && value < param.min) {
            return Return.Failed(`${label} cannot be smaller than ${param.min}`)
        }
        if (param.max !== undefined && value > param.max) {
            return Return.Failed(`${label} cannot be greater than ${param.max}`)
        }
        
        return Return.Ok();
    } else if (param.type === "boolean") {
        if (typeof value !== "boolean") {
            return Return.Failed(`${label} has wrong type ${typeof value}, but expected boolean`)
        }
        
        return Return.Ok();
    }
    
    return Return.Ok();
} 

interface ParameterSection {
    section: string;
    parameters: StorageV1AppParameter[];
}

function groupParameters(parameters: StorageV1AppParameter[] | undefined): ParameterSection[] {
    const sections: ParameterSection[] = [{section: "", parameters: []}];
    for (let i = 0; i < arr(parameters).length; i++) {
        const param = parameters![i];
        if (!param.variable || !param.label) {
            continue
        }
        
        if (!param.section) {
            sections[0].parameters.push(param);
            continue;
        }
        
        const section = sections.find(section => section.section === param.section);
        if (section) {
            section.parameters.push(param);
        } else {
            sections.push({
                section: param.section,
                parameters: [param]
            })
        }
    }
    
    return sections;
}

function getDefaultParameters(parametersString: string | undefined, definitions: StorageV1AppParameter[] | undefined) {
    if (arr(definitions).length === 0) {
        return "";
    }

    let parameters: any = {};
    if (parametersString) {
        try {
            parameters = jsyaml.load(parametersString);
            if (!parameters) {
                parameters = {};
            }
        } catch(err) {
            console.error(err)
        }
    }
    
    for (let i=0; i<definitions!.length; i++) {
        const value = getDeepValue(parameters, definitions![i].variable!);
        if (value === undefined && definitions![i].defaultValue !== undefined) {
            setDeepValue(parameters, definitions![i].variable!, definitions![i].defaultValue);
        }
    }
    
    return jsyaml.dump(parameters);
}

export default class AppParameters extends React.PureComponent<AppParametersProps, AppParametersState> {
    state: AppParametersState = {
        parameters: this.props.mode === "create" ? getDefaultParameters(this.props.parameters, this.props.parameterDefinitions) : this.props.parameters || "",
    };
    
    getParameters = (skipValidation?: boolean): Result<string> => {
        const parameters = this.props.parameterDefinitions;
        if (!parameters?.length) {
            return Return.Value("");
        }
        
        try {
            const obj = jsyaml.load(this.state.parameters);
            for (let i = 0; i < parameters.length; i++) {
                if (!parameters[i].variable || !parameters[i].label) {
                    continue
                }
                
                let value = getDeepValue(obj, parameters[i].variable!);
                if (value === undefined && parameters[i].required) {
                    if (parameters[i].defaultValue) {
                        if (parameters[i].type === "number") {
                            setDeepValue(obj, parameters[i].variable!, parseInt(parameters[i].defaultValue!));
                        } else if (parameters[i].type === "boolean") {
                            setDeepValue(obj, parameters[i].variable!, parameters[i].defaultValue! === 'true');
                        } else {
                            setDeepValue(obj, parameters[i].variable!, parameters[i].defaultValue);
                        }
                        
                        continue;
                    }
                    
                    if (skipValidation) {
                        continue
                    }
                    return Return.Failed(`${parameters[i].label} is required`);
                } else if (parameters[i].type === "number") {
                    setDeepValue(obj, parameters[i].variable!, parseInt(value));
                    value = getDeepValue(obj, parameters[i].variable!);
                }
                
                if (!skipValidation) {
                    const result = validate(parameters[i].label!, value, parameters[i]);
                    if (result.err) {
                        return result;
                    }
                }
            }
            
            return Return.Value(jsyaml.dump(obj))
        } catch(err: any) {
            console.error("Parse yaml: "+(err?.message || err));
            if (skipValidation) {
                return Return.Value("");
            }
            
            return Return.Failed("Parse yaml: "+(err?.message || err))
        }
    }
    
    renderParameterInput = (param: StorageV1AppParameter) => {
        let parameters: any = {};
        try {
            parameters = jsyaml.load(this.state.parameters);
            if (!parameters) {
                parameters = {};
            }
        } catch(err) {
            console.error(err)
        }
        
        const value = getDeepValue(parameters, param.variable!);
        const setValue = (value: any) => {
            setDeepValue(parameters, param.variable!, value);
            this.setState({parameters: jsyaml.dump(parameters)})
        }

        if (arr(param.options).length > 0) {
            return <Select showSearch
                           filterOption={selectDefaultFilter}
                           value={value}
                           style={{"width": "100%"}}
                           placeholder={param.placeholder}
                           onChange={value => setValue(value)}>
                {arr(param.options).map(option => <Option key={option} value={option}>{option}</Option>)}
            </Select>
        } else if (!param.type || param.type === "string") {
            return <Input placeholder={param.placeholder} 
                          value={value} 
                          onChange={(e) => setValue(e.target.value)} />
        } else if (param.type === "password") {
            return <Input placeholder={param.placeholder}
                          value={value}
                          type={"password"}
                          onChange={(e) => setValue(e.target.value)} />
        } else if (param.type === "multiline") {
            return <TextArea placeholder={param.placeholder}
                             value={value}
                             onChange={(e) => setValue(e.target.value)} />
        } else if (param.type === "boolean") {
            return <Checkbox value={value} onChange={value => setValue(value.target.checked)}>{param.label}</Checkbox>
        } else if (param.type === "number") {
            return <Input placeholder={param.placeholder}
                          value={value}
                          type={"number"}
                          onChange={(e) => setValue(parseInt(e.target.value))} />
        }
        
        return undefined;
    }
    
    renderParameter = (param: StorageV1AppParameter) => {
        return <React.Fragment key={param.variable}>
            <Label>{param.label}{param.required && <span className={"color-error"}>*</span>}</Label>
            {this.renderParameterInput(param)}
            {param.description && <Description>{param.description}</Description>}
        </React.Fragment>
    }
    
    renderParameters = (params: StorageV1AppParameter[]) => {
        const rows: React.ReactNode[] = [];
        for (let i = 0; i < params.length; i+=2) {
            if (i+1 >= params.length) {
                rows.push(<div key={params[i].variable}>
                    {this.renderParameter(params[i])}
                </div>);
            } else {
                rows.push(<div className={"row"} key={params[i].variable}>
                    <div>
                        {this.renderParameter(params[i])}
                    </div>
                    <div>
                        {this.renderParameter(params[i+1])}
                    </div>
                </div>)
            }
        }
        
        return rows;
    }
    
    renderSection = (section: ParameterSection) => {
        if (!section.section) {
            return <React.Fragment key={section.section+""}>
                {this.renderParameters(section.parameters)}
            </React.Fragment>
        }
        
        return <Section title={section.section}>
            {this.renderParameters(section.parameters)}
        </Section>    
    }
    
    render() {
        if (this.props.mode === "batch") {
            return null;
        }
        
        return <div>
            {groupParameters(this.props.parameterDefinitions).map(section => this.renderSection(section))}
        </div>
    }
}