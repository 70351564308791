import React, {RefObject} from "react";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import Select from "../../../../../components/Select/Select";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {Result, ResultError, Return} from "../../../../../lib/result";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import Query from "../../../../../components/Query/Query";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Input from "../../../../../components/Input/Input";
import Description from "../../../../../components/Description/Description";
import YAMLEditor from "../../../../../components/YAMLEditor/YAMLEditor";
import Label from "../../../../../components/Label/Label";
import styles from "./VClusterTemplateAdvancedOptions.module.scss";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import Section from "../../../../../components/Drawer/Section/Section";
import {
    getSelectedApps,
    reduceSelectedApps,
    SelectedApp,
    TargetNamespaceSwitcher, updateSelectedApps
} from "../../../VClusters/VClusterDrawer/Sections/VClusterAdvancedOptions";
import {StorageV1VirtualClusterTemplate} from "../../../../../../gen/models/storageV1VirtualClusterTemplate";
import {ManagementV1App} from "../../../../../../gen/models/managementV1App";
import LabelsAnnotationsSection from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import {ManagementV1VirtualClusterTemplate} from "../../../../../../gen/models/managementV1VirtualClusterTemplate";
import {displayName} from "../../../../../lib/helper";
import {
    defaultSpaceObjects
} from "../../../../Spaces/SpaceTemplates/SpaceTemplateDrawer/Sections/SpaceTemplateConfiguration";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import constants from "../../../../../constants/constants";
const { Option } = Select;

interface VClusterTemplateAdvancedOptionsState {
    values?: string;
    version?: string;
    distro?: string;
    objects?: string;
    automaticSync: boolean;
    apps?: SelectedApp[];
}

interface VClusterTemplateAdvancedOptionsProps extends SectionProps {
    vClusterTemplate?: ManagementV1VirtualClusterTemplate;
}

export default class VClusterTemplateAdvancedOptions extends React.PureComponent<VClusterTemplateAdvancedOptionsProps, VClusterTemplateAdvancedOptionsState> {
    vClusterLabelsSectionRef?: RefObject<LabelsAnnotationsSection>;
    
    state: VClusterTemplateAdvancedOptionsState = {
        values: this.props.vClusterTemplate?.spec?.template?.helmRelease?.values || `# Additional helm values for the virtual cluster
# Loft will automatically add the correct service CIDR 
# and k3s version to the helm values upon deployment
storage:
  size: 5Gi

# syncer:
   # If you don't want to sync ingresses from the virtual cluster to 
   # the host cluster uncomment the next lines
   # extraArgs: ["--disable-sync-resources=ingresses"]`,
        version: this.props.vClusterTemplate?.spec?.template?.helmRelease?.chart?.version,
        distro: this.props.vClusterTemplate?.spec?.template?.helmRelease?.chart?.name || "vcluster",
        objects: this.props.vClusterTemplate?.spec?.template?.objects,
        automaticSync: this.props.vClusterTemplate?.metadata?.annotations?.[constants.LoftTemplateAlwaysSyncAnnotation] === "true",
        apps: getSelectedApps(arr(this.props.vClusterTemplate?.spec?.template?.apps)),
    };

    constructor(props: VClusterTemplateAdvancedOptionsProps) {
        super(props);

        this.vClusterLabelsSectionRef = React.createRef<LabelsAnnotationsSection>();
    }

    create = async (vCluster: ManagementV1VirtualClusterTemplate): Promise<ResultError> => {
        if (!vCluster.spec) {
            vCluster.spec = {};
        }
        if (!vCluster.spec.template) {
            vCluster.spec.template = {};
        }
        if (!vCluster.spec.template.helmRelease) {
            vCluster.spec.template.helmRelease = {};
        }
        if (!vCluster.spec.template.apps) {
            vCluster.spec.template.apps = [];
        }
        if (!vCluster.spec.template.metadata) {
            vCluster.spec.template.metadata = {};
        }
        // set virtual cluster annotations
        let annotationsResult = this.vClusterLabelsSectionRef!.current!.create(vCluster.spec!.template!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        vCluster.spec.template.helmRelease.chart = {
            name: this.state.distro,
            version: this.state.version
        }
        vCluster.spec.template.helmRelease.values = this.state.values;
        vCluster.spec.template.objects = this.state.objects;
        vCluster.spec.template.apps = [];
        const apps = arr(this.state.apps);
        for (let i = 0; i < apps.length; i++) {
            const app = apps[i];
            const namespaces = arr(app.targetNamespaces);
            for (let j = 0; j < namespaces.length; j++) {
                const namespace = namespaces[j];
                vCluster.spec.template.apps.push({
                    name: app.app,
                    namespace: namespace
                })
            }
        }
        if (this.state.automaticSync) {
            if (!vCluster.metadata) {
                vCluster.metadata = {};
            }
            if (!vCluster.metadata.annotations) {
                vCluster.metadata.annotations = {};
            }
            vCluster.metadata.annotations[constants.LoftTemplateAlwaysSyncAnnotation] = "true";
        } else {
            if (!vCluster.metadata) {
                vCluster.metadata = {};
            }
            if (!vCluster.metadata.annotations) {
                vCluster.metadata.annotations = {};
            }
            delete vCluster.metadata.annotations[constants.LoftTemplateAlwaysSyncAnnotation];
        }
        return Return.Ok();
    };

    update = async (vCluster: StorageV1VirtualClusterTemplate): Promise<ResultError> => {
        return this.create(vCluster);
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <React.Fragment>
            <Section title={"Configuration"}>
            <Query query={async () => {
                let apps: ManagementV1App[] = [];
                const appsResult = await client.management(Resources.ManagementV1App).List();
                if (appsResult.err) {
                    console.error(appsResult.val)
                } else {
                    apps = arr(appsResult.val.items);
                }

                return Return.Value({
                    apps
                });
            }}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />;
                        }

                        return <React.Fragment>
                            <LabelsAnnotationsSection {...this.props} name={"Virtual Cluster Labels & Annotations"} metadata={this.props.vClusterTemplate ? this.props.vClusterTemplate.spec?.template?.metadata : undefined} ref={this.vClusterLabelsSectionRef} />
                            <div className={"row"}>
                                <div>
                                    <Label>Virtual Cluster Version</Label>
                                    <Input resetable={this.props.mode !== "create"}
                                           placeholder={"Empty for default version"}
                                           value={this.state.version}
                                           onChange={(e) => this.setState({version: e.target.value})} />
                                    <Description>The chart version of the virtual cluster to deploy</Description>
                                </div>
                                <div>
                                    <Label>Virtual Kubernetes Distribution</Label>
                                    <Select
                                        className={styles["select"]}
                                        style={{ width: '100%' }}
                                        placeholder="Please select a Kubernetes distribution"
                                        value={this.state.distro}
                                        onChange={(value) => this.setState({distro: value})}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={selectDefaultFilter}
                                    >
                                        <Option key={"k3s"} value={"vcluster"}>k3s</Option>
                                        <Option key={"k0s"} value={"vcluster-k0s"}>k0s</Option>
                                        <Option key={"k8s"} value={"vcluster-k8s"}>k8s</Option>
                                    </Select>
                                    <Description>The Kubernetes distribution to use for the virtual cluster.</Description>
                                </div>
                            </div>
                            <Label>Helm Values for Virtual Cluster Chart</Label>
                            <YAMLEditor value={this.state.values} 
                                        minLines={10}
                                        maxLines={100}
                                        onChange={val => this.setState({values: val})} />
                            <Description>For all available values, refer to the <a href={"https://vcluster.com/docs"} target={"_blank"}>vcluster documentation</a></Description>
                            <SectionExpander name={"Objects"}>
                                <Label>Objects that will be created inside the virtual cluster</Label>
                                <YAMLEditor placeholder={defaultSpaceObjects}
                                            value={this.state.objects}
                                            minLines={20}
                                            maxLines={100}
                                            onChange={val => this.setState({objects: val})} />
                                <Description>These objects will be created inside the virtual cluster. Objects can also be synced from the template.</Description>
                            </SectionExpander>
                            <SectionExpander name={"Apps"}>
                                <Label>Which apps should get deployed within the virtual cluster?</Label>
                                <Select
                                    className={styles["select"]}
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select apps that should be deployed in the virtual cluster"
                                    value={reduceSelectedApps(this.state.apps)}
                                    onChange={(value) => this.setState({apps: updateSelectedApps(value, this.state.apps)})}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={selectDefaultFilter}
                                >
                                    {arr(result.data?.apps).map(app => <Option key={app.metadata?.name} value={app.metadata?.name!}>
                                        {displayName(app)}
                                    </Option>)}
                                </Select>
                                <Description>Each app will be deployed into the specified virtual cluster namespaces. If a namespace does not exist within the virtual cluster, it will be created before the app is deployed. Apps cannot be synced from the template and will only be created once upon virtual cluster creation</Description>
                                <SectionExpander name={"Edit Target Namespaces For Apps"}>
                                    {
                                        !arr(this.state.apps).length ? <Description>
                                            Please add an app via the input above.
                                        </Description> : <div>
                                            {
                                                this.state.apps?.map((selectedApp, index) => <TargetNamespaceSwitcher selectedApp={selectedApp}
                                                                                                                      displayName={displayName(arr(result.data?.apps).find(app => selectedApp.app === app.metadata?.name))}
                                                                                                                      onChange={(app, namespaces) => {
                                                                                                                          const newApps = [...arr(this.state.apps)];
                                                                                                                          const oldNamespaces = [...newApps[index].targetNamespaces]

                                                                                                                          // delete namespaces that are not there anymore
                                                                                                                          for (let i = arr(oldNamespaces).length - 1; i >= 0; i--) {
                                                                                                                              const found = arr(namespaces).find(n => n === oldNamespaces![i]);
                                                                                                                              if (!found) {
                                                                                                                                  oldNamespaces?.splice(i, 1);
                                                                                                                              }
                                                                                                                          }

                                                                                                                          // add namespaces that are not there yet
                                                                                                                          const addItems: string[] = [];
                                                                                                                          for (let i = arr(namespaces).length - 1; i >= 0; i--) {
                                                                                                                              const found = arr(oldNamespaces).find(n => n === namespaces![i]);
                                                                                                                              if (!found) {
                                                                                                                                  addItems.push(namespaces![i]);
                                                                                                                              }
                                                                                                                          }

                                                                                                                          newApps[index] = {
                                                                                                                              app: app,
                                                                                                                              targetNamespaces: [...oldNamespaces, ...addItems],
                                                                                                                          }
                                                                                                                          this.setState({
                                                                                                                              apps: newApps
                                                                                                                          });
                                                                                                                      }} />)
                                            }
                                        </div>
                                    }
                                </SectionExpander>
                            </SectionExpander>
                        </React.Fragment>
                    }
                }
            </Query>
        </Section>
        <Section title={"Sync Template Changes"} defaultFolded={true} foldable={true}>
            <Label>Sync template changes automatically to all virtual clusters</Label>
            <Checkbox checked={this.state.automaticSync}
                      onChange={e => this.setState({automaticSync: e.target.checked})}>
                Always sync template changes to all virtual clusters
            </Checkbox>
            <Description>If enabled, will sync template changes automatically to all virtual clusters that have this template assigned. You can also manually or automatically sync single virtual cluster or only sync all virtual clusters once.</Description>
        </Section>
        </React.Fragment>
    }
}