import React from "react";
import { SectionProps } from "../../../../components/Drawer/ItemDrawer";
import {ManagementV1Cluster} from "../../../../../gen/models/managementV1Cluster";
import {ResultError, Return} from "../../../../lib/result";
import Description from "../../../../components/Description/Description";
import Section from "../../../../components/Drawer/Section/Section";
import Label from "../../../../components/Label/Label";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import YAMLEditor from "../../../../components/YAMLEditor/YAMLEditor";

const AgentExtraValues = "loft.sh/agent-values";
const IgnoreAgent = "loft.sh/cluster-ignore-agent";

interface DirectClusterEndpointState {
    agentExtraValues: string | undefined;
    ignoreAgent: boolean | undefined;
}

interface DirectClusterEndpointProps extends SectionProps {
    cluster: ManagementV1Cluster | undefined;
}

export default class DirectClusterEndpoint extends React.PureComponent<DirectClusterEndpointProps, DirectClusterEndpointState> {
    state: DirectClusterEndpointState = {
        agentExtraValues: this.props.cluster?.metadata?.annotations?.[AgentExtraValues],
        ignoreAgent: this.props.cluster?.metadata?.annotations?.[IgnoreAgent] === "true",
    };

    update = (cluster: ManagementV1Cluster): ResultError => {
        if (!cluster.metadata) {
            cluster.metadata = {};
        }
        if (!cluster.metadata.annotations) {
            cluster.metadata.annotations = {};
        }

        if (this.state.agentExtraValues) {
            cluster.metadata.annotations[AgentExtraValues] = this.state.agentExtraValues;
        } else {
            delete cluster.metadata.annotations[AgentExtraValues];
        }
        if (this.state.ignoreAgent) {
            cluster.metadata.annotations[IgnoreAgent] = this.state.ignoreAgent + "";
        } else {
            delete cluster.metadata.annotations[IgnoreAgent];
        }
        return Return.Ok();
    };

    render() {
        if (["batch", "create"].includes(this.props.mode)) {
            return null;
        }

        return <Section title={`Agent`} foldable={true} defaultFolded={true}>
            <Label>Manage Loft Agent</Label>
            <Checkbox onChange={e => this.setState({ignoreAgent: e.target.checked})}
                      checked={this.state.ignoreAgent}>Ignore Agent</Checkbox>
            <Description>If this is checked, Loft will not manage the agent for this cluster and expect the user to manage the Loft agent release</Description>
            <Label>Extra Agent Values</Label>
            <YAMLEditor value={this.state.agentExtraValues}
                        minLines={5}
                        maxLines={100}
                        onChange={(val) => this.setState({agentExtraValues: val})}
                        placeholder={`replicaCount: 3
...`} />
            <Description>Extra Helm values for the agent release.</Description>
        </Section>
    }
}