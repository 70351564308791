import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {RouteComponentProps} from "react-router";
import Spaces from "./views/Spaces/Spaces/Spaces";
import Clusters from "./views/Clusters/Clusters";
import License from "./views/Admin/License/License";
import Users from "./views/Users/Users";
import Teams from "./views/Users/Teams/Teams";
import Accounts from "./views/Clusters/Details/Accounts/Accounts";
import * as ClusterSpaces from "./views/Clusters/Details/Spaces/Spaces";
import * as ClusterQuotas from "./views/Clusters/Details/Quotas/Quotas";
import * as ClusterVCluster from "./views/Clusters/Details/VClusters/VClusters";
import Quotas from "./views/Quotas/Quotas";
import TemplateInstances from "./views/Clusters/Details/TemplateInstances/TemplateInstances";
import Templates from "./views/Clusters/Details/Templates/Templates";
import Apps from "./views/Clusters/Details/Apps/Apps";
import BaseApps from "./views/Apps/Apps";
import Overview from "./views/Clusters/Details/Overview/Overview";
import Profile from "./views/Profile/Profile/Profile";
import * as SharedSecrets from "./views/Secrets/Secrets";
import AccessKeys from "./views/Profile/AccessKeys/AccessKeys";
import Billing from "./views/Admin/Billing/Billing";
import Resources from "./views/Clusters/Details/Resources/Resources";
import VClusters from "./views/VClusters/VClusters/VClusters";
import Config from "./views/Admin/Config/Config";
import Pods from "./views/Spaces/Spaces/Pods/Pods";
import VClusterPods from "./views/VClusters/VClusters/Pods/Pods";
import VClusterApps from "./views/VClusters/VClusters/Apps/Apps";
import VClusterSecrets from "./views/VClusters/VClusters/Secrets/Secrets";
import VClusterConfigMaps from "./views/VClusters/VClusters/ConfigMaps/ConfigMaps";
import VClusterDeployments from "./views/VClusters/VClusters/Deployments/Deployments";
import VClusterEvents from "./views/VClusters/VClusters/Events/Events";
import VClusterIngresses from "./views/VClusters/VClusters/Ingresses/Ingresses";
import VClusterStatefulSets from "./views/VClusters/VClusters/StatefulSets/StatefulSets";
import VClusterServices from "./views/VClusters/VClusters/Services/Services";
import VClusterOther from "./views/VClusters/VClusters/Other/Other";
import Services from "./views/Spaces/Spaces/Services/Services";
import Ingresses from "./views/Spaces/Spaces/Ingresses/Ingresses";
import Other from "./views/Spaces/Spaces/Other/Other";
import Deployments from "./views/Spaces/Spaces/Deployments/Deployments";
import StatefulSets from "./views/Spaces/Spaces/StatefulSets/StatefulSets";
import Events from "./views/Spaces/Spaces/Events/Events";
import Secrets from "./views/Spaces/Spaces/Secrets/Secrets";
import ConfigMaps from "./views/Spaces/Spaces/ConfigMaps/ConfigMaps";
import Upgrade from "./views/Admin/Upgrade/Upgrade";
import Namespaces from "./views/VClusters/VClusters/Namespaces/Namespaces";
import ClusterAccountTemplates from "./views/Users/ClusterAccountTemplates/ClusterAccountTemplates";
import SpaceApps from "./views/Spaces/Spaces/Apps/Apps";
import Monitoring from "./views/Clusters/Details/Monitoring/Monitoring";
import PolicyViolations from "./views/Audit/PolicyViolations/PolicyViolations";
import Policies from "./views/Clusters/Details/Policies/Policies";
import Violations from "./views/Clusters/Details/Policies/Violations/Violations";
import {Metrics} from "./views/Spaces/Spaces/Metrics/Metrics";
import TeamPermissions from "./views/Users/Teams/TeamPermissions/TeamPermissions";
import UserPermissions from "./views/Users/Details/UserPermissions/UserPermissions";
import UserAccessKeys from "./views/Users/Details/UserAccessKeys/UserAccessKeys";
import TeamAccessKeys from "./views/Users/Teams/TeamAccessKeys/TeamAccessKeys";
import SpaceTemplates from "./views/Spaces/SpaceTemplates/SpaceTemplates";
import VClusterTemplates from "./views/VClusters/VClusterTemplates/VClusterTemplates";
import ClusterAccess from "./views/Clusters/ClusterAccess/ClusterAccess";
import ClusterRoles from "./views/Clusters/ClusterRoles/ClusterRoles";
import SpaceConstraints from "./views/Clusters/SpaceConstraints/SpaceConstraints";
import ManagementRoles from "./views/Users/ManagementRoles/ManagementRoles";
import LocalClusterAccess from "./views/Clusters/Details/ClusterAccess/ClusterAccess";
import Activity from "./views/Audit/Activity/Activity";
import UserActivity from "./views/Users/Details/UserActivity/UserActivity";
import TeamActivity from "./views/Users/Teams/TeamActivity/TeamActivity";
import VClusterActivity from "./views/VClusters/VClusters/Activity/VClusterActivity";
import SpaceActivity from "./views/Spaces/Spaces/Activity/SpaceActivity";
import ClusterActivity from "./views/Clusters/Details/Activity/ClusterActivity";
import Tasks from "./views/Audit/Tasks/Tasks";

// app routes are only accessible if the user is logged in
export const appRoutes: Array<{
    path: string;
    Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}> = [
    {
        path: "/vclusters",
        Component: VClusters,
    },
    {
        path: "/vclustertemplates",
        Component: VClusterTemplates,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster",
        Component: Namespaces,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/namespaces",
        Component: Namespaces,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/pods",
        Component: VClusterPods,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/apps",
        Component: VClusterApps,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/deployments",
        Component: VClusterDeployments,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/secrets",
        Component: VClusterSecrets,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/configmaps",
        Component: VClusterConfigMaps,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/events",
        Component: VClusterEvents,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/statefulsets",
        Component: VClusterStatefulSets,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/services",
        Component: VClusterServices,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/ingresses",
        Component: VClusterIngresses,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/activity",
        Component: VClusterActivity,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/other",
        Component: VClusterOther,
    },
    {
        path: "/vclusters/:cluster/:namespace/:vcluster/other/:resource",
        Component: VClusterOther,
    },
    {
        path: "/clusters",
        Component: Clusters,
    },
    {
        path: "/clusters/clusters",
        Component: Clusters,
    },
    {
        path: "/clusters/clusteraccess",
        Component: ClusterAccess,
    },
    {
        path: "/clusters/clusterroles",
        Component: ClusterRoles,
    },
    {
        path: "/clusters/spaceconstraints",
        Component: SpaceConstraints,
    },
    {
        path: "/clusters/details/:cluster",
        Component: Overview,
    },
    {
        path: "/clusters/details/:cluster/cluster",
        Component: Overview,
    },
    {
        path: "/clusters/details/:cluster/clusteraccess",
        Component: LocalClusterAccess,
    },
    {
        path: "/clusters/details/:cluster/accounts",
        Component: Accounts,
    },
    {
        path: "/clusters/details/:cluster/monitoring",
        Component: Monitoring,
    },
    {
        path: "/clusters/details/:cluster/policies",
        Component: Policies,
    },
    {
        path: "/clusters/details/:cluster/policies/:policy",
        Component: Violations,
    },
    {
        path: "/clusters/details/:cluster/spaces",
        Component: ClusterSpaces.default,
    },
    {
        path: "/clusters/details/:cluster/quotas",
        Component: ClusterQuotas.default,
    },
    {
        path: "/clusters/details/:cluster/vclusters",
        Component: ClusterVCluster.default,
    },
    {
        path: "/clusters/details/:cluster/templateinstances",
        Component: TemplateInstances,
    },
    {
        path: "/clusters/details/:cluster/templates",
        Component: Templates,
    },
    {
        path: "/clusters/details/:cluster/apps",
        Component: Apps,
    },
    {
        path: "/clusters/details/:cluster/activity",
        Component: ClusterActivity,
    },
    {
        path: "/clusters/details/:cluster/resources",
        Component: Resources,
    },
    {
        path: "/clusters/details/:cluster/resources/:resource",
        Component: Resources,
    },
    {
        path: "/apps",
        Component: BaseApps,
    },
    {
        path: "/quotas",
        Component: Quotas,
    },
    {
        path: "/spacetemplates",
        Component: SpaceTemplates,
    },
    {
        path: "/spaces",
        Component: Spaces,
    },
    {
        path: "/spaces/:cluster/:space",
        Component: Pods,
    },
    {
        path: "/spaces/:cluster/:space/pods",
        Component: Pods,
    },
    {
        path: "/spaces/:cluster/:space/deployments",
        Component: Deployments,
    },
    {
        path: "/spaces/:cluster/:space/secrets",
        Component: Secrets,
    },
    {
        path: "/spaces/:cluster/:space/configmaps",
        Component: ConfigMaps,
    },
    {
        path: "/spaces/:cluster/:space/events",
        Component: Events,
    },
    {
        path: "/spaces/:cluster/:space/statefulsets",
        Component: StatefulSets,
    },
    {
        path: "/spaces/:cluster/:space/services",
        Component: Services,
    },
    {
        path: "/spaces/:cluster/:space/ingresses",
        Component: Ingresses,
    },
    {
        path: "/spaces/:cluster/:space/apps",
        Component: SpaceApps,
    },
    {
        path: "/spaces/:cluster/:space/metrics",
        Component: Metrics,
    },
    {
        path: "/spaces/:cluster/:space/activity",
        Component: SpaceActivity,
    },
    {
        path: "/spaces/:cluster/:space/other",
        Component: Other,
    },
    {
        path: "/spaces/:cluster/:space/other/:resource",
        Component: Other,
    },
    {
        path: "/users",
        Component: Users,
    },
    {
        path: "/users/users",
        Component: Users,
    },
    {
        path: "/users/details/:user",
        Component: UserAccessKeys,
    },
    {
        path: "/users/details/:user/permissions",
        Component: UserPermissions,
    },
    {
        path: "/users/details/:user/activity",
        Component: UserActivity,
    },
    {
        path: "/users/details/:user/access-keys",
        Component: UserAccessKeys,
    },
    {
        path: "/users/teams",
        Component: Teams,
    },
    {
        path: "/users/teams/:team",
        Component: TeamAccessKeys,
    },
    {
        path: "/users/teams/:team/access-keys",
        Component: TeamAccessKeys,
    },
    {
        path: "/users/teams/:team/activity",
        Component: TeamActivity,
    },
    {
        path: "/users/teams/:team/permissions",
        Component: TeamPermissions,
    },
    {
        path: "/users/managementroles",
        Component: ManagementRoles,
    },
    {
        path: "/users/accounttemplates",
        Component: ClusterAccountTemplates,
    },
    {
        path: "/secrets",
        Component: SharedSecrets.default,
    },
    {
        path: "/admin",
        Component: Config,
    },
    {
        path: "/admin/license",
        Component: License,
    },
    {
        path: "/admin/billing",
        Component: Billing,
    },
    {
        path: "/admin/config",
        Component: Config,
    },
    {
        path: "/admin/upgrade",
        Component: Upgrade,
    },
    {
        path: "/profile",
        Component: Profile,
    },
    {
        path: "/profile/access-keys",
        Component: AccessKeys,
    },
    {
        path: "/audit",
        Component: Activity,
    },
    {
        path: "/audit/tasks",
        Component: Tasks,
    },
    {
        path: "/audit/policies",
        Component: PolicyViolations,
    },
];

interface Props {}

export default function AppRoutes(props: Props) {
    const renderRoutes: Array<JSX.Element> = [];
    for (let entry of appRoutes) {
        renderRoutes.push(<Route key={entry.path} exact path={entry.path} component={entry.Component} />)
    }

    return (
        <React.Fragment>
            <Switch>
                {renderRoutes}
                <Route render={() => <h1>Page not found</h1>} />
            </Switch>
        </React.Fragment>
    );
};
