import React, {useState} from "react";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {DeleteOutlined} from "@ant-design/icons/lib";
import styles from "./ClusterDeletePopup.module.scss";
import {DeleteModal, Modal} from "../../../lib/Modal/Modal";
import ClientMessage from "../../../lib/Message/ClientMessage";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import {useUser} from "../../../contexts/UserContext/UserContext";

interface Props {
    cluster: string;
    refetch: () => Promise<void>;
}

export default function ClusterDeletePopup(props: Props) {
    const [visible, setVisible] = useState<boolean>(false);
    const user = useUser();

    return <React.Fragment>
      <Tooltip title={"delete"}>
        <DeleteOutlined className={styles["button"]} onClick={() => setVisible(true)} />
      </Tooltip>

      <DeleteModal title={`Delete Cluster ${props.cluster}`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
        const message = ClientMessage.Loading();
        const result = await client.management(Resources.ManagementV1Cluster).Delete(props.cluster);
        if (result.err) {
          message.Error(result);
          return;
        }

        message?.DoneManagement();
        await props.refetch();
      }}>
      <div>
        { `Are you sure you want to delete the cluster ${props.cluster}? This action CANNOT be reverted!`}
      </div>
      </DeleteModal>
    </React.Fragment>
}