import React, {useRef} from "react";
import * as Ant from "antd";
import * as AntTextAreaProps from "antd/lib/input/TextArea";
import styles from "./TextArea.module.scss";
import {filterResetProps, ResetProps, useReset} from "../../lib/hooks/Reset/reset";
import {without} from "../../lib/helpers/renderhelper";
const { TextArea: AntTextArea } = Ant.Input;

export interface TextAreaProps extends AntTextAreaProps.TextAreaProps, Omit<ResetProps<string>, "value"> {

}

export default function TextArea(props: TextAreaProps) {
    const {changed, onSelect, onReset} = useReset<string>(props, (val) => {
        if (props.onChange) {
            // dirty hack but mostly works
            props.onChange({
                target: {
                    value: val,
                }
            } as any);
        }
    });
    const classNames = [styles["textarea"]];
    if (props.className) {
        classNames.push(props.className);
    }
    if (changed) {
        classNames.push(styles["changed"]);
    }

    return <span className={classNames.join(" ")}>
        <AntTextArea {...filterResetProps(without(props, ["className"]))} className={classNames.join(" ")} onSelect={onSelect} onClick={onSelect} autoSize={props.autoSize || { minRows: 5, maxRows: 20 }} />
        {changed && <span className={styles["text"]} onClick={(e) => onReset(e)}>Reset</span>}
    </span>
}
