import React from "react";
import {Alert} from "antd";
import {AlertProps} from "antd/lib/alert";
import styles from "./InfoAlert.module.scss";

export default function InfoAlert(props: AlertProps) {
    const classNames = [styles["info-alert"]];
    if (props.className) {
        classNames.push(props.className)
    }

    return <Alert {...props} className={classNames.join(" ")} type="info" showIcon />
}