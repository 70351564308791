import React, {useEffect, useState} from "react";
import useQuery from "../../../../../lib/Query/Query";
import client, {RequestOptionsVCluster} from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import Table, {TableActions} from "../../../../../components/Table/Table";
import {arr, removeDuplicateFilters} from "../../../../../lib/helpers/renderhelper";
import {CoreV1Event} from "@kubernetes/client-node";
import {stringSorter, timeSorter} from "../../../../../lib/helpers/sorthelper";
import DynamicTime from "../../../../../components/DynamicTime/DynamicTime";
import ShowYamlPopup from "../../../../../components/ShowYamlPopup/ShowYamlPopup";
import {WarningOutlined} from "@ant-design/icons";
import styles from "./EventsTable.module.scss";
import Checkbox from "../../../../../components/Checkbox/Checkbox";

export function getEventLastOccurrence(event: CoreV1Event): Date | undefined {
    return event.lastTimestamp || event.series?.lastObservedTime || event.metadata?.creationTimestamp;
}

function getTableColumns(refetch: () => Promise<void>, events: CoreV1Event[], cluster: string | undefined, namespace: string | undefined, vCluster: RequestOptionsVCluster | undefined) {
    let columns = [
        {
            title: 'Last Occurrence',
            sorter: (a: CoreV1Event, b: CoreV1Event) => timeSorter(getEventLastOccurrence(a), getEventLastOccurrence(b)),
            render: (event: CoreV1Event) => {
                return <DynamicTime timestamp={getEventLastOccurrence(event)} useTooltip={true}/>
            }
        },
        {
            title: 'Type',
            sorter: (a: CoreV1Event, b: CoreV1Event) => stringSorter(a.type, b.type),
            filters: removeDuplicateFilters(arr(events).map(obj => {
                return {
                    text: obj.type!,
                    value: obj.type + ""
                }
            })),
            onFilter: (value: string, record: CoreV1Event) => record.type === value,
            render: (event: CoreV1Event) => {
                if (event.type === "Warning") {
                    return <span className={styles["type-warning"]}>
                        <WarningOutlined />{event.type}
                    </span>
                }
                
                return event.type;
            }
        },
        {
            title: 'Object',
            sorter: (a: CoreV1Event, b: CoreV1Event) => stringSorter((a.involvedObject?.kind + "/" + a.involvedObject?.name), (b.involvedObject?.kind + "/" + b.involvedObject?.name)),
            filters: removeDuplicateFilters(arr(events).map(obj => {
                const name = obj.involvedObject?.kind + "/" + obj.involvedObject?.name;
                return {
                    text: name!,
                    value: name + ""
                }
            })),
            onFilter: (value: string, record: CoreV1Event) => (record.involvedObject?.kind + "/" + record.involvedObject?.name) === value,
            render: (event: CoreV1Event) => {
                return event.involvedObject?.kind + "/" + event.involvedObject?.name;
            }
        },
        {
            title: 'Reason',
            sorter: (a: CoreV1Event, b: CoreV1Event) => stringSorter(a.reason, b.reason),
            render: (event: CoreV1Event) => {
                return event.reason;
            }
        },
        {
            title: 'Message',
            sorter: (a: CoreV1Event, b: CoreV1Event) => stringSorter(a.message, b.message),
            render: (event: CoreV1Event) => {
                return event.message;
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: CoreV1Event, b: CoreV1Event) => timeSorter(a.metadata.creationTimestamp, b.metadata.creationTimestamp),
            render: (event: CoreV1Event) => {
                return <DynamicTime timestamp={event.metadata.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (event: CoreV1Event) => {
                return <TableActions>
                    <ShowYamlPopup className={"blue-btn"} canUpdate={false} cluster={cluster} namespace={namespace} vCluster={vCluster} object={event} resource={Resources.V1Event} name={event.metadata?.name!} refetch={refetch} />
                </TableActions>;
            }
        },
    ];
    if (!namespace) {
        columns = [{
            title: "Namespace",
            sorter: (a: CoreV1Event, b: CoreV1Event) => stringSorter(a.metadata?.namespace, b.metadata?.namespace),
            filters: removeDuplicateFilters(arr(events).map(obj => {
                return {
                    text: obj.metadata?.namespace!,
                    value: obj.metadata?.namespace + ""
                }
            })),
            onFilter: (value: string, record: CoreV1Event) => record.metadata?.namespace === value,
            render: (event: CoreV1Event) => {
                return event.metadata?.namespace;
            }
            
        }, ...columns]
    }
    
    return columns;
}

function filter(item: CoreV1Event, value: string) {
    return !!(item.metadata?.namespace?.includes(value) || item.metadata?.name?.includes(value) || (item.involvedObject?.kind + "/" + item.involvedObject?.name).includes(value) || item.message?.includes(value) || item.reason?.includes(value));
}

interface EventsTableProps {
    cluster?: string;
    namespace?: string;
    vCluster?: RequestOptionsVCluster;
    
    top?: React.ReactNode;
}

function EventsTable(props: EventsTableProps) {
    const [showAllEvents, setShowAllEvents] = useState<boolean>(false);
    const {loading, error, data: unsortedData, refetch} = useQuery(async () => await client.auto(props.cluster, props.vCluster, Resources.V1Event).Namespace(props.namespace).List());
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 4000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);
    
    const data = arr(unsortedData?.items).filter(event => showAllEvents || event.type === "Warning").sort((a, b) => -timeSorter(getEventLastOccurrence(a), getEventLastOccurrence(b)))
    return <div>
        <Table loading={!unsortedData && loading}
               columns={getTableColumns(refetch, data, props.cluster, props.namespace, props.vCluster) as any} 
               dataSource={data ? arr(data).map(event => { return {...event, key: event.metadata?.name}}) : undefined} 
               error={error} 
               filter={filter} 
               refetch={refetch} 
               header={{
                    top: props.top,
                    middleLeft: <span style={{marginLeft: "15px"}}><Checkbox onChange={e => setShowAllEvents(e.target.checked)} checked={showAllEvents}>Show All Events</Checkbox></span>,
                }} />
    </div>
}

export default EventsTable;