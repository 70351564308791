import React, {RefObject} from "react";
import Section from "../../../../../components/Drawer/Section/Section";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import LabelsAnnotationsSection, {LabelsAnnotationsSectionProps} from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import Label from "../../../../../components/Label/Label";
import Input from "../../../../../components/Input/Input";
import Description from "../../../../../components/Description/Description";
import {
    convertMinutesToSeconds,
    convertSecondsToMinutes, getDeleteAfter, getSleepAfter, setDeleteAfter, setSleepAfter
} from "../../../../../lib/helpers/sleepmodehelper";
import constants from "../../../../../constants/constants";
import {arr} from "../../../../../lib/helpers/renderhelper";
import Select from "../../../../../components/Select/Select";
import styles from "./AccountTemplate.module.scss";
import {ResultError, Return} from "../../../../../lib/result";
import {ManagementV1ClusterAccountTemplate} from "../../../../../../gen/models/managementV1ClusterAccountTemplate";
import {StorageV1AccountTemplate} from "../../../../../../gen/models/storageV1AccountTemplate";

interface AccountTemplateProps extends SectionProps, LabelsAnnotationsSectionProps {
    clusterAccountTemplates?: ManagementV1ClusterAccountTemplate[];
    clusterAccountTemplate?: ManagementV1ClusterAccountTemplate;
}

interface AccountTemplateState {
    spaceLimit: number | undefined;
    spaceLimitChanged: boolean;

    sleepConfigSleepAfter: number | undefined;
    sleepConfigSleepAfterChanged: boolean;

    sleepConfigDeleteAfter: number | undefined;
    sleepConfigDeleteAfterChanged: boolean;

    spaceClusterRole: string | undefined;
    spaceClusterRoleChanged: boolean;

    templates: string[];
    templatesChanged: boolean;
}

export default class AccountTemplate extends React.PureComponent<AccountTemplateProps, AccountTemplateState> {
    accountLabelsSectionRef?: RefObject<LabelsAnnotationsSection>;
    labelsSectionRef?: RefObject<LabelsAnnotationsSection>;

    state: AccountTemplateState = {
        spaceLimit: this.props.clusterAccountTemplate ? this.props.clusterAccountTemplate?.spec?.accountTemplate?.spec?.space?.limit : undefined,
        spaceLimitChanged: false,

        spaceClusterRole: this.props.clusterAccountTemplate ? this.props.clusterAccountTemplate?.spec?.accountTemplate?.spec?.space?.clusterRole : "loft-cluster-space-admin",
        spaceClusterRoleChanged: false,

        sleepConfigSleepAfter: this.props.clusterAccountTemplate ? getSleepAfter(this.props.clusterAccountTemplate?.spec?.accountTemplate?.spec?.space?.spaceTemplate?.metadata) : undefined,
        sleepConfigSleepAfterChanged: false,

        sleepConfigDeleteAfter: this.props.clusterAccountTemplate ? getDeleteAfter(this.props.clusterAccountTemplate?.spec?.accountTemplate?.spec?.space?.spaceTemplate?.metadata) : undefined,
        sleepConfigDeleteAfterChanged: false,

        templates: arr(this.props.clusterAccountTemplate?.spec?.accountTemplate?.spec?.space?.templateInstances?.map(templateInstance => templateInstance.spec?.template!)),
        templatesChanged: false,
    };

    constructor(props: AccountTemplateProps) {
        super(props);

        this.labelsSectionRef = React.createRef<LabelsAnnotationsSection>();
        this.accountLabelsSectionRef = React.createRef<LabelsAnnotationsSection>();
    }

    create(clusterAccountTemplate: ManagementV1ClusterAccountTemplate): ResultError {
        if (!clusterAccountTemplate.spec) {
            clusterAccountTemplate.spec = {};
        }
        if (!clusterAccountTemplate.spec.accountTemplate) {
            clusterAccountTemplate.spec.accountTemplate = {};
        }
        if (!clusterAccountTemplate.spec.accountTemplate.metadata) {
            clusterAccountTemplate.spec.accountTemplate.metadata = {};
        }

        // set account annotations
        let annotationsResult = this.accountLabelsSectionRef!.current!.create(clusterAccountTemplate.spec!.accountTemplate!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        // set templates
        this.setTemplates(clusterAccountTemplate.spec.accountTemplate);

        // set space limit
        clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.clusterRole = this.state.spaceClusterRole || "loft-cluster-space-admin";
        clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.limit = this.state.spaceLimit;
        clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate = {
            metadata: {},
        };

        // set space annotations
        annotationsResult = this.labelsSectionRef!.current!.create(clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        // set sleep config
        setSleepAfter(clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate.metadata, this.state.sleepConfigSleepAfter);
        setDeleteAfter(clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate.metadata, this.state.sleepConfigDeleteAfter);
        return Return.Ok();
    }

    update(clusterAccountTemplate: ManagementV1ClusterAccountTemplate): ResultError {
        if (!clusterAccountTemplate.metadata) {
            clusterAccountTemplate.metadata = {};
        }
        if (!clusterAccountTemplate.spec) {
            clusterAccountTemplate.spec = {};
        }
        if (!clusterAccountTemplate.spec.accountTemplate) {
            clusterAccountTemplate.spec.accountTemplate = {};
        }
        if (!clusterAccountTemplate.spec.accountTemplate.metadata) {
            clusterAccountTemplate.spec.accountTemplate.metadata = {};
        }
        if (!clusterAccountTemplate.spec.accountTemplate.spec) {
            clusterAccountTemplate.spec.accountTemplate.spec = {};
        }
        if (!clusterAccountTemplate.spec.accountTemplate.spec.space) {
            clusterAccountTemplate.spec.accountTemplate.spec.space = {};
        }
        if (!clusterAccountTemplate.spec.accountTemplate.spec.space.spaceTemplate) {
            clusterAccountTemplate.spec.accountTemplate.spec.space.spaceTemplate = {};
        }
        if (!clusterAccountTemplate.spec.accountTemplate.spec.space.spaceTemplate.metadata) {
            clusterAccountTemplate.spec.accountTemplate.spec.space.spaceTemplate.metadata = {};
        }

        // set templates
        if (this.state.templatesChanged) {
            this.setTemplates(clusterAccountTemplate.spec.accountTemplate);
        }

        // set space limit
        if (this.state.spaceLimitChanged) {
            clusterAccountTemplate.spec.accountTemplate.spec!.space!.limit = this.state.spaceLimit;
        }

        // set space cluster role
        if (this.state.spaceClusterRoleChanged) {
            clusterAccountTemplate.spec.accountTemplate.spec!.space!.clusterRole = this.state.spaceClusterRole;
        }

        // set account annotations
        let annotationsResult = this.accountLabelsSectionRef!.current!.create(clusterAccountTemplate.spec.accountTemplate.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        // set space annotations
        annotationsResult = this.labelsSectionRef!.current!.create(clusterAccountTemplate.spec.accountTemplate.spec!.space!.spaceTemplate!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        // set sleep config sleep after
        if (this.state.sleepConfigSleepAfterChanged) {
            if (!clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate.metadata) {
                clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate.metadata = {};
            }
            setSleepAfter(clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate.metadata, this.state.sleepConfigSleepAfter);
        }

        // set sleep config delete all pods
        if (this.state.sleepConfigDeleteAfter) {
            if (!clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate.metadata) {
                clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate.metadata = {};
            }
            setDeleteAfter(clusterAccountTemplate.spec!.accountTemplate!.spec!.space!.spaceTemplate.metadata, this.state.sleepConfigDeleteAfter);
        }

        return Return.Ok();
    }

    batch(clusterAccountTemplates: ManagementV1ClusterAccountTemplate[]): ResultError {
        for (let i = 0; i < clusterAccountTemplates.length; i++) {
            const result = this.update(clusterAccountTemplates[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    }

    setTemplates(account: StorageV1AccountTemplate) {
        if (!account.spec) {
            account.spec = {};
        }
        if (!account.spec.space) {
            account.spec.space = {};
        }
        if (!account.spec.space.templateInstances) {
            account.spec.space.templateInstances = [];
        }

        const newTemplates = arr(this.state.templates);

        // delete the ones that shouldn't be there
        for (let i = account.spec.space.templateInstances.length - 1; i >= 0; i--) {
            const template = account.spec.space.templateInstances[i];
            const found = newTemplates.find(newTemplate => template.spec?.template === newTemplate) !== undefined;
            if (!found) {
                account.spec.space.templateInstances.splice(i, 1);
            }
        }

        // add the ones that should be there
        for (let i = 0; i < newTemplates.length; i++) {
            const found = account.spec.space.templateInstances.find(oldTemplate => oldTemplate.spec?.template === newTemplates[i]) !== undefined;
            if (!found) {
                account.spec.space.templateInstances.push({
                    spec: {
                        template: newTemplates[i],
                        sync: true
                    }
                });
            }
        }
    }

    setSleepAfter = (val: number | undefined) => {
        this.setState({
            sleepConfigSleepAfter: convertMinutesToSeconds(val)
        })
    };

    setDeleteAfter = (val: number | undefined) => {
        this.setState({
            sleepConfigDeleteAfter: convertMinutesToSeconds(val),
        })
    };

    render() {
        return <Section title={"2. Create Account in Clusters"} foldable={true} defaultFolded={true}>
            <LabelsAnnotationsSection {...this.props} name={"Account Labels & Annotations"} metadata={this.props.clusterAccountTemplate ? this.props.clusterAccountTemplate.spec?.accountTemplate?.metadata : undefined} ref={this.accountLabelsSectionRef} />
            <div className={styles["row"]}>
                <div>
                    <Label>Space Limit</Label>
                    <Input resetable={this.props.mode !== "create"}
                           type={"number"}
                           onChangedStatus={status => this.setState({spaceLimitChanged: status})}
                           value={this.state.spaceLimit}
                           placeholder={"Max number of spaces"}
                           onChange={(e) => this.setState({spaceLimit: parseInt(e.target.value)})} />
                    <Description>Leave empty for unlimited.</Description>
                </div>
                <div>
                    <Label>Space Default Cluster Role</Label>
                    <Input resetable={this.props.mode !== "create"}
                           onChangedStatus={status => this.setState({spaceClusterRoleChanged: status})}
                           placeholder={"Default Space Cluster Role"}
                           value={this.state.spaceClusterRole}
                           onChange={e => this.setState({spaceClusterRole: e.target.value})} />
                    <Description>The role that is bound to the account for a new space.</Description>
                </div>
            </div>
            <div className={styles["row"]}>
                <div>
                    <Label>Sleep After Inactivity</Label>
                    <Input resetable={this.props.mode !== "create"}
                           type={"number"}
                           onChangedStatus={status => this.setState({sleepConfigSleepAfterChanged: status})}
                           value={convertSecondsToMinutes(this.state.sleepConfigSleepAfter)}
                           placeholder={"Minutes before sleeping"}
                           onChange={(e) => this.setSleepAfter(parseFloat(e.target.value))} />
                    <Description>Leave empty for manual <a href={"https://loft.sh/docs/getting-started/explore/sleep-mode"} target={"_blank"}>sleeping</a> only.</Description>
                </div>
                <div>
                    <Label>Delete Space After Inactivity</Label>
                    <Input resetable={this.props.mode !== "create"}
                           type={"number"}
                           onChangedStatus={status => this.setState({sleepConfigDeleteAfterChanged: status})}
                           value={convertSecondsToMinutes(this.state.sleepConfigDeleteAfter)}
                           placeholder={"Minutes before deletion"}
                           onChange={(e) => this.setDeleteAfter(parseFloat(e.target.value))} />
                    <Description>Delete the space if it is inactive for this period.</Description>
                </div>
            </div>
            <div>
                <Label>Enforce Templates</Label>
                <Select mode="tags" tokenSeparators={[',', ' ']}
                        resetable={this.props.mode !== "create"}
                        onChangedStatus={status => this.setState({templatesChanged: status})}
                        value={this.state.templates}
                        placeholder={"my-template1, my-template2"}
                        onChange={value => this.setState({templates: value})} />
                <Description>For each of these <a href={"https://loft.sh/docs"} target={"_blank"}>templates</a>, loft will automatically create a TemplateInstance in every new space.</Description>
            </div>
            <LabelsAnnotationsSection {...this.props} name={"Space Labels & Annotations"} metadata={this.props.clusterAccountTemplate ? this.props.clusterAccountTemplate.spec?.accountTemplate?.spec?.space?.spaceTemplate?.metadata : undefined} ref={this.labelsSectionRef} />
        </Section>
    }
}