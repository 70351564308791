import React from "react";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";
import {Space} from "antd";
import Button from "../Button/Button";
import client from "../../lib/client";
import AsyncButton from "../AsyncButton/AsyncButton";
import AuthLayout from "../AuthLayout/AuthLayout";
import {ResultError} from "../../lib/result";

interface ReLoginProps {
    error: ResultError;
    refetch: () => Promise<void>
}

export function ReLogin(props: ReLoginProps) {
    return <AuthLayout title={"An Error Occurred"} description={"Please relogin or retry."}>
        <ErrorMessage error={props.error} />
        <Space size={10} style={{display: "flex", justifyContent: "center"}}>
            <AsyncButton type={"primary"} onClickAsync={async () => {
                await client.logout();
                window.location.href = "/";
            }}>Relogin</AsyncButton>
            <AsyncButton type={"primary"} onClickAsync={async () => {
                await props.refetch();
            }}>Retry</AsyncButton>
        </Space>
    </AuthLayout>
}