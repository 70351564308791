import React, {useState} from "react";
import styles from './ClusterSubdomain.module.scss';
import Input from "../../../../../components/Input/Input";
import {ArrowRightOutlined, WarningOutlined} from "@ant-design/icons/lib";
import {Alert} from "antd";
import Description from "../../../../../components/Description/Description";
import {useLicense} from "../../../../../contexts/LicenseContext/LicenseContext";
import {arr} from "../../../../../lib/helpers/renderhelper";
import Label from "../../../../../components/Label/Label";
import useQuery from "../../../../../lib/Query/Query";
import {Return} from "../../../../../lib/result";
import client from "../../../../../lib/client";
import {NewResource, Resources} from "../../../../../lib/resources";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../../components/Loading/Loading";
import constants from "../../../../../constants/constants";
import Button from "../../../../../components/Button/Button";
import LabeledValue from "../../../../../components/LabeledValue/LabeledValue";
import {alert, confirm} from "../../../../../lib/Modal/Modal";
import ClientMessage from "../../../../../lib/Message/ClientMessage";

interface ClusterSubdomainProps {
    cluster: string;
}

function renderUpdateConfirmContent(existingSubdomain?: string, existingSubdomainTarget?: string, newSubdomain?: string, newSubdomainTarget?: string, baseDomain?: string) {
    if (existingSubdomain && !newSubdomain) {
        return <div>
            This will unset the current subdomain *.{existingSubdomain}. You can add a subdomain again later.
        </div>
    }
    if (!existingSubdomain && newSubdomain) {
        return <div>
            This will create a new DNS entry for subdomain *.{newSubdomain}{baseDomain} that points to {newSubdomainTarget}.
        </div>
    }
    if (existingSubdomain && newSubdomain) {
        if (existingSubdomain === newSubdomain) {
            return <div>
                This will update the existing DNS entry of subdomain *.{newSubdomain}{baseDomain} to point to {newSubdomainTarget}.
            </div>
        } else {
            return <div>
                This will delete the old DNS entry of subdomain *.{existingSubdomain} and create a new DNS entry for subdomain *.{newSubdomain}{baseDomain} that points to {newSubdomainTarget}.
            </div>
        }
    }

    // This should not happen
    return <div>
        This will update the DNS entry for subdomain *.{newSubdomain}{baseDomain} to point to {newSubdomainTarget}.
    </div>
}

export default function ClusterSubdomain(props: ClusterSubdomainProps) {
    const license = useLicense();
    const [existingSubdomain, setExistingSubdomain] = useState<string | undefined>();
    const [existingSubdomainTarget, setExistingSubdomainTarget] = useState<string | undefined>();
    const [subdomain, setSubdomain] = useState<string | undefined>();
    const [target, setTarget] = useState<string | undefined>();
    const {loading, error, refetch} = useQuery<boolean>(async () => {
        const baseDomains = arr(license?.license?.status?.info?.baseDomains);
        if (baseDomains.length !== 1) {
            return Return.Value(false);
        }

        const clusterResult = await client.management(Resources.ManagementV1Cluster).Get(props.cluster!);
        if (clusterResult.err) {
            return clusterResult;
        }

        const baseDomain = baseDomains[0];
        const existingSubDomain = clusterResult.val.metadata?.annotations?.[constants.LoftClusterDomainAnnotation];
        if (existingSubDomain && existingSubDomain.indexOf(baseDomain) !== -1) {
            setSubdomain(existingSubDomain.substring(0, existingSubDomain.indexOf(baseDomain)));
            setExistingSubdomain(existingSubDomain);
        }

        const existingSubDomainTarget = clusterResult.val.metadata?.annotations?.[constants.LoftClusterDomainTargetAnnotation];
        if (existingSubDomainTarget) {
            setTarget(existingSubDomainTarget);
            setExistingSubdomainTarget(existingSubDomainTarget);
        } else {
            // we try to find a sub domain target
            const listServicesResult = await client.cluster(props.cluster, Resources.V1Service).List({
                labelSelector: "app.kubernetes.io/managed-by=Helm"
            });
            if (listServicesResult.err) {
                return listServicesResult;
            }

            // try to find the nginx-ingress or ingress-nginx LoadBalancer
            for (let i = 0; i < arr(listServicesResult.val.items).length; i++) {
                const service = listServicesResult.val.items[i];
                if (service.spec?.type !== "LoadBalancer") {
                    continue;
                } else if (service.metadata?.labels?.["app"] !== "nginx-ingress" && service.metadata?.labels?.["app.kubernetes.io/name"] !== "ingress-nginx") {
                    continue;
                } else if (arr(service.status?.loadBalancer?.ingress).length !== 1) {
                    continue;
                }

                setTarget(service.status?.loadBalancer?.ingress?.[0].ip || service.status?.loadBalancer?.ingress?.[0].hostname);
            }
        }

        return Return.Value(true);
    }, {refetch: license?.license?.status?.info?.baseDomains});

    const baseDomains = arr(license?.license?.status?.info?.baseDomains);
    if (baseDomains.length !== 1) {
        return <div />;
    }

    const baseDomain = baseDomains[0];
    const newSubdomain = subdomain+baseDomain;
    return <div>
        <Label.Bold>Cluster Subdomain for Spaces</Label.Bold>
        {error ? <ErrorMessage error={error} /> : loading ? <Loading /> : <div className={styles["cluster-subdomain"]}>
            <LabeledValue label={"Subdomain"}>
                <Input placeholder={props.cluster} addonBefore={"*."} addonAfter={license?.license?.status?.info?.baseDomains![0]} value={subdomain} onChange={e => setSubdomain(e.target.value)} />
            </LabeledValue>
            <ArrowRightOutlined />
            <LabeledValue label={"Ingress Controller External-IP"}>
                <Input placeholder={"0.0.0.0"} value={target} onChange={e => setTarget(e.target.value)} />
            </LabeledValue>
            <Button type={"primary"} onClick={() => {
                if (!existingSubdomain && !subdomain) {
                    alert({
                        title: "Warning",
                        content: <div>
                            Please enter a subdomain
                        </div>
                    });
                    return;
                } else if (subdomain && !target) {
                    alert({
                        title: "Warning",
                        content: <div>
                            Please enter a target IP or HOSTNAME that the subdomain should point to
                        </div>
                    });
                    return;
                } else if (existingSubdomain && subdomain && existingSubdomain === newSubdomain && existingSubdomainTarget === target) {
                    alert({
                        title: "Warning",
                        content: <div>
                            Please change either subdomain or target
                        </div>
                    });
                    return;
                }

                confirm({
                    title: "Update Cluster Subdomain",
                    content: renderUpdateConfirmContent(existingSubdomain, existingSubdomainTarget, subdomain, target, baseDomain),
                    onOkAsync: async () => {
                        const message = ClientMessage.Loading(props.cluster!);
                        const clusterDomainResult = await client.management(Resources.ManagementV1ClusterDomain).Name(props.cluster).Create(NewResource(Resources.ManagementV1ClusterDomain, props.cluster, {
                            domain: subdomain ? newSubdomain : "",
                            target: target,
                        }));
                        if (clusterDomainResult.err) {
                            message.ErrorCluster(clusterDomainResult, props.cluster!);
                            return;
                        }

                        await refetch();
                        message.DoneCluster(props.cluster);
                    }
                })
            }}>Update</Button>
        </div>}
        <Description>This wildcard subdomain can be used for ingress hostnames in spaces and vclusters within this cluster. <WarningOutlined/> DNS changes may take up to 24h.</Description>
        {!target && 
          <Alert className={styles["alert"]} message="To use the cluster subdomain feature, you need to install an ingress controller into the cluster, e.g. 'ingress-nginx' (see above)." type="warning" showIcon/>
        }
    </div>
}