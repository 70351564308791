import React from "react";
import styles from "./LabeledValue.module.scss";

interface LabeledValueProps {
    className?: string;
    style?: React.CSSProperties;
    label?: React.ReactNode;
}

export default function LabeledValue(props: React.PropsWithChildren<LabeledValueProps>) {
    const classNames = [styles["labeled-value"]];
    if (props.className) {
        classNames.push(props.className);
    }

    return <div className={classNames.join(" ")} style={props.style}>
        <div className={styles["label"]}>{props.label}</div>
        <div className={styles["value"]}>{props.children}</div>
    </div>
}