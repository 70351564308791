import { V1Status } from '@kubernetes/client-node';
import { IDisposable, ITerminalAddon, Terminal } from 'xterm';

export interface IExecAddonOptions {
    bidirectional?: boolean;
    onError?(status: V1Status): void;
    onSuccess?(status: V1Status): void;
}

enum ExecChannels {
    StdinStream = 0,
    StdoutStream = 1,
    StderrStream = 2,
    StatusStream = 3,
    ResizeStream = 4
}

export class ExecAddon implements ITerminalAddon {
    private _bidirectional: boolean;
    private _disposables: IDisposable[] = [];
    private _terminal?: Terminal;
    private _socket: WebSocket;
    private _onError: (status: V1Status) => void;
    private _onSuccess: (status: V1Status) => void;

    constructor(socket: WebSocket, options?: IExecAddonOptions) {
        this._socket = socket;
        // always set binary type to arraybuffer, we do not handle blobs
        this._socket.binaryType = 'arraybuffer';
        this._bidirectional = !(options && options.bidirectional === false);

        this._onError = (options?.onError) ? options.onError : () => { }
        this._onSuccess = (options?.onSuccess) ? options.onSuccess : () => { }
    }

    public activate(terminal: Terminal): void {
        this._terminal = terminal;

        this._disposables.push(
            addSocketListener(this._socket, 'message', ev => {
                const data: Buffer = Buffer.from(ev.data);
                const streamNum = data.readInt8(0);
                switch (streamNum) {
                    case ExecChannels.StdoutStream:
                    case ExecChannels.StderrStream:
                        terminal.write(data.slice(1));
                        break;
                    case ExecChannels.StatusStream:
                        this._handleStatus(data.slice(1))
                        break;
                }
            })
        );

        if (this._bidirectional) {
            this._disposables.push(terminal.onData(data => this._sendData(data)));
            this._disposables.push(terminal.onBinary(data => this._sendBinary(data)));
        }

        // Set initial size
        const initialWidth = terminal.cols;
        const initialHeight = terminal.rows;
        this._sendResize(initialHeight, initialWidth)

        this._disposables.push(terminal.onResize(({ cols: width, rows: height }: { cols: number, rows: number }) => {
            this._sendResize(height, width)
        }));
        this._disposables.push(addSocketListener(this._socket, 'close', () => this.dispose()));
        this._disposables.push(addSocketListener(this._socket, 'error', () => this.dispose()));
    }

    public dispose(): void {
        this._sendClose();
        for (const d of this._disposables) {
            d.dispose();
        }
    }

    private _sendClose(): void {
        if (this._socket.readyState !== 1) {
            return;
        }
        const exitCode = String.fromCharCode(4); // ctrl+d
        this._send(exitCode);
    }

    private _sendData(data: string): void {
        if (this._socket.readyState !== 1) {
            return;
        }
        this._send(data);
    }

    private _sendBinary(data: string): void {
        if (this._socket.readyState !== 1) {
            return;
        }
        const buffer = new Uint8Array(data.length);
        for (let i = 0; i < data.length; ++i) {
            buffer[i] = data.charCodeAt(i) & 255;
        }
        this._send(Buffer.from(buffer));
    }

    private _sendResize(height: number, width: number): void {
        if (this._socket.readyState !== 1) {
            return;
        }
        const resize = JSON.stringify({ height, width });
        this._send(resize, ExecChannels.ResizeStream)
    }

    private _handleStatus(buffer: Buffer): void {
        const status: V1Status = JSON.parse(buffer.toString('utf8'))
        if (status.status == "Success") {
            this._onSuccess(status)
        } else {
            const exitCode = getExitCode(status);
            if (exitCode) {
                this._terminal?.write(`\rcommand terminated with exit code ${exitCode}...`)
            } else {
                this._terminal?.write(`\rcommand terminated...`)
            }
            this._onError(status)
        }
    }

    private _send(data: Buffer | string, streamNum: number = ExecChannels.StdinStream) {
        const buff = Buffer.alloc(data.length + 1);
        buff.writeInt8(streamNum, 0);
        if (data instanceof Buffer) {
            data.copy(buff, 1);
        } else {
            buff.write(data, 1);
        }
        this._socket.send(buff);
    }
}

function addSocketListener<K extends keyof WebSocketEventMap>(socket: WebSocket, type: K, handler: (this: WebSocket, ev: WebSocketEventMap[K]) => any): IDisposable {
    socket.addEventListener(type, handler);
    return {
        dispose: () => {
            if (!handler) {
                // Already disposed
                return;
            }
            socket.removeEventListener(type, handler);
        }
    };
}

function getExitCode(status: V1Status): string {
    const { details = {} } = status;
    const { causes = [] } = details;
    
    const exitCause = causes?.find((cause) => cause.reason == "ExitCode");
    if (exitCause != null) {
        const { message: exitCode = ''} = exitCause;
        return exitCode;
    }
    
    return ''
}