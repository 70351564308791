import React, {useEffect} from "react";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import {arr, getDisplayNameFromEntity} from "../../../lib/helpers/renderhelper";
import Table, {TableActions} from "../../../components/Table/Table";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import {Resources} from "../../../lib/resources";
import useQuery from "../../../lib/Query/Query";
import client from "../../../lib/client";
import styles from "./Tasks.module.scss";
import Owner from "../../../components/Owner/Owner";
import {ManagementV1Task} from "../../../../gen/models/managementV1Task";
import AuditHeader from "../AuditHeader/AuditHeader";
import {ToggleColumn, ToggleColumnContent} from "../../../contexts/ToggleColumn/ToggleColumn";
import {creationTimestampSorter, stringSorter} from "../../../lib/helpers/sorthelper";
import {displayName} from "../../../lib/helper";
import FixedText from "../../../components/FixedText/FixedText";
import Description from "../../../components/Description/Description";
import {DeleteOutlined, WarningOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {IconPodLogsPopup, TextPodLogsPopup} from "../../Spaces/Spaces/Pods/PodLogsPopup";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {deleteConfirm} from "../../../lib/Modal/Modal";
import ClientMessage from "../../../lib/Message/ClientMessage";

interface Props {
    
}

function getTableColumns(refetch: () => Promise<void>) {
    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            sorter: (a: ManagementV1Task, b: ManagementV1Task) => stringSorter(displayName(a), displayName(b)),
            render: (task: ManagementV1Task) => {
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <TextPodLogsPopup text={displayName(task)} task={task.metadata?.name} />
                    },
                    () => {
                        return <FixedText className={"clickable-link"} maxWidth={200} text={task.metadata?.name} />;
                    },
                ]}/>
            }
        },
        {
            title: "Initiator",
            render: (task: ManagementV1Task) => {
                if (!task.status?.owner) {
                    return "Unknown";
                }
                if (task.status?.owner.team) {
                    return <Owner isTeam={true} displayName={task.status?.owner.team.displayName} username={task.status?.owner.team.username} kubeName={task.status?.owner.team.name} />
                }
                
                return <Owner displayName={task.status?.owner.user?.displayName} username={task.status?.owner.user?.username} kubeName={task.status?.owner.user?.name} />
            },
        },
        {
            title: 'Target',
            render: (task: ManagementV1Task) => {
                if (task.spec?.target?.cluster) {
                    if (task.status?.cluster) {
                        return <Link className={"hover-link"} to={`/clusters/details/${task.spec.target.cluster.cluster}`}><FixedText text={getDisplayNameFromEntity(task.status.cluster)} /></Link>
                    } else {
                        return <Link className={"hover-link"} to={`/clusters/details/${task.spec.target.cluster.cluster}`}><FixedText text={task.spec.target.cluster.cluster} /></Link>
                    }
                } else if (task.spec?.target?.virtualCluster) {
                    return <Link className={"hover-link"} to={`/vclusters/${task.spec?.target?.virtualCluster.cluster}/${task.spec?.target?.virtualCluster.namespace}/${task.spec?.target?.virtualCluster.name}`}><FixedText text={task.spec?.target?.virtualCluster.name+" (Virtual Cluster)"} /></Link>
                }
                
                return "Management API";
            }
        },
        {
            title: 'Status',
            render: (task: ManagementV1Task) => {
                if (task.status?.podPhase+"" === "Running" || task.status?.podPhase+"" === "Succeeded") {
                    return task.status?.podPhase;
                } else if (task.status?.podPhase+"" === "Failed") {
                    return <span className={"color-error"}><WarningOutlined style={{paddingRight: "5px"}} />Failed</span>
                }
                
                return <span className={"color-warning"}>{task.status?.podPhase}</span>
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ManagementV1Task, b: ManagementV1Task) => creationTimestampSorter(a, b),
            render: (task: ManagementV1Task) => {
                return <DynamicTime useTooltip={true} timestamp={task.metadata?.creationTimestamp} />
            }
        },
        {
            title: 'Actions',
            width: "150px",
            render: (task: ManagementV1Task) => {
                return <TableActions className={styles["actions"]}>
                    <IconPodLogsPopup className={"blue-btn"} task={task.metadata?.name!} text={displayName(task)} />
                    <ShowYamlPopup className={"blue-btn"} object={task} resource={Resources.ManagementV1Task} name={task.metadata?.name} canUpdate={false} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={"actions-delete-btn"} onClick={() => {
                            deleteConfirm({
                                title: `Delete Task: ${displayName(task)}`,
                                content: `Are you sure you want to delete the task ${displayName(task)}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await client.management(Resources.ManagementV1Task).Delete(task.metadata?.name!);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        }];
}

export default function Tasks(props: Props) {
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1Task).List());
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 4000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);

    return <div>
        <Table className={styles["table"]}
               loading={!data && loading}
               columns={getTableColumns(refetch)}
               dataSource={data ? arr(data.items).sort((a,b) => creationTimestampSorter(a, b)*-1).map(task => { return {...task, key: task.metadata?.name}}) : undefined} error={error} refetch={refetch} header={{
            top: <AuditHeader>
                <Description.Table>Tasks are certain activities such as space or virtual cluster creations, helm or kubectl apply actions triggered by users or teams that run within a pod inside the management instance.</Description.Table>
            </AuditHeader>
        }} />
    </div>
};
