import React from "react";
import ActivityTable from "../../../Audit/Activity/ActivityTable";
import {useRouteMatch} from "react-router-dom";
import VClusterHeader from "../VClusterHeader/VClusterHeader";
import Description from "../../../../components/Description/Description";

export default function VClusterActivity() {
    const match: any = useRouteMatch();
    const {cluster, namespace, vcluster} = match.params as any;
    return <ActivityTable top={<VClusterHeader>
        <Description.Table>Shows the user and team activity inside this virtual cluster depending on your audit configuration.</Description.Table>
    </VClusterHeader>} hideTarget={true} target={`virtualcluster:${cluster}:${namespace}:${vcluster}`} />
}