import { FileOutlined } from '@ant-design/icons/lib';
import React, { useContext } from 'react';
import FixedText from '../../../../components/FixedText/FixedText';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import { RequestOptionsVCluster } from '../../../../lib/client';
import { DockContext, DockTabActions, DockTabKind } from '../../../../components/Dock/DockContext';
import styles from './PodLogsPopup.module.scss';

interface PodLogsPopupProps<T> {
    className?: string;
    onClose: () => void;
    initialContainer?: string;

    vCluster?: RequestOptionsVCluster;
    cluster?: string;

    text?: string;

    task?: string;
    pod?: string;
    namespace?: string;
}

export function IconPodLogsPopup<T>(props: Omit<PodLogsPopupProps<T>, "onClose">) {
    const { cluster, namespace, pod, vCluster } = props;
    const { dispatch } = useContext(DockContext);
    const onClick = () => {
        dispatch({
            type: DockTabActions.OPEN,
            payload: {
                kind: DockTabKind.LOGS,
                cluster,
                vCluster,
                namespace,
                pod,
            }
        })
    }

    return <React.Fragment>
        <Tooltip title={"logs"}>
            <FileOutlined className={props.className} onClick={onClick} />
        </Tooltip>
    </React.Fragment>
}

export function TextPodLogsPopup<T>(props: Omit<PodLogsPopupProps<T>, "onClose">) {
    const { cluster, namespace, pod, vCluster, task } = props;
    const { dispatch } = useContext(DockContext);
    const onClick = () => {
        dispatch({
            type: DockTabActions.OPEN,
            payload: {
                kind: DockTabKind.LOGS,
                cluster,
                vCluster,
                namespace,
                pod,
                task,
            }
        })
    }

    let classes = [styles["hoverable"]];
    if (props.className) {
        classes = [props.className];
    }

    return <React.Fragment>
        <Tooltip title={"show logs"}>
            {props.text ? <span className={classes.join(" ")} onClick={onClick}>{props.text}</span> : <FixedText maxWidth={300} className={classes.join(" ")} onClick={onClick} text={props.text || props.pod} />}
        </Tooltip>
    </React.Fragment>
}