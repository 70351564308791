import React from "react";
import {
    WarningOutlined, SolutionOutlined
} from "@ant-design/icons/lib";
import { useRouteMatch, useHistory } from "react-router-dom";
import Header from "../../../components/Header/Header";
import {CheckCircleOutlined} from "@ant-design/icons";

interface ClusterHeaderProps {

}

export default function AuditHeader(props: React.PropsWithChildren<ClusterHeaderProps>) {
    const routeMatch = useRouteMatch<{cluster: string}>();
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1] === "audit" ? "activity" : path[path.length - 1];

    return <Header title={"Audit"} selectedKey={selectedKey} items={[
        {
            key: "activity",
            url: `/audit`,
            content: <React.Fragment>
                <SolutionOutlined />Activity
            </React.Fragment>
        },
        {
            key: "tasks",
            url: `/audit/tasks`,
            content: <React.Fragment>
                <CheckCircleOutlined />Tasks
            </React.Fragment>
        },
        {
            key: "policies",
            url: `/audit/policies`,
            content: <React.Fragment>
                <WarningOutlined />Policy Violations
            </React.Fragment>
        },
    ]}>
        {props.children}
    </Header>
}