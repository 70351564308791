import {GroupVersionResource} from "../../lib/client";
import {
    ConfigMapYaml,
    CronJobYaml,
    DaemonSetYaml,
    DeploymentYaml,
    EndpointsYaml,
    GenericYaml,
    IngressYaml,
    JobYaml,
    LimitRangeYaml,
    NetworkPolicyYaml,
    PersistentVolumeClaimYaml,
    PodDisruptionBudgetYaml,
    PodYaml,
    ReplicaSetYaml,
    ReplicationControllerYaml,
    ResourceQuotaYaml,
    RoleBindingYaml,
    RoleYaml,
    SecretYaml,
    ServiceAccountYaml,
    ServiceYaml,
    StatefulSetYaml
} from "./resources";

const resourceNameAndResourcesObj: { [key: string]: string } = {
    "Pod:v1": PodYaml,
    "Deployment:apps/v1": DeploymentYaml,
    "Service:v1": ServiceYaml,
    "ServiceAccount:v1": ServiceAccountYaml,
    "Secret:v1": SecretYaml,
    "ConfigMap:v1": ConfigMapYaml,
    "LimitRange:v1": LimitRangeYaml,
    "Endpoints:v1": EndpointsYaml,
    "PersistentVolumeClaim:v1": PersistentVolumeClaimYaml,
    "StatefulSet:apps/v1": StatefulSetYaml,
    "Ingress:networking.k8s.io/v1": IngressYaml,
    "ReplicationController:v1": ReplicationControllerYaml,
    "ResourceQuota:v1": ResourceQuotaYaml,
    "DaemonSet:apps/v1": DaemonSetYaml,
    "NetworkPolicy:networking.k8s.io/v1": NetworkPolicyYaml,
    "PodDisruptionBudget:policy/v1": PodDisruptionBudgetYaml,
    "Role:rbac.authorization.k8s.io/v1": RoleYaml,
    "RoleBinding:rbac.authorization.k8s.io/v1": RoleBindingYaml,
    "ReplicaSet:apps/v1": ReplicaSetYaml,
    "CronJob:batch/v1": CronJobYaml,
    "Job:batch/v1": JobYaml,
}

export default function getDefaultResourceYamlAndPlaceHolder(resource: GroupVersionResource<any> | undefined, namespace: string | undefined): any | undefined {
    const resourceKind = resource?.kind || "unsupported"
    const resourceVersion = resource?.group ? resource.group + "/" + resource.version : resource?.version || "unsupported"

    const fullResourceName: string = resourceKind + ":" + resourceVersion

    const randomChars = (Math.random() + 1).toString(36).substring(7);
    const resourceName = resource?.kind.toLowerCase() + "-" + randomChars;

    const defaultResourceYaml = resourceNameAndResourcesObj[fullResourceName];

    if (defaultResourceYaml) {
        const defaultResourceValue = defaultResourceYaml?.replace("{RESOURCE_NAME}", resourceName)
            .replace("{RESOURCE_NAMESPACE}", namespace || "")
            .replace("{RESOURCE_KIND}", resourceKind)
            .replace("{RESOURCE_VERSION}", resourceVersion)
        return {
            defaultResourceYaml: defaultResourceValue,
            placeholderYaml: defaultResourceValue
        };
    }
    return {
        placeholderYaml: GenericYaml?.replace("{RESOURCE_NAME}", resourceName)
            .replace("{RESOURCE_NAMESPACE}", namespace || "")
            .replace("{RESOURCE_KIND}", resourceKind)
            .replace("{RESOURCE_VERSION}", resourceVersion)
    };
}
