import React from "react";
import {Steps} from "antd";
import ClusterConnectStep1 from "./ClusterConnectStep1";
import ClusterConnectStep2 from "./ClusterConnectStep2";
import ClusterConnectStep3 from "./ClusterConnectStep3";
import ClusterConnectStep4 from "./ClusterConnectStep4";
import {LoadingOutlined} from "@ant-design/icons/lib";
import styles from "./ClusterConnect.module.scss";
import {V1ObjectMeta} from "@kubernetes/client-node";

interface Props {
    refetch: () => Promise<void>;
}

interface connectStepProps {
    expanded: boolean;
    loading: boolean;
    title: string;
    description: string;

    render: () => React.ReactNode;
}

function renderClusterConnectStep(props: connectStepProps) {
    return <Steps.Step title={props.title} icon={(props.expanded && props.loading) ? <LoadingOutlined /> : undefined} description={<div>
        <div className={styles["description"]}>{props.description}</div>
        {props.expanded && props.render()}
    </div>}  />
}

function ClusterConnect(props: Props) {
    const [currentStep, setCurrentStep] = React.useState<number>(0);
    const [clusterDisplayName, setClusterDisplayName] = React.useState<string>("");
    const [clusterNameChanged, setClusterNameChanged] = React.useState<boolean>(false);
    const [clusterName, setClusterName] = React.useState<string>("");
    const [labelsAnnotations, setLabelsAnnotations] = React.useState<V1ObjectMeta>({});
    const state = {
        currentStep,
        setCurrentStep,
        clusterName,
        setClusterName,
        labelsAnnotations,
        setLabelsAnnotations,
        clusterDisplayName,
        setClusterDisplayName,
        clusterNameChanged,
        setClusterNameChanged,
        refetch: props.refetch,
    };
    const steps = [
        {
            expanded: currentStep == 0,
            loading: false,
            title: "Cluster Name",
            description: "Choose a name for the cluster.",
            render: () => <ClusterConnectStep1 {...state} />
        },
        {
            expanded: currentStep == 1,
            loading: false,
            title: "Cluster Connection",
            description: "Provide a valid kube-config for the cluster.",
            render: () => <ClusterConnectStep2 {...state} />
        },
        {
            expanded: currentStep == 2,
            loading: currentStep == 2,
            title: "Required Services",
            description: "Ensure that loft agent is installed.",
            render: () => <ClusterConnectStep3 {...state} />
        },
        {
            expanded: currentStep == 3,
            loading: false,
            title: "Next Steps",
            description: "Create Spaces within the cluster.",
            render: () => <ClusterConnectStep4 {...state} />
        }
    ];

    return <Steps direction="vertical" current={currentStep}>
        {steps.map(step => renderClusterConnectStep(step))}
    </Steps>
}

export default ClusterConnect;