import React from "react";
import useQuery, {QueryResult} from "../../lib/Query/Query";
import {Result} from "../../lib/result";

export interface QueryProps<T> {
    query: () => Promise<Result<T>>;
    refetch?: any[];
    skip?: boolean;
    delay?: number;
    children: (result: QueryResult<T>) => JSX.Element | null;
}

export default function Query<T>(props: QueryProps<T>) {
    const query = useQuery(props.query, {skip: props.skip, delay: props.delay, refetch: props.refetch});
    return props.children(query);
}