import {ResultError, Return} from "../result";
import {NewResource} from "../resources";
import client, {GroupVersionResource, RequestOptionsVCluster} from "../client";
import {V1ObjectMeta} from "../../../gen/models/V1ObjectMeta";

export type Section<E> = (obj: E) => Promise<ResultError> | ResultError;

export type BatchSection<E> = (objs: Array<E>) => Promise<ResultError> | ResultError;

interface GenericObject {
    metadata?: V1ObjectMeta;
    spec?: {};
}

async function applyBatchSections<E>(objs: Array<E>, sections: Array<BatchSection<E>> | undefined): Promise<ResultError> {
    if (!sections) {
        return Return.Ok();
    }

    for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        const result = await section(objs);
        if (result?.err) {
            return result;
        }
    }

    return Return.Ok();
}

async function applySections<E>(obj: E, sections: Array<Section<E>> | undefined): Promise<ResultError> {
    if (!sections) {
        return Return.Ok();
    }
    
    try {
        for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            const result = await section(obj);
            if (result?.err) {
                return result;
            }
        }
    } catch(err: any) {
        console.error(err);
        return Return.Failed(err?.message ? err.message + "" : err + "")
    }

    return Return.Ok();
}

export interface CreateOptions<E> {
    resource: GroupVersionResource<E>,
    defaultData?: E;
    cluster?: string;
    vCluster?: RequestOptionsVCluster | undefined
    
    sections: Array<Section<E>>,
    afterSections?: Array<Section<E>>;
}

export interface UpdateOptions<E> {
    existing: E;
    resource: GroupVersionResource<E>,
    cluster?: string;
    vCluster?: RequestOptionsVCluster | undefined
    
    sections: Array<Section<E>>
    afterSections?: Array<Section<E>>;
}

export interface BatchOptions<E> {
    existings: Array<E>;
    resource: GroupVersionResource<E>,
    cluster?: string;
    vCluster?: RequestOptionsVCluster | undefined
    
    sections: Array<BatchSection<E>>
    afterSections?: Array<BatchSection<E>>;
}

export async function genericOnCreate<E extends GenericObject>(options: CreateOptions<E>): Promise<ResultError> {
    const obj = NewResource(options.resource, undefined, options?.defaultData);

    // apply sections
    const result = await applySections(obj, options.sections)
    if (result?.err) {
        return result;
    }

    // create 
    const createResult = await client.auto(options?.cluster, options?.vCluster, options.resource).Namespace(obj?.metadata?.namespace).Create(obj);
    if (createResult.err) {
        return createResult;
    }

    // apply after sections
    const afterResult = await applySections(obj, options?.afterSections)
    if (afterResult?.err) {
        return afterResult;
    }

    return Return.Ok();
}

export async function genericOnUpdate<E extends GenericObject>(options: UpdateOptions<E>): Promise<ResultError> {
    if (!options.existing) {
        return Return.Ok();
    }

    // make sure the object is up to date
    const getResult = await client.auto(options?.cluster, options?.vCluster, options.resource).Namespace(options.existing?.metadata?.namespace).Get(options.existing.metadata?.name!);
    if (getResult.err) {
        return getResult;
    }

    options.existing = getResult.val;

    // apply sections
    const result = await applySections(options.existing, options.sections)
    if (result?.err) {
        return result;
    }

    // update
    const updateResult = await client.auto(options?.cluster, options?.vCluster, options.resource).Namespace(options.existing?.metadata?.namespace).Update(options.existing.metadata?.name!, options.existing);
    if (updateResult.err) {
        return updateResult;
    }

    // apply after sections
    const afterResult = await applySections(updateResult.val, options?.afterSections)
    if (afterResult?.err) {
        return afterResult;
    }

    return Return.Ok();
} 

export async function genericOnBatch<E extends GenericObject>(options: BatchOptions<E>) {
    if (!options.existings) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the team has changed meanwhile
    const listResult = await client.auto(options?.cluster, options?.vCluster, options.resource).List();
    if (listResult.err) {
        return listResult;
    }

    // assign the new objects
    options.existings = listResult.val.items.filter(existing => options.existings!.find(oldObj => oldObj.metadata?.name === existing.metadata?.name && (!existing.metadata?.namespace || oldObj.metadata?.namespace === existing.metadata?.namespace)));

    // apply sections
    const result = await applyBatchSections(options.existings, options.sections)
    if (result?.err) {
        return result;
    }

    // update
    for (let i = 0; i < options.existings.length; i++) {
        const updateResult = await client.auto(options?.cluster, options?.vCluster, options.resource).Namespace(options.existings[i].metadata?.namespace).Update(options.existings[i].metadata?.name!, options.existings[i]);
        if (updateResult.err) {
            return updateResult;
        }

        options.existings[i] = updateResult.val;
    }

    // apply after sections
    const afterResult = await applyBatchSections(options.existings, options?.afterSections)
    if (afterResult?.err) {
        return afterResult;
    }

    return Return.Ok();
}


