import React, {RefObject} from "react";
import {DrawerDispatch} from "../../../contexts/drawer/DrawerContext";
import {ManagementV1App} from "../../../../gen/models/managementV1App";
import AppParameters from "../AppParameters/AppParameters";
import {arr} from "../../../lib/helpers/renderhelper";
import ItemDrawer from "../ItemDrawer";
import {displayName} from "../../../lib/helper";
import ClientMessage from "../../../lib/Message/ClientMessage";
import Description from "../../Description/Description";

interface AppParametersDrawerProps {
    drawerDispatcher: DrawerDispatch;
    apps: AppWithNamespace[];
    
    onCreate: (parameters: AppWithParameters[]) => any;
    onClose: () => any;
}

interface AppParametersDrawerState {
    appParameters: string[];
    
    index: number;
}

function getNextApp(apps: AppWithNamespace[], currentIndex: number): number {
    for (let i = currentIndex + 1; i < apps.length; i++) {
        if (arr(apps[i].app?.spec?.parameters).length > 0) {
            return i; 
        }
    }
    
    return -1;
}

function getPreviousApp(apps: AppWithNamespace[], currentIndex: number): number {
    for (let i = currentIndex - 1; i >= 0; i--) {
        if (arr(apps[i].app?.spec?.parameters).length > 0) {
            return i;
        }
    }

    return -1;
}

export interface AppWithNamespace {
    app: ManagementV1App | undefined;
    namespace?: string | undefined;
}

export interface AppWithParameters extends AppWithNamespace {
    parameters: string | undefined;
}

export default class AppParametersDrawer extends React.PureComponent<AppParametersDrawerProps, AppParametersDrawerState> {
    parametersRef?: RefObject<AppParameters>;
    state: AppParametersDrawerState = {
        appParameters: arr(this.props.apps).map(() => ""),
        index: getNextApp(this.props.apps, -1)
    };

    constructor(props: AppParametersDrawerProps) {
        super(props);

        this.parametersRef = React.createRef<AppParameters>();
        this.setTitle(getNextApp(this.props.apps, -1));
    }
    
    setTitle(index: number) {
        const currentApp = this.props.apps[index];
        this.props.drawerDispatcher({
            title:  `App Parameters ${currentApp.namespace ? "(Namespace: " + currentApp.namespace + ")" : ""}: ${displayName(currentApp.app)}`,
            update: true,
        })
    }

    render() {
        const nextApp = getNextApp(this.props.apps, this.state.index);
        const previousApp = getPreviousApp(this.props.apps, this.state.index);
        const currentApp = this.props.apps[this.state.index];
        return <ItemDrawer closeButtonText={"Back"}
                           onClose={() => {
                               const parameters = this.parametersRef?.current?.getParameters(true);
                               if (parameters?.err) {
                                   ClientMessage.Error(parameters);
                                   return;
                               }
                               
                               const newParameters = [...this.state.appParameters];
                               newParameters[this.state.index] = parameters?.val || "";
                               if (previousApp === -1) {
                                   this.props.onClose();
                               } else {
                                   this.setState({index: previousApp, appParameters: newParameters});
                                   this.setTitle(previousApp);
                               }
                           }}
                           okButtonText={nextApp === -1 ? "Create" : "Next"} 
                           onOkAsync={async () => {
                               const parameters = this.parametersRef?.current?.getParameters();
                               if (parameters?.err) {
                                   ClientMessage.Error(parameters);
                                   return;
                               }

                               const newParameters = [...this.state.appParameters];
                               newParameters[this.state.index] = parameters?.val || "";
                               if (nextApp === -1) {
                                   this.setState({appParameters: newParameters});
                                   this.props.onCreate(this.props.apps.map((app, index) => ({
                                       app: app.app,
                                       namespace: app.namespace,
                                       parameters: newParameters[index]
                                   })));
                               } else {
                                   this.setState({index: nextApp, appParameters: newParameters});
                                   this.setTitle(nextApp);
                               }
                           }}>
            <Description>Please fill out the parameters below to create the app {displayName(currentApp.app)}{currentApp.namespace ? <span> in namespace {currentApp.namespace}</span> : undefined}</Description>
            <AppParameters key={this.state.index} 
                           parameters={this.state.appParameters[this.state.index]} 
                           parameterDefinitions={currentApp.app?.spec?.parameters} 
                           mode={"create"} 
                           ref={this.parametersRef} />
        </ItemDrawer>
    }
}