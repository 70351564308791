import styles from "./Breadcrumb.module.scss";
import React from "react";
import {Link} from "react-router-dom";
import * as H from "history";
import {Breadcrumb as AntdBreadcrumb} from "antd";
import {StarFilled, StarOutlined} from "@ant-design/icons";
import {arr} from "../../lib/helpers/renderhelper";
import {Tooltip} from "../Tooltip/Tooltip";

interface Route {
    key: string;
    name: string;
    url: string;
}

export type FavouritesState = {
    favouriteRoutes: Route[];
}

interface FavouritesContextType {
    state: FavouritesState,
    setState: (state: FavouritesState) => void
}

export const FavouritesContext = React.createContext<FavouritesContextType | undefined>(undefined);

const FAVOURITE_ROUTE_KEY = "LOFT_FAVOURITE_ROUTES"

let favouriteRoutes: FavouritesState = {favouriteRoutes: []};
try {
    const localStorageData = localStorage.getItem(FAVOURITE_ROUTE_KEY);
    if (localStorageData) {
        favouriteRoutes = JSON.parse(localStorageData) as any;
    }
} catch(err) {
    favouriteRoutes = {favouriteRoutes: []};
}

export function FavouritesProvider(props: React.PropsWithChildren<{}>) {
    const [state, setState] = React.useState<FavouritesState>(favouriteRoutes);
    return (
        <FavouritesContext.Provider value={{state, setState: newState => {
                localStorage.setItem(FAVOURITE_ROUTE_KEY, JSON.stringify(newState));
                setState(newState);
        }}}>
            {props.children}
        </FavouritesContext.Provider>
    )
}

const customTransform = [
    (url: string, pathPart: string) => new RegExp(`^/secrets/[^/]+/[^/]+$`).test(url) ? pathPart : false,
    (url: string, pathPart: string) => new RegExp(`^/secrets/[^/]+$`).test(url) ? pathPart : false,
    (url: string, pathPart: string) => new RegExp(`^/clusters/details/[^/]+$`).test(url) ? pathPart : false,
    (url: string, pathPart: string) => new RegExp(`^/clusters/details/[^/]+/resources/[^/]+$`).test(url) ? pathPart : false,
    (url: string, pathPart: string) => new RegExp(`^/users/details/[^/]+$`).test(url) ? pathPart : false,
    (url: string, pathPart: string) => new RegExp(`^/users/teams/[^/]+$`).test(url) ? pathPart : false,
    (url: string, pathPart: string) => new RegExp(`^/spaces/[^/]+/[^/]+/other/[^/]+$`).test(url) ? pathPart : false,
    (url: string, pathPart: string) => new RegExp(`^/vclusters/[^/]+/[^/]+/[^/]+/other/[^/]+$`).test(url) ? pathPart : false,
    (url: string) => new RegExp(`^/users/managementroles$`).test(url) ? "Management Roles" : false,
    (url: string) => new RegExp(`^/clusters/clusterroles$`).test(url) ? "Cluster Roles" : false,
    (url: string) => new RegExp(`^/clusters/clusteraccess$`).test(url) ? "Cluster Access" : false,
    (url: string) => new RegExp(`^/clusters/spaceconstraints$`).test(url) ? "Space Constraints" : false,
    (url: string) => new RegExp(`^/vclusters$`).test(url) ? "Virtual Clusters" : false,
    (url: string) => new RegExp(`^/vclustertemplates$`).test(url) ? "Virtual Cluster Templates" : false,
    (url: string) => new RegExp(`^/users/details$`).test(url) ? "" : false,
    (url: string) => new RegExp(`^/clusters/details$`).test(url) ? "" : false,
    (url: string) => new RegExp(`^/spacetemplates$`).test(url) ? "Space Templates" : false,
    (url: string) => new RegExp(`^/users/accounttemplates$`).test(url) ? "Account Templates" : false,
    (url: string) => new RegExp(`^/clusters/details/[^/]+/templateinstances$`).test(url) ? "Template Instances" : false,
    (url: string) => new RegExp(`^/spaces/[^/]+/[^/]+/templateinstances$`).test(url) ? "Template Instances" : false,
    (url: string) => new RegExp(`^/vclusters/[^/]+$`).test(url) ? "" : false,
    (url: string) => new RegExp(`^/vclusters/[^/]+/[^/]+$`).test(url) ? "" : false,
    (url: string, pathPart: string) => new RegExp(`^/vclusters/[^/]+/[^/]+/[^/]+$`).test(url) ? pathPart : false,
    (url: string) => new RegExp(`^/clusters/details/[^/]+/clusteraccess`).test(url) ? "Cluster Access" : false,
    (url: string) => new RegExp(`^/clusters/details/[^/]+/vclusters`).test(url) ? "Virtual Clusters" : false,
    (url: string) => new RegExp(`^/clusters/details/[^/]+/vclustertemplates`).test(url) ? "Virtual Cluster Templates" : false,
    (url: string, pathPart: string) => new RegExp(`^/spaces/[^/]+/[^/]+$`).test(url) ? pathPart : false,
    (url: string) => new RegExp(`^/spaces/[^/]+$`).test(url) ? "" : false,
    (url: string, pathPart: string) => new RegExp(`^/clusters/details/[^/]+/policies/[^/]+$`).test(url) ? pathPart : false,
];

interface BreadcrumbProps {
    location: H.Location<any>;
}

export function getRouteName(routePath: string) {
    const pathSnippets = routePath.split('/').filter(i => i);
    const name: string[] = []; 
    pathSnippets.forEach((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        const transformFn = customTransform.find(tranformFn => tranformFn(url, pathSnippets[index]) !== false);
        const value = transformFn ? transformFn(url, pathSnippets[index]) : pathSnippets[index].charAt(0).toUpperCase() + pathSnippets[index].substring(1).replace("-", " ");
        if (!value) {
            return;
        }

        name.push(value);
    });
    
    return name.join(" > ")
}

export function removeFavouriteRoute(favourites: FavouritesContextType | undefined, routePath: string) {
    favourites?.setState({favouriteRoutes: arr(favourites?.state.favouriteRoutes).filter(route => route.key !== routePath)})
}

export function addFavouriteRoute(favourites: FavouritesContextType | undefined, routePath: string) {
    favourites?.setState({favouriteRoutes: [...arr(favourites?.state.favouriteRoutes),
        {
            key: routePath,
            name: getRouteName(routePath),
            url: routePath
        }
    ]})
}

export function Breadcrumb(props: BreadcrumbProps) {
    const favourites = React.useContext(FavouritesContext);
    const pathSnippets = props.location.pathname.split('/').filter(i => i);
    const title: string[] = [];
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        const transformFn = customTransform.find(tranformFn => tranformFn(url, pathSnippets[index]) !== false);
        const value = transformFn ? transformFn(url, pathSnippets[index]) : pathSnippets[index].charAt(0).toUpperCase() + pathSnippets[index].substring(1).replace("-", " ");
        if (!value) {
            return <React.Fragment key={url} />;
        }

        title.push(value);
        return (
            <AntdBreadcrumb.Item key={url}>
                <Link to={url}>{value}</Link>
            </AntdBreadcrumb.Item>
        );
    });

    const routeName = title.join(" > ");
    document.title = routeName ? `Loft - ${routeName}` : `Loft - Kubernetes Namespaces & Virtual Clusters`;
    const breadcrumbItems = [
        <AntdBreadcrumb.Item key="home">
            <Link to="/spaces">Home</Link>
        </AntdBreadcrumb.Item>,
    ].concat(extraBreadcrumbItems);
    
    const favouriteRoute = routeName && favourites?.state.favouriteRoutes.find(route => route.key === props.location.pathname);
    return <div className={styles["breadcrumb-wrapper"]}>
        <AntdBreadcrumb className={styles["breadcrumb"]} separator=">" style={{ margin: '24px 0' }}>
            {breadcrumbItems}
        </AntdBreadcrumb>
        {favouriteRoute ? 
            <Tooltip title={"unmark favourite"}>
                <StarFilled className={styles["star"]+" "+styles["star-filled"]} onClick={() => removeFavouriteRoute(favourites, favouriteRoute.key)} />
            </Tooltip>
             :
            <Tooltip title={"mark as favourite"}>
                <StarOutlined className={styles["star"]} onClick={() => addFavouriteRoute(favourites, props.location.pathname)} />
            </Tooltip>
        }
    </div>
}