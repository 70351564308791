import React, {RefObject} from "react";
import Section from "../../../../../../components/Drawer/Section/Section";
import {SectionProps} from "../../../../../../components/Drawer/ItemDrawer";
import {ClusterAccount} from "../../Accounts";
import LabelsAnnotationsSection, {LabelsAnnotationsSectionProps} from "../../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import Label from "../../../../../../components/Label/Label";
import Input from "../../../../../../components/Input/Input";
import Description from "../../../../../../components/Description/Description";
import {Resources} from "../../../../../../lib/resources";
import {
    convertMinutesToSeconds,
    convertSecondsToMinutes, getDeleteAfter, getSleepAfter, setDeleteAfter, setSleepAfter
} from "../../../../../../lib/helpers/sleepmodehelper";
import constants from "../../../../../../constants/constants";
import Query from "../../../../../../components/Query/Query";
import client from "../../../../../../lib/client";
import {ErrorMessage} from "../../../../../../components/ErrorMessage/ErrorMessage";
import {arr, selectDefaultFilter, selectOwnerFilter} from "../../../../../../lib/helpers/renderhelper";
import Select from "../../../../../../components/Select/Select";
import Owner from "../../../../../../components/Owner/Owner";
import styles from "./AccountSpaceCreation.module.scss";
import {ResultError, Return} from "../../../../../../lib/result";
import {ConfigV1alpha1Account} from "../../../../../../../gen/models/configV1alpha1Account";
const { Option } = Select;

interface AccountSpaceCreationProps extends SectionProps, LabelsAnnotationsSectionProps {
    cluster: string;
    accounts: ClusterAccount[];

    account?: ClusterAccount;
}

interface AccountSpaceCreationState {
    spaceLimit: number | undefined;
    spaceLimitChanged: boolean;
    spaceLimitInitial: number | undefined;

    sleepConfigSleepAfter: number | undefined;
    sleepConfigSleepAfterChanged: boolean;
    sleepConfigSleepAfterInitial: number | undefined;

    sleepConfigDeleteAfter: number | undefined;
    sleepConfigDeleteAfterChanged: boolean;
    sleepConfigDeleteAfterInitial: number | undefined;

    spaceClusterRole: string | undefined;
    spaceClusterRoleChanged: boolean;
    spaceClusterRoleInitial: string | undefined;

    templates: string[];
    templatesChanged: boolean;
    templatesInitial: string[];

    // this is a workaround, because the logic of the antd select does not really allow
    // to prevent selection of items except making them disabled (which is not desired in this case)
    // so we will quickly select and deselect
    copyFromAccount: string | undefined;
}

export default class AccountSpaceCreation extends React.PureComponent<AccountSpaceCreationProps, AccountSpaceCreationState> {
    labelsSectionRef?: RefObject<LabelsAnnotationsSection>;
    state: AccountSpaceCreationState = {
        spaceLimit: this.props.account ? this.props.account?.account?.spec?.space?.limit : undefined,
        spaceLimitChanged: false,
        spaceLimitInitial: this.props.account ? this.props.account?.account?.spec?.space?.limit : undefined,

        spaceClusterRole: this.props.account ? this.props.account?.account?.spec?.space?.clusterRole : "loft-cluster-space-admin",
        spaceClusterRoleChanged: false,
        spaceClusterRoleInitial: this.props.account ? this.props.account?.account?.spec?.space?.clusterRole : "loft-cluster-space-admin",

        sleepConfigSleepAfter: this.props.account ? getSleepAfter(this.props.account?.account?.spec?.space?.spaceTemplate?.metadata) : undefined,
        sleepConfigSleepAfterChanged: false,
        sleepConfigSleepAfterInitial: this.props.account ? getSleepAfter(this.props.account?.account?.spec?.space?.spaceTemplate?.metadata) : undefined,

        sleepConfigDeleteAfter: this.props.account ? getDeleteAfter(this.props.account?.account?.spec?.space?.spaceTemplate?.metadata) : undefined,
        sleepConfigDeleteAfterChanged: false,
        sleepConfigDeleteAfterInitial: this.props.account ? getDeleteAfter(this.props.account?.account?.spec?.space?.spaceTemplate?.metadata) : undefined,

        templates: arr(this.props.account?.account?.spec?.space?.templateInstances?.map(templateInstance => templateInstance.spec?.template!)),
        templatesChanged: false,
        templatesInitial: arr(this.props.account?.account?.spec?.space?.templateInstances?.map(templateInstance => templateInstance.spec?.template!)),

        copyFromAccount: undefined,
    };

    constructor(props: AccountSpaceCreationProps) {
        super(props);

        this.labelsSectionRef = React.createRef<LabelsAnnotationsSection>();
    }

    create(account: ConfigV1alpha1Account): ResultError {
        if (!account.metadata) {
            account.metadata = {};
        }

        // set templates
        this.setTemplates(account);

        // set space limit
        account.spec!.space!.clusterRole = this.state.spaceClusterRole || "loft-cluster-space-admin";
        account.spec!.space!.limit = this.state.spaceLimit;
        account.spec!.space!.spaceTemplate = {
            metadata: {},
        };

        // set annotations
        const annotationsResult = this.labelsSectionRef!.current!.create(account.spec!.space!.spaceTemplate!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        // set sleep config
        setSleepAfter(account.spec!.space!.spaceTemplate.metadata, this.state.sleepConfigSleepAfter);
        setDeleteAfter(account.spec!.space!.spaceTemplate.metadata, this.state.sleepConfigDeleteAfter);
        return Return.Ok();
    }

    update(account: ConfigV1alpha1Account): ResultError {
        if (!account.metadata) {
            account.metadata = {};
        }
        if (!account.spec) {
            account.spec = {};
        }
        if (!account.spec.space) {
            account.spec.space = {};
        }
        if (!account.spec.space.spaceTemplate) {
            account.spec.space.spaceTemplate = {};
        }
        if (!account.spec.space.spaceTemplate.metadata) {
            account.spec.space.spaceTemplate.metadata = {};
        }

        // set templates
        if (this.state.templatesChanged) {
            this.setTemplates(account);
        }

        // set space limit
        if (this.state.spaceLimitChanged) {
            account.spec!.space!.limit = this.state.spaceLimit;
        }

        // set space cluster role
        if (this.state.spaceClusterRoleChanged) {
            account.spec!.space!.clusterRole = this.state.spaceClusterRole;
        }

        // set annotations
        const annotationsResult = this.labelsSectionRef!.current!.create(account.spec!.space!.spaceTemplate!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        // set sleep config sleep after
        if (this.state.sleepConfigSleepAfterChanged) {
            setSleepAfter(account.spec!.space!.spaceTemplate.metadata, this.state.sleepConfigSleepAfter);
        }

        // set sleep config delete all pods
        if (this.state.sleepConfigDeleteAfterChanged) {
            setDeleteAfter(account.spec!.space!.spaceTemplate.metadata, this.state.sleepConfigDeleteAfter);
        }

        return Return.Ok();
    }

    batch(accounts: ConfigV1alpha1Account[]): ResultError {
        for (let i = 0; i < accounts.length; i++) {
            const result = this.update(accounts[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    }

    setTemplates(account: ConfigV1alpha1Account) {
        if (!account.spec) {
            account.spec = {};
        }
        if (!account.spec.space) {
            account.spec.space = {};
        }
        if (!account.spec.space.templateInstances) {
            account.spec.space.templateInstances = [];
        }

        const newTemplates = arr(this.state.templates);

        // delete the ones that shouldn't be there
        for (let i = account.spec.space.templateInstances.length - 1; i >= 0; i--) {
            const template = account.spec.space.templateInstances[i];
            const found = newTemplates.find(newTemplate => template.spec?.template === newTemplate) !== undefined;
            if (!found) {
                account.spec.space.templateInstances.splice(i, 1);
            }
        }

        // add the ones that should be there
        for (let i = 0; i < newTemplates.length; i++) {
            const found = account.spec.space.templateInstances.find(oldTemplate => oldTemplate.spec?.template === newTemplates[i]) !== undefined;
            if (!found) {
                account.spec.space.templateInstances.push({
                   spec: {
                       template: newTemplates[i],
                       sync: true
                   }
                });
            }
        }
    }

    setSleepAfter = (val: number | undefined) => {
        this.setState({
            sleepConfigSleepAfter: convertMinutesToSeconds(val)
        })
    };

    setDeleteAfter = (val: number | undefined) => {
        this.setState({
            sleepConfigDeleteAfter: convertMinutesToSeconds(val),
        })
    };

    render() {
        return <Section title={"Space Creation Settings"} foldable={true} defaultFolded={true}>
            <div>
                <Label>Copy From Existing Account</Label>
                <Select
                    showSearch
                    allowClear
                    placeholder="Select Account"
                    style={{ width: '100%' }}
                    value={this.state.copyFromAccount}
                    onChange={value => {
                        const account = this.props.accounts.find(account => account.account?.metadata?.name === value);
                        if (account) {
                            // update state
                            this.setState({
                                spaceLimitChanged: true,
                                spaceLimit: account.account?.spec?.space?.limit,

                                spaceClusterRoleChanged: true,
                                spaceClusterRole: account.account?.spec?.space?.clusterRole,

                                sleepConfigSleepAfterChanged: true,
                                sleepConfigSleepAfter: getSleepAfter(account.account?.spec?.space?.spaceTemplate?.metadata),

                                sleepConfigDeleteAfterChanged: true,
                                sleepConfigDeleteAfter: getDeleteAfter(account.account?.spec?.space?.spaceTemplate?.metadata),

                                templatesChanged: true,
                                templates: arr(account.account?.spec?.space?.templateInstances?.map(templateInstance => templateInstance.spec?.template!)),

                                copyFromAccount: ""
                            }, () => this.setState({copyFromAccount: undefined}));

                            // update metadata
                            this.labelsSectionRef?.current?.setMetadata(account.account?.spec?.space?.spaceTemplate?.metadata);
                        }
                    }}
                    optionFilterProp="children"
                    filterOption={selectOwnerFilter}
                >
                    {this.props.accounts ? this.props.accounts.map(account => <Option key={account.account?.metadata?.name!} value={account.account?.metadata?.name!}>
                        <Owner isTeam={account.isTeam} displayName={account.member.info?.displayName} username={account.member.info?.username} kubeName={account.member.info?.name} type={"small"} />
                    </Option>) : undefined}
                </Select>
                <Description>Selecting an account will copy the values for space defaults into the fields below</Description>
            </div>
            <div className={styles["row"]}>
                <div>
                    <Label>Space Limit</Label>
                    <Input resetable={this.props.mode !== "create"}
                           changed={this.state.spaceLimitChanged}
                           initial={this.state.spaceLimitInitial as any}
                           type={"number"}
                           onChangedStatus={status => this.setState({spaceLimitChanged: status})}
                           value={this.state.spaceLimit}
                           placeholder={"Max number of spaces"}
                           onChange={(e) => this.setState({spaceLimit: parseInt(e.target.value)})} />
                   <Description>Leave empty for unlimited.</Description>
                </div>
                <div>
                    <Label>Space Default Cluster Role</Label>
                    <Query query={async () => await client.cluster(this.props.cluster, Resources.V1ClusterRole).List()}>
                        {
                            result => {
                                if (result.error) {
                                    return <span className={"color-error"}>{result.error.val?.message!}</span>
                                }

                                return <Select showSearch
                                               className={styles["cluster-role-select"]}
                                               resetable={this.props.mode !== "create"}
                                               changed={this.state.spaceClusterRoleChanged}
                                               initial={this.state.spaceClusterRoleInitial as any}
                                               onChangedStatus={status => this.setState({spaceClusterRoleChanged: status})}
                                               filterOption={selectDefaultFilter}
                                               placeholder={"Default Space Cluster Role"}
                                               value={this.state.spaceClusterRole}
                                               onChange={value => this.setState({spaceClusterRole: value})}>
                                    {arr(result.data?.items).map(clusterRole => <Option key={clusterRole.metadata?.name!} value={clusterRole.metadata?.name!}>{clusterRole.metadata?.name!}</Option>)}
                                </Select>
                            }
                        }
                    </Query>
                    <Description>The role that is bound to the account for a new space.</Description>
                </div>
            </div>
            <div className={styles["row"]}>
                <div>
                    <Label>Sleep After Inactivity</Label>
                    <Input resetable={this.props.mode !== "create"}
                           changed={this.state.sleepConfigSleepAfterChanged}
                           initial={convertSecondsToMinutes(this.state.sleepConfigSleepAfterInitial)}
                           type={"number"}
                           onChangedStatus={status => this.setState({sleepConfigSleepAfterChanged: status})}
                           value={convertSecondsToMinutes(this.state.sleepConfigSleepAfter)}
                           placeholder={"Minutes before sleeping"}
                           onChange={(e) => this.setSleepAfter(parseInt(e.target.value))} />
                    <Description>Leave empty for manual sleeping only.</Description>
                </div>
                <div>
                    <Label>Delete Space After Inactivity</Label>
                    <Input resetable={this.props.mode !== "create"}
                           changed={this.state.sleepConfigDeleteAfterChanged}
                           initial={convertSecondsToMinutes(this.state.sleepConfigDeleteAfterInitial)}
                           type={"number"}
                           onChangedStatus={status => this.setState({sleepConfigDeleteAfterChanged: status})}
                           value={convertSecondsToMinutes(this.state.sleepConfigDeleteAfter)}
                           placeholder={"Minutes before deletion"}
                           onChange={(e) => this.setDeleteAfter(parseInt(e.target.value))} />
                    <Description>Delete the space if it is inactive for this period.</Description>
                </div>
            </div>
            <div>
                <Label>Enforce Templates</Label>
                <Query query={async () => {
                    const result = await client.cluster(this.props.cluster, Resources.ConfigV1alpha1Template).List();
                    if (result.err) {
                        return result;
                    }

                    if (this.props.mode === "create") {
                        const defaultTemplates = result.val.items.filter(t => t.metadata?.labels?.[constants.LoftClusterDefaultTemplateLabel] === "true");
                        if (defaultTemplates.length > 0) {
                            this.setState({
                                templates: defaultTemplates.map(t => t.metadata!.name!)
                            })
                        }
                    }

                    return result;
                }}>
                    {
                        result => {
                            if (result.error) {
                                return <ErrorMessage error={result.error} />;
                            }

                            return <Select
                                resetable={this.props.mode !== "create"}
                                changed={this.state.templatesChanged}
                                initial={this.state.templatesInitial}
                                value={this.state.templates}
                                loading={result.loading}
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select templates that should be enforced in a new space"
                                onChangedStatus={status => this.setState({templatesChanged: status})}
                                onChange={(value) => {
                                    this.setState({
                                        templates: value
                                    });
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {result.data ? result.data.items.map(template => <Option key={template.metadata?.name!} value={template.metadata?.name!}>{template.metadata?.name}</Option>) : undefined}
                            </Select>
                        }
                    }
                </Query>
                <Description>For each of these templates, loft will automatically create a TemplateInstance in every new space.</Description>
            </div>
            <LabelsAnnotationsSection {...this.props} name={"Space Labels & Annotations"} metadata={this.props.account ? this.props.account.account?.spec?.space?.spaceTemplate?.metadata : undefined} ref={this.labelsSectionRef} />
        </Section>
    }
}