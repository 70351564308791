import {ResultError, Return} from "./result";

var nameValidationRegEx = new RegExp("[a-z0-9]([-a-z0-9]*[a-z0-9])?");

export function ValidateKubeName(input: string): ResultError {
    if (input.match(nameValidationRegEx)) {
        return Return.Ok();
    }

    return Return.Failed(`input "${input}" is invalid: can only contain lowercase characters, numbers and dashes`)
}