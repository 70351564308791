import {CodeOutlined, FileTextOutlined} from '@ant-design/icons';
import {Tabs} from 'antd';
import React, {PropsWithChildren, useContext, useEffect, useRef, useState} from 'react';
import styles from './Dock.module.scss';
import {DockContext, DockTab, DockTabActions, DockTabKind} from './DockContext';
import {PodExecDockTab} from './PodExecDockTab';
import {PodLogsDockTab} from './PodLogsDockTab';

const { TabPane } = Tabs;

export function Dock(props: PropsWithChildren<{}>) {
    const { state: { tabs, focusedTab }, dispatch } = useContext(DockContext);
    const [visible, setVisible] = useState<boolean>(true);
    const dockRef = useRef<HTMLDivElement>(null);
    const dockWrapperRef = useRef<HTMLDivElement>(null);
    const [dockHeight, setDockHeight] = useState<number>(350);
    useEffect(() => {
        if (tabs.length > 0) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [tabs]);

    const [dragging, setDragging] = useState(false);
    useEffect(() => {
        const stopDragging = () => {
            setDragging(false);
        };
        const onMouseMove = (e: MouseEvent) => {
            if (!dragging) {
                return false;
            }

            const dockRect = dockWrapperRef.current?.getBoundingClientRect();
            if (dockRect) {
                const newDockHeight = dockRect.height - (e.clientY - dockRect.top);
                setDockHeight(Math.min(Math.round(dockRect.height - 70), Math.max(250, newDockHeight)));
            }
        };
        
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', stopDragging);
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', stopDragging);
        };
    }, [dragging, dockWrapperRef]);

    const startDragging = (e: React.MouseEvent) => {
        setDragging(true);
        e.preventDefault();
    };
    const onEdit = (targetKey: any, action: string) => {
        if (action === 'remove') {
            dispatch({ type: DockTabActions.CLOSE, id: targetKey })
        }
    };
    const onChange = (targetKey: any) => {
        dispatch({ type: DockTabActions.FOCUS, id: targetKey })
    }
    
    const dividerStyles = [dragging ? styles["divider-dragging"] : styles["divider"]];
    const dockStyles = [styles["bottom"]];
    if (!visible) {
        dividerStyles.push("hidden");
        dockStyles.push("hidden");
    }

    const tabPanes = tabs.map(tab => tabPane(tab, dockHeight));
    return (
        <div className={styles["dock"]} ref={dockWrapperRef}>
            <div className={styles["top"]}>{props.children}</div>
            <div className={dividerStyles.join(" ")} onMouseDown={startDragging} />
            <div className={dockStyles.join(" ")} style={{flex: `0 0 ${dockHeight}px`}} ref={dockRef}>
                {
                    visible &&
                    <Tabs hideAdd onEdit={onEdit} type="editable-card" activeKey={focusedTab} onChange={onChange}>
                        {tabPanes}
                    </Tabs>
                }
            </div>
        </div>
    );
}

function tabPane(tab: DockTab, dockHeight: number): React.ReactNode {
    const { kind, ...tabProps } = tab;
    switch (tab.kind) {
        case DockTabKind.LOGS:
            return <TabPane tab={tabName(tab)} key={tab.id} closable={true}>
                <PodLogsDockTab key={tab.id} {...tabProps} dockHeight={dockHeight} />
            </TabPane>
        case DockTabKind.EXEC:
            return <TabPane tab={tabName(tab)} key={tab.id} closable={true}>
                <PodExecDockTab key={tab.id} {...tabProps} dockHeight={dockHeight} />
            </TabPane>
    }
}

function tabName(tab: DockTab): React.ReactNode {
    const tabTitle = tab.task ? `Task: ${tab.task}` : `${tab.kind === DockTabKind.EXEC ? "Shell" : "Logs"}: ${tab.pod}`;
    switch (tab.kind) {
        case DockTabKind.EXEC:
            return <span>
                <CodeOutlined />
                {tabTitle}
            </span>
        case DockTabKind.LOGS:
            return <span>
                <FileTextOutlined />
                {tabTitle}
            </span>
    };
}
