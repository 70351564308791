import React from "react";
import * as Ant from "antd";
import styles from "./AutoComplete.module.scss";
import {filterResetProps, ResetProps, useReset} from "../../lib/hooks/Reset/reset";
import {without} from "../../lib/helpers/renderhelper";
import * as AntAutoComplete from "antd/lib/auto-complete";

export interface AutoCompleteProps extends AntAutoComplete.AutoCompleteProps, Omit<ResetProps<string>, "value"> {
}

export function defaultAutoCompleteFilter(inputValue: string, option: any) {
    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
}

export default function AutoComplete(props: AutoCompleteProps) {
    const {changed, onSelect, onReset} = useReset<string>(props, props.onChange as any);
    const classNames = [styles["autocomplete"]];
    if (props.className) {
        classNames.push(props.className);
    }
    if (changed) {
        classNames.push(styles["changed"]);
    }

    return <span className={classNames.join(" ")}>
        <Ant.AutoComplete {...filterResetProps(without(props, ["className"]))} className={classNames.join(" ")} onSelect={onSelect} onClick={onSelect} onChange={(val: any, option) => {
            if (props.onChange) {
                props.onChange(val, option);
            }

            onSelect();
        }} />
        {changed && <span className={styles["text"]} onClick={(e) => onReset(e)}>Reset</span>}
    </span>
}