import React from "react";
import styles from "./Description.module.scss";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

export interface DescriptionProps {
    className?: string;
    style?: React.CSSProperties;
}

function Description(props: React.PropsWithChildren<DescriptionProps>) {
    let classNames = [styles["description"]];
    if (props.className) {
        classNames.push(props.className);
    }

    return <div className={classNames.join(" ")} style={props.style}>
        {props.children}
    </div>
}

Description.Table = function (props: React.PropsWithChildren<DescriptionProps>) {
    return <Description {...props} className={styles["description-table"]} />
}

Description.Column = function (props: React.PropsWithChildren<DescriptionProps>) {
    let classNames = [styles["description-column"]];
    if (props.className) {
        classNames.push(props.className);
    }

    let minWidth: any = '300px';
    if (props.style?.minWidth) {
        minWidth = props.style.minWidth;
    }

    return <div {...props} className={classNames.join(" ")} style={{ minWidth }}>
        <ReactMarkdown children={props.children + ""}
                       linkTarget={(href, children, title) => {
                           if (href.indexOf("/") === 0) {
                               return "";
                           }

                           return "_blank";
                       }}
                       remarkPlugins={[remarkGfm]} />
    </div>
}

export default Description;