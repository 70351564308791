import React from "react";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {Result, ResultError, Return} from "../../../../../lib/result";
import Section from "../../../../../components/Drawer/Section/Section";
import Description from "../../../../../components/Description/Description";
import YAMLEditor from "../../../../../components/YAMLEditor/YAMLEditor";
import {ManagementV1SpaceConstraint} from "../../../../../../gen/models/managementV1SpaceConstraint";

interface SpaceConstraintObjectsState {
    manifests: string;
}

interface SpaceConstraintObjectsProps extends SectionProps {
    spaceConstraint?: ManagementV1SpaceConstraint;
}

export default class SpaceConstraintObjects extends React.PureComponent<SpaceConstraintObjectsProps, SpaceConstraintObjectsState> {
    state: SpaceConstraintObjectsState = {
        manifests: this.props.spaceConstraint?.spec?.localSpaceConstraintTemplate?.spec?.spaceTemplate?.objects || "",
    };

    create = async (spaceConstraint: ManagementV1SpaceConstraint): Promise<ResultError> => {
        return this.update(spaceConstraint);
    };

    update = async (spaceConstraint: ManagementV1SpaceConstraint): Promise<ResultError> => {
        if (!spaceConstraint.spec) {
            spaceConstraint.spec = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate) {
            spaceConstraint.spec.localSpaceConstraintTemplate = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate.spec) {
            spaceConstraint.spec.localSpaceConstraintTemplate.spec = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate) {
            spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate = {};
        }
        spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate.objects = this.state.manifests;
        
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={`Enforce Resources`} defaultFolded={true} foldable={true}>
            <Description>Specify which Kubernetes resources should be present in a space that uses these Space Constraints.</Description>
            <YAMLEditor value={this.state.manifests} minLines={10} maxLines={100} onChange={val => {
                this.setState({manifests: val});
            }} placeholder={`apiVersion: v1
kind: LimitRange
metadata:
  name: my-limit-range
spec:
  limits:
...`} />
            <Description>Each manifest will be created or updated within a space that has these space constraints assigned. For more information please take a look at the <a href={"https://loft.sh/docs"} target={"_blank"}>loft documenation</a>.</Description>
        </Section>
    }
}