import React from "react";
import Label from "../../../../../components/Label/Label";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Input from "../../../../../components/Input/Input";
import {ManagementV1UserProfile} from "../../../../../../gen/models/managementV1UserProfile";
import Description from "../../../../../components/Description/Description";

interface UsernameState {
    username: string | undefined;
    displayName: string | undefined;
}

interface UsernameProps extends SectionProps {
    username: string | undefined;
    displayName: string | undefined;
    show: boolean;
}

export default class Username extends React.PureComponent<UsernameProps, UsernameState> {
    state: UsernameState = {
        username: this.props.username,
        displayName: this.props.displayName,
    };

    create = (obj: ManagementV1UserProfile): ResultError => {
        if (!this.props.show) {
            return Return.Ok();
        }

        obj.displayName = this.state.displayName;
        obj.username = this.state.username;
        return Return.Ok();
    };

    render() {
        if (this.props.mode !== "create" || !this.props.show) {
            return null;
        }

        return <div>
            <Label>Display Name</Label>
            <Input onChange={e => this.setState({displayName: e.target.value})} placeholder={"Frank Johnson"} value={this.state.displayName} />
            <Description>The display name can be changed without side effects.</Description>
            <Label style={{"marginTop": "26px"}}>Username</Label>
            <Input onChange={e => this.setState({username: e.target.value})} placeholder={"name@company.tld"} value={this.state.username} />
            <Description className={"color-warning"}>Changing your username will have irreversible consequences and is not recommended.</Description>
        </div>
    }
}