import React from "react";
import AdminHeader from "../AdminHeader/AdminHeader";
import styles from "./Billing.module.scss";
import Loading from "../../../components/Loading/Loading";
import {Space} from "antd";
import Label from "../../../components/Label/Label";
import LabeledValue from "../../../components/LabeledValue/LabeledValue";
import {formatDate} from "../../../lib/helper";
import AsyncButton from "../../../components/AsyncButton/AsyncButton";
import {ToolOutlined} from "@ant-design/icons/lib";
import {openLicenseLink} from "../License/License";
import {ManagementV1License} from "../../../../gen/models/managementV1License";
import {useLicense} from "../../../contexts/LicenseContext/LicenseContext";

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function getMonthName(license: ManagementV1License) {
    if (license.status?.info?.subscription?.defaultPaymentMethod?.card?.expMonth && license.status?.info?.subscription?.defaultPaymentMethod?.card?.expMonth > 0) {
        return monthNames[license.status?.info?.subscription?.defaultPaymentMethod?.card?.expMonth - 1];
    }

    return "??";
}

function renderBillingContact(license: ManagementV1License) {
    const firstName = license.status?.info?.customer?.firstName || "";
    const lastName = license.status?.info?.customer?.lastName || "";
    const email = license.status?.info?.customer?.email || "";
    if (!firstName) {
        return "-";
    }

    return <span>
        {firstName} {lastName}
        {email ? <React.Fragment><br />{email}</React.Fragment> : undefined}
    </span>
}

function renderAddress(license: ManagementV1License) {
    const addressLine1 = license.status?.info?.customer?.addressLine1;
    const addressLine2 = license.status?.info?.customer?.addressLine2;
    if (!addressLine1) {
        return "-";
    }

    return <span>
        {addressLine1}
        {addressLine2 ? <React.Fragment><br />{addressLine2}</React.Fragment> : undefined}
    </span>
}

function renderCity(license: ManagementV1License) {
    const city = license.status?.info?.customer?.city;
    const postalCode = license.status?.info?.customer?.postalCode;
    const country = license.status?.info?.customer?.country;
    if (!city) {
        return "-";
    }

    return <span>
        {city}{postalCode ? ", " + postalCode : undefined}
        {country ? <React.Fragment><br />{country}</React.Fragment> : undefined}
    </span>
}

export default function Billing() {
    const licenseState = useLicense();
    if (!licenseState || !licenseState.license) {
        return <Loading />;
    }

    const license = licenseState.license;
    let nextInvoice = license.status?.info?.subscription?.nextInvoice;
    if (!nextInvoice) {
        nextInvoice = license.status?.info?.subscription?.trialEnd;

        if (!nextInvoice) {
            nextInvoice = license.status?.info?.subscription?.currentPeriodEnd;
        }
    }
    return <div className={styles["admin-wrapper"]}>
        <AdminHeader />
        <Space size={40} direction={"vertical"}>
            <div>
                <Label.Bold>Customer Information</Label.Bold>
                <LabeledValue label={"Company"}>
                    {license.status?.info?.customer?.company || "-"}
                </LabeledValue>
                <div className={styles["margin-top"]}>
                    <Space size={40} align={"start"}>
                        <LabeledValue label={"Billing Contact"}>
                            {renderBillingContact(license)}
                        </LabeledValue>
                        <LabeledValue label={"Address"}>
                            {renderAddress(license)}
                        </LabeledValue>
                        <LabeledValue label={"City"}>
                            {renderCity(license)}
                        </LabeledValue>
                    </Space>
                </div>
                {license.status?.info?.links?.changeCustomerInfo && <div className={styles["margin-top"]}>
                    <AsyncButton className={styles["margin-top"]} type={"primary"} onClickAsync={async () => await openLicenseLink(license, "changeCustomerInfo", licenseState?.token?.status?.token!)}><ToolOutlined />Update Customer Information</AsyncButton>
                </div>}
            </div>
            <div>
                <Label.Bold>Payment Method</Label.Bold>
                <Space size={40} align={"start"}>
                    <LabeledValue label={"Type"}>
                        {license.status?.info?.subscription?.defaultPaymentMethod?.card ? "Credit Card" : "-"}
                    </LabeledValue>
                    <LabeledValue label={"Card Information"}>
                        {license.status?.info?.subscription?.defaultPaymentMethod?.card ? license.status?.info?.subscription?.defaultPaymentMethod?.card?.brand?.toUpperCase() + " ending on " + license.status?.info?.subscription?.defaultPaymentMethod?.card.last4 : "-"}
                    </LabeledValue>
                    <LabeledValue label={"Expiration"}>
                        {license.status?.info?.subscription?.defaultPaymentMethod?.card ? getMonthName(license) + " " + license.status?.info?.subscription?.defaultPaymentMethod?.card.expYear : "-"}
                    </LabeledValue>
                    <LabeledValue label={"License Fee"}>
                        {license.status?.info?.plan?.currency.toUpperCase()}&nbsp;{((license.status?.info?.plan?.price || 0) / 100).toFixed(2)}&nbsp;/&nbsp;{license.status?.info?.plan?.interval}
                    </LabeledValue>
                    <LabeledValue label={"Next Invoice"}>
                        {nextInvoice ? formatDate(nextInvoice*1000) : "-"}
                    </LabeledValue>
                </Space>
                {license.status?.info?.links?.changePaymentMethod || license.status?.info?.links?.viewInvoices ? <div className={styles["margin-top"]}>
                    {license.status?.info?.links?.changePaymentMethod && <AsyncButton className={styles["margin-top"]} type={"primary"} onClickAsync={async () => await openLicenseLink(license, "changePaymentMethod", licenseState?.token?.status?.token!)}><ToolOutlined />Update Payment Method</AsyncButton>}
                    {license.status?.info?.links?.viewInvoices && <AsyncButton className={styles["margin-top"] + " " + styles["view-invoices"]} onClickAsync={async () => await openLicenseLink(license, "viewInvoices", licenseState?.token?.status?.token!)}>View Invoices</AsyncButton>}
                </div> : undefined}
            </div>
        </Space>
    </div>
}