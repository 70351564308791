import {V1ObjectMeta, V1OwnerReference, V1Pod} from "@kubernetes/client-node";
import {Result, Return} from "../result";
import {GroupVersionResource} from "../client";
import {Unstructured} from "../types";
import {arr} from "./renderhelper";

export function mapToLabelSelector(labelsMap?: {[key: string]: string}): string {
    if (!labelsMap) {
        return "";
    }

    const mapValues = []
    for(let key in labelsMap) {
        mapValues.push(key + "=" + labelsMap[key]);
    }
    return mapValues.join(",");
}

export function fromResourceString(groupVersionResourceString: string): GroupVersionResource<Unstructured> {
    const idx = groupVersionResourceString.indexOf(".");
    if (idx === -1) {
        return {
            resource: groupVersionResourceString,
            group: "",
            version: "",
            kind: ""
        };
    }

    return {
        resource: groupVersionResourceString.substring(0, idx),
        group: groupVersionResourceString.substring(idx + 1),
        version: "",
        kind: ""
    };
}

export function toResourceString<T>(groupVersionResource: GroupVersionResource<T>): string {
    if (groupVersionResource.group) {
        return [groupVersionResource.resource, groupVersionResource.group].join(".");
    }

    return groupVersionResource.resource;
}

export function parseApiVersion(apiVersion?: string): Result<string[]> {
    if (!apiVersion) {
        return Return.Failed("invalid apiVersion: " + apiVersion)
    }

    const splitted = apiVersion.split("/");
    if (splitted.length === 1) {
        return Return.Value(["", splitted[0]] as any);
    } else if (splitted.length === 2) {
        return Return.Value([splitted[0], splitted[1]] as any);
    }

    return Return.Failed("unexpected apiVersion: " + apiVersion)
}

export function addOwner(meta: V1ObjectMeta, owner: V1OwnerReference) {
    if (!meta.ownerReferences) {
        meta.ownerReferences = [];
    }

    meta.ownerReferences.push(owner);
}

export function newOwnerReference(apiVersion: string, kind: string, meta: V1ObjectMeta): V1OwnerReference {
    return {
        uid: meta.uid!,
        name: meta.name!,
        apiVersion,
        kind,
    }
}

export function isPodReady(pod: V1Pod): boolean {
    if (pod.status?.phase !== "Running") {
        return false;
    }

    for (let i = 0; i < arr(pod.status?.initContainerStatuses).length; i++) {
        const container = pod.status!.initContainerStatuses![i];
        if (container.state?.terminated && container.state?.terminated?.exitCode == 0) {
            continue;
        } 
        
        return false
    }
    
    let hasRunning = false;
    for (let i = arr(pod.status?.containerStatuses).length - 1; i >= 0; i--) {
        const container = pod.status!.containerStatuses![i];
        if (container.state?.waiting) {
            return false;
        } else if (container.state?.terminated) {
            return false;
        } else if (!container.ready || !container.state?.running) {
            return false;
        }

        hasRunning = true
    }
    
    if (!hasRunning || pod.metadata?.deletionTimestamp) {
        return false;
    }
    return true;
}

export function getPodStatus(pod: V1Pod): string {
    let reason = pod.status?.phase!;
    if (pod.status?.reason) {
        reason = pod.status.reason!;
    }

    let initializing = false
    for (let i = 0; i < arr(pod.status?.initContainerStatuses).length; i++) {
        const container = pod.status!.initContainerStatuses![i];
        if (container.state?.terminated && container.state?.terminated?.exitCode == 0) {
            continue;
        } else if (container.state?.terminated) {
            // initialization is failed
            if (!container.state?.terminated?.reason) {
                if (container.state?.terminated?.signal) {
                    reason = "Init:Signal:"+container.state.terminated.signal;
                } else {
                    reason = "Init:ExitCode:"+container.state.terminated.exitCode;
                }
            } else {
                reason = "Init:" + container.state.terminated.reason;
            }
            initializing = true;
        } else if (container.state?.waiting?.reason && container.state?.waiting?.reason != "PodInitializing") {
            reason = "Init:" + container.state.waiting.reason;
            initializing = true;
        } else {
            reason = "Init:"+i+"/"+arr(pod.spec?.initContainers).length;
            initializing = true;
        }

        break
    }
    if (!initializing) {
        let hasRunning = false;
        for (let i = arr(pod.status?.containerStatuses).length - 1; i >= 0; i--) {
            const container = pod.status!.containerStatuses![i];
            if (container.state?.waiting?.reason) {
                reason = container.state?.waiting?.reason
            } else if (container.state?.terminated?.reason) {
                reason = container.state?.terminated?.reason
            } else if (container.state?.terminated && !container.state?.terminated.reason) {
                if (container.state.terminated.signal) {
                    reason = "Signal:"+container.state.terminated.signal;
                } else {
                    reason = "ExitCode:"+container.state.terminated.exitCode;
                }
            } else if (container.ready && container.state?.running) {
                hasRunning = true
            }
        }

        // change pod status back to "Running" if there is at least one container still reporting as "Running" status
        if (reason === "Completed" && hasRunning) {
            reason = "Running"
        }
    }

    if (pod.metadata?.deletionTimestamp && pod.status?.reason == "NodeLost") {
        reason = "Unknown"
    } else if (pod.metadata?.deletionTimestamp) {
        reason = "Terminating"
    }

    return reason;
}
