import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import client from "./lib/client";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import {appRoutes} from "./AppRoutes";
import UserProvider from "./contexts/UserContext/UserContext";
import Login, {LOFT_REDIRECT_ON_LOGIN_IDENTIFIER} from "./views/Auth/Login/Login";
import ResetPassword from "./views/Auth/ResetPassword/ResetPassword";
import CreateAccount from "./views/Auth/CreateAccount/CreateAccount";
import OidcGrant from "./views/Auth/OidcGrant/OidcGrant";
import LicenseProvider from "./contexts/LicenseContext/LicenseContext";

const baseRoutes: Array<{
    path: string;
    Component: React.ComponentType<any>;
}> = [
    {
        path: "/",
        Component: () => {
            window.location.href = client.isLoggedIn() ? "/spaces" : "/login";
            return null;
        },
    },
    {
        path: "/invite",
        Component: CreateAccount,
    },
    {
        path: "/reset-password",
        Component: ResetPassword,
    },
    {
        path: "/login",
        Component: Login,
    },
    {
        path: "/grant",
        Component: OidcGrant,
    },
];

interface Props {}

function Routes(props: Props) {
    const isLoggedIn = client.isLoggedIn();
    const renderRoutes: Array<JSX.Element> = [];

    // add base routes
    let isBaseRoute = false;
    for (let entry of baseRoutes) {
        if (!isLoggedIn && window.location.pathname === entry.path) {
            isBaseRoute = true;
        }
        
        renderRoutes.push(<Route exact key={entry.path} path={entry.path} component={entry.Component} />)
    }

    // add app routes
    if (!isLoggedIn) {
        for (let entry of appRoutes) {
            renderRoutes.push(<Redirect exact key={entry.path} path={entry.path} to="/" />);
        }

        renderRoutes.push(<Route key={-1} render={() => <h1>Page not found</h1>} />)
    } else {
        renderRoutes.push(<Route key={-1} component={BaseLayout} />)
    }

    let children = <Switch>
        {renderRoutes}
    </Switch>;

    // if we are logged in we wrap it with the user provider
    if (client.isLoggedIn()) {
        children = <BrowserRouter>
            <UserProvider>
                <LicenseProvider>
                    {children}
                </LicenseProvider>
            </UserProvider>
        </BrowserRouter>;
    } else {
        children = <BrowserRouter>{children}</BrowserRouter>;
    }
    
    // set redirect point if we are not logged in and the current path 
    // isn't a base route
    if (!isLoggedIn && !isBaseRoute && !localStorage.getItem(LOFT_REDIRECT_ON_LOGIN_IDENTIFIER)) {
        localStorage.setItem(LOFT_REDIRECT_ON_LOGIN_IDENTIFIER, window.location.pathname);
    }

    return children;
}

export default Routes;
