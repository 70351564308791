import React from "react";
import styles from "./Label.module.scss";

export interface LabelProps {
    className?: string;
    style?: React.CSSProperties;
}

function Label(props: React.PropsWithChildren<LabelProps>) {
    let classNames = [styles["label"]];
    if (props.className) {
        classNames.push(props.className);
    }

    return <div className={classNames.join(" ")} style={props.style}>
        {props.children}
    </div>
}

Label.Bold = function(props: React.PropsWithChildren<LabelProps>) {
    let classNames = [styles["bold"]];
    if (props.className) {
        classNames.push(props.className);
    }

    return <Label className={classNames.join(" ")}>
        {props.children}
    </Label>
};

export default Label;