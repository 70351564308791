import React, {RefObject} from "react";
import {ResultError, Return} from "../../../../lib/result";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import Section from "../../../../components/Drawer/Section/Section";
import {arr, selectDefaultFilter, selectOwnerFilter} from "../../../../lib/helpers/renderhelper";
import Query from "../../../../components/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../components/Loading/Loading";
import Label from "../../../../components/Label/Label";
import Select from "../../../../components/Select/Select";
import styles from "./UserTeamMemberships.module.scss";
import Description from "../../../../components/Description/Description";
import {StorageV1ClusterRoleRef} from "../../../../../gen/models/agentstorageV1ClusterRoleRef";
import {displayName} from "../../../../lib/helper";
import {ManagementV1ClusterRoleTemplate} from "../../../../../gen/models/managementV1ClusterRoleTemplate";
import ImagePullSecrets from "./ImagePullSecrets";
import {StorageV1KindSecretRef} from "../../../../../gen/models/storageV1KindSecretRef";
import constants from "../../../../constants/constants";
import SectionExpander from "../../../../components/Drawer/SectionExpander/SectionExpander";
const { Option } = Select;

interface UserPermissionsState {
    clusterRoles: string[];
    clusterRolesChanged: boolean;

    groups: string[] | undefined;
    groupsChanged: boolean;
}

interface UserPermissionsProps extends SectionProps {
    title?: string;
    label?: string;
    description?: string;
    
    hideGroups: boolean;

    groups: Array<string> | undefined;
    clusterRoles: Array<StorageV1ClusterRoleRef> | undefined;
    imagePullSecrets: Array<StorageV1KindSecretRef> | undefined;
}

interface UserSpec {
    'groups'?: Array<string>;
    'clusterRoles'?: Array<StorageV1ClusterRoleRef>;
    'imagePullSecrets'?: Array<StorageV1KindSecretRef>;
}

export default class UserPermissions extends React.PureComponent<UserPermissionsProps, UserPermissionsState> {
    imagePullSecrets?: RefObject<ImagePullSecrets>;
    state: UserPermissionsState = {
        clusterRoles: arr(this.props.clusterRoles).map(ref => ref.name!),
        clusterRolesChanged: false,

        groups: arr(this.props.groups),
        groupsChanged: false
    };

    constructor(props: UserPermissionsProps) {
        super(props);

        this.imagePullSecrets = React.createRef<ImagePullSecrets>();
    }

    create = (spec: UserSpec): ResultError => {
        if (!this.props.hideGroups) {
            spec.groups = this.state.groups;
        }
        spec.clusterRoles = this.state.clusterRoles.map(clusterRole => ({name: clusterRole}));
        const result = this.imagePullSecrets?.current?.create(spec);
        if (result?.err) {
            return result;
        }
        
        return Return.Ok(); 
    };

    update = (spec: UserSpec): ResultError => {
        if (!this.props.hideGroups && this.state.groupsChanged) {
            spec.groups = this.state.groups;
        }
        if (this.state.clusterRolesChanged) {
            spec.clusterRoles = this.state.clusterRoles.map(clusterRole => ({name: clusterRole}));
        }

        const result = this.imagePullSecrets?.current?.update(spec);
        if (result?.err) {
            return result;
        }
        
        return Return.Ok();
    };
    
    batch = (specs: UserSpec[]): ResultError => {
        for (let i = 0; i < arr(specs).length; i++) {
            const result = this.update(specs[i]);
            if (result.err) {
                return result;
            }
        }
        
        return Return.Ok();
    }

    renderOptions(globalClusterRoleTemplates?: ManagementV1ClusterRoleTemplate[]) {
        const options: JSX.Element[] = [];

        // add cluster roles
        globalClusterRoleTemplates?.forEach(clusterRole => {
            options.push(<Option key={clusterRole.metadata?.name!} value={clusterRole?.metadata?.name!}>
                {displayName(clusterRole)}
            </Option>)
        });

        return options;
    }

    render() {
        return <Section title={this.props.title} foldable={true} defaultFolded={true}>
            <Query query={async () => {
                const result = await client.management(Resources.ManagementV1ClusterRoleTemplate).List();
                if (result.err) {
                    return result;
                }
                
                return Return.Value(arr(result.val.items).filter(globalClusterRole => !!globalClusterRole.spec?.management && globalClusterRole.metadata?.annotations?.[constants.LoftIgnoreAnnotation] !== "true"));
            }}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading) {
                            return <Loading />
                        }

                        return <div>
                            <Label>{this.props.label}</Label>
                            <Select
                                resetable={this.props.mode !== "create"}
                                onChangedStatus={changed => this.setState({clusterRolesChanged: changed})}
                                className={styles["select"]}
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select cluster roles to assign"
                                value={this.state.clusterRoles}
                                onChange={value => this.setState({clusterRoles: value})}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {this.renderOptions(result.data)}
                            </Select>
                            <Description>{this.props.description}</Description>
                        </div>
                    }
                }
            </Query>
            <ImagePullSecrets mode={this.props.mode} imagePullSecrets={this.props.imagePullSecrets} ref={this.imagePullSecrets} />
            {!this.props.hideGroups && <SectionExpander name={"Groups"}>
                <Label>Groups for User (optional)</Label>
                <Select mode="tags" tokenSeparators={[',', ' ']} resetable={this.props.mode === "update"} onChangedStatus={changed => this.setState({groupsChanged: changed})} placeholder={"my-group, my-group1"} value={this.state.groups} onChange={value => this.setState({groups: value})} />
                <Description>Groups are an alternative way to assign users to teams. You can define groups here and define member groups on each team.</Description>
            </SectionExpander>}
        </Section>
    }
}