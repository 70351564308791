import React from "react";
import client from "../../lib/client";
import {Resources} from "../../lib/resources";
import DynamicTime from "../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../components/Table/Table";
import useQuery from "../../lib/Query/Query";
import styles from "./Secrets.module.scss";
import {ApiOutlined, DeleteOutlined, SettingOutlined} from "@ant-design/icons/lib";
import {alert, deleteConfirm} from "../../lib/Modal/Modal";
import ClientMessage from "../../lib/Message/ClientMessage";
import {Button, Space} from "antd";
import {DrawerDispatch, useItemDrawer} from "../../contexts/drawer/DrawerContext";
import {arr, getDisplayName} from "../../lib/helpers/renderhelper";
import {Tooltip} from "../../components/Tooltip/Tooltip";
import {creationTimestampSorter, displayNameSorter, nameSorter, stringSorter} from "../../lib/helpers/sorthelper";
import ShowYamlPopup from "../../components/ShowYamlPopup/ShowYamlPopup";
import {ManagementV1SharedSecret} from "../../../gen/models/managementV1SharedSecret";
import {MultiItem} from "../../components/MultiItem/MultiItem";
import SecretsDrawer from "./SecretsDrawer/SecretsDrawer";
import {Link, useRouteMatch} from "react-router-dom";
import {useUser} from "../../contexts/UserContext/UserContext";
import FixedText from "../../components/FixedText/FixedText";
import {ToggleColumn, ToggleColumnContent} from "../../contexts/ToggleColumn/ToggleColumn";
import {displayName} from "../../lib/helper";
import {ManagementV1Cluster} from "../../../gen/models/managementV1Cluster";
import Code from "../../components/Code/Code";
import Description from "../../components/Description/Description";

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch) {
    const editSecret = (secret: ManagementV1SharedSecret) => {
        drawerDispatcher({
            title: "Edit Shared Secret: " + displayName(secret),
            content: <SecretsDrawer mode={"update"} secret={secret} refetch={refetch} />
        })
    };

    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            render: (secret: ManagementV1SharedSecret) => {
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <span onClick={() => editSecret(secret)} className={styles["clickable"]}><FixedText maxWidth={200} text={displayName(secret)} /></span>;
                    },
                    () => {
                        return <span onClick={() => editSecret(secret)} className={styles["clickable"]}><FixedText maxWidth={200} text={secret.metadata?.name} /></span>;
                    },
                ]}/>
            }
        },
        {
            title: "Description",
            render: (cluster: ManagementV1Cluster) => {
                return cluster.spec?.description || "";
            }
        },
        {
            title: 'Namespace',
            sorter: (a: ManagementV1SharedSecret, b: ManagementV1SharedSecret) => nameSorter(a, b),
            render: (secret: ManagementV1SharedSecret) => {
                return <Link to={`/secrets/${secret.metadata?.namespace}`} className={styles["namespace"]}><FixedText text={secret.metadata?.namespace} /></Link>;
            }
        },
        {
            title: 'Keys',
            render: (secret: ManagementV1SharedSecret) => {
                return <MultiItem items={Object.keys(secret.spec?.data || {}).map(key => ({key, children: key}))} maxItems={5} />;
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ManagementV1SharedSecret, b: ManagementV1SharedSecret) => creationTimestampSorter(a, b),
            render: (secret: ManagementV1SharedSecret) => {
                return <DynamicTime timestamp={secret.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (secret: ManagementV1SharedSecret) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title={"use"}>
                        <ApiOutlined className={styles["setting"]} onClick={() => {
                            alert({
                                title: `Use this Shared Secret`,
                                okText: "Close",
                                width: 750,
                                content: <Space direction={"vertical"} size={10}>
                                    <h4>Retrieve or set a value with Loft CLI</h4>
                                    <div>
                                        Make sure you have <a className={"text-bold color-primary"} href={"https://loft.sh/install-cli"} target={"_blank"}>loft CLI</a> installed and use one of the following commands to retrieve or set a value on this secret:
                                    </div>
                                    <Code hideIcon={true} text={<React.Fragment>
                                        <div># Get a key from shared secret</div>
                                        <div>loft get secret {secret.metadata?.name!} --namespace {secret.metadata?.namespace}</div>
                                        <div># Get my-key from shared secret</div>
                                        <div>loft get secret {secret.metadata?.name!}.my-key --namespace {secret.metadata?.namespace}</div>
                                        <div># Set my-key to my-value in shared secret</div>
                                        <div>loft set secret {secret.metadata?.name!}.my-key my-value --namespace {secret.metadata?.namespace}</div>
                                    </React.Fragment>} />
                                    <h4 style={{marginTop: "10px"}}>Sync Shared Secret to Kubernetes secret</h4>
                                    <div>
                                        You can also sync this secret into a regular Kubernetes secret in an owned space, by referencing the shared secrets with the labels <b>loft.sh/sharedsecret-name</b> and <b>loft.sh/sharedsecret-namespace</b>:
                                    </div>
                                    <Code hideIcon={true} text={<React.Fragment>
                                        <div>apiVersion: v1</div>
                                        <div>kind: Secret</div>
                                        <div>metadata:</div>
                                        <div>&nbsp;&nbsp;name: my-secret</div>
                                        <div>&nbsp;&nbsp;namespace: space-owned-by-user-or-team</div>
                                        <div>&nbsp;&nbsp;labels:</div>
                                        <div>&nbsp;&nbsp;&nbsp;&nbsp;loft.sh/sharedsecret-name: {secret.metadata?.name}</div>
                                        <div>&nbsp;&nbsp;&nbsp;&nbsp;loft.sh/sharedsecret-namespace: {secret.metadata?.namespace}</div>
                                        <div>type: Opaque</div>
                                        <div># Will get filled by Loft automatically upon creation</div>
                                        <div>data: {"{}"}</div>
                                    </React.Fragment>} />
                                </Space>
                            })
                        }} />
                    </Tooltip>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editSecret(secret)} />
                    </Tooltip>
                    <ShowYamlPopup className={styles["setting"]} object={secret} resource={Resources.ManagementV1SharedSecret} namespace={secret.metadata?.namespace!} name={secret.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={styles["delete"]} onClick={() => {
                            deleteConfirm({
                                title: `Delete Shared Secret: ${displayName(secret)}`,
                                content: `Are you sure you want to delete the shared secret ${displayName(secret)}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await client.management(Resources.ManagementV1SharedSecret).Namespace(secret.metadata?.namespace!).Delete(secret.metadata?.name!);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1SharedSecret, value: string) {
    return !!(item.metadata?.name?.includes(value) || item.metadata?.namespace?.includes(value) || item.spec?.displayName?.includes(value) || item.spec?.description?.includes(value));
}

function Secrets() {
    const user = useUser();
    const drawerDispatcher = useItemDrawer();
    const match: {params?: {namespace: string}} | undefined = useRouteMatch() as any;
    let namespace: string | undefined = undefined;
    if (match?.params?.namespace) {
        namespace = match?.params?.namespace;
    }
    
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1SharedSecret).Namespace(namespace).List());
    const {loading: permissionLoading, error: permissionError, data: permissionData} = useQuery(async () => await client.management(Resources.ManagementV1SharedSecret).CanI("create"));
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    const secrets = arr(data?.items).sort((a, b) => displayNameSorter(a, b))
    return <div>
        <Table className={styles["table"]} loading={permissionLoading || loading} columns={getTableColumns(refetch, drawerDispatcher)} dataSource={secrets.map(secret => { return {...secret, key: secret.metadata!.namespace! + "/" + secret.metadata!.name!}})} error={error || permissionError} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <Description.Table>
                Shared Secrets can be used to share sensitive information across users, teams and connected clusters. You can either access shared secrets through the Loft CLI or sync them directly to a regular Kubernetes secret.
            </Description.Table>,
            right: permissionData ? <Button type={"primary"} onClick={() => {
                let defaultNamespace = namespace;
                if (!defaultNamespace) {
                    defaultNamespace = "loft";
                }
                
                drawerDispatcher({
                    title: "Add Shared Secret",
                    content: <SecretsDrawer mode={"create"} namespace={defaultNamespace} forceNamespace={!!namespace} refetch={refetch} />
                });
            }}>Add Shared Secret</Button> : undefined,
            selectedActions: <React.Fragment>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Shared Secrets`,
                            content: `Are you sure you want to delete the shared secrets ${selectedRowKeys.join(", ")}? This action CANNOT be reverted! This will delete all shared secrets with all keys inside them.`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const splitted = (selectedRowKeys[i] + "").split("/");
                                    const result = await client.management(Resources.ManagementV1SharedSecret).Namespace(splitted[0]).Delete(splitted[1] as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneManagement();
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}

export default Secrets;