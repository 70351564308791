import {V1ObjectMeta} from "@kubernetes/client-node";
import {displayName} from "../helper";

function parseVersion(v: string | undefined): {major: number, alpha?: number, beta?: number} {
    if (v && v.length >= 2 && v.charAt(0) === "v") {
        const major = parseInt(v.charAt(1));
        if (v.indexOf("alpha") !== -1) {
            const alpha = parseInt(v.substr(v.indexOf("alpha") + 5))
            return {major, alpha}
        } else if (v.indexOf("beta") !== -1) {
            const beta = parseInt(v.substr(v.indexOf("beta") + 4))
            return {major, beta}
        }
        
        return {major}; 
    }
    
    return {major: 0}
}

export function versionCompare(a: string | undefined, b: string | undefined) {
    const versionA = parseVersion(a);
    const versionB = parseVersion(b);
    if (versionA.major === versionB.major) {
        const hasMinorA = versionA.alpha || versionA.beta;
        const hasMinorB = versionB.alpha || versionB.beta;
        if (hasMinorA && hasMinorB) {
            const hasBetaA = versionA.beta;
            const hasBetaB = versionB.beta;
            if (hasBetaA && hasBetaB) {
                return numberSorter(hasBetaA, hasBetaB);
            } else if (!hasBetaA && hasBetaB) {
                return -1;
            } else if (hasBetaA && !hasBetaB) {
                return 1;
            }
            
            return numberSorter(versionA.alpha, versionB.alpha);
        } else if (hasMinorA && !hasMinorB) {
            return -1;
        } else if (!hasMinorA && hasMinorB) {
            return 1;
        }
        
        return 0;
    }
    
    return numberSorter(versionA.major, versionB.major)
}

export function stringCompare(a: string | undefined, b: string | undefined) {
    if (!a) {
        a = "";
    }
    if (!b) {
        b = "";
    }

    if (a > b) {
        return 1;
    } else if (a < b) {
        return -1;
    }
    return 0;
}

export function numberSorter(a: number | undefined, b: number | undefined) {
    const ta = a ? a : 0;
    const tb = b ? b : 0;
    return ta - tb;
}

export function timeSorter(a: Date | string | undefined, b: Date | string | undefined) {
    const ta = a ? new Date(a).getTime() : 0;
    const tb = b ? new Date(b).getTime() : 0;
    return ta - tb;
}

export interface DisplayNameObject {
    metadata?: V1ObjectMeta, 
    spec?: {
        displayName?: string;
        description?: string;
    }
}

export function displayNameSorter(a?: DisplayNameObject, b?: DisplayNameObject) {
    return stringCompare(displayName(a), displayName(b))
}

export function stringSorter(a: string | undefined, b: string | undefined) {
    return stringCompare(a, b);
}

export function creationTimestampSorter(a?: {metadata?: V1ObjectMeta}, b?: {metadata?: V1ObjectMeta}) {
    return timeSorter(a?.metadata?.creationTimestamp, b?.metadata?.creationTimestamp);
}

export function nameSorter(a?: {metadata?: V1ObjectMeta}, b?: {metadata?: V1ObjectMeta}) {
    return stringCompare(a?.metadata?.name, b?.metadata?.name);
}