import React from "react";
import {useRouteMatch} from "react-router-dom";
import SpaceHeader from "../SpaceHeader/SpaceHeader";
import EventsTable from "./EventsTable/EventsTable";

export default function Events() {
    const match = useRouteMatch();
    const {cluster, space} = match.params as any;

    return <EventsTable cluster={cluster}
                        namespace={space}
                        top={<SpaceHeader />} />
}