import React, {useState} from "react";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined} from "@ant-design/icons/lib";
import styles from "./TemplateDeletePopup.module.scss";
import {DeleteModal, Modal} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import {arr} from "../../../../lib/helpers/renderhelper";

interface Props {
    templates: string[];
    cluster: string;
    refetch: () => Promise<void>;
    className?: string;
}

export default function TemplateDeletePopup(props: Props) {
    const [visible, setVisible] = useState<boolean>(false);
    const [deleteInstances, setDeleteInstances] = useState<boolean>(true);
    const [deleteFromAccounts, setDeleteFromAccounts] = useState<boolean>(true);

    return <React.Fragment>
        <Tooltip title="delete">
            <DeleteOutlined className={props.className ? props.className : styles["delete"]} onClick={() => setVisible(true)} />
        </Tooltip>
        <DeleteModal title={`Delete Template${props.templates.length === 1 ? ": " + props.templates[0] : "s"}`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
            const message = ClientMessage.Loading(props.cluster);

            for(let template of props.templates) {
              const result = await client.cluster(props.cluster, Resources.ConfigV1alpha1Template).Delete(template);
              if(result.err) {
                message.Result(result);
                break;
              }
  
              // delete all instances
              if (deleteInstances) {
                  const instancesResult = await client.cluster(props.cluster, Resources.ConfigV1alpha1TemplateInstance).List();
                  if (instancesResult.err) {
                      message.Error(instancesResult);
                      break;
                  }
  
                  // loop instances
                  const instances = arr(instancesResult.val.items);
                  for (let i = 0; i < instances.length; i++) {
                      const instance = instances[i];
                      if (instance.spec?.template === template) {
                          const deleteResult = await client.cluster(props.cluster, Resources.ConfigV1alpha1TemplateInstance).Namespace(instance.metadata?.namespace!).Delete(instance.metadata?.name!);
                          if (deleteResult.err) {
                              message.Error(deleteResult);
                              break;
                          }
                      }
                  }
              }
  
              // delete from accounts
              if (deleteFromAccounts) {
                  const accountsResult = await client.cluster(props.cluster, Resources.ConfigV1alpha1Account).List();
                  if (accountsResult.err) {
                      message.Error(accountsResult);
                      break;
                  }
  
                  // loop accounts
                  const accounts = arr(accountsResult.val.items);
                  for (let i = 0; i < accounts.length; i++) {
                      const account = accounts[i];
  
                      // loop enforced templates
                      const instanceIndex = arr(account.spec?.space?.templateInstances).findIndex(instance => instance.spec?.template === template);
                      if (instanceIndex !== -1) {
                          account.spec!.space!.templateInstances!.splice(instanceIndex, 1);
  
                          const updateResult = await client.cluster(props.cluster, Resources.ConfigV1alpha1Account).Update(account.metadata?.name!, account);
                          if (updateResult.err) {
                              message.Error(updateResult);
                              break;
                          }
                      }
                  }
              }
              
              message.Result(result);
            }

            await props.refetch();
            setVisible(false);
        }}>

            <div>
                Are you sure you want to delete the template{props.templates.length === 1 ? "" : "s"} {props.templates.join(", ")}?
            </div>
            <div style={{marginTop: "5px"}}>
                <Checkbox onChange={e => setDeleteInstances(e.target.checked)} checked={deleteInstances}>Delete ALL template instances</Checkbox>
            </div>
            <div>
                <Checkbox onChange={e => setDeleteFromAccounts(e.target.checked)} checked={deleteFromAccounts}>Delete from ALL accounts (section: space creation -&gt; enforced templates)</Checkbox>
            </div>
        </DeleteModal>
    </React.Fragment>
}