import React from "react";
import RolesTable from "../../Users/ManagementRoles/RolesTable/RolesTable";
import ClustersHeader from "../ClustersHeader/ClustersHeader";
import Description from "../../../components/Description/Description";

interface Props {}

function ClusterRoles(props: Props) {
    return <RolesTable top={<ClustersHeader>
        <Description.Table><a href={"https://kubernetes.io/docs/reference/access-authn-authz/rbac/#role-and-clusterrole"} target={"_blank"}>Kubernetes RBAC cluster roles</a> that are synced to the specified connected clusters. These cluster roles can be assigned to users or teams in their owned spaces or cluster wide through cluster access objects.</Description.Table>
    </ClustersHeader>} management={false} />
}

export default ClusterRoles;
