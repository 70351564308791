import React, {RefObject} from "react";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {
    convertMinutesToSeconds,
    convertSecondsToMinutes, getDeleteAfter, getSleepAfter, setDeleteAfter, setSleepAfter
} from "../../../../../lib/helpers/sleepmodehelper";
import Section from "../../../../../components/Drawer/Section/Section";
import {ManagementV1SpaceTemplate} from "../../../../../../gen/models/managementV1SpaceTemplate";
import {ClusterV1Space} from "../../../../../../gen/models/clusterV1Space";
import SleepMode from "../../../../../components/Drawer/Sections/SleepMode/SleepMode";
import constants from "../../../../../constants/constants";

interface SpaceSleepModeState {
}

interface SpaceSleepModeProps extends SectionProps {
    // If the space is modified this will be non empty
    space?: ClusterV1Space;
}

export default class SpaceSleepMode extends React.PureComponent<SpaceSleepModeProps, SpaceSleepModeState> {
    sleepModeRef?: RefObject<SleepMode>;
    state: SpaceSleepModeState = {};

    constructor(props: SpaceSleepModeProps) {
        super(props);

        this.sleepModeRef = React.createRef<SleepMode>();
    }

    create = (space: ClusterV1Space): ResultError => {
        if (!space.metadata) {
            space.metadata = {};
        }
        
        const result = this.sleepModeRef!.current!.create(space);
        if (result.err) {
            return result;
        }
        
        if (!space.metadata.annotations) {
            space.metadata.annotations = {}
        }
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (timezone) {
            space.metadata.annotations["sleepmode.loft.sh/timezone"] = timezone;
        }
        
        return Return.Ok();
    };

    update = (space: ClusterV1Space): ResultError => {
        return this.sleepModeRef!.current!.update(space);
    };

    batch = (spaces: ClusterV1Space[]): ResultError => {
        return this.sleepModeRef!.current!.batch(spaces);
    };

    setSleepAfterValue = (val: number | undefined) => {
        this.setState({
            sleepConfigSleepAfter: convertMinutesToSeconds(val),
        })
    };

    setDeleteAfterValue = (val: number | undefined) => {
        this.setState({
            sleepConfigDeleteAfter: convertMinutesToSeconds(val),
        })
    };

    selectTemplate(spaceTemplate: ManagementV1SpaceTemplate | undefined) {
        this.sleepModeRef?.current?.selectTemplate(spaceTemplate);
    }

    render() {
        return <Section title={"Sleep Mode & Authentication"} foldable={true} defaultFolded={true}>
            <SleepMode mode={this.props.mode} obj={this.props.space} ref={this.sleepModeRef} />
        </Section>
    }
}