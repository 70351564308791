import {useRouteMatch} from "react-router-dom";
import React, {useState} from "react";
import client, {GroupVersionResource} from "../../../../lib/client";
import {Unstructured} from "../../../../lib/types";
import useQuery from "../../../../lib/Query/Query";
import {arr} from "../../../../lib/helpers/renderhelper";
import {ResourceTable} from "../../../../components/ResourceTable/ResourceTable";
import VClusterHeader from "../VClusterHeader/VClusterHeader";
import {ApiResources} from "../../../Clusters/Details/Resources/Resources";

const cache: {[cluster: string]: GroupVersionResource<Unstructured>[]} = {};

export default function Other() {
    const match = useRouteMatch();
    const {cluster, namespace, vcluster, resource} = match.params as any;

    // get api resources
    const [selectedResource, setSelectedResource] = useState<GroupVersionResource<Unstructured> | undefined>(undefined);
    let {loading, error, data: apiResourcesRaw} = useQuery(async () => {
        const result = await client.vClusterNonResource({cluster, namespace, name: vcluster}).ApiResources(true, false);
        if (result.err) {
            return result;
        }

        cache[cluster+"/"+namespace+"/"+vcluster] = result.val;
        return result;
    });
    if (!apiResourcesRaw) {
        apiResourcesRaw = cache[cluster+"/"+namespace+"/"+vcluster];
    }
    
    const apiResources = arr(apiResourcesRaw).filter(r => arr(r.verbs).includes("list") && arr(r.verbs).includes("get"))
    return <div>
        <ResourceTable vCluster={{
                         cluster: cluster,
                         namespace: namespace,
                         name: vcluster
                       }}
                       addNamespaceColumn={selectedResource?.namespaced}
                       resource={selectedResource}
                       top={<VClusterHeader>
                           <ApiResources selectedResource={selectedResource}
                                         setSelectedResource={setSelectedResource}
                                         apiResources={apiResources}
                                         loading={loading}
                                         error={error}
                                         defaultResource={resource}
                                         baseUrl={`/vclusters/${cluster}/${namespace}/${vcluster}/other`} />
                       </VClusterHeader>} />
    </div>
};