import React, {RefObject} from "react";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import Select from "../../../../../components/Select/Select";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {ResultError, Return} from "../../../../../lib/result";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import Query from "../../../../../components/Query/Query";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Description from "../../../../../components/Description/Description";
import Label from "../../../../../components/Label/Label";
import Section from "../../../../../components/Drawer/Section/Section";
import {ManagementV1App} from "../../../../../../gen/models/managementV1App";
import LabelsAnnotationsSection from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import {ManagementV1SpaceTemplate} from "../../../../../../gen/models/managementV1SpaceTemplate";
import {displayName} from "../../../../../lib/helper";
import SleepMode from "../../../../../components/Drawer/Sections/SleepMode/SleepMode";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import YAMLEditor from "../../../../../components/YAMLEditor/YAMLEditor";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import constants from "../../../../../constants/constants";
const { Option } = Select;

interface SpaceTemplateConfigurationState {
    apps?: string[];
    automaticSync?: boolean;
    objects?: string;
}

interface SpaceTemplateConfigurationProps extends SectionProps {
    spaceTemplate?: ManagementV1SpaceTemplate;
}

export const defaultSpaceObjects = `apiVersion: v1
kind: ConfigMap
metadata:
  name: my-config-map
data:
  key: "value"
---
apiVersion: v1
kind: OtherObject
...`

export default class SpaceTemplateConfiguration extends React.PureComponent<SpaceTemplateConfigurationProps, SpaceTemplateConfigurationState> {
    spaceLabelsSectionRef?: RefObject<LabelsAnnotationsSection>;
    sleepModeRef?: RefObject<SleepMode>;
    
    state: SpaceTemplateConfigurationState = {
        apps: this.props.spaceTemplate?.spec?.template?.apps?.map(app => app.name!) || [],
        automaticSync: this.props.spaceTemplate?.metadata?.annotations?.[constants.LoftTemplateAlwaysSyncAnnotation] === "true",
        objects: this.props.spaceTemplate?.spec?.template?.objects
    };

    constructor(props: SpaceTemplateConfigurationProps) {
        super(props);

        this.spaceLabelsSectionRef = React.createRef<LabelsAnnotationsSection>();
        this.sleepModeRef = React.createRef<SleepMode>();
    }

    create = (space: ManagementV1SpaceTemplate): ResultError => {
        if (!space.spec) {
            space.spec = {};
        }
        if (!space.spec.template) {
            space.spec.template = {};
        }
        if (!space.spec.template.apps) {
            space.spec.template.apps = [];
        }
        if (!space.spec.template.metadata) {
            space.spec.template.metadata = {};
        }
        // set virtual cluster annotations
        const annotationsResult = this.spaceLabelsSectionRef!.current!.create(space.spec!.template!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }
        const sleepModeResult = this.sleepModeRef!.current!.create(space.spec!.template);
        if (sleepModeResult.err) {
            return sleepModeResult;
        }

        const apps = arr(this.state.apps);
        space.spec.template.apps = apps.map(app => ({name: app}));
        space.spec.template.objects = this.state.objects;
        if (this.state.automaticSync) {
            if (!space.metadata) {
                space.metadata = {};
            }
            if (!space.metadata.annotations) {
                space.metadata.annotations = {};
            }
            space.metadata.annotations[constants.LoftTemplateAlwaysSyncAnnotation] = "true";
        } else {
            if (!space.metadata) {
                space.metadata = {};
            }
            if (!space.metadata.annotations) {
                space.metadata.annotations = {};
            }
            delete space.metadata.annotations[constants.LoftTemplateAlwaysSyncAnnotation];
        }

        return Return.Ok();
    };

    update = (space: ManagementV1SpaceTemplate): ResultError => {
        if (!space.spec) {
            space.spec = {};
        }
        if (!space.spec.template) {
            space.spec.template = {};
        }
        if (!space.spec.template.apps) {
            space.spec.template.apps = [];
        }
        if (!space.spec.template.metadata) {
            space.spec.template.metadata = {};
        }
        // set virtual cluster annotations
        const annotationsResult = this.spaceLabelsSectionRef!.current!.update(space.spec!.template!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }
        const sleepModeResult = this.sleepModeRef!.current!.update(space.spec!.template);
        if (sleepModeResult.err) {
            return sleepModeResult;
        }

        const apps = arr(this.state.apps);
        space.spec.template.apps = apps.map(app => ({name: app}));
        space.spec.template.objects = this.state.objects;
        if (this.state.automaticSync) {
            if (!space.metadata) {
                space.metadata = {};
            }
            if (!space.metadata.annotations) {
                space.metadata.annotations = {};
            }
            space.metadata.annotations[constants.LoftTemplateAlwaysSyncAnnotation] = "true";
        } else {
            if (!space.metadata) {
                space.metadata = {};
            }
            if (!space.metadata.annotations) {
                space.metadata.annotations = {};
            }
            delete space.metadata.annotations[constants.LoftTemplateAlwaysSyncAnnotation];
        }
        return Return.Ok()
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <React.Fragment>
            <Section title={"Configuration"}>
            <Query query={async () => {
                let apps: ManagementV1App[] = [];
                const appsResult = await client.management(Resources.ManagementV1App).List();
                if (appsResult.err) {
                    console.error(appsResult.val)
                } else {
                    apps = arr(appsResult.val.items);
                }

                return Return.Value({
                    apps
                });
            }}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />;
                        }

                        return <React.Fragment>
                            <LabelsAnnotationsSection {...this.props} name={"Space Labels & Annotations"} metadata={this.props.spaceTemplate ? this.props.spaceTemplate.spec?.template?.metadata : undefined} ref={this.spaceLabelsSectionRef} />
                            <SleepMode mode={this.props.mode} obj={this.props.spaceTemplate?.spec?.template} ref={this.sleepModeRef} />
                            <SectionExpander name={"Objects"}>
                                <Label>Objects that will be created inside the space</Label>
                                <YAMLEditor placeholder={defaultSpaceObjects}
                                            value={this.state.objects}
                                            minLines={20}
                                            maxLines={100}
                                            onChange={val => this.setState({objects: val})} />
                                <Description>These objects will be created inside the space. They will be created with user permissions, so any objects that the user is not allowed to create inside the space will fail on creation. Objects can also be synced on template sync.</Description>
                            </SectionExpander>
                            <SectionExpander name={"Apps"}>
                                <Label>Which apps should get deployed within the space?</Label>
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select apps that should be deployed in the space"
                                    value={this.state.apps}
                                    onChange={(value) => this.setState({apps: value})}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={selectDefaultFilter}
                                >
                                    {arr(result.data?.apps).map(app => <Option key={app.metadata?.name} value={app.metadata?.name!}>
                                        {displayName(app)}
                                    </Option>)}
                                </Select>
                                <Description>Each app will be deployed into the created space. Apps cannot be synced through a space template and are only created once during space creation.</Description>
                            </SectionExpander>
                        </React.Fragment>
                    }
                }
            </Query>
        </Section>
            <Section title={"Sync Template Changes"} defaultFolded={true} foldable={true}>
                <Label>Sync template changes automatically to all spaces</Label>
                <Checkbox checked={this.state.automaticSync}
                          onChange={e => this.setState({automaticSync: e.target.checked})}>
                    Always sync template changes to all spaces
                </Checkbox>
                <Description>If enabled, will sync template changes automatically to all spaces that have this template assigned. You can also manually or automatically sync single spaces or only sync all spaces once.</Description>
            </Section>
        </React.Fragment>
    }
}