import React from 'react';
import Loading from "../../components/Loading/Loading";
import client from "../../lib/client";
import {Resources} from "../../lib/resources";
import {Return} from "../../lib/result";
import constants from "../../constants/constants";
import CompleteProfile, {UserInfo} from "../../views/Auth/CompleteProfile/CompleteProfile";
import {ManagementV1User} from "../../../gen/models/managementV1User";
import useQuery from "../../lib/Query/Query";
import styles from "./UserContext.module.scss";
import {ReLogin} from "../../components/ReLogin/ReLogin";

type Refetch = () => Promise<void>
export type UserContextState = ManagementV1User | undefined;

export const UserStateContext = React.createContext<UserContextState | undefined>({});
const UserDispatchContext = React.createContext<Refetch | undefined>(undefined);

function renderCompleteProfile(user: UserContextState) {
    if (!user || client.impersonatedUser()) {
        return null;
    }

    // show the complete profile view if there is information missing
    let completeProfile = !user.metadata!.annotations || !user.metadata!.annotations[constants.LoftUserCustomDataAnnotation];
    if (!completeProfile) {
        return null;
    }

    return <CompleteProfile showOrganization={true} />
}

function UserProvider(props: React.PropsWithChildren<{}>) {
    const {loading, error, data, refetch} = useQuery(async () => {
        const userResponse = await client.getUser();
        if (userResponse.err) {
            console.error(userResponse.val);
            return userResponse;
        }
        
        const response = await client.management(Resources.ManagementV1User).Get(userResponse.val!);
        if (response.err) {
            console.error(response.val);
            return response;
        }

        return Return.Value(response.val);
    });

    const completeRefetch = async () => {
        await refetch();
    };
    if (error) {
        return <ReLogin refetch={completeRefetch} error={error!} />
    } else if (loading) {
        return <div className={styles["loading"]}>
            <Loading />
        </div>
    }

    // children
    let children = props.children;

    // show the complete profile view if there is information missing
    const completeProfile = renderCompleteProfile(data!);
    if (completeProfile) {
        children = completeProfile;
    }

    return (
        <UserStateContext.Provider value={data!}>
            <UserDispatchContext.Provider value={completeRefetch}>
                        {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    )
}

export function useUserRefetch() {
    let context = React.useContext(UserDispatchContext);
    if (!context) {
        throw new Error("useExampleProvider has to be used below a ExampleProvider component");
    }

    return context;
}

export function useUser() {
    let context = React.useContext(UserStateContext);
    if (!context) {
        return undefined;
    }

    return context;
}

export default UserProvider;