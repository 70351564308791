import React from "react";
import {AuditOutlined, CloudOutlined, TeamOutlined, UserOutlined} from "@ant-design/icons/lib";
import {useRouteMatch} from "react-router-dom";
import Header from "../../../components/Header/Header";
import useQuery from "../../../lib/Query/Query";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import {arr} from "../../../lib/helpers/renderhelper";

interface UserHeaderProps {

}

export default function UsersHeader(props: React.PropsWithChildren<UserHeaderProps>) {
    const {data} = useQuery(async () => client.management(Resources.ManagementV1ClusterAccountTemplate).List());
    const routeMatch = useRouteMatch<{cluster: string}>();
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1];
    const items = [
        {
            key: "users",
            url: `/users/users`,
            content: <React.Fragment>
                <UserOutlined />Users
            </React.Fragment>
        },
        {
            key: "teams",
            url: `/users/teams`,
            content: <React.Fragment>
                <TeamOutlined />Teams
            </React.Fragment>
        },
        {
            key: "managementroles",
            url: `/users/managementroles`,
            content: <React.Fragment>
                <AuditOutlined />Management Roles
            </React.Fragment>
        },
    ];
    if (arr(data?.items).length > 0) {
        items.push({
            key: "accounttemplates",
            url: `/users/accounttemplates`,
            content: <React.Fragment>
                <CloudOutlined />Account Templates<span className={"color-warning"}>(Deprecated)</span>
            </React.Fragment>
        });
    }

    return <Header title={"User Management"} selectedKey={selectedKey} items={items}>
        {props.children}
    </Header>
}