import React from "react";
import client from "../../lib/client";
import {findHashParameter} from "../../lib/helper";
import Loading from "../Loading/Loading";
import useQuery from "../../lib/Query/Query";
import {Return} from "../../lib/result";
import {Button} from "antd";
import styles from "../../views/Auth/Login/Login.module.scss";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";
import AuthLayout from "../AuthLayout/AuthLayout";

interface Props {
    
}

export default function ParameterLogin(props: React.PropsWithChildren<Props>) {
    const {error, loading, refetch} = useQuery(async () => {
        const token = findHashParameter("token");
        if (token) {
            // make sure we cleanup old access keys
            if (client.isLoggedIn()) {
                await client.logout();
            }
            
            const response = await client.loginWithAccessKey(token);
            if (response.err) {
                return response;
            }

            // redirect
            window.location.href = window.location.href.split('#')[0];

            // never ending promise
            await new Promise(resolve => {});
        }

        return Return.Ok();
    });

    if (!error && loading) {
        return <Loading />;
    } else if (error) {
        return <AuthLayout title="Error during token login" description="please press button below to retry" onSubmit={async () => {
            await refetch();
            return false;
        }}>
            <ErrorMessage className={styles["error"]} error={error} />
            <div className={styles["sign-in-wrapper"]}>
                <Button tabIndex={3} type={"primary"} htmlType={"submit"}>Retry</Button>
            </div>
        </AuthLayout>;
    }

    return props.children as any;
}