import React from "react";
import {ManagementV1App} from "../../../../../gen/models/managementV1App";
import { SectionProps } from "../../../../components/Drawer/ItemDrawer";
import Select from "../../../../components/Select/Select";
import {ResultError, Return} from "../../../../lib/result";
import Section from "../../../../components/Drawer/Section/Section";
import Description from "../../../../components/Description/Description";
import Label from "../../../../components/Label/Label";
import {convertToArray, parseArray} from "../../../Users/ManagementRoles/RolesDrawer/Sections/RolesConfig";
import YAMLEditor from "../../../../components/YAMLEditor/YAMLEditor";
const { Option } = Select;

interface AppParametersState {
    parameters: string | undefined
}

interface AppParametersProps extends SectionProps {
    app?: ManagementV1App;
}

export default class AppParameters extends React.PureComponent<AppParametersProps, AppParametersState> {
    state: AppParametersState = {
        parameters: parseArray(this.props.app?.spec?.parameters)
    };

    create = (app: ManagementV1App): ResultError => {
        if (!app.spec) {
            app.spec = {};
        }
        if (!this.state.parameters) {
            delete(app.spec.parameters);
            return Return.Ok();
        }
        
        const result = convertToArray(this.state.parameters);
        if (result.err) {
            return result;
        }

        app.spec.parameters = result.val;
        return Return.Ok();
    };

    update = (app: ManagementV1App): ResultError => {
        return this.create(app);
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={`Parameters`} defaultFolded={true} foldable={true}>
            <Label>App Parameters</Label>
            <YAMLEditor value={this.state.parameters}
                        onChange={(value) => this.setState({parameters: value})}
                        minLines={20} 
                        maxLines={100}
                        placeholder={`# Value accessible at {{ .Values.foo.bar }} within the helm chart values or manifest
- variable: foo.bar
  label: My Custom Parameter
  description: Please choose a value
  options:
  - Option 1
  - Option 2
  section: My First Section
- variable: other
  label: My Verified Parameter
  required: true
  description: Value with lowercase letters between 3 and 20 characters 
  validation: "^[a-zA-Z]{3,20}$"`} />
            <Description>App parameters will be prompted to the user that deploys the app through the UI or CLI. App parameters can be used within the app's manifests or values definition via {"{{ .Values.foo.bar }}"}. Parameters are useful to customize apps for a user or template.</Description>
        </Section>
    }
}