import React from "react";
import * as antd from "antd";
import {TabsProps} from "antd/lib/tabs";
import styles from "./Tabs.module.scss";

function Tabs(props: React.PropsWithChildren<TabsProps>) {
    const classNames = [styles["tabs"]];
    if (props.className) {
        classNames.push(props.className);
    }

    return <antd.Tabs {...props} className={classNames.join(" ")} />;
}

Tabs.TabPane = antd.Tabs.TabPane;

export default Tabs;