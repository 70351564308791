import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Metadata from "../../../../../components/Drawer/Sections/Metadata/Metadata";
import {ResultError, Return} from "../../../../../lib/result";
import client from "../../../../../lib/client";
import {useDrawerDispatcher} from "../../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../../lib/Message/ClientMessage";
import {PolicyV1beta1JsPolicy} from "../../../../../../gen/models/policyV1beta1JsPolicy";
import PolicyOptions from "./PolicyOptions";
import PolicyAdvanced from "./PolicyAdvanced";
import {NewResource, Resources} from "../../../../../lib/resources";
import {randomString} from "../../../../../lib/helper";

export interface PoliciesDrawerProps extends SectionProps {
    policy?: PolicyV1beta1JsPolicy;
    
    cluster: string;
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<PoliciesDrawerProps, "mode"|"refetch"> & {metadataRef: Metadata, policyOptionsRef: PolicyOptions, policyAdvancedRef: PolicyAdvanced};

async function onCreate({cluster, metadataRef, policyOptionsRef, policyAdvancedRef}: ChangeFunctionProps): Promise<ResultError> {
    // make sure we have an object
    let policy = NewResource(Resources.PolicyV1beta1JsPolicy)

    // apply metadata
    const applyMetadataResult = await metadataRef.create(policy, async (metadata) => {
        metadata.name = "generated." + randomString(5) + ".loft.sh";
        return Return.Ok();
    });
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }
    
    // apply options
    const applyOptionsResult = await policyOptionsRef.create(policy);
    if (applyOptionsResult.err) {
        return applyOptionsResult;
    }

    // apply advanced
    const applyAdvancedResult = await policyAdvancedRef.create(policy);
    if (applyAdvancedResult.err) {
        return applyAdvancedResult;
    }

    // create
    const createPolicyResult = await client.cluster(cluster, Resources.PolicyV1beta1JsPolicy).Create(policy);
    if (createPolicyResult.err) {
        return createPolicyResult;
    }

    return Return.Ok();
}

async function onUpdate({cluster, policy: originalPolicy, metadataRef, policyOptionsRef, policyAdvancedRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalPolicy) {
        return Return.Ok();
    }

    // make sure the team object is up to date
    const getResult = await client.cluster(cluster, Resources.PolicyV1beta1JsPolicy).Get(originalPolicy.metadata?.name!);
    if (getResult.err) {
        return getResult;
    }

    // Set policy
    let policy = getResult.val;

    // apply metadata
    const applyMetadataResult = await metadataRef.update(policy);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply options
    const applyOptionsResult = await policyOptionsRef.update(policy);
    if (applyOptionsResult.err) {
        return applyOptionsResult;
    }

    // apply advanced
    const applyAdvancedResult = await policyAdvancedRef.update(policy);
    if (applyAdvancedResult.err) {
        return applyAdvancedResult;
    }

    // update
    const updateResult = await client.cluster(cluster, Resources.PolicyV1beta1JsPolicy).Update(policy.metadata?.name!, policy!);
    if (updateResult.err) {
        return updateResult;
    }

    return Return.Ok();
}

export default function PoliciesDrawer(props: PoliciesDrawerProps) {
    const drawer = useDrawerDispatcher();
    const metadataRef = useRef<Metadata>(null);
    const policyOptionsRef = useRef<PolicyOptions>(null);
    const policyAdvancedRef = useRef<PolicyAdvanced>(null);
    
    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading(props.cluster);

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({cluster: props.cluster, metadataRef: metadataRef.current!, policyOptionsRef: policyOptionsRef.current!, policyAdvancedRef: policyAdvancedRef.current!});
        } else if (props.mode === "update") {
            result = await onUpdate({cluster: props.cluster, policy: props.policy, metadataRef: metadataRef.current!, policyOptionsRef: policyOptionsRef.current!, policyAdvancedRef: policyAdvancedRef.current!});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorCluster(result, props.cluster);
            return;
        }

        // refetch
        await props.refetch();
        message.DoneCluster(props.cluster);

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} namePlaceholder={"my-policy.loft.sh"} type={"Policy"} obj={props.policy} ref={metadataRef} noMargin={true} />
        <PolicyOptions mode={props.mode} policy={props.policy} cluster={props.cluster} ref={policyOptionsRef} />
        <PolicyAdvanced mode={props.mode} policy={props.policy} ref={policyAdvancedRef} />
    </ItemDrawer>
}