import React, {RefObject, useState} from "react";
import {Modal} from "../../lib/Modal/Modal";
import {Terminal} from "../Terminal/Terminal";
import styles from "./ProgressPopup.module.scss";

interface ProgressPopupState {
    visible: boolean;
    closable: boolean;
}

export interface ProgressPopupProps {
    title: string;
}

export class ProgressPopup extends React.PureComponent<ProgressPopupProps, ProgressPopupState> {
    onCloseFn?: () => Promise<void>;
    terminalRef?: RefObject<Terminal>;
    state: ProgressPopupState = {
        visible: false,
        closable: false
    };

    constructor(props: ProgressPopupProps) {
        super(props);

        this.terminalRef = React.createRef<Terminal>();
    }
    
    isVisible = () => {
        return this.state.visible;
    }
    
    setVisible = async (visible: boolean, closable: boolean, onClose?: () => Promise<void>): Promise<void> => {
        this.onCloseFn = onClose;
        if (visible === this.state.visible && closable === this.state.closable) {
            return Promise.resolve();
        }
        
        return new Promise((resolve) => {
            this.setState({
                visible,
                closable
            }, () => {
                if (visible) {
                    this.terminalRef?.current?.updateDimensions();
                }

                resolve();
            });
        })
    }
    
    onClose = async (e: React.MouseEvent<HTMLElement>) => {
        if (this.onCloseFn) {
            await this.onCloseFn()
        }
        
        this.setState({
            visible: false
        })
    }
    
    clear = () => {
        this.terminalRef?.current?.clear();
    }
    
    write = (data: string) => {
        if (this.state.visible) {
            this.terminalRef?.current?.write(data);
        }
    }
    
    writeln = (data: string) => {
        if (this.state.visible) {
            this.terminalRef?.current?.writeln(data);
        }
    }
    
    render() {
        return <Modal title={this.props.title} 
                      visible={this.state.visible} 
                      closable={false} 
                      maskClosable={false} 
                      okText={"Close"} 
                      onOkAsync={this.onClose} 
                      hideButtons={!this.state.closable}
                      hideCancel={true} 
                      width={"90vw"}>
            <Terminal className={styles["terminal"]} ref={this.terminalRef} />
        </Modal>
    }
} 
