import React, {useState} from "react";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {Modal} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {ClusterV1SleepModeConfig} from "../../../../../gen/models/clusterV1SleepModeConfig";
import SleepingIcon from "../../../../images/sleeping-icon.svg";
import Icon from "@ant-design/icons";
import {Wait} from "../../../../lib/helper";
import {arr} from "../../../../lib/helpers/renderhelper";
import {checkEnabledSleepMode} from "./SpaceTable";
import {ManagementV1Feature} from "../../../../../gen/models/managementV1Feature";
import {LicenseState} from "../../../../contexts/LicenseContext/LicenseContext";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Input from "../../../../components/Input/Input";
import styles from "./SleepPopup.module.scss";
import {Alert} from "antd";

interface Props {
    config: ClusterV1SleepModeConfig;
    spaceName: string | undefined;
    cluster: string;
    refetch: () => Promise<void>;
    className?: string;
}

export default function SleepPopup(props: Props) {
    const [visible, setVisible] = useState<boolean>(false);
    const [forceSleep, setForceSleep] = useState<boolean>(false);
    const [forceSleepDuration, setForceSleepDuration] = useState<number>(0);

    return <React.Fragment>
        <Tooltip title="sleep">
            <Icon className={props.className} component={SleepingIcon as any} onClick={() => {
                setVisible(true);
            }} />
        </Tooltip>
        <Modal title={`Put Space ${props.spaceName} to Sleep`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
            const message = ClientMessage.Loading(props.cluster);
            if (!props.config) {
                props.config = {}
            }
            if (!props.config.spec) {
                props.config.spec = {}
            }

            props.config.spec.forceSleep = true;
            if (forceSleep) {
                props.config.spec.forceSleepDuration = !forceSleepDuration ? 0 : Math.round(forceSleepDuration * 3600);
            }

            const result = await client.cluster(props.cluster!, Resources.ClusterV1SleepModeConfig).Namespace(props.spaceName).Create(props.config!);
            if (result.err) {
                message.ErrorCluster(result, props.cluster!);
                return;
            }

            // wait until space is sleeping
            for (let i = 0; i < 30; i++) {
                await Wait(200);

                const result = await client.cluster(props.cluster!, Resources.ClusterV1SleepModeConfig).Namespace(props.spaceName).List();
                if (result.err) {
                    message.ErrorCluster(result, props.cluster!);
                    return;
                } else if (arr(result.val.items).length === 0) {
                    continue;
                }

                if (!!result.val.items[0].status?.sleepingSince) {
                    break;
                }
            }

            message.Result(result);
            if (result.ok && props.refetch) {
                await props.refetch();
            }
        }}>
            <div>
                Are you sure you want to put the space {props.spaceName} to sleep? This will scale down all deployments, replicasets and statefulsets to zero.
            </div>
            <div className={styles["checkbox"]}>
                <Checkbox checked={forceSleep} onChange={e => setForceSleep(e.target.checked)}>
                    Prevent automatic wake up for <Input className={styles["hours"]} type="number" value={forceSleepDuration} onChange={e => setForceSleepDuration(parseFloat(e.target.value))} /> hour(s) (0 is infinite)
                </Checkbox>
            </div>
            {forceSleep && <Alert className={styles["info"]} message="The space will stay sleeping for the given time frame and can only be woken up through the loft CLI or the loft UI. Furthermore, loft will block all pod related requests to that space while it is sleeping." type="info" showIcon />}
        </Modal>
    </React.Fragment>
}