import React from "react";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import Select from "../../../../../components/Select/Select";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {Result, ResultError, Return} from "../../../../../lib/result";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import Query from "../../../../../components/Query/Query";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Description from "../../../../../components/Description/Description";
import Label from "../../../../../components/Label/Label";
import Section from "../../../../../components/Drawer/Section/Section";
import {ManagementV1App} from "../../../../../../gen/models/managementV1App";
import {ManagementV1SpaceTemplate} from "../../../../../../gen/models/managementV1SpaceTemplate";
import {displayName} from "../../../../../lib/helper";
import {ClusterV1Space} from "../../../../../../gen/models/clusterV1Space";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import YAMLEditor from "../../../../../components/YAMLEditor/YAMLEditor";
import {defaultSpaceObjects} from "../../../SpaceTemplates/SpaceTemplateDrawer/Sections/SpaceTemplateConfiguration";
const { Option } = Select;

interface SpaceAppsState {
    apps?: string[];
    objects?: string | undefined;
}

interface SpaceAppsProps extends SectionProps {
    space: ClusterV1Space | undefined;
}

export default class SpaceApps extends React.PureComponent<SpaceAppsProps, SpaceAppsState> {
    private availableApps: Array<ManagementV1App> = [];
    
    state: SpaceAppsState = {
        apps: [],
        objects: this.props.space?.spec?.objects,
    };
    
    selectTemplate(spaceTemplate: ManagementV1SpaceTemplate | undefined) {
        if (!spaceTemplate) {
            return;
        }
        
        this.setState({
            apps: arr(spaceTemplate.spec?.template?.apps?.map(app => app.name!)),
            objects: spaceTemplate.spec?.template?.objects,
        })
    }
    
    getApps(): Result<Array<ManagementV1App>> {
        const deployApps: Array<ManagementV1App> = [];
        const apps = arr(this.state.apps);
        for (let i = 0; i < apps.length; i++) {
            const app = this.availableApps.find(app => app.metadata?.name === apps[i]);
            if (!app) {
                return Return.Failed(`cannot find app ${apps[i]}, the user has either no access to this app or it does not exist`)
            }
            
            deployApps.push(app);
        }
        
        return Return.Value(deployApps);
    }

    create = (space: ClusterV1Space): ResultError => {
        if (!space.spec) {
            space.spec = {};
        }

        space.spec.objects = this.state.objects;
        return Return.Ok();
    };

    update = (space: ClusterV1Space): ResultError => {
        if (!space.spec) {
            space.spec = {};
        }

        space.spec.objects = this.state.objects;
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={"Objects"} foldable={true} defaultFolded={true}>
            <Label>Objects that will be created inside the space</Label>
            <YAMLEditor placeholder={defaultSpaceObjects}
                        value={this.state.objects}
                        minLines={20}
                        maxLines={100}
                        onChange={val => this.setState({objects: val})} />
            <Description>These objects will be created inside the space. They will be created with user permissions, so any objects that the user is not allowed to create inside the space will fail on creation. Objects also can be synced on template sync.</Description>
            {this.props.mode === "create" && <SectionExpander name={"Apps"}>
                <Query query={async () => {
                    let apps: ManagementV1App[] = [];
                    const appsResult = await client.management(Resources.ManagementV1App).List();
                    if (appsResult.err) {
                        console.error(appsResult.val)
                    } else {
                        apps = arr(appsResult.val.items);
                    }

                    return Return.Value({
                        apps
                    });
                }}>
                    {
                        result => {
                            if (result.error) {
                                return <ErrorMessage error={result.error} />;
                            }

                            this.availableApps = arr(result.data?.apps);
                            return <React.Fragment>
                                <Label>Which apps should get deployed within the space?</Label>
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select apps that should be deployed in the space"
                                    value={this.state.apps}
                                    onChange={(value) => this.setState({apps: value})}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={selectDefaultFilter}
                                >
                                    {arr(result.data?.apps).map(app => <Option key={app.metadata?.name} value={app.metadata?.name!}>
                                        {displayName(app)}
                                    </Option>)}
                                </Select>
                                <Description>Each app will be deployed into the created space</Description>
                            </React.Fragment>
                        }
                    }
                </Query>
            </SectionExpander>}
        </Section>
    }
}