import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {ResultError, Return} from "../../../../lib/result";
import {ManagementV1ClusterAccountTemplate} from "../../../../../gen/models/managementV1ClusterAccountTemplate";
import Metadata from "../../../../components/Drawer/Sections/Metadata/Metadata";
import ClusterSelector from "./Sections/ClusterSelector";
import AccountTemplate from "./Sections/AccountTemplate";
import {NewResource, Resources} from "../../../../lib/resources";
import client from "../../../../lib/client";
import AccountResources from "./Sections/AccountResources";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import {ManagementV1User} from "../../../../../gen/models/managementV1User";
import Access, {VerbMapping} from "../../../../components/Drawer/Sections/Access/Access";

export const defaultVerbMappingClusterAccountTemplate: VerbMapping[] = [
    {
        key: "*",
        text: "All"
    },
    {
        key: "create",
        text: "create"
    },
    {
        key: "get",
        text: "view"
    },
    {
        key: "update",
        text: "update"
    },
    {
        key: "delete",
        text: "delete"
    },
    {
        key: "bind",
        text: "bind"
    },
]

export interface ClusterAccountTemplateDrawerProps extends SectionProps {
    clusterAccountTemplate?: ManagementV1ClusterAccountTemplate;
    clusterAccountTemplates?: ManagementV1ClusterAccountTemplate[];
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<ClusterAccountTemplateDrawerProps, "mode"|"refetch"> & {accessRef: Access | null, user: ManagementV1User | undefined, metadataRef: Metadata | null, clusterSelectorRef: ClusterSelector | null, accountTemplateRef?: AccountTemplate | null, accountResourcesRef?: AccountResources | null};

async function onCreate(args: ChangeFunctionProps) {
    // make sure we have a object
    let clusterAccountTemplate = NewResource(Resources.ManagementV1ClusterAccountTemplate, undefined, {spec: {}});

    // apply metadata
    let result = await args.metadataRef?.create(clusterAccountTemplate);
    if (result?.err) {
        return result;
    }

    // apply cluster selector
    result = await args.clusterSelectorRef?.create(clusterAccountTemplate);
    if (result?.err) {
        return result;
    }

    // apply account template
    result = await args.accountTemplateRef?.create(clusterAccountTemplate);
    if (result?.err) {
        return result;
    }

    // apply account quota
    result = await args.accountResourcesRef?.create(clusterAccountTemplate);
    if (result?.err) {
        return result;
    }

    // apply access
    const accessResult = await args.accessRef?.create(clusterAccountTemplate);
    if (accessResult?.err) {
        return accessResult;
    }

    // create cluster account template
    const createResult = await client.management(Resources.ManagementV1ClusterAccountTemplate).Create(clusterAccountTemplate);
    if (createResult.err) {
        return createResult;
    }

    return Return.Ok();
}


async function onUpdate(args: ChangeFunctionProps) {
    if (!args.clusterAccountTemplate) {
        return Return.Ok();
    }

    // make sure the team object is up to date
    const getResult = await client.management(Resources.ManagementV1ClusterAccountTemplate).Get(args.clusterAccountTemplate.metadata?.name!);
    if (getResult.err) {
        return getResult;
    }

    const clusterAccountTemplate = getResult.val;

    // apply metadata
    let result = await args.metadataRef?.update(clusterAccountTemplate);
    if (result?.err) {
        return result;
    }

    // apply cluster selector
    result = await args.clusterSelectorRef?.update(clusterAccountTemplate);
    if (result?.err) {
        return result;
    }

    // apply account template
    result = await args.accountTemplateRef?.update(clusterAccountTemplate);
    if (result?.err) {
        return result;
    }

    // apply account quota
    result = await args.accountResourcesRef?.update(clusterAccountTemplate);
    if (result?.err) {
        return result;
    }

    // apply access
    const accessResult = await args.accessRef?.update(clusterAccountTemplate);
    if (accessResult?.err) {
        return accessResult;
    }

    // update resource
    const updateResult = await client.management(Resources.ManagementV1ClusterAccountTemplate).Update(clusterAccountTemplate.metadata?.name!, clusterAccountTemplate);
    if (updateResult.err) {
        return updateResult;
    }

    return Return.Ok();
}

async function onBatch(args: ChangeFunctionProps) {
    if (!args.clusterAccountTemplates) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the team has changed meanwhile
    const getResult = await client.management(Resources.ManagementV1ClusterAccountTemplate).List();
    if (getResult.err) {
        return getResult;
    }

    // assign the new cluster account templates
    const clusterAccountTemplates: ManagementV1ClusterAccountTemplate[] = getResult.val.items.filter(clusterAccountTemplate => clusterAccountTemplates!.find((old: ManagementV1ClusterAccountTemplate) => old.metadata?.name === clusterAccountTemplate.metadata?.name));

    // apply metadata
    let result = await args.metadataRef?.batch(clusterAccountTemplates);
    if (result?.err) {
        return result;
    }

    // apply cluster selector
    result = await args.clusterSelectorRef?.batch(clusterAccountTemplates);
    if (result?.err) {
        return result;
    }

    // apply account template
    result = await args.accountTemplateRef?.batch(clusterAccountTemplates);
    if (result?.err) {
        return result;
    }

    // apply access
    const accessResult = await args.accessRef?.batch(clusterAccountTemplates);
    if (accessResult?.err) {
        return accessResult;
    }

    // update objects
    for (let i = 0; i < clusterAccountTemplates.length; i++) {
        const updateResult = await client.management(Resources.ManagementV1ClusterAccountTemplate).Update(clusterAccountTemplates[i].metadata?.name!, clusterAccountTemplates[i]);
        if (updateResult.err) {
            return updateResult;
        }
    }

    return Return.Ok();
}

export default function ClusterAccountTemplateDrawer(props: ClusterAccountTemplateDrawerProps) {
    const drawer = useDrawerDispatcher();
    const user = useUser();
    const metadataRef = useRef<Metadata>(null);
    const clusterSelectorRef = useRef<ClusterSelector>(null);
    const accountTemplateRef = useRef<AccountTemplate>(null);
    const accountResourcesRef = useRef<AccountResources>(null);
    const accessRef = useRef<Access>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({user, accessRef: accessRef.current!, metadataRef: metadataRef.current, clusterSelectorRef: clusterSelectorRef.current, accountTemplateRef: accountTemplateRef.current, accountResourcesRef: accountResourcesRef.current});
        } else if (props.mode === "update") {
            result = await onUpdate({user, accessRef: accessRef.current!, clusterAccountTemplate: props.clusterAccountTemplate, metadataRef: metadataRef.current, clusterSelectorRef: clusterSelectorRef.current, accountTemplateRef: accountTemplateRef.current, accountResourcesRef: accountResourcesRef.current});
        } else if (props.mode === "batch") {
            result = await onBatch({user, accessRef: accessRef.current!, clusterAccountTemplates: props.clusterAccountTemplates, metadataRef: metadataRef.current, clusterSelectorRef: clusterSelectorRef.current, accountTemplateRef: accountTemplateRef.current, accountResourcesRef: accountResourcesRef.current});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorManagement(result);
            return;
        }

        // refetch
        await props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Cluster Account Template"} obj={props.clusterAccountTemplate} noMargin ref={metadataRef} />
        <ClusterSelector mode={props.mode} clusterAccountTemplate={props.clusterAccountTemplate} clusterAccountTemplates={props.clusterAccountTemplates} ref={clusterSelectorRef} />
        <AccountTemplate mode={props.mode} clusterAccountTemplate={props.clusterAccountTemplate} clusterAccountTemplates={props.clusterAccountTemplates} ref={accountTemplateRef} />
        <AccountResources mode={props.mode} clusterAccountTemplate={props.clusterAccountTemplate} ref={accountResourcesRef} />
        <Access mode={props.mode} kind={"Cluster Account Template"} user={user} access={props.clusterAccountTemplate} allowedVerbs={defaultVerbMappingClusterAccountTemplate} ref={accessRef} />
    </ItemDrawer>
}