import React from "react";
import styles from "../../Templates/TemplateDrawer/Sections/TemplateSpec.module.scss";
import {PolicyV1beta1JsPolicy} from "../../../../../../gen/models/policyV1beta1JsPolicy";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {ResultError, Return} from "../../../../../lib/result";
import {arr} from "../../../../../lib/helpers/renderhelper";
import Label from "../../../../../components/Label/Label";
import Description from "../../../../../components/Description/Description";
import Select from "../../../../../components/Select/Select";
import Section from "../../../../../components/Drawer/Section/Section";
import YAMLEditor from "../../../../../components/YAMLEditor/YAMLEditor";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import Input from "../../../../../components/Input/Input";
import {GroupVersionResource} from "../../../../../lib/client";
import {Unstructured} from "../../../../../lib/types";
import {useApiResourceClusterQuery} from "../../Resources/Resources";
import Loading from "../../../../../components/Loading/Loading";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import {stringSorter} from "../../../../../lib/helpers/sorthelper";
const { Option } = Select;

const examples: {[key: string]: {
    javascript: string, 
    scope: string,
    type: string,
    resources: string[],
    operations: string[],
    apiGroups: string[],
    apiVersions: string[]
}} = {
    "Deny Space Owned By Team": {
        javascript: `// Do not allow creation of spaces that have a team as owner
if (request.object?.spec?.team) {
    deny("Teams cannot own spaces");
}`,
        type: "Validating",
        scope: "*",
        resources: ["spaces"],
        operations: ["CREATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Deny Pods of User": {
        javascript: `// Do not allow the user "test" to create pods anywhere
if (request.userInfo?.username === "loft:user:test") {
    deny("Sorry test, but you are not allowed to create pods in this cluster");
}`,
        type: "Validating",
        scope: "Namespaced",
        resources: ["pods"],
        operations: ["CREATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Deny Resources in default Namespace": {
        javascript: `if (request.namespace === "default") {
  deny("Creation of resources within the default namespace is not allowed!");
}`,
        type: "Validating",
        scope: "Namespaced",
        resources: ["*"],
        operations: ["CREATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Deny Privileged Pods": {
        javascript: `const containers = request.object.spec?.containers || [];
const initContainers = request.object.spec?.initContainers || [];

[...containers, ...initContainers].forEach(container => {
  if (container.securityContext?.privileged) {
    deny("spec.containers[*].securityContext.privileged is not allowed")
  }
})`,
        type: "Validating",
        scope: "Namespaced",
        resources: ["pods"],
        operations: ["CREATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Deny Ingress Classes": {
        javascript: `// ingress class can be set via annotation "kubernetes.io/ingress.class" or through spec.ingressClassName.
const allowedIngressClasses = ["nginx"];
const ingressClasses = [request.object.metadata?.annotations?.["kubernetes.io/ingress.class"], request.object.spec.ingressClassName];
const notAllowed = ingressClasses.filter(ingressClass => ingressClass && !allowedIngressClasses.includes(ingressClass));
if (notAllowed.length > 0) {
  deny(\`ingress class `+"${notAllowed[0]}"+` is not allowed\`);
}`,
        type: "Validating",
        scope: "Namespaced",
        resources: ["ingresses"],
        operations: ["CREATE", "UPDATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Allow Space only if Part of Team": {
        javascript: `// Allow space creation only if part of team "admins"
if (!request.userInfo?.groups?.includes("loft:team:admins")) {
    deny("Sorry you cannot create spaces since you are not part of the admins team");
}
`,
        type: "Validating",
        scope: "*",
        resources: ["spaces"],
        operations: ["CREATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Set User as Annotation On New Object": {
        javascript: `// Set the current user as an annotation on the object
if (request.userInfo?.username?.indexOf("loft:user") === 0) {
    if (!request.object.metadata) {
        request.object.metadata = {};
    }
    if (!request.object.metadata.annotations) {
        request.object.metadata.annotations = {};
    }
    request.object.metadata.annotations["loft.sh/created-by-user"] = request.userInfo?.username?.substring("loft:user:".length);
    mutate(request.object);
}`,
        type: "Mutating",
        scope: "Namespaced",
        resources: ["*"],
        operations: ["CREATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Inject Sidecar Container": {
        javascript: `// if the annotation inject-side-car: 'true' is set on the pod we inject a sidecar
if (request.object.metadata?.annotations?.["inject-side-car"] === "true") {
  if (!request.object.spec.containers) {
    request.object.spec.containers = [];
  }
    
  // add sidecar
  request.object.spec.containers.push({
    name: "injected-container",
    image: "busybox",
    command: ["sleep", "3600"]
  });

  mutate(request.object);
}`,
        type: "Mutating",
        scope: "*",
        resources: ["pods"],
        operations: ["CREATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Unique Ingress Hosts": {
        javascript: `// iterate over all ingresses in cluster and check for same hosts
list("Ingress", "networking.k8s.io/v1").forEach(ingress => {
  // don't check self on UPDATE
  if (ingress.metadata.name === request.name && ingress.metadata.namespace === request.namespace) {
    return;
  }

  ingress.spec.rules?.forEach(rule => {
    request.object.spec.rules?.forEach(objRule => {
      if (rule.host === objRule.host) {
        deny(\`ingress ` + "${ingress.metadata.namespace}/${ingress.metadata.name} already uses host ${rule.host}" + `\`);
      }
    })
  })
})`,
        type: "Validating",
        scope: "*",
        resources: ["ingresses"],
        operations: ["CREATE", "UPDATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Enforce NodeSelector": {
        javascript: `// This policy enforces a node selector for pods
// that are created in namespaces with the
// label with-node-selector: 'true'

const namespace = get("Namespace", "v1", request.namespace, {cache: false});
if (namespace?.metadata?.labels?.["with-node-selector"] === "true") {
    // set the new label selector
    request.object.spec.nodeSelector = {
      "foo": "value",
      "test": "test2"
    }
}

mutate(request.object);`,
        type: "Mutating",
        scope: "*",
        resources: ["pods"],
        operations: ["CREATE"],
        apiGroups: [],
        apiVersions: []
    },
    "Sync Secrets": {
        javascript: `// this policy will sync changes to a secret to all other secrets in the cluster
// that have labels in the form of: {"owner-name": SECRET, "owner-namespace": NAMESPACE}

// is this a parent or child secret?
const labels = request.object.metadata?.labels;
let syncSecret = undefined; 
if (labels?.["sync"] === "true") {
  syncSecret = request.object;
} else if (labels?.["owner-name"] && labels?.["owner-namespace"]) {
  syncSecret = get("Secret", "v1", labels?.["owner-namespace"] + "/" + labels?.["owner-name"]);
}

// this is not a secret we should sync
if (!syncSecret) {
  allow();
}

// list all owned secrets
list("Secret", "v1", {
  labelSelector: \`owner-name=`+"${syncSecret.metadata.name},owner-namespace=${syncSecret.metadata.namespace}"+`\`
}).forEach(secret => {
  // only update if data has actually changed
  if (JSON.stringify(secret.data) === JSON.stringify(syncSecret.data)) {
    return;
  }

  // sync the secret data
  secret.data = syncSecret.data;
  const updateResult = update(secret);
  if (!updateResult.ok) {
    // just requeue if we encounter an error
    requeue(updateResult.message);
  } else {
    print(\`Successfully synced secret `+"${secret.metadata.namespace}/${secret.metadata.name}"+`\`);
  }
});`,
        type: "Controller",
        scope: "*",
        resources: ["secrets"],
        operations: ["CREATE"],
        apiGroups: [],
        apiVersions: []
    }
}

interface PolicyOptionsState {
    selectedExample: string | undefined;
    
    type: string;
    scope: string;
    javascript: string;
    operations: string[];
    resources: string[];
    apiGroups: string[];
    apiVersions: string[];
}

interface PolicyOptionsProps extends SectionProps {
    policy?: PolicyV1beta1JsPolicy;
    
    cluster: string;
}

export interface ApiResourcesQueryProps {
    cluster: string;
    children: (result: {
        loading: boolean | undefined,
        error: ResultError | undefined,
        apiResourcesRaw: GroupVersionResource<Unstructured>[]
    }) => JSX.Element | null;
}

export function ApiResourcesQuery(props: ApiResourcesQueryProps) {
    const query = useApiResourceClusterQuery({cluster: props.cluster, removeDuplicates: false, includeSubResources: true});
    return props.children(query);
}

export default class PolicyOptions extends React.PureComponent<PolicyOptionsProps, PolicyOptionsState> {
    state: PolicyOptionsState = {
        selectedExample: undefined,
        scope: this.props.policy?.spec?.scope || "*",
        type: this.props.policy?.spec?.type || "Validating",
        javascript: this.props.policy?.spec?.javascript || "",
        operations: this.props.policy?.spec?.operations || ["*"],
        resources: this.props.policy?.spec?.resources || ["pods"],
        apiGroups: this.props.policy?.spec?.apiGroups || [],
        apiVersions: this.props.policy?.spec?.apiVersions || [],
    };

    create = (policy: PolicyV1beta1JsPolicy): ResultError => {
        if (arr(this.state.resources).length === 0) {
            return Return.Failed("at least a single resource is required")
        }
        if (arr(this.state.operations).length === 0) {
            return Return.Failed("at least a single operation is required")
        }
        
        if (!policy.spec) {
            policy.spec = {};
        }
        policy.spec.javascript = this.state.javascript;
        policy.spec.type = this.state.type;
        policy.spec.operations = this.state.operations;
        policy.spec.resources = this.state.resources;
        policy.spec.scope = this.state.scope;
        policy.spec.apiGroups = this.state.apiGroups;
        policy.spec.apiVersions = this.state.apiVersions;
        return Return.Ok();
    };

    update = (policy: PolicyV1beta1JsPolicy): ResultError => {
        return this.create(policy);
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={"Options"}>
            <ApiResourcesQuery cluster={this.props.cluster}>
                {
                    result => {
                        if (result.loading) {
                            return <Loading style={{margin: "10px 0"}} />
                        } else if (result.error) {
                            return <ErrorMessage error={result.error} />;
                        }
                        
                        const resourcesWithDuplicates = arr(result.apiResourcesRaw.map(raw => raw.subResource ? raw.resource + "/" + raw.subResource : raw.resource));
                        resourcesWithDuplicates.sort(stringSorter);
                        const resources = ["*", "*/*", ...new Set(resourcesWithDuplicates)];

                        const groupsWithDuplicates = arr(result.apiResourcesRaw.map(raw => raw.group));
                        groupsWithDuplicates.sort(stringSorter);
                        const groups = ["*", ...new Set(groupsWithDuplicates)];
                        
                        const versionsWithDuplicates = arr(result.apiResourcesRaw.map(raw => raw.version));
                        versionsWithDuplicates.sort(stringSorter);
                        const versions = ["*", ...new Set(versionsWithDuplicates)];
                        return <React.Fragment>
                            {
                                !this.props.policy &&
                                <React.Fragment>
                                    <Label>Choose an Example</Label>
                                    <Select showSearch placeholder="Select an Example" style={{ width: '100%' }} value={this.state.selectedExample} onChange={value => {
                                        this.setState({
                                            selectedExample: "",
                                            javascript: examples[value!].javascript,
                                            operations: examples[value!].operations,
                                            resources: examples[value!].resources,
                                            type: examples[value!].type,
                                            apiGroups: examples[value!].apiGroups,
                                            apiVersions: examples[value!].apiVersions,
                                            scope: examples[value!].scope,
                                        }, () => this.setState({selectedExample: undefined}))
                                    }}>
                                        {Object.keys(examples).map(example => <Option key={example} value={example}>{example}</Option>)}
                                    </Select>
                                    <Description>Select an example to start from</Description>
                                </React.Fragment>
                            }
                            <div className="drawer-row">
                                <div>
                                    <Label>Policy Type</Label>
                                    {this.props.mode === "update" ? <Input readOnly value={this.state.type || "Validating"} style={{width: "100%"}} /> : <Select value={this.state.type}
                                                                                                                                                                 style={{width: "100%"}}
                                                                                                                                                                 placeholder={"Validating"}
                                                                                                                                                                 onChange={(e) => this.setState({type: e})}>
                                        <Option key={"Validating"} value={"Validating"}>
                                            Validating
                                        </Option>
                                        <Option key={"Mutating"} value={"Mutating"}>
                                            Mutating
                                        </Option>
                                        <Option key={"Controller"} value={"Controller"}>
                                            Controller
                                        </Option>
                                    </Select>}
                                    <Description>The type of the policy.</Description>
                                </div>
                                <div>
                                    <Label>Policy Scope</Label>
                                    <Select value={this.state.scope}
                                            style={{width: "100%"}}
                                            placeholder={"*"}
                                            onChange={(e) => this.setState({scope: e})}>
                                        <Option key={"*"} value={"*"}>
                                            *
                                        </Option>
                                        <Option key={"Namespaced"} value={"Namespaced"}>
                                            Namespaced
                                        </Option>
                                        <Option key={"Cluster"} value={"Cluster"}>
                                            Cluster
                                        </Option>
                                    </Select>
                                    <Description>What resource scope should apply.</Description>
                                </div>
                            </div>
                            <div className="drawer-row">
                                <div>
                                    <Label>Matching Resources</Label>
                                    <Select value={this.state.resources}
                                            mode="multiple"
                                            style={{width: "100%"}}
                                            placeholder={"pods, services, ingresses ..."}
                                            onChange={(e) => this.setState({resources: e})}>
                                        {
                                            resources.map(resource => <Option key={resource} value={resource}>{resource}</Option>)
                                        }
                                    </Select>
                                    <Description>The Kubernetes resources this policy should act on.</Description>
                                </div>
                                <div>
                                    <Label>Matching Operations</Label>
                                    <Select value={this.state.operations}
                                            mode="multiple"
                                            style={{width: "100%"}}
                                            placeholder={"*"}
                                            onChange={(e) => this.setState({operations: e})}>
                                        <Option key={"*"} value={"*"}>
                                            * (All Operations)
                                        </Option>
                                        <Option key={"CREATE"} value={"CREATE"}>
                                            CREATE
                                        </Option>
                                        <Option key={"UPDATE"} value={"UPDATE"}>
                                            UPDATE
                                        </Option>
                                        <Option key={"DELETE"} value={"DELETE"}>
                                            DELETE
                                        </Option>
                                    </Select>
                                    <Description>The Kubernetes resource operations this policy should act on.</Description>
                                </div>
                            </div>
                            <SectionExpander name={"API Groups & Versions"}>
                                <div className="drawer-row">
                                    <div>
                                        <Label>Matching API Groups</Label>
                                        <Select value={this.state.apiGroups}
                                                mode="multiple"
                                                style={{width: "100%"}}
                                                placeholder={"*"}
                                                onChange={(e) => this.setState({apiGroups: e})}>
                                            {
                                                groups.map(group => <Option key={group} value={group}>{group}</Option>)
                                            }
                                        </Select>
                                        <Description>The Kubernetes API Groups this policy should operate on.</Description>
                                    </div>
                                    <div>
                                        <Label>Matching API Versions</Label>
                                        <Select value={this.state.apiVersions}
                                                mode="multiple"
                                                style={{width: "100%"}}
                                                placeholder={"*"}
                                                onChange={(e) => this.setState({apiVersions: e})}>
                                            {
                                                versions.map(version => <Option key={version} value={version}>{version}</Option>)
                                            }
                                        </Select>
                                        <Description>The Kubernetes API Versions this policy should operate on.</Description>
                                    </div>
                                </div>
                            </SectionExpander>
                            <Label>Policy Javascript</Label>
                            <YAMLEditor value={this.state.javascript} 
                                        minLines={10}
                                        maxLines={100}
                                        onChange={val => {this.setState({javascript: val})}} 
                                        placeholder={`// Your javascript code here`} 
                                        language="javascript" />
                            <Description>The actual payload of the policy that will be executed if a resource matches.</Description>
                        </React.Fragment>
                    }
                }
            </ApiResourcesQuery>
        </Section>
    }
}
