import React from "react";
import {displayName, formatURIComponent} from "../../../../lib/helper";
import {DrawerDispatch, useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import * as History from "history";
import {ToggleColumn, ToggleColumnContent} from "../../../../contexts/ToggleColumn/ToggleColumn";
import FixedText from "../../../../components/FixedText/FixedText";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import Table, {TableActions} from "../../../../components/Table/Table";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import Button from "../../../../components/Button/Button";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons";
import {deleteConfirm} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {
    arr,
    Filter,
    getDisplayNameFromEntity,
    removeDuplicateFilters
} from "../../../../lib/helpers/renderhelper";
import {
    creationTimestampSorter,
    displayNameSorter,
    numberSorter,
    stringSorter
} from "../../../../lib/helpers/sorthelper";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import {MultiItem} from "../../../../components/MultiItem/MultiItem";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import Owner from "../../../../components/Owner/Owner";
import styles from "./ClusterAccess.module.scss";
import {ClusterV1LocalClusterAccess} from "../../../../../gen/models/clusterV1LocalClusterAccess";
import ClusterAccessDrawer from "./ClusterAccessDrawer/ClusterAccessDrawer";
import constants from "../../../../constants/constants";
import Description from "../../../../components/Description/Description";

interface Props {}

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, history: History.History<any>, cluster: string, allClusterAccesses: ClusterV1LocalClusterAccess[]) {
    const editClusterAccess = (clusterAccess: ClusterV1LocalClusterAccess) => {
        drawerDispatcher({
            title: "Edit Cluster Access: " + displayName(clusterAccess),
            content: <ClusterAccessDrawer mode={"update"} cluster={cluster} clusterAccess={clusterAccess} refetch={refetch} />
        })
    };

    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            render: (clusterAccess: ClusterV1LocalClusterAccess) => {
                if (isDisabled(clusterAccess)) {
                    return <ToggleColumnContent id={"displayname"} columns={[
                        () => {
                            if (!!clusterAccess.metadata?.labels?.[constants.LoftClusterGlobalTemplate]) {
                                return <Tooltip title={<span>Generated from Cluster Access <Link to={`/clusters/clusteraccess#search=${formatURIComponent(displayName(clusterAccess))}`}>{displayName(clusterAccess)}</Link></span>}>
                                    <span>
                                        <FixedText text={displayName(clusterAccess)} />
                                    </span>
                                </Tooltip>
                            }
                            
                            return <FixedText text={displayName(clusterAccess)} />;
                        },
                        () => {
                            return <FixedText text={clusterAccess.metadata?.name} />;
                        },
                    ]}/>
                }
                
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editClusterAccess(clusterAccess)} text={displayName(clusterAccess)} />;
                    },
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editClusterAccess(clusterAccess)} text={clusterAccess.metadata?.name} />;
                    },
                ]}/>
            }
        },
        {
            title: "Description",
            render: (clusterAccess: ClusterV1LocalClusterAccess) => {
                return <Description.Column>{clusterAccess.spec?.description || ""}</Description.Column>;
            }
        },
        {
            title: 'Users',
            filters: removeDuplicateFilters(arr(allClusterAccesses).reduce((previous: Filter[], clusterAccess): Filter[] => {
                const clusters = clusterAccess.status?.users?.map(c => {
                    if (c.user) {
                        return ({
                            text: getDisplayNameFromEntity(c.user),
                            value: getDisplayNameFromEntity(c.user) + ""
                        })
                    } else {
                        return ({
                            text: getDisplayNameFromEntity(c.team),
                            value: getDisplayNameFromEntity(c.team) + ""
                        })
                    }
                })
                return [...previous, ...arr(clusters)];
            }, [])),
            onFilter: (value: string | number | boolean, cluster: ClusterV1LocalClusterAccess) => {
                return !!cluster.status?.users?.find(c => {
                    if (c.user) {
                        return getDisplayNameFromEntity(c.user) === value
                    } else {
                        return getDisplayNameFromEntity(c.team) === value
                    }
                });
            },
            render: (cluster: ClusterV1LocalClusterAccess) => {
                const users = arr(cluster.status?.users).map(u => {
                    if (u.user) {
                        return {
                            key: u.user.name,
                            children: <Owner displayName={u.user.displayName}
                                             username={u.user.username}
                                             kubeName={u.user.name}
                                             isTeam={false}
                                             className={styles["owner"]} />
                        }
                    } else if (u.team) {
                        return {
                            key: u.team.name,
                            children: <Owner displayName={u.team.displayName}
                                             username={u.team.username}
                                             kubeName={u.team.name}
                                             isTeam={true}
                                             className={styles["owner"]} />
                        }
                    }

                    return {};
                });
                return <MultiItem items={users} maxItems={5} />;
            }
        },
        {
            title: 'Teams',
            filters: removeDuplicateFilters(arr(allClusterAccesses).reduce((previous: Filter[], clusterAccess): Filter[] => {
                const clusters = clusterAccess.status?.teams?.map(c => ({
                    text: getDisplayNameFromEntity(c),
                    value: getDisplayNameFromEntity(c) + ""
                }))
                return [...previous, ...arr(clusters)];
            }, [])),
            onFilter: (value: string | number | boolean, cluster: ClusterV1LocalClusterAccess) => {
                return !!cluster.status?.teams?.find(c => getDisplayNameFromEntity(c) === value);
            },
            showSorterTooltip: false,
            render: (cluster: ClusterV1LocalClusterAccess) => {
                const teams = arr(cluster.status?.teams);
                return <MultiItem items={teams.map(c => ({key: c.name, children: <Owner displayName={c.displayName}
                                                                                        username={c.username}
                                                                                        kubeName={c.name}
                                                                                        isTeam={true}
                                                                                        className={styles["owner"]} />}))} maxItems={5} />;
            }
        },
        {
            title: <Tooltip title={"Cluster access rules with higher priority will override rules with lower priority"}>Priority</Tooltip>,
            sorter: (a: ClusterV1LocalClusterAccess, b: ClusterV1LocalClusterAccess) => numberSorter(a.spec?.priority, b.spec?.priority),
            render: (cluster: ClusterV1LocalClusterAccess) => {
                return cluster.spec?.priority || "None"
            }
        },
        {
            title: 'Space Constraints',
            sorter: (a: ClusterV1LocalClusterAccess, b: ClusterV1LocalClusterAccess) => stringSorter(a.spec?.spaceConstraintsRef, b.spec?.spaceConstraintsRef),
            render: (cluster: ClusterV1LocalClusterAccess) => {
                if (!cluster.status?.spaceConstraint) {
                    return <span>None</span>;
                }
                
                return <Link to={"/clusters/spaceconstraints"} className={"hover-link"}>{getDisplayNameFromEntity(cluster.status?.spaceConstraint)}</Link>;
            }
        },
        {
            title: 'Created',
            sorter: (a: ClusterV1LocalClusterAccess, b: ClusterV1LocalClusterAccess) => creationTimestampSorter(a, b),
            render: (cluster: ClusterV1LocalClusterAccess) => {
                return <DynamicTime timestamp={cluster.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "210px",
            render: (clusterAccess: ClusterV1LocalClusterAccess) => {
                if (isDisabled(clusterAccess)) {
                    return <TableActions>
                        <ShowYamlPopup className={"blue-btn"} canUpdate={false} object={clusterAccess} cluster={cluster} resource={Resources.ClusterV1LocalClusterAccess} name={clusterAccess.metadata?.name!} refetch={refetch} />
                    </TableActions>;
                }
                
                return <TableActions>
                    <Tooltip title="edit">
                        <SettingOutlined className={"blue-btn"} onClick={() => editClusterAccess(clusterAccess)} />
                    </Tooltip>
                    <ShowYamlPopup className={"blue-btn"} object={clusterAccess} cluster={cluster} resource={Resources.ClusterV1LocalClusterAccess} name={clusterAccess.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={"actions-delete-btn"} onClick={() => {
                            deleteConfirm({
                                title: `Delete Cluster Access: ${clusterAccess?.metadata?.name}`,
                                content: `Are you sure you want to delete the cluster access ${clusterAccess?.metadata?.name}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await client.cluster(cluster, Resources.ClusterV1LocalClusterAccess).Delete(clusterAccess?.metadata?.name as string);
                                    if (result.err) {
                                        message.ErrorCluster(result, cluster)
                                        return result;
                                    }
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ClusterV1LocalClusterAccess, value: string) {
    return !!(item.metadata?.name?.includes(value) || item.spec?.displayName?.includes(value) || item.spec?.description?.includes(value));
}

function isDisabled(clusterAccess: ClusterV1LocalClusterAccess) {
    return !!clusterAccess.metadata?.ownerReferences?.length || !!clusterAccess.metadata?.labels?.[constants.LoftClusterGlobalTemplate];
}

function ClusterAccess(props: Props) {
    const history = useHistory();
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.cluster(cluster, Resources.ClusterV1LocalClusterAccess).List());
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
        getCheckboxProps: (clusterAccess: ClusterV1LocalClusterAccess) => ({
            disabled: isDisabled(clusterAccess),
        }),
    };

    const allClusterAccesses = arr(data?.items).map(cluster => { return {...cluster, key: cluster.metadata!.name!}}).sort((a, b) => displayNameSorter(a, b));
    return <div>
        <Table showSorterTooltip={false} loading={loading} columns={getTableColumns(refetch, drawerDispatcher, history, cluster, allClusterAccesses)} dataSource={arr(allClusterAccesses)} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <ClusterHeader />,
            right: <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Create Cluster Access",
                    content: <ClusterAccessDrawer mode={"create"} cluster={cluster} refetch={refetch} />
                });
            }}>Create Cluster Access</Button>,
            selectedActions: <React.Fragment>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={"actions-delete-btn-batch"} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Cluster Access`,
                            content: `Are you sure you want to delete the cluster access ${selectedRowKeys.join(", ")}?`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const clusterName = selectedRowKeys[i];
                                    const result = await client.cluster(cluster, Resources.ClusterV1LocalClusterAccess).Delete(clusterName as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneCluster(cluster);
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}

export default ClusterAccess;
