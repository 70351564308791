import React, {useRef, useState} from "react";
import {Tooltip} from "../../../../../components/Tooltip/Tooltip";
import {ReloadOutlined} from "@ant-design/icons/lib";
import styles from "./AppRollbackPopup.module.scss";
import {Modal} from "../../../../../lib/Modal/Modal";
import ClientMessage from "../../../../../lib/Message/ClientMessage";
import {RequestOptionsVCluster} from "../../../../../lib/client";
import {HelmRelease} from "./AppsTable";
import {useUser} from "../../../../../contexts/UserContext/UserContext";
import {ProgressPopup} from "../../../../../components/ProgressPopup/ProgressPopup";
import {streamTask} from "../../../../Spaces/Spaces/SpaceDrawer/SpaceDrawer";
import {convertReleaseToHelmTask} from "../AppsDrawer/AppsDrawer";

interface Props {
    vCluster?: RequestOptionsVCluster
    cluster?: string;
    refetch: () => Promise<void>;
    className?: string;
    release: HelmRelease;
}

export default function AppRollbackPopup(props: Props) {
    const user = useUser();
    const progressPopupRef = useRef<ProgressPopup>(null);
    const [visible, setVisible] = useState<boolean>(false);

    return <React.Fragment>
        <Tooltip title="rollback">
            <ReloadOutlined className={props.className ? props.className : styles["setting"]} onClick={() => setVisible(true)} />
        </Tooltip>
        <Modal title={`Rollback App ${props.release.release.metadata?.name}`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
            const message = ClientMessage.Loading(props.cluster);

            // stream task
            const streamTaskResult = await streamTask(user!, convertReleaseToHelmTask(props.release.release, "Rollback", undefined, props.release.app?.spec?.streamContainer, props.cluster, props.vCluster), progressPopupRef.current!, false,true);
            if (streamTaskResult?.err) {
                message.ErrorCluster(streamTaskResult, props.cluster!);
                return;
            }

            await props.refetch();
            message.DoneCluster(props.cluster!);
            setVisible(false);
        }}>
            <div>
                Are you sure you want to rollback the app {props.release.release.metadata?.name}?
            </div>
        </Modal>
        <ProgressPopup title={"Rolling Back App"} ref={progressPopupRef} />
    </React.Fragment>
}