import React from "react";
import {useRouteMatch} from "react-router-dom";
import TemplatesInstancesTable from "../TemplateInstancesTable/TemplateInstancesTable";
import ClusterHeader from "../ClusterHeader/ClusterHeader";

export default function TemplateInstances() {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    
    return <TemplatesInstancesTable cluster={cluster} top={<ClusterHeader />} />
}
