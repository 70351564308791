import React, {useEffect, useState} from "react";
import client, {GroupVersionResource, List} from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import useQuery from "../../../lib/Query/Query";
import {SpaceTable} from "./SpaceTable/SpaceTable";
import {arr, selectDefaultFilter} from "../../../lib/helpers/renderhelper";
import Select from "../../../components/Select/Select";
import Loading from "../../../components/Loading/Loading";
import {stringSorter} from "../../../lib/helpers/sorthelper";
import {CloudOutlined} from "@ant-design/icons";
import styles from './Spaces.module.scss';
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {displayName, safeParse} from "../../../lib/helper";
import {Result, Return} from "../../../lib/result";
import SpacesHeader from "../SpacesHeader/SpacesHeader";
import Description from "../../../components/Description/Description";
const { Option } = Select;

export interface ClusterObject<E> {
    cluster?: string;
    object?: E;
}

export function useClusterQuery<E>(resource: GroupVersionResource<E>, localStorageKey: string) {
    const initialClusters = safeParse<string[]>(localStorage.getItem(localStorageKey));
    const [isActive, setActive] = useState<boolean>(false);
    const [selectedClusters, oldSetSelectedClusters] = useState<string[] | undefined>(initialClusters);
    const setSelectedClusters = (value: string[] | undefined) => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
        oldSetSelectedClusters(value);
    }
    
    const {loading: clustersLoading, data: clustersData} = useQuery(async () => {
        const clusterResult = await client.management(Resources.ManagementV1Cluster).List();
        if (clusterResult.err) {
            return clusterResult;
        }

        const clusters = arr(clusterResult.val.items);
        clusters.sort((a, b) => stringSorter(a.metadata?.name, b.metadata?.name));
        if (clusters.length > 2) {
            if (!initialClusters) {
                setSelectedClusters([clusters[0].metadata?.name!, clusters[1].metadata?.name!]);
            } else {
                // make sure the initial clusters exist
                setSelectedClusters(initialClusters.filter(cluster => clusters.find(c => c.metadata?.name === cluster)));
            }
        } else {
            setSelectedClusters(clusters.map(cluster => cluster.metadata?.name!))
        }

        return clusterResult;
    });
    
    const classNames = [styles["select-wrapper"]];
    if (isActive) {
        classNames.push(styles["active"])
    }
    
    const {loading, error, data, refetch} = useQuery(async () => {
        if (arr(selectedClusters).length > 0) {
            const promises: Array<Promise<Result<List<E>>>> = [];
            for (let i = 0; i < selectedClusters!.length; i++) {
                promises.push(client.cluster(selectedClusters![i], resource).List());
            }
            
            const results = await Promise.all(promises);
            const resultArr: Array<ClusterObject<E>> = [];
            for (let i = 0; i < results.length; i++) {
                const result = results[i];
                if (result.err) {
                    console.error(`Error listing in cluster ${selectedClusters![i]}: ${result.val.message}"`)
                    continue;
                }
                
                resultArr.push(...arr(result.val.items).map(item => {
                    return {
                        cluster: selectedClusters![i],
                        object: item,
                    }
                }));
            }
            
            return Return.Value(resultArr);
        }
        
        return Return.Value(undefined);
    }, {refetch: selectedClusters});
    return {
        loading,
        error,
        data,
        refetch,
        clustersLoading,
        clustersData,
        clustersSelect: arr(clustersData?.items).length > 2 && <div className={classNames.join(" ")}>
            <Tooltip title="Selected Clusters">
                <CloudOutlined />
            </Tooltip>
            <Select
                mode="multiple"
                placeholder="Cluster"
                value={selectedClusters}
                onChange={value => {
                    setSelectedClusters(value);
                }}
                showSearch
                optionFilterProp="children"
                filterOption={selectDefaultFilter}
                maxTagCount={"responsive"}
                style={{width: "300px"}}
                onFocus={() => setActive(true)}
                onBlur={() => setActive(false)}
            >
                {clustersData ? clustersData.items?.map(cluster => <Option key={cluster.metadata?.name!} value={cluster.metadata?.name!}>{displayName(cluster)}</Option>) : undefined}
            </Select>
        </div>
    }
}

export default function Spaces() {
    const {loading, error, data, clustersData, clustersLoading, clustersSelect, refetch} = useClusterQuery(Resources.ClusterV1Space, "SELECTED_CLUSTERS_SPACES");
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 5000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);
    if (clustersLoading) {
        return <Loading />;
    }
    
    return <SpaceTable top={<SpacesHeader>
        <Description.Table>Spaces are Kubernetes namespaces that can be owned by a Loft user or team and can be shared between them. New spaces can be created through clicking the button on the right or through the <a target={"_blank"} href={"https://loft.sh/docs/getting-started/install/cli"}>Loft CLI</a>.</Description.Table>
    </SpacesHeader>} error={error} loading={loading} clusters={clustersData?.items} spaces={data} left={clustersSelect} refetch={refetch} />
}