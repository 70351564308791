import React from "react";
import AuthLayout from "../../../components/AuthLayout/AuthLayout";
import client from "../../../lib/client";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {V1ClientIDInfo, V1Code} from "../../../lib/types";
import {findGetParameter} from "../../../lib/helper";
import {Result, ResultError, Return} from "../../../lib/result";
import {LOFT_REDIRECT_ON_LOGIN_IDENTIFIER} from "../Login/Login";
import Query from "../../../components/Query/Query";
import Loading from "../../../components/Loading/Loading";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import {useUser} from "../../../contexts/UserContext/UserContext";
import Button from "../../../components/Button/Button";
import {useErrorLoading} from "../../../lib/messageHook";
import styles from "./OidcGrant.module.scss";
import {getDisplayName} from "../../../lib/helpers/renderhelper";
import {Space} from "antd";
import {Resources} from "../../../lib/resources";

interface Props extends RouteComponentProps {

}

async function onSubmit(setLoading: (loading: boolean) => void, setError: (err: ResultError) => void) {
    setLoading(true);

    const clientId = findGetParameter("client_id");
    const scope = findGetParameter("scope");
    const redirectUri = findGetParameter("redirect_uri");
    const nonce = findGetParameter("nonce");
    const state = findGetParameter("state");
    const responseType = findGetParameter("response_type");
    if (responseType && responseType !== "code") {
        setError(Return.Failed("response_type is invalid, only type 'code' is accepted"));
        return Return.Failed("response_type is invalid, only type 'code' is accepted");
    }

    const parameters = [];
    if (clientId) {
        parameters.push("client_id=" + encodeURIComponent(clientId));
    }
    if (scope) {
        parameters.push("scope=" + encodeURIComponent(scope));
    }
    if (redirectUri) {
        parameters.push("redirect_uri=" + encodeURIComponent(redirectUri));
    }
    if (nonce) {
        parameters.push("nonce=" + encodeURIComponent(nonce));
    }

    const codeResponse = await client.doRaw<V1Code>("/oidc/code?" + parameters.join("&"));
    if (codeResponse.err) {
        setError(codeResponse);
        return codeResponse;
    }

    let fullRedirectUri = redirectUri + "?code=" + encodeURIComponent(codeResponse.val.code!);
    if (state) {
        fullRedirectUri += "&state=" + encodeURIComponent(state!);
    }

    window.location.href = fullRedirectUri;
    return Return.Ok();
}

function OidcGrant(props: Props) {
    const user = useUser();
    const [errorLoading, setLoading, setError] = useErrorLoading();

    // reroute if not logged in
    if (!client.isLoggedIn()) {
        const location = window.location.href + "";
        localStorage.setItem(LOFT_REDIRECT_ON_LOGIN_IDENTIFIER, location);

        // we need a little bit time here otherwise the wrong location is saved into the local storage
        window.setTimeout(() => props.history.push("/"), 200);
        return null;
    }

    return <AuthLayout title="Grant Access To Application" description="to allow it to access loft" onSubmit={() => onSubmit(setLoading, setError)}>
        <Query query={async () => {
            const clientId = findGetParameter("client_id");
            if (!clientId) {
                return Return.Failed("client_id is missing")
            }

            const accessKeyResult = await client.management(Resources.ManagementV1UserAccessKeys).Get(user?.metadata?.name!);
            if (accessKeyResult.err) {
                return accessKeyResult;
            } else if (accessKeyResult.val?.accessKeys?.find(key => key.spec?.oidcProvider?.clientId === clientId)) {
                // access was already granted, then just submit
                const result = await onSubmit(setLoading, setError);
                if (result.err) {
                    return result;
                }

                // never ending promise
                await new Promise(resolve => {});
            }

            return await client.doRaw<V1ClientIDInfo>("/oidc/client-info?client_id=" + encodeURIComponent(clientId));
        }}>
            {
                result => {
                    if (result.loading) {
                        return <Loading />;
                    } else if (result.error) {
                        return <ErrorMessage error={result.error} />;
                    }

                    return <React.Fragment>
                        <div className={styles["big-info-text"]}>Do you want to give application</div>
                        <div className={styles["app-name"]}>{result.data?.name}</div>
                        <div className={styles["big-info-text"]}>access to loft user&nbsp;<span className={"text-large-bold"}>{getDisplayName(user?.spec?.displayName, user?.spec?.username, user?.metadata?.name)}</span></div>
                        <ErrorMessage className={styles["error"]} error={errorLoading.error} />
                        <Space className={styles["button-wrapper"]}>
                            <Button onClick={() => props.history.push("/spaces")}>Deny</Button>
                            <Button type={"primary"} loading={errorLoading.loading} htmlType={"submit"}>Grant</Button>
                        </Space>
                    </React.Fragment>
                }
            }
        </Query>
    </AuthLayout>
}

export default withRouter(OidcGrant);