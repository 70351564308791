import React, {Component} from "react";
import {Button} from "antd";
import {useDrawerDispatcher} from "../../contexts/drawer/DrawerContext";
import styles from "./ItemDrawer.module.scss";
import AsyncButton from "../AsyncButton/AsyncButton";

interface ItemDrawerProps {
    hideButtons?: boolean;
    className?: string;

    okButtonText?: React.ReactNode;
    closeButtonText?: React.ReactNode;
    
    onOkAsync?: (e: React.MouseEvent<HTMLElement>) => Promise<any>;
    onClose?: (e: React.MouseEvent<HTMLElement>) => any;
}

export interface SectionProps {
    mode: "create" | "update" | "batch";
    foldable?: boolean;
    defaultFolded?: boolean;
}

export default function ItemDrawer(props: React.PropsWithChildren<ItemDrawerProps>) {
    const drawer = useDrawerDispatcher();
    const classNames = [styles["item-drawer"]];
    if (props.className) {
        classNames.push(props.className);
    }
    
    return <div className={classNames.join(" ")}>
        <div className={styles["content"]}>
            {props.children}
        </div>
        {!props.hideButtons && <div className={styles["buttons"]}>
            <Button onClick={props.onClose ? props.onClose : () => drawer({})}>{props.closeButtonText || "Close"}</Button>
            <AsyncButton onClickAsync={props.onOkAsync} type={"primary"}>{props.okButtonText ? props.okButtonText : "Create"}</AsyncButton>
        </div>}
    </div>
}