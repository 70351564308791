import React from "react";
import {ResultError} from "../../lib/result";
import "./ErrorMessage.scss";
import {Alert} from "antd";

interface Props {
    error: ResultError;
    className?: string;
    style?: React.CSSProperties;
}

export function ErrorMessage(props: Props) {
    if (props.error.ok) {
        return null;
    }

    const classNames = ["ErrorMessage"];
    if (props.className) {
        classNames.push(props.className);
    }

    if (typeof props.error.val?.message === 'string') {
        const message = "Error: " + props.error.val?.message + " " + (props.error.val?.reason ? " (" + props.error.val?.reason + ")" : "");
        return <Alert message={message} className={classNames.join(" ")} style={props.style} type="error" showIcon />
    }

    return <Alert message={props.error.val?.message} className={classNames.join(" ")} style={props.style} type="error" showIcon />
}

