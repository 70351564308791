import React from "react";
import {MoreOutlined} from "@ant-design/icons/lib";
import styles from "./ToggleColumn.module.scss";

type State = {[key: string]: number}

interface ToggleColumnContextType {
    state: State,
    setState: (state: State) => void
}

const ToggleColumnContext = React.createContext<ToggleColumnContextType | undefined>(undefined);

export function ToggleColumnProvider(props: React.PropsWithChildren<{}>) {
    const [state, setState] = React.useState<State>({});
    return (
        <ToggleColumnContext.Provider value={{state, setState}}>
            {props.children}
        </ToggleColumnContext.Provider>
    )
}

export function useToggleColumnProvider() {
    let context = React.useContext(ToggleColumnContext);
    if (!context) {
        throw new Error("useDrawerProvider has to be used below a DrawerProvider component");
    }

    return context;
}

interface ToggleColumnProps {
    id: string;
    columns: React.ReactNode[];
}

interface ToggleColumnContentProps {
    id: string;
    columns: (() => JSX.Element | null | string)[];
}

export function ToggleColumn(props: ToggleColumnProps) {
    const state = useToggleColumnProvider();
    const key = state.state[props.id] === undefined ? 0 : state.state[props.id];

    return <span className={styles["toggle-column"]}>
        <MoreOutlined onClick={e => {
            e.stopPropagation();
            state.setState({...state.state, [props.id]: (key + 1 >= props.columns.length ? 0 : key + 1)});
        }} />{props.columns[key]}
    </span>
}

export function ToggleColumnContent(props: ToggleColumnContentProps) {
    const state = useToggleColumnProvider();
    const key = state.state[props.id] === undefined ? 0 : state.state[props.id];
    return props.columns[key]() as any;
}
