import React from "react";
import {
    ApartmentOutlined,
    ApiOutlined,
    BlockOutlined,
    CloudServerOutlined,
    ClusterOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    FireOutlined,
    LockOutlined,
    SaveOutlined, WarningOutlined
} from "@ant-design/icons/lib";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import Header from "../../../../components/Header/Header";
import {AppstoreAddOutlined, SolutionOutlined} from "@ant-design/icons";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {aggregateCores, aggregateMemory, getActions} from "../SpaceTable/SpaceTable";
import styles from "./SpaceHeader.module.scss";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {arr} from "../../../../lib/helpers/renderhelper";
import {useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import {useFeatures, useLicense} from "../../../../contexts/LicenseContext/LicenseContext";
import {ClusterObject} from "../Spaces";
import {ClusterV1Space} from "../../../../../gen/models/clusterV1Space";

interface SpacesHeaderProps {

}

export default function SpaceHeader(props: React.PropsWithChildren<SpacesHeaderProps>) {
    const routeMatch = useRouteMatch<{cluster: string, space: string}>();
    const cluster = routeMatch.params.cluster;
    const space = routeMatch.params.space;
    const path = routeMatch.path.split("/");
    const drawerDispatcher = useItemDrawer();
    const features = useFeatures();
    const license = useLicense();

    const selectedKey = path[path.length - 1] === ":space" ? "pods" : path[path.length -2] === "other" ? "other" : path[path.length - 1];
    const {data: metricsData} = useQuery(async () => await client.cluster(cluster!, Resources.V1Beta1PodMetrics).Namespace(space).List());
    const {data: eventsData} = useQuery(async () => await client.cluster(cluster!, Resources.V1Event).Namespace(space).List());

    const {data: templateData} = useQuery(async () => await client.management(Resources.ManagementV1SpaceTemplate).List())

    // we refresh the object here, otherwise it can be possible that we get a conflict error if the space has changed meanwhile
    const {data:spaceResult, refetch: spaceRefetch} = useQuery(async () =>await client.cluster(cluster, Resources.ClusterV1Space).Get(space));

    const spaceObject : ClusterObject<ClusterV1Space> = {
        cluster : cluster,
        object : spaceResult?spaceResult:{}
    }

    const history = useHistory();

    const refetch = () => {
        return spaceRefetch();
    };

    let metricsTooltip: React.ReactNode | undefined = undefined;
    if (metricsData) {
        metricsTooltip = <Tooltip title={"Used Resources by Space"}>
            <Link className={styles["metrics"]} to={`/spaces/${cluster}/${space}/metrics`}>
                <DashboardOutlined/>
                <span>{aggregateCores({metrics: metricsData.items}).toFixed(2)} Cores</span>
                <span className={styles["delimiter"]}>|</span>
                <span>{(aggregateMemory({metrics: metricsData.items}) / 1024 / 1024 / 1024).toFixed(1)} GB</span>
            </Link>
        </Tooltip>
    }
    
    let eventsTooltip: React.ReactNode | undefined = undefined;
    if (eventsData) {
        const warnings = arr(eventsData.items).filter(event => event.type === "Warning");
        if (warnings.length > 0) {
            eventsTooltip = <Tooltip title={warnings.length + " Warnings in Space"}>
                <Link className={styles["warnings"]} to={`/spaces/${cluster}/${space}/events`}>
                    <WarningOutlined />
                    {warnings.length} Warnings
                </Link>
            </Tooltip>
        }
    }

    let title: React.ReactNode = space;
    if (eventsTooltip || metricsTooltip) {
        title = <span>
            {space}
            {eventsTooltip}
            {metricsTooltip}
        </span>
    }

    let actions: React.ReactNode = getActions(spaceObject, templateData?.items, features, license, history, drawerDispatcher, refetch)

    return <Header title={title} actions={actions} selectedKey={selectedKey} items={[
        {
            key: "pods",
            url: `/spaces/${cluster}/${space}/pods`,
            content: <React.Fragment>
                <CloudServerOutlined />Pods
            </React.Fragment>
        },
        {
            key: "apps",
            url: `/spaces/${cluster}/${space}/apps`,
            content: <React.Fragment>
                <AppstoreAddOutlined />Apps
            </React.Fragment>
        },
        {
            key: "deployments",
            url: `/spaces/${cluster}/${space}/deployments`,
            content: <React.Fragment>
                <ClusterOutlined />Deployments
            </React.Fragment>
        },
        {
            key: "statefulsets",
            url: `/spaces/${cluster}/${space}/statefulsets`,
            content: <React.Fragment>
                <DatabaseOutlined />StatefulSets
            </React.Fragment>
        },
        {
            key: "services",
            url: `/spaces/${cluster}/${space}/services`,
            content: <React.Fragment>
                <ApartmentOutlined />Services
            </React.Fragment>
        },
        {
            key: "ingresses",
            url: `/spaces/${cluster}/${space}/ingresses`,
            content: <React.Fragment>
                <ApiOutlined />Ingresses
            </React.Fragment>
        },
        {
            key: "events",
            url: `/spaces/${cluster}/${space}/events`,
            content: <React.Fragment>
                <FireOutlined />Events
            </React.Fragment>
        },
        {
            key: "secrets",
            url: `/spaces/${cluster}/${space}/secrets`,
            content: <React.Fragment>
                <LockOutlined />Secrets
            </React.Fragment>
        },
        {
            key: "configmaps",
            url: `/spaces/${cluster}/${space}/configmaps`,
            content: <React.Fragment>
                <SaveOutlined />ConfigMaps
            </React.Fragment>
        },
        {
            key: "metrics",
            url: `/spaces/${cluster}/${space}/metrics`,
            content: <React.Fragment>
                <DashboardOutlined />Metrics
            </React.Fragment>
        },
        {
            key: "activity",
            url: `/spaces/${cluster}/${space}/activity`,
            content: <React.Fragment>
                <SolutionOutlined />Activity
            </React.Fragment>
        },
        {
            key: "other",
            url: `/spaces/${cluster}/${space}/other`,
            content: <React.Fragment>
                <BlockOutlined />Other
            </React.Fragment>
        }
    ]}>
        {props.children}
    </Header>
}