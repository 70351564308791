import React from "react";
import Description from "../../../components/Description/Description";
import AuditHeader from "../AuditHeader/AuditHeader";
import ActivityTable from "./ActivityTable";

interface Props {
}

export default function Activity(props: Props) {
    return <ActivityTable top={<AuditHeader>
        <Description.Table>Shows the user and team activity across the management instance, connected clusters and virtual clusters depending on your audit configuration.</Description.Table>
    </AuditHeader>} />
};
