import React from "react";
import {
    LockOutlined,
    SecurityScanOutlined, SolutionOutlined
} from "@ant-design/icons/lib";
import { useRouteMatch } from "react-router-dom";
import Header from "../../../../components/Header/Header";

interface TeamHeaderProps {

}

export default function TeamHeader(props: React.PropsWithChildren<TeamHeaderProps>) {
    const routeMatch = useRouteMatch<{team: string}>();
    const team = routeMatch.params.team;
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1] === ":team" ? "access-keys" : path[path.length - 1];

    return <Header title={team} selectedKey={selectedKey} items={[
        {
            key: "access-keys",
            url: `/users/teams/${team}/access-keys`,
            content: <React.Fragment>
                <LockOutlined />Access Keys
            </React.Fragment>
        },
        {
            key: "activity",
            url: `/users/teams/${team}/activity`,
            content: <React.Fragment>
                <SolutionOutlined />Activity
            </React.Fragment>
        },
        {
            key: "permissions",
            url: `/users/teams/${team}/permissions`,
            content: <React.Fragment>
                <SecurityScanOutlined />Assigned Cluster Roles
            </React.Fragment>
        },
    ]}>
        {props.children}
    </Header>
}