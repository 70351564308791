import React from "react";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import AccessKeysTable from "../../../Profile/AccessKeysTable/AccessKeysTable";
import {useRouteMatch} from "react-router-dom";
import TeamHeader from "../TeamHeader/TeamHeader";
import Description from "../../../../components/Description/Description";

export default function TeamAccessKeys() {
    const match: any = useRouteMatch();
    const team = match.params?.team;
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1TeamAccessKeys).Get(team!));
    return <AccessKeysTable error={error} loading={loading} accessKeys={data ? data.accessKeys : undefined} team={team} top={<TeamHeader>
        <Description.Table>
            Access Keys are a way to log into the Loft CLI without specifying a password. You also see all currently active access keys in this view created automatically by the Loft UI or CLI.
        </Description.Table>
    </TeamHeader>} refetch={refetch} />
}