import React from "react";
import {
    UserOutlined,
    AuditOutlined, ExpandOutlined,
    LoginOutlined, CloudOutlined
} from "@ant-design/icons/lib";
import { useRouteMatch } from "react-router-dom";
import Header from "../../../components/Header/Header";

interface ClustersHeaderProps {

}

export default function ClustersHeader(props: React.PropsWithChildren<ClustersHeaderProps>) {
    const routeMatch = useRouteMatch<{cluster: string}>();
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1];
    
    return <Header title={"Cluster Management"} selectedKey={selectedKey} items={[
        {
            key: "clusters",
            url: `/clusters/clusters`,
            content: <React.Fragment>
                <CloudOutlined />Clusters
            </React.Fragment>
        },
        {
            key: "clusteraccess",
            url: `/clusters/clusteraccess`,
            content: <React.Fragment>
                <LoginOutlined />Cluster Access
            </React.Fragment>
        },
        {
            key: "clusterroles",
            url: `/clusters/clusterroles`,
            content: <React.Fragment>
                <AuditOutlined />Cluster Roles
            </React.Fragment>
        },
        {
            key: "spaceconstraints",
            url: `/clusters/spaceconstraints`,
            content: <React.Fragment>
                <ExpandOutlined />Space Constraints
            </React.Fragment>
        },
    ]}>
        {props.children}
    </Header>
}