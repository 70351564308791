import React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Menu} from "antd";
import styles from './SideMenu.module.scss';
import {BlockOutlined, CloudOutlined} from "@ant-design/icons/lib";
import useQuery from "../../lib/Query/Query";
import client from "../../lib/client";
import {Resources} from "../../lib/resources";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";

export interface MenuItem {
    link: string;
    name: string;
    icon: React.ExoticComponent;
    admin?: boolean;
}

export interface MenuProps extends RouteComponentProps {
    items: Array<MenuItem>;
    mapping?: {[from: string]: string}
}

function getSelected(props: MenuProps, items: Array<MenuItem>): number {
    const path = props.mapping?.[props.location.pathname] || props.location.pathname;
    for(let i = 0; i < items.length; i++) {
        if (path.startsWith(items[i].link)) {
            return i;
        }
    }

    return 0;
}

function SideMenu(props: MenuProps) {
    const {data: admin} = useQuery(async () => await client.management(Resources.ManagementV1User).CanI("create"));
    const items = props.items.filter(item => !item.admin || (item.admin && admin));
    const selectedKeys = [getSelected(props, items).toString()];
    return <Menu
        mode={'inline'}
        theme={'light'}
        className={styles["menu"]}
        selectedKeys={selectedKeys}
    >
        {items.map((item, index) =>
            <Menu.Item key={index.toString()} icon={<item.icon />}>
                <Link to={item.link}>{item.name}</Link>
            </Menu.Item>
        )}
    </Menu>;
}

export default withRouter(SideMenu);