import React from "react";
import Label from "../../../../../../components/Label/Label";
import {ResultError, Return} from "../../../../../../lib/result";
import {SectionProps} from "../../../../../../components/Drawer/ItemDrawer";
import {ClusterV1HelmRelease} from "../../../../../../../gen/models/clusterV1HelmRelease";
import Input from "../../../../../../components/Input/Input";
import {chartName} from "../../../Overview/AppsList/AppsList";
import Section from "../../../../../../components/Drawer/Section/Section";
import {StorageV1HelmChart} from "../../../../../../../gen/models/storageV1HelmChart";
import {HelmRelease} from "../../AppsTable/AppsTable";
import Checkbox from "../../../../../../components/Checkbox/Checkbox";
import {ManagementV1App} from "../../../../../../../gen/models/managementV1App";
import SectionExpander from "../../../../../../components/Drawer/SectionExpander/SectionExpander";

interface HelmChartState {
    chartRepo: string | undefined;
    chartName: string | undefined;
    chartRepoUsername: string | undefined;
    chartRepoPassword: string | undefined;
    chartInsecure: boolean | undefined;
}

interface HelmChartProps extends SectionProps {
    show: boolean;
    selectedChart?: StorageV1HelmChart | undefined;
    selectedApp?: ManagementV1App | undefined;

    charts: Array<StorageV1HelmChart>;
    release?: HelmRelease;
}

export default class HelmChart extends React.PureComponent<HelmChartProps, HelmChartState> {
    state: HelmChartState = {
        chartRepo: this.props.release?.release?.spec?.chart?.repoURL || this.props.selectedApp?.spec?.config?.chart?.repoURL || this.props.charts?.find(chart => chartName(chart) === chartName(this.props.selectedChart))?.repository?.url,
        chartName: this.props.release?.release.spec?.chart?.name || this.props.selectedApp?.spec?.config?.chart?.name || this.props.selectedChart?.metadata?.name,
        chartRepoUsername: this.props.release?.release.spec?.chart?.username || this.props.selectedApp?.spec?.config?.chart?.username || this.props.charts?.find(chart => chartName(chart) === chartName(this.props.selectedChart))?.repository?.username,
        chartRepoPassword: this.props.release?.release.spec?.chart?.password || this.props.selectedApp?.spec?.config?.chart?.password || this.props.charts?.find(chart => chartName(chart) === chartName(this.props.selectedChart))?.repository?.password,
        chartInsecure: !!(this.props.release?.release.spec?.chart?.insecureSkipTlsVerify || this.props.selectedApp?.spec?.config?.chart?.insecureSkipTlsVerify || this.props.charts?.find(chart => chartName(chart) === chartName(this.props.selectedChart))?.repository?.insecure),
    };

    create = (obj: ClusterV1HelmRelease): ResultError => {
        if (!this.props.show) {
            return Return.Ok();
        }
        if (!this.state.chartName) {
            return Return.Failed("no chart name provided");
        } else if (!this.state.chartRepo) {
            return Return.Failed("no chart repo provided");
        }

        if (!obj.spec) {
            obj.spec = {};
        }
        if (!obj.spec.chart) {
            obj.spec.chart = {};
        }

        obj.spec.chart.name = this.state.chartName;
        obj.spec.chart.repoURL = this.state.chartRepo;
        obj.spec.chart.username = this.state.chartRepoUsername;
        obj.spec.chart.password = this.state.chartRepoPassword;
        obj.spec.chart.insecureSkipTlsVerify = this.state.chartInsecure;
        return Return.Ok();
    };

    update = (obj: ClusterV1HelmRelease): ResultError => {
        if (!this.props.show) {
            return Return.Ok();
        }
        if (!!this.props.release?.chart) {
            return Return.Ok();
        }

        return this.create(obj);
    };
    
    selectCustomHelm = () => {
        this.setState({
            chartRepo: undefined,
            chartName: undefined,
            chartRepoUsername: undefined,
            chartRepoPassword: undefined,
            chartInsecure: undefined,
        });
    }
    
    selectApp = (app: ManagementV1App | undefined) => {
        if (!app) {
            return;
        }

        this.setState({
            chartRepo: app.spec?.config?.chart?.repoURL,
            chartName: app.spec?.config?.chart?.name,
            chartRepoUsername: app.spec?.config?.chart?.username,
            chartRepoPassword: app.spec?.config?.chart?.password,
            chartInsecure: app.spec?.config?.chart?.insecureSkipTlsVerify,
        });
    }

    selectChart = (chart: StorageV1HelmChart | undefined) => {
        if (!chart) {
            return;
        }
        
        this.setState({
            chartRepo: chart.repository?.url,
            chartName: chart.metadata?.name,
            chartRepoUsername: chart.repository?.username,
            chartRepoPassword: chart.repository?.password,
            chartInsecure: chart.repository?.insecure
        });
    };

    render() {
        if (this.props.mode === "batch" || !this.props.show || this.props.selectedApp || this.props.selectedChart) {
            return null;
        }

        return <Section title={"Chart Location"} noMargin={this.props.mode === "update"}>
            <div className={"row"}>
                <div>
                    <Label>Chart Repository URL</Label>
                    <Input value={this.state.chartRepo} placeholder={"https://chart.domain.tld"} onChange={e => this.setState({chartRepo: e.target.value}, () => this.selectChart(undefined))} />
                </div>
                <div>
                    <Label>Chart Name</Label>
                    <Input readOnly={!!this.props.release} value={this.state.chartName} placeholder={"some-chart-name"} onChange={e => this.setState({chartName: e.target.value}, () => this.selectChart(undefined))} />
                </div>
            </div>
            <SectionExpander name={"Advanced Options"}>
                <div className={"row"}>
                    <div>
                        <Label>Chart Repository Username</Label>
                        <Input value={this.state.chartRepoUsername} placeholder={"repo-username"} onChange={e => this.setState({chartRepoUsername: e.target.value}, () => this.selectChart(undefined))} />
                    </div>
                    <div>
                        <Label>Chart Repository Password</Label>
                        <Input type="password" value={this.state.chartRepoPassword} placeholder={"****************"} onChange={e => this.setState({chartRepoPassword: e.target.value}, () => this.selectChart(undefined))} />
                    </div>
                </div>
                <div>
                    <div>
                        <Label>
                            Insecure Skip TLS Verify
                        </Label>
                        <Checkbox checked={this.state.chartInsecure}
                                  onChange={e => this.setState({chartInsecure: e.target.checked})}>
                            Skip tls certificate checks for chart download
                        </Checkbox>
                    </div>
                </div>
            </SectionExpander>
        </Section>
    }
}