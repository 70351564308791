import React from "react";
import * as Ant from "antd";
import * as AntProgress from "antd/lib/progress";
import styles from "./Progress.module.scss";

export interface ProgressProps extends AntProgress.ProgressProps {

}

export default function Progress(props: ProgressProps) {
    let classNames = [styles["progress"]];
    if (props.className) {
        classNames.push(props.className);
    }

    return <Ant.Progress {...props} className={classNames.join(" ")} />
}
