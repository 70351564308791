import React, {useState} from "react";
import styles from "./Section.module.scss";
import {MinusSquareOutlined, PlusSquareOutlined} from "@ant-design/icons/lib";

interface Props {
    title: React.ReactNode;
    foldable?: boolean;
    defaultFolded?: boolean;
    noMargin?: boolean;
    noLastLine?: boolean;
}

export default function Section(props: React.PropsWithChildren<Props>) {
    const [hidden, setHidden] = useState<boolean>(!!props.defaultFolded);
    const classNames = [styles["section"]];
    if (props.noLastLine) {
        classNames.push(styles["no-last-line"]);
    }
    
    return <div className={classNames.join(" ")} style={!props.noMargin ? {marginTop: "32px"} : undefined}>
        <div className={!props.foldable ? styles["header"] : styles["header"] + " " + styles["clickable"]} onClick={props.foldable ? () => setHidden(!hidden) : undefined}>
            {!props.foldable ? undefined : !hidden ? <MinusSquareOutlined className={styles["fold-icon"]} /> : <PlusSquareOutlined className={styles["fold-icon"]} />}{props.title}
        </div>
        <div className={styles["content"]} style={{"display": hidden ? "none" : "block"}}>
            {props.children}
        </div>
    </div>
}