import React, {useEffect, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import styles from "./Search.module.scss";
import inputStyles from "../Input/Input.module.scss";
import {without} from "../../lib/helpers/renderhelper";
import * as Ant from "antd";
import * as AntInput from "antd/lib/input/Input";
import {Tooltip} from "antd";
import {findHashParameter, setHashParameter} from "../../lib/helper";

export interface SearchProps extends AntInput.InputProps {
    fromUrl?: boolean;
}

export function Search(props: SearchProps) {
    const [isActive, setActive] = useState(false);
    const classNames = [styles["search"]];
    if (props.className) {
        classNames.push(props.className);
    }
    if (isActive) {
        classNames.push(styles["active"]);
    }
    useEffect(() => {
        if (props.onChange && props.fromUrl && !props.value) {
            const initialSearch = findHashParameter("search");
            if (initialSearch) {
                props.onChange({
                    target: {
                        value: initialSearch,
                    }
                } as any)
            }
        }
    }, [])
    
    return <span className={classNames.join(" ")}>
        <Tooltip title={"Type to Search"}>
            <SearchOutlined />
        </Tooltip>
        <Ant.Input {...without(props, ["className", "fromUrl"])} className={inputStyles["input"]} onChange={e => { setHashParameter("search", e.target.value); return props.onChange?.(e) }} onFocus={() => setActive(true)} onBlur={() => setActive(false)} />
    </span>
}