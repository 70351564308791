import {CodeOutlined, DownOutlined, PauseOutlined, ReloadOutlined, UpOutlined} from '@ant-design/icons';
import {Space, Tabs, Tooltip} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import { SearchAddon } from 'xterm-addon-search';

import Checkbox from '../Checkbox/Checkbox';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import Loading from '../Loading/Loading';
import Query from '../Query/Query';
import { Search } from '../Search/Search';
import Select from '../Select/Select';
import { StreamAddon } from '../Terminal/StreamAddon';
import { Terminal } from '../Terminal/Terminal';
import client, { RequestOptionsVCluster } from '../../lib/client';
import { arr, selectDefaultFilter } from '../../lib/helpers/renderhelper';
import useQuery, { QueryResult } from '../../lib/Query/Query';
import { Resources } from '../../lib/resources';
import { Return } from '../../lib/result';
import styles from './PodExecDockTab.module.scss';
import {calculateTerminalSize} from "./PodExecDockTab";
import {DockContext, DockTabActions, DockTabKind} from "./DockContext";

const Option = Select.Option;
const { TabPane } = Tabs;

interface PodLogsDockTabProps {
    className?: string;
    onClose?: () => void;
    container?: string;

    vCluster?: RequestOptionsVCluster;
    cluster?: string;

    text?: string;

    task?: string;
    pod?: string;
    namespace?: string;
    dockHeight?: number;
}

export function PodLogsDockTab(props: PodLogsDockTabProps) {
    const [containers, setContainers] = useState<string[]>([]);
    const { dispatch } = useContext(DockContext);
    const [selectedContainer, setSelectedContainer] = useState<string>(props.container || "");
    const { error, loading } = useQuery(async () => {
        const podRequest = await client.auto(props.cluster, props.vCluster, Resources.V1Pod).Namespace(props.namespace).Get(props.pod!);
        if (podRequest.err) {
            return podRequest;
        }

        const pod = podRequest.val;
        const containers: string[] = [];
        arr(pod.spec?.initContainers).forEach(container => containers.push(container.name));
        arr(pod.spec?.containers).forEach(container => containers.push(container.name));
        if (containers.length === 0) {
            return Return.Failed("Pod has no containers");
        }

        setContainers(containers);
        setSelectedContainer(props.container || containers[0]);
        return Return.Ok();
    }, { skip: !props.pod });

    const [isPaused, setPaused] = useState<boolean>(false);
    const [isClosed, setClosed] = useState<boolean>(false);
    let isMounted = false
    useEffect(() => {
        isMounted = true;
        return () => {
            isMounted = false;
        }
    })

    const onStreamClose = useCallback(() => {
        if (isMounted) {
            setClosed(true);
        }
    }, [isClosed]);

    const onRefreshClosed = useCallback((result: QueryResult<ReadableStreamDefaultReader<Uint8Array>>) => {
        setClosed(false)
        setPaused(false)
        result.refetch();
    }, [isClosed, isPaused]);

    const onRefresh = useCallback((result: QueryResult<ReadableStreamDefaultReader<Uint8Array>>) => {
        result.refetch();
        setPaused(!isPaused);
    }, [isPaused]);

    const onPause = useCallback((result: QueryResult<ReadableStreamDefaultReader<Uint8Array>>) => {
        result.data?.cancel();
        setPaused(!isPaused);
    }, [isPaused]);

    const [searchAddon] = useState(new SearchAddon());
    const [searchTerm, setSearchTerm] = useState<string>();

    const onSearchNext = useCallback(() => {
        if (searchTerm) {
            searchAddon.findNext(searchTerm);
        }
    }, [searchAddon, searchTerm]);

    const onSearchPrev = useCallback(() => {
        if (searchTerm) {
            searchAddon.findPrevious(searchTerm);
        }
    }, [searchAddon, searchTerm]);

    const [showPrevious, setShowPrevious] = useState<boolean>(false);
    const onChangePrevious = (e: CheckboxChangeEvent) => {
        setShowPrevious(e.target.checked);
    }

    const [showTimestamps, setShowTimestamps] = useState<boolean>(false);
    const onChangeTimestamps = (e: CheckboxChangeEvent) => {
        setShowTimestamps(e.target.checked);
    }

    if (error) {
        return <ErrorMessage error={error} />;
    } else if (loading) {
        return null;
    }

    return <Query
        query={
            async () => {
                if (props.pod) {
                    return await client
                        .autoNonResource(props.cluster, props.vCluster)
                        .Logs(
                            props.namespace!,
                            props.pod,
                            {
                                container: selectedContainer,
                                follow: true,
                                timestamps: showTimestamps,
                                previous: showPrevious,
                            }
                        )
                }

                return await client
                    .managementNonResource()
                    .TaskLogs(
                        props.task!,
                        {
                            follow: true,
                            timestamps: showTimestamps,
                        }
                    )
            }
        }
        refetch={[selectedContainer, showTimestamps, showPrevious]}>
        {
            result => {
                if (result.error) {
                    return <ErrorMessage error={result.error} />;
                }

                return <div>
                    <div className={styles["header"]}>
                        <div className={styles["select-wrapper"]}>
                            {!props.task && <React.Fragment>
                                <span>Container:</span>
                                <Select showSearch
                                    placeholder={"Select a container"}
                                    dropdownClassName={styles["dropdown"]}
                                    optionFilterProp="children"
                                    style={{ width: "200px", marginRight: "5px" }}
                                    filterOption={selectDefaultFilter}
                                    value={selectedContainer}
                                    onChange={value => setSelectedContainer(value)}>
                                    {arr(containers).map(container => {
                                        return <Option key={container} value={container}>{container}</Option>
                                    })}
                                </Select>
                                <Tooltip title={"shell"}>
                                    <CodeOutlined className={styles["setting"]} onClick={() => {
                                        dispatch({
                                            type: DockTabActions.OPEN,
                                            payload: {
                                                kind: DockTabKind.EXEC,
                                                cluster: props.cluster,
                                                vCluster: props.vCluster,
                                                namespace: props.namespace,
                                                pod: props.pod,
                                                container: selectedContainer,
                                            }
                                        })
                                    }} />
                                </Tooltip>
                            </React.Fragment>}
                        </div>
                        <Space direction="horizontal" className={styles["right-wrapper"]}>
                            {props.pod && <Checkbox onChange={onChangePrevious}>Show previous</Checkbox>}
                            <Checkbox onChange={onChangeTimestamps}>Show timestamps</Checkbox>
                            <Search className={styles["search"]}
                                placeholder={"Search"}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onPressEnter={onSearchNext} />
                            <Tooltip title={"previous"}>
                                <UpOutlined className={styles["setting"]} onClick={onSearchPrev} />
                            </Tooltip>
                            <Tooltip title={"next"}>
                                <DownOutlined className={styles["setting"]} onClick={onSearchNext} />
                            </Tooltip>
                            {
                                isClosed
                                    ? <Tooltip title={"refresh"}>
                                        <ReloadOutlined className={styles["setting"]} onClick={() => onRefreshClosed(result)} />
                                    </Tooltip>
                                    : isPaused
                                        ?
                                        <Tooltip title={"refresh"}>
                                            <ReloadOutlined className={styles["setting"]} onClick={() => onRefresh(result)} />
                                        </Tooltip>
                                        :
                                        <Tooltip title={"pause"}>
                                            <PauseOutlined className={styles["setting"]} onClick={() => onPause(result)} />
                                        </Tooltip>
                            }
                        </Space>
                    </div>
                    <div>
                        {
                            result.loading
                                ? <div><Loading /></div>
                                : <Terminal className={styles["terminal"]}
                                            height={calculateTerminalSize(props.dockHeight)}
                                            addons={[
                                                searchAddon,
                                                new StreamAddon(result.data!, { onClose: onStreamClose }),
                                            ]} />
                        }
                    </div>
                </div>
            }
        }
    </Query>
}