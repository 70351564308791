import React from "react";
import {Menu} from "antd";
import styles from "./Header.module.scss";
import {arr} from "../../lib/helpers/renderhelper";
import {Link} from "react-router-dom";

export interface HeaderItem {
    key: string;
    content: React.ReactNode;
    url: string;
}

export interface HeaderProps {
    title?: React.ReactNode;
    actions?: React.ReactNode;
    selectedKey?: string;
    items?: Array<HeaderItem>;
}

function Header(props: React.PropsWithChildren<HeaderProps>) {
    return <div>
        <div className={styles["header"]}>
            {props.title && <div className={styles["title"]}>
                <div className={styles["name"]}>
                    {props.title}
                </div>
                {props.actions && <div className={styles["actions"]}>
                    {props.actions}
                    </div>
                }
            </div>}
            <Menu mode="horizontal" className={styles["menu"]} selectedKeys={props.selectedKey !== undefined ? [props.selectedKey] : []}>
                {
                    arr(props.items).map((item: HeaderItem) =>
                        <Menu.Item key={item.key}>
                          <Link to={item.url}>
                            {item.content}
                          </Link>
                        </Menu.Item>
                    )
                }
            </Menu>
        </div>
        {props.children}
    </div>
}

export default Header;