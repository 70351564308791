import React, {useEffect, useState} from "react";
import {Result, ResultError, Return} from "../result";
import {useEffectAsync, useEffectAsyncOnce, useEffectOnce, Wait} from "../helper";
import {arr} from "../helpers/renderhelper";

interface State<T> {
    data?: T;
    loading: boolean;
    error: ResultError | undefined;
}

export interface QueryResult<T> {
    skipped: boolean;
    loading: boolean;
    error: ResultError | undefined;
    data?: T;
    refetch: () => Promise<void>;
}

export interface QueryOptions {
    skip?: boolean;
    delay?: number;
    refetch?: any[];
    unmount?: () => void;
}

export function usePeriodicQuery<T>(query: () => Promise<Result<T>>, interval: number, options?: QueryOptions): QueryResult<T> {
    const result = useQuery<T>(query, options);
    useEffectOnce(() => {
        const intervalHandle = window.setInterval(result.refetch, interval);
        return () => window.clearInterval(intervalHandle);
    });

    return result;
}

function useQuery<T>(query: () => Promise<Result<T>>, options?: QueryOptions): QueryResult<T> {
    const [refetch, setRefetch] = useState<number>(0);
    const [state, setState] = useState<State<T>>({
        data: undefined,
        loading: true,
        error: undefined
    });
    const {data, loading, error} = state;
    useEffect(() => {
        let active = true;
        trigger();
        return () => {
            active = false;
            options?.unmount?.()
        }

        async function trigger () {
            if (options?.skip) {
                return;
            } else if (options?.delay) {
                await Wait(options.delay);
            }
            if (!active) {
                return;
            }

            setState(state => state.loading ? state : ({data: state.data, loading: true, error: state.error}));
            const result = await query();
            if (!active) {
                return;
            }
            if (result.err) {
                setState(state => ({data: state.data, loading: false, error: result}));
                return;
            }

            setState({data: result.val, loading: false, error: undefined});
        }
    }, [...arr(options?.refetch), options?.skip, refetch]);
    return {
        skipped: !!options?.skip,
        loading: !options?.skip && loading,
        error,
        data,
        refetch: async () => setRefetch(refetch => refetch + 1)
    }
}

export default useQuery;