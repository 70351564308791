import React from "react";
import Tabs from "../../../../components/Tabs/Tabs";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {ManagementV1App} from "../../../../../gen/models/managementV1App";
import {Result, ResultError, Return} from "../../../../lib/result";
import Section from "../../../../components/Drawer/Section/Section";
import Label from "../../../../components/Label/Label";
import Description from "../../../../components/Description/Description";
import styles from "./AppResources.module.scss";
import Input from "../../../../components/Input/Input";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import YAMLEditor from "../../../../components/YAMLEditor/YAMLEditor";
import jsyaml from "js-yaml";
import SectionExpander from "../../../../components/Drawer/SectionExpander/SectionExpander";
const { TabPane } = Tabs;

const bashManifests = `apiVersion: batch/v1
kind: Job
metadata:
  name: {{ .Release.Name }}-{{ .Release.Revision }}
  namespace: {{ .Release.Namespace }}
spec:
  ttlSecondsAfterFinished: 120
  backoffLimit: 0
  template:
    metadata:
      labels:
        loft.sh/job: {{ .Release.Name | quote }}
        loft.sh/revision: {{ .Release.Revision | quote }}
    spec:
      serviceAccountName: job-{{ .Release.Name }}
      containers:
        - command: ["bash"]
          args: 
          - '-c'
          - |-
{{ .Values.script | indent 12 }}
          image: dtzar/helm-kubectl:3
          name: bash
      restartPolicy: Never
---
apiVersion: v1
kind: ServiceAccount
metadata:
  name: job-{{ .Release.Name }}
  namespace: {{ .Release.Namespace }}
---
apiVersion: rbac.authorization.k8s.io/v1
kind: RoleBinding
metadata:
  name: job-{{ .Release.Name }}-binding
  namespace: {{ .Release.Namespace }}
subjects:
- kind: ServiceAccount
  name: job-{{ .Release.Name }}
  namespace: {{ .Release.Namespace }}
roleRef:
  kind: ClusterRole
  {{- if .Values.loft.virtualClusterName }}
  name: cluster-admin
  {{- else }}
  name: loft-cluster-space-admin
  {{- end }}
  apiGroup: rbac.authorization.k8s.io
` 

interface AppResourcesState {
    manifests: string;

    chartName: string | undefined;
    chartVersion: string | undefined;
    chartRepo: string | undefined;
    chartRepoUsername: string | undefined;
    chartRepoPassword: string | undefined;
    chartValues: string | undefined;
    chartInsecure: boolean | undefined;
    
    bashScript: string | undefined;
    bashManifests: string | undefined;
    
    activeKey: string;
    
    wait: boolean | undefined;
}

interface AppResourcesProps extends SectionProps {
    app?: ManagementV1App;

    allApps: ManagementV1App[];
}

function setScript(values: string | undefined, script: string | undefined) {
    if (!values) {
        return jsyaml.dump({
            script
        });
    }

    try {
        let out = jsyaml.load(values) as any;
        if (out) {
            out["script"] = script;
            return jsyaml.dump(out);
        }
    } catch(err) {
        console.error(err);
    }

    return jsyaml.dump({
        script
    });
}

function getScript(values: string | undefined) {
    if (!values) {
        return undefined;
    }
    
    try {
        const out = jsyaml.load(values) as any;
        if (out && "script" in out) {
            return out["script"];
        }
    } catch(err) {
        console.error(err);
    }

    return undefined;
}

export default class AppResources extends React.PureComponent<AppResourcesProps, AppResourcesState> {
    state: AppResourcesState = {
        manifests: this.props.app?.spec?.config?.manifests || "",

        chartName: this.props.app?.spec?.config?.chart?.name,
        chartVersion: this.props.app?.spec?.config?.chart?.version,
        chartRepo: this.props.app?.spec?.config?.chart?.repoURL,
        chartInsecure: !!this.props.app?.spec?.config?.chart?.insecureSkipTlsVerify,
        chartRepoUsername: this.props.app?.spec?.config?.chart?.username,
        chartRepoPassword: this.props.app?.spec?.config?.chart?.password,
        chartValues: this.props.app?.spec?.config?.values,
        
        bashScript: this.props.app?.spec?.streamContainer ? getScript(this.props.app?.spec?.config?.values) : "",
        bashManifests: this.props.app?.spec?.streamContainer ? this.props.app?.spec?.config?.manifests : bashManifests,

        activeKey: this.props.app?.spec?.streamContainer ? "3" : this.props.app?.spec?.config?.chart?.name ? "2" : "1",
        
        wait: this.props.app?.spec?.wait,
    };

    create = async (app: ManagementV1App): Promise<ResultError> => {
        if (!app.spec) {
            app.spec = {};
        }
        app.spec.wait = this.state.wait;
        return this.update(app);
    };

    update = async (app: ManagementV1App): Promise<ResultError> => {
        if (!app.spec) {
            app.spec = {};
        }
        if (!app.spec.config) {
            app.spec.config = {};
        }

        if (this.state.activeKey === "3") {
            app.spec.config.manifests = this.state.bashManifests;
            app.spec.config.values = setScript(this.props.app?.spec?.config?.values, this.state.bashScript);
            app.spec.config.chart = undefined;
            if (!app.spec.streamContainer) {
                app.spec.streamContainer = {
                    selector: {
                        matchLabels: {
                            "loft.sh/job": "{{ .Release.Name }}",
                            "loft.sh/revision": "{{ .Release.Revision }}",
                        },
                    },
                    container: "bash",
                }
            }
            return Return.Ok();
        } else if (this.state.activeKey === "1") {
            app.spec.config.manifests = this.state.manifests;
            app.spec.config.chart = undefined;
            app.spec.streamContainer = undefined;
            return Return.Ok();
        }
        
        // helm
        if (!this.state.chartName) {
            return Return.Failed("please enter a valid chart name");
        }
        
        if (!app.spec.config.chart) {
            app.spec.config.chart = {};
        }
        app.spec.config.chart.name = this.state.chartName

        if (!this.state.chartRepo) {
            return Return.Failed("Chart repo is missing")
        }

        app.spec.config.chart.repoURL = this.state.chartRepo
        app.spec.config.values = this.state.chartValues
        app.spec.config.chart.username = this.state.chartRepoUsername
        app.spec.config.chart.password = this.state.chartRepoPassword
        app.spec.config.chart.insecureSkipTlsVerify = this.state.chartInsecure
        app.spec.config.chart.version = this.state.chartVersion
        app.spec.config.manifests = undefined;
        app.spec.streamContainer = undefined;
        
        app.spec.wait = this.state.wait;
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={`App Definition`} foldable={true}>
                <Description>Specify which Kubernetes resources should be deployed when this app gets applied to a virtual cluster or space</Description>
                <Tabs onChange={(activeKey) => this.setState({activeKey: activeKey})} defaultActiveKey={this.state.activeKey} type="card" size={"small"}>
                    <TabPane tab="kubectl" key="1">
                        <Label>Kubernetes Manifests</Label>
                        <YAMLEditor value={this.state.manifests}
                                    minLines={10}
                                    maxLines={100}
                                    onChange={val => {
                            this.setState({manifests: val});
                        }} placeholder={`apiVersion: apps/v1
kind: Deployment
...`} />
                        <Description>Each manifest will be created when this app is deployed to a space or virtual cluster and packed as a helm chart. For more information please take a look at the <a href={"https://loft.sh/docs"} target={"_blank"}>loft documenation</a>.</Description>
                    </TabPane>
                    <TabPane tab="helm" key="2">
                        <Description>Loft will deploy this helm chart via the helm CLI into a space or virtual cluster. For more information please take a look at the <a href={"https://loft.sh/docs"} target={"_blank"}>loft documentation</a></Description>
                        <div className={styles["row"]}>
                            <div>
                                <Label>Chart Name</Label>
                                <Input placeholder={"mysql"} value={this.state.chartName} onChange={e => this.setState({chartName: e.target.value})} />
                            </div>
                            <div>
                                <Label>Chart Version</Label>
                                <Input placeholder={"4.5.1"} value={this.state.chartVersion} onChange={e => this.setState({chartVersion: e.target.value})} />
                            </div>
                        </div>
                        <Label>Chart Repository URL</Label>
                        <Input placeholder={"https://chart.mydomain.tld"} value={this.state.chartRepo} onChange={e => this.setState({chartRepo: e.target.value})} />
                        <Description>Browse charts and chart repositories at <a href={"https://artifacthub.io/"} target={"_blank"}>ArtifactHub.io</a></Description>
                        <div className={styles["row"]}>
                            <div>
                                <Label>Chart Repository Username</Label>
                                <Input placeholder={"repo-username"} value={this.state.chartRepoUsername} onChange={e => this.setState({chartRepoUsername: e.target.value})} />
                            </div>
                            <div>
                                <Label>Chart Repository Password</Label>
                                <Input type={"password"} placeholder={"****************"} value={this.state.chartRepoPassword} onChange={e => this.setState({chartRepoPassword: e.target.value})} />
                            </div>
                        </div>
                        <Label>Chart Values</Label>
                        <YAMLEditor value={this.state.chartValues} 
                                    className={styles["chart-values"]}
                                    onChange={val => this.setState({chartValues: val})}
                                    minLines={5}
                                    maxLines={100}
                                    placeholder={`ingress:
  enabled: false
...`} />
                        <Checkbox checked={this.state.chartInsecure}
                                  onChange={e => this.setState({chartInsecure: e.target.checked})}>
                            Skip TLS certificate checks for chart download
                        </Checkbox>
                    </TabPane>
                    <TabPane tab="bash" key="3">
                        <Description>Loft will deploy a container into the target space or vcluster and execute the following bash script. For more information please take a look at the <a href={"https://loft.sh/docs"} target={"_blank"}>loft documentation</a></Description>
                        <Label>Script to execute</Label>
                        <YAMLEditor value={this.state.bashScript}
                                    language={"sh"}
                                    minLines={20}
                                    maxLines={100}
                                    onChange={val => this.setState({bashScript: val})} placeholder={`# These commands are executed in a shell within the target space or virtual cluster
kubectl apply -f https://path.to.my.yamls/test.yaml

# You can use kubectl or helm or other commands here
helm install my-release myrepo/mychart

# Or install new commands with apk
apk update && apk add ...`} />
                        <Description>This is the shell script to execute within the target space or virtual cluster.</Description>
                        <SectionExpander name={"Manifests For Bash Job Execution"}>
                            <Label>Manifests used to create the target pod</Label>
                            <YAMLEditor value={this.state.bashManifests}
                                        minLines={10}
                                        maxLines={100}
                                        onChange={val => this.setState({bashManifests: val})} />
                            <Description>These manifests are used to create the bash job within the target space or virtual cluster.</Description>
                        </SectionExpander>
                    </TabPane>``
                </Tabs>
            <div className={styles["margin-top"]}>
                <Checkbox onChange={e => this.setState({wait: e.target.checked})} checked={this.state.wait}>Wait for deployed resources</Checkbox>
                <Description>If enabled Loft will wait during install and upgrading of this App for the resources to become ready.</Description>
            </div>
        </Section>
    }
}