import React, {useRef} from "react";
import Metadata from "../../../../../components/Drawer/Sections/Metadata/Metadata";
import ItemDrawer, {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../../contexts/drawer/DrawerContext";
import AccountOwner from "./Sections/AccountOwner";
import {ClusterAccount} from "../Accounts";
import constants from "../../../../../constants/constants";
import AccountSpaceCreation from "./Sections/AccountSpaceCreation";
import {NewResource, Resources} from "../../../../../lib/resources";
import client from "../../../../../lib/client";
import {ErrorTypeNotFound, ResultError, Return} from "../../../../../lib/result";
import ClientMessage from "../../../../../lib/Message/ClientMessage";

export interface AccountDrawerProps extends SectionProps {
    cluster: string;
    allAccounts: ClusterAccount[];

    account?: ClusterAccount;
    accounts?: ClusterAccount[];
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<AccountDrawerProps, "mode"|"refetch"|"allAccounts"> & {accountOwnerRef: AccountOwner, metadataRef: Metadata, accountSpaceCreationRef: AccountSpaceCreation};

async function onCreate({cluster, accountOwnerRef, metadataRef, accountSpaceCreationRef}: ChangeFunctionProps): Promise<ResultError> {
    // make sure we have an account object
    let account = NewResource(Resources.ConfigV1alpha1Account, undefined, {spec: {
        space: {
            clusterRole: "loft-cluster-space-admin",
        }
    }});

    // apply account owner
    const applyOwnerResult = await accountOwnerRef.create(account);
    if (applyOwnerResult.err) {
        return applyOwnerResult;
    }

    // apply account metadata
    const applyMetadataResult = await metadataRef.create(account,async metadata => {
        // check if there is an account with the owner name
        const ownerName = metadata!.labels![constants.UserLabel] ? metadata!.labels![constants.UserLabel] : metadata!.labels![constants.TeamLabel];
        const result = await client.cluster(cluster, Resources.ConfigV1alpha1Account).Get(ownerName);
        if (result.err) {
            if (result.val.type !== ErrorTypeNotFound) {
                return result;
            }

            metadata.name = ownerName;
        } else {
            metadata.generateName = ownerName + "-";
        }

        return Return.Ok();
    });
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply space creation ref
    const applySpaceCreationResult = accountSpaceCreationRef.create(account);
    if (applySpaceCreationResult.err) {
        return applySpaceCreationResult;
    }

    // create account
    const createAccountResult = await client.cluster(cluster, Resources.ConfigV1alpha1Account).Create(account);
    if (createAccountResult.err) {
        return createAccountResult;
    }

    return Return.Ok();
}

async function onUpdate({account: originalAccount, cluster, accountOwnerRef, metadataRef, accountSpaceCreationRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalAccount) {
        return Return.Ok();
    }

    // make sure the team object is up to date
    const getAccountResult = await client.cluster(cluster, Resources.ConfigV1alpha1Account).Get(originalAccount.account!.metadata?.name!);
    if (getAccountResult.err) {
        return getAccountResult;
    }

    // Set account
    let account = getAccountResult.val;

    // apply account owner
    const applyOwnerResult = await accountOwnerRef.update(account);
    if (applyOwnerResult.err) {
        return applyOwnerResult;
    }

    // apply account metadata
    const applyMetadataResult = await metadataRef.update(account);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply space creation ref
    const applySpaceCreationResult = accountSpaceCreationRef.update(account);
    if (applySpaceCreationResult.err) {
        return applySpaceCreationResult;
    }

    // update account
    const updateAccountResult = await client.cluster(cluster, Resources.ConfigV1alpha1Account).Update(account.metadata?.name!, account!);
    if (updateAccountResult.err) {
        return updateAccountResult;
    }

    return Return.Ok();
}

async function onBatch({accounts: originalAccounts, cluster, metadataRef, accountSpaceCreationRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalAccounts) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the team has changed meanwhile
    const listResult = await client.cluster(cluster, Resources.ConfigV1alpha1Account).List();
    if (listResult.err) {
        return listResult;
    }

    // assign the new spaces
    const accounts = listResult.val.items.filter(account => originalAccounts!.find(oldAccount => oldAccount.account?.metadata?.name === account.metadata?.name));

    // apply account metadata
    const applyMetadataResult = await metadataRef.batch(accounts);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply space creation ref
    const applySpaceCreationResult = accountSpaceCreationRef.batch(accounts);
    if (applySpaceCreationResult.err) {
        return applySpaceCreationResult;
    }

    // update accounts
    for (let i = 0; i < accounts.length; i++) {
        const account = accounts[i];
        const updateAccountResult = await client.cluster(cluster, Resources.ConfigV1alpha1Account).Update(account.metadata?.name!, account!);
        if (updateAccountResult.err) {
            return updateAccountResult;
        }

        accounts[i] = updateAccountResult.val;
    }

    return Return.Ok();
}

export default function AccountDrawer(props: AccountDrawerProps) {
    const drawer = useDrawerDispatcher();
    const accountOwnerRef = useRef<AccountOwner>(null);
    const metadataRef = useRef<Metadata>(null);
    const accountSpaceCreationRef = useRef<AccountSpaceCreation>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading(props.cluster);

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({cluster: props.cluster, accountOwnerRef: accountOwnerRef.current!, metadataRef: metadataRef.current!, accountSpaceCreationRef: accountSpaceCreationRef.current!});
        } else if (props.mode === "update") {
            result = await onUpdate({account: props.account, cluster: props.cluster, accountOwnerRef: accountOwnerRef.current!, metadataRef: metadataRef.current!, accountSpaceCreationRef: accountSpaceCreationRef.current!});
        } else if (props.mode === "batch") {
            result = await onBatch({accounts: props.accounts, cluster: props.cluster, accountOwnerRef: accountOwnerRef.current!, metadataRef: metadataRef.current!, accountSpaceCreationRef: accountSpaceCreationRef.current!});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorCluster(result, props.cluster);
            return;
        }

        // refetch
        await props.refetch();

        message.DoneCluster(props.cluster);

        // close drawer
        drawer({});
    }}>
        <AccountOwner cluster={props.cluster} mode={props.mode} owner={props.account ? {isTeam: props.account.isTeam, info: props.account.member.info!} : undefined} ref={accountOwnerRef} />
        <Metadata mode={props.mode} type={"Account"} obj={props.account?.account} ref={metadataRef} filterLabels={[constants.UserLabel, constants.TeamLabel]} foldable={true} defaultFolded={true} />
        <AccountSpaceCreation mode={props.mode} cluster={props.cluster} accounts={props.allAccounts} account={props.account} ref={accountSpaceCreationRef} />
    </ItemDrawer>
}