import React from "react";
import {useRouteMatch} from "react-router-dom";
import VClusterHeader from "../VClusterHeader/VClusterHeader";
import EventsTable from "../../../Spaces/Spaces/Events/EventsTable/EventsTable";

export default function Events() {
    const match = useRouteMatch();
    const {cluster, namespace, vcluster} = match.params as any;
    return <EventsTable vCluster={{cluster, namespace, name: vcluster}}
                        top={<VClusterHeader />} />
}