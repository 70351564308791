import React from "react";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import ActivityTable from "../../../Audit/Activity/ActivityTable";
import {useRouteMatch} from "react-router-dom";
import Description from "../../../../components/Description/Description";

interface Props {
}

export default function ClusterActivity(props: Props) {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    return <ActivityTable top={<ClusterHeader>
        <Description.Table>Shows the user and team activity inside this connected cluster depending on your audit configuration.</Description.Table>
    </ClusterHeader>} hideTarget={true} target={"cluster:"+cluster} />
};
