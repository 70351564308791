import React, {useRef} from "react";
import {ManagementV1App} from "../../../../gen/models/managementV1App";
import {ResultError, Return} from "../../../lib/result";
import Metadata from "../../../components/Drawer/Sections/Metadata/Metadata";
import ItemDrawer, {SectionProps} from "../../../components/Drawer/ItemDrawer";
import {Resources} from "../../../lib/resources";
import client from "../../../lib/client";
import {useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../lib/Message/ClientMessage";
import AppInfo from "./Sections/AppInfo";
import AppResources from "./Sections/AppResources";
import Access from "../../../components/Drawer/Sections/Access/Access";
import {useUser} from "../../../contexts/UserContext/UserContext";
import Query from "../../../components/Query/Query";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import {genericOnCreate, genericOnUpdate} from "../../../lib/helpers/drawerhelper";
import AppParameters from "./Sections/AppParameters";

export interface AppDrawerProps extends SectionProps {
    app?: ManagementV1App;
    allApps: ManagementV1App[];
    
    refetch: () => Promise<void>;
}

export default function AppDrawer(props: AppDrawerProps) {
    const drawer = useItemDrawer();
    const user = useUser();
    const metadataRef = useRef<Metadata>(null);
    const appInfoRef = useRef<AppInfo>(null);
    const appResourcesRef = useRef<AppResources>(null);
    const appParametersRef = useRef<AppParameters>(null);
    const accessRef = useRef<Access>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await genericOnCreate({
                resource: Resources.ManagementV1App,
                sections: [
                    async (obj) => metadataRef.current!.create(obj, async metadata => {
                        metadata.generateName = "app-";
                        return Return.Ok();
                    }),
                    appInfoRef.current!.create,
                    appParametersRef.current!.create,
                    appResourcesRef.current!.create,
                    accessRef.current!.create,
                ],
                defaultData: {
                    spec: {}
                }
            });
        } else if (props.mode === "update") {
            result = await genericOnUpdate({
                existing: props.app!,
                resource: Resources.ManagementV1App,
                sections: [
                    metadataRef.current!.update,
                    appInfoRef.current!.update,
                    appParametersRef.current!.update,
                    appResourcesRef.current!.update,
                    accessRef.current!.update,
                ],
            });
        }

        // check if there was an error
        if (result?.err) {
            message.Error(result);
            return;
        }

        // refetch
        await props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"App"} obj={props.app} showDisplayName={true} showDescription={true} noMargin ref={metadataRef} />
        <AppResources mode={props.mode} app={props.app} allApps={props.allApps} ref={appResourcesRef} />
        <AppParameters mode={props.mode} app={props.app} ref={appParametersRef} />
        <AppInfo mode={props.mode} app={props.app} ref={appInfoRef} />
        <Query query={async () => await client.management(Resources.ManagementV1User).CanI("bind")} skip={!!props.app}>
            {
                result => {
                    if (result.error) {
                        return <ErrorMessage error={result.error} />
                    } else if (result.loading) {
                        return null;
                    }

                    return <Access mode={props.mode} kind={"App"} user={user} defaultRules={result.data ? [
                        {
                          users: [user?.metadata?.name!],
                          subresources: ["*"],
                          verbs: ["*"]  
                        },
                        {
                          users: ["*"], 
                          subresources: ["*"], 
                          verbs: ["get"]
                        }
                    ] : undefined} access={props.app} ref={accessRef} />
                }
            }
        </Query>
    </ItemDrawer>
}
