import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../components/Drawer/ItemDrawer";
import {ManagementV1SharedSecret} from "../../../../gen/models/managementV1SharedSecret";
import Metadata from "../../../components/Drawer/Sections/Metadata/Metadata";
import {ResultError, Return} from "../../../lib/result";
import {Resources} from "../../../lib/resources";
import {useDrawerDispatcher} from "../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../lib/Message/ClientMessage";
import Access, {VerbMapping} from "../../../components/Drawer/Sections/Access/Access";
import {useUser} from "../../../contexts/UserContext/UserContext";
import {genericOnBatch, genericOnCreate, genericOnUpdate} from "../../../lib/helpers/drawerhelper";
import {arr} from "../../../lib/helpers/renderhelper";
import SecretData from "./Sections/SecretData";
import {ManagementV1SpaceConstraint} from "../../../../gen/models/managementV1SpaceConstraint";

export interface SecretsDrawerProps extends SectionProps {
    secret?: ManagementV1SharedSecret;
    secrets?: Array<ManagementV1SharedSecret>;
    
    namespace?: string;
    forceNamespace?: boolean;
    refetch: () => Promise<void>;
}

const defaultVerbMapping: VerbMapping[] = [
    {
        key: "*",
        text: "All"
    },
    {
        key: "get",
        text: "view"
    },
    {
        key: "update",
        text: "update"
    },
    {
        key: "delete",
        text: "delete"
    },
    {
        key: "bind",
        text: "bind"
    },
    {
        key: "sync",
        text: "sync"
    },
]

export default function SecretsDrawer(props: SecretsDrawerProps) {
    const drawer = useDrawerDispatcher();
    const user = useUser();
    const metadataRef = useRef<Metadata>(null);
    const accessRef = useRef<Access>(null);
    const secretDataRef = useRef<SecretData>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await genericOnCreate<ManagementV1SharedSecret>({
                resource: Resources.ManagementV1SharedSecret,
                sections: [
                    metadataRef.current!.create,
                    secretDataRef.current!.create,
                    accessRef!.current!.create,
                ],
                defaultData: {
                    spec: {}
                }
            });
        } else if (props.mode === "update") {
            result = await genericOnUpdate<ManagementV1SharedSecret>({
                existing: props.secret!,
                resource: Resources.ManagementV1SharedSecret,
                sections: [
                    metadataRef.current!.update,
                    secretDataRef.current!.update,
                    accessRef!.current!.update,
                ],
            });
        } else if (props.mode === "batch") {
            result = await genericOnBatch<ManagementV1SharedSecret>({
                existings: arr(props.secrets),
                resource: Resources.ManagementV1SharedSecret,
                sections: [
                    metadataRef.current!.batch,
                    accessRef!.current!.batch,
                ],
            });
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorManagement(result);
            return;
        }

        // refetch
        await props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Shared Secret"} obj={props.secret ? props.secret : {metadata: {namespace: props.namespace}}} showDisplayName={true} showDescription={true} ref={metadataRef} namespaced={true} forceNamespace={props.forceNamespace} noMargin={true} />
        <SecretData mode={props.mode} secret={props.secret} ref={secretDataRef} />
        <Access mode={props.mode} kind={"Shared Secret"} user={user} access={props.secret} allowedVerbs={defaultVerbMapping} ref={accessRef} />
    </ItemDrawer>
}