import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {useItemDrawer} from "../../../../../contexts/drawer/DrawerContext";
import Metadata from "../../../../../components/Drawer/Sections/Metadata/Metadata";
import {ConfigV1alpha1Template} from "../../../../../../gen/models/configV1alpha1Template";
import TemplateSpec from "./Sections/TemplateSpec";
import ClientMessage from "../../../../../lib/Message/ClientMessage";
import {ResultError, Return} from "../../../../../lib/result";
import {NewResource, Resources} from "../../../../../lib/resources";
import client from "../../../../../lib/client";

export interface TemplateDrawerProps extends SectionProps {
    cluster: string;
    allTemplates: ConfigV1alpha1Template[];

    template?: ConfigV1alpha1Template;
    templates?: ConfigV1alpha1Template[];

    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<TemplateDrawerProps, "mode"|"allTemplates"|"refetch"> & {metadataRef: Metadata, templateSpecRef: TemplateSpec};

async function onCreate({cluster, metadataRef, templateSpecRef}: ChangeFunctionProps): Promise<ResultError> {
    // make sure we have an object
    let template = NewResource(Resources.ConfigV1alpha1Template, undefined, {});

    // apply metadata
    const applyMetadataResult = await metadataRef.create(template, async metadata => {
        metadata.generateName = "template-";
        return Return.Ok();
    });
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply spec
    const applySpec = await templateSpecRef.create(template);
    if (applySpec.err) {
        return applySpec;
    }

    // create
    const createResult = await client.cluster(cluster, Resources.ConfigV1alpha1Template).Create(template);
    if (createResult.err) {
        return createResult;
    }

    // set to the correct template
    template = createResult.val;

    // update secrets
    const updateSecretsResult = await templateSpecRef.updateSecrets(template);
    if (updateSecretsResult.err) {
        return updateSecretsResult;
    }

    return Return.Ok();
}

async function onUpdate({template: originalTemplate, cluster, metadataRef, templateSpecRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalTemplate) {
        return Return.Ok();
    }

    // make sure the object is up to date
    const getResult = await client.cluster(cluster, Resources.ConfigV1alpha1Template).Get(originalTemplate.metadata?.name!);
    if (getResult.err) {
        return getResult;
    }

    // set object
    let template = getResult.val;

    // apply metadata
    const applyMetadataResult = await metadataRef.update(template);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply spec
    const applySpec = await templateSpecRef.update(template);
    if (applySpec.err) {
        return applySpec;
    }

    // update
    const updateResult = await client.cluster(cluster, Resources.ConfigV1alpha1Template).Update(template.metadata?.name!, template!);
    if (updateResult.err) {
        return updateResult;
    }

    // update secrets
    const updateSecretsResult = await templateSpecRef.updateSecrets(template);
    if (updateSecretsResult.err) {
        return updateSecretsResult;
    }

    return Return.Ok();
}

async function onBatch({templates: originalTemplates, cluster, metadataRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalTemplates) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the team has changed meanwhile
    const listResult = await client.cluster(cluster, Resources.ConfigV1alpha1Template).List();
    if (listResult.err) {
        return listResult;
    }

    // assign
    const templates = listResult.val.items.filter(newObj => originalTemplates!.find(old => old.metadata?.name === newObj.metadata?.name));

    // apply metadata
    const applyMetadataResult = await metadataRef.batch(templates);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // update objects
    for (let i = 0; i < templates.length; i++) {
        const obj = templates[i];
        const updateResult = await client.cluster(cluster, Resources.ConfigV1alpha1Template).Update(obj.metadata?.name!, obj!);
        if (updateResult.err) {
            return updateResult;
        }

        templates[i] = updateResult.val;
    }

    return Return.Ok();
}

export default function TemplateDrawer(props: TemplateDrawerProps) {
    const drawer = useItemDrawer();
    const metadataRef = useRef<Metadata>(null);
    const templateSpecRef = useRef<TemplateSpec>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading(props.cluster);

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({cluster: props.cluster, metadataRef: metadataRef.current!, templateSpecRef: templateSpecRef.current!});
        } else if (props.mode === "update") {
            result = await onUpdate({template: props.template, cluster: props.cluster, metadataRef: metadataRef.current!, templateSpecRef: templateSpecRef.current!});
        } else if (props.mode === "batch") {
            result = await onBatch({templates: props.templates, cluster: props.cluster, metadataRef: metadataRef.current!, templateSpecRef: templateSpecRef.current!});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorCluster(result, props.cluster);
            return;
        }

        // refetch
        await props.refetch();

        message.DoneCluster(props.cluster);

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Template"} obj={props.template} noMargin ref={metadataRef} />
        <TemplateSpec mode={props.mode} cluster={props.cluster} template={props.template} allTemplates={props.allTemplates} ref={templateSpecRef} />
    </ItemDrawer>
}
