import React, {useState} from "react";
import styles from "./License.module.scss";
import AdminHeader from "../AdminHeader/AdminHeader";
import {Space} from "antd";
import {DeleteOutlined, EyeOutlined, ToolOutlined} from "@ant-design/icons/lib";
import Label from "../../../components/Label/Label";
import LabeledValue from "../../../components/LabeledValue/LabeledValue";
import Button from "../../../components/Button/Button";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import Query from "../../../components/Query/Query";
import Loading from "../../../components/Loading/Loading";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import {Return} from "../../../lib/result";
import {findGetParameter, formatTime, timeAgo, Wait} from "../../../lib/helper";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {arr} from "../../../lib/helpers/renderhelper";
import AsyncButton from "../../../components/AsyncButton/AsyncButton";
import {ManagementV1License} from "../../../../gen/models/managementV1License";
import {alert} from "../../../lib/Modal/Modal";
import {useLicense, useLicenseRefresh} from "../../../contexts/LicenseContext/LicenseContext";

export function openLicenseLink(license: ManagementV1License, name: string, token: string) {
    // find link
    const link = license.status?.info?.links?.[name];
    if (!link) {
        alert({
            title: `An Error Occurred`,
            content: `Couldn't find link ${name}`,
        });
        return;
    }

    // replace token and instance and open in new tab
    window.open(
        link.replace("{INSTANCE}", license.status?.instance!).replace("{TOKEN}", token).replace("{CALLBACK_URL}", encodeURIComponent(window.location.protocol + "//" + window.location.host + "/admin/license?refresh=true")),
        '_blank'
    );
}

export default function License() {
    const [hideLicense, setHideLicense] = useState(true);
    const licenseState = useLicense();
    const licenseRefresh = useLicenseRefresh();

    return <div className={styles["admin-wrapper"]}>
        <AdminHeader />
        <Query query={async () => {
            const refresh = findGetParameter("refresh");
            if (refresh && licenseRefresh) {
                await licenseRefresh();
            }

            return Return.Ok();
        }}>
            {
                result => {
                    if (result.loading) {
                        return <Loading />;
                    } else if (result.error) {
                        return <ErrorMessage error={result.error} />;
                    } else if (!licenseState || !licenseState.license) {
                        return <Loading />;
                    }

                    const license = licenseState.license;
                    const licenseKey = license.status?.info?.license;
                    const restoreLicenseKeyLink = license.status?.info?.links?.["restoreLicenseKey"];
                    const created = license.status?.info?.subscription?.created ? license.status.info.subscription.created * 1000 : new Date().getTime();
                    const userLimit = license.status?.info?.resourceLimits?.find(limit => limit.kind === "User")?.limit || -1;
                    const teamLimit = license.status?.info?.resourceLimits?.find(limit => limit.kind === "Team")?.limit || -1;
                    const clusterLimit = license.status?.info?.resourceLimits?.find(limit => limit.kind === "Cluster")?.limit || -1;
                    const vClusterLimit = license.status?.info?.resourceLimits?.find(limit => limit.kind === "VirtualCluster")?.limit || -1;

                    const features = license.status?.info?.features || {};
                    let featuresLimited = false;
                    const featuresLabels = Object.keys(features).sort(function(a,b){return features[a] ? -1 : 1}).map((featureName, _) => {
                        const featureValue = features[featureName];
                        if (!featureValue) {
                            featuresLimited = true
                        }
                        return  <LabeledValue key={featureName} label={featureName.replace(/([A-Z])/g, " $1")}>
                            {featureValue ? "enabled" : <span className="color-error text-bold">disabled</span>}
                        </LabeledValue>
                    });
                    return <Space size={40} direction={"vertical"}>
                        <div>
                            <Label.Bold>Instance &amp; License</Label.Bold>
                            <Space size={40} align={"start"}>
                                <LabeledValue label={"Instance ID"}>
                                    {license.status?.instance}
                                </LabeledValue>
                                <LabeledValue label={"Registered"}>
                                    <Tooltip title={formatTime(created)}>
                                        {timeAgo(created)}
                                    </Tooltip>
                                </LabeledValue>
                            </Space>
                            <div className={styles["margin-top"]}>
                                <LabeledValue label={"License Key"}>
                                    {!licenseKey ? "-" : hideLicense ? licenseKey.substring(0, 8) + "-XXXX-XXXX-XXXX-XXXXXXXXXXXX" : licenseKey}
                                </LabeledValue>
                            </div>
                            <div className={styles["margin-top"]}>
                                <Space className={styles["margin-top"]} size={10} align={"start"}>
                                    {licenseRefresh && <AsyncButton type={"primary"} onClickAsync={async () => await licenseRefresh()}><ToolOutlined />Refresh License</AsyncButton>}
                                    {restoreLicenseKeyLink && <AsyncButton type={"primary"} onClickAsync={async () => await openLicenseLink(license, "restoreLicenseKey", licenseState?.token?.status?.token!)}><ToolOutlined />Restore License Key</AsyncButton>}
                                    <AsyncButton type={"primary"} onClickAsync={async () => await openLicenseLink(license, "changeLicenseKey", licenseState?.token?.status?.token!)}><ToolOutlined />{licenseKey ? "Change" : "Enter"} License Key</AsyncButton>
                                    {licenseKey && <Button onClick={() => setHideLicense(!hideLicense)}><EyeOutlined />{hideLicense ? "Show" : "Hide"} License Key</Button>}
                                </Space>
                            </div>
                        </div>
                        <div>
                            <Label.Bold>Subscription</Label.Bold>
                            <Space size={40} align={"start"}>
                                <LabeledValue label={"Plan"}>
                                    {license.status?.info?.plan?.product?.name}
                                </LabeledValue>
                                <LabeledValue label={"License Fee"}>
                                    {license.status?.info?.plan?.currency.toUpperCase()}&nbsp;{((license.status?.info?.plan?.price || 0)/100).toFixed(2)}&nbsp;/&nbsp;{license.status?.info?.plan?.interval}
                                </LabeledValue>
                                <LabeledValue label={"Status"}>
                                    {license.status?.info?.subscription?.status}
                                </LabeledValue>
                            </Space>
                            <div className={styles["margin-top"]}>
                                <Space className={styles["margin-top"]} size={10} align={"start"}>
                                    <AsyncButton type={"primary"} onClickAsync={async () => await openLicenseLink(license, "switchPlan", licenseState?.token?.status?.token!)}><ToolOutlined />Change Plan</AsyncButton>
                                    {license.status?.info?.links?.cancelSubscriptionItem && <AsyncButton onClickAsync={async () => await openLicenseLink(license, "cancelSubscriptionItem", licenseState?.token?.status?.token!)}><DeleteOutlined />Cancel Plan</AsyncButton>}
                                </Space>
                            </div>
                        </div>
                        <div>
                            <Label.Bold>Limits</Label.Bold>
                            <Space size={40} align={"start"}>
                                <LabeledValue label={"Users"}>
                                    {userLimit === -1 ? "unlimited" : <span>
                                        <Query query={async () => {
                                            const listResult = await client.management(Resources.ManagementV1User).List();
                                            if (listResult.err) {
                                                return listResult;
                                            }
                                            
                                            listResult.val.items = listResult.val.items.filter(user => !user.spec?.disabled);
                                            return listResult;
                                        }}>
                                            {result => (result.loading || result.error) ? <span>?/{userLimit} users</span> : <span className={arr(result.data?.items).length >= userLimit ? "color-error text-bold" : arr(result.data?.items).length >= userLimit*0.8 ? "color-warning" : undefined}>{arr(result.data?.items).length}/{userLimit} users</span>}
                                        </Query>
                                    </span>}
                                </LabeledValue>
                                <LabeledValue label={"Teams"}>
                                    {teamLimit === -1 ? "unlimited" : <span>
                                        <Query query={async () => await client.management(Resources.ManagementV1Team).List()}>
                                            {result => (result.loading || result.error) ? <span>?/{teamLimit} teams</span> : <span className={arr(result.data?.items).length >= teamLimit ? "color-error text-bold" : arr(result.data?.items).length >= teamLimit*0.8 ? "color-warning" : undefined}>{arr(result.data?.items).length}/{teamLimit} teams</span>}
                                        </Query>
                                    </span>}
                                </LabeledValue>
                                <LabeledValue label={"Clusters"}>
                                    {clusterLimit === -1 ? "unlimited" : <span>
                                        <Query query={async () => await client.management(Resources.ManagementV1Cluster).List()}>
                                            {result => (result.loading || result.error) ? <span>?/{clusterLimit} clusters</span> : <span className={arr(result.data?.items).length >= clusterLimit ? "color-error text-bold" : arr(result.data?.items).length >= clusterLimit*0.8 ? "color-warning" : undefined}>{arr(result.data?.items).length}/{clusterLimit} clusters</span>}
                                        </Query>
                                    </span>}
                                </LabeledValue>
                                <LabeledValue label={"Virtual Clusters"}>
                                    {vClusterLimit === -1 ? "unlimited" : <span>
                                        <span>{vClusterLimit} Virtual Clusters</span>
                                    </span>}
                                </LabeledValue>
                            </Space>
                            <div className={styles["margin-top"]}>
                                {license.status?.info?.links?.changeQuantity && <Space className={styles["margin-top"]}size={10} align={"start"}>
                                      <AsyncButton type={"primary"} onClickAsync={async () => await openLicenseLink(license, "changeQuantity", licenseState?.token?.status?.token!)}><ToolOutlined />Change User Limit</AsyncButton>
                                </Space>}
                            </div>
                        </div>
                        {features && Object.keys(features).length > 0 && <div>
                            <Label.Bold>Features</Label.Bold>
                            <Space size={40} align={"start"}>
                                {featuresLabels}
                            </Space>
                            {featuresLimited && <div className={styles["margin-top"]}>
                                <Space className={styles["margin-top"]} size={10} align={"start"}>
                                      <AsyncButton type={"primary"} onClickAsync={async () => await openLicenseLink(license, "switchPlan", licenseState?.token?.status?.token!)}><ToolOutlined />Upgrade</AsyncButton>
                                </Space>
                            </div>}
                        </div>}
                    </Space>
                }
            }
        </Query>
    </div>
}
