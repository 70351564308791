import React from "react";
import {
    BlockOutlined,
    CopyOutlined,
} from "@ant-design/icons/lib";
import { useRouteMatch, useHistory } from "react-router-dom";
import Header from "../../../components/Header/Header";

interface SpacesHeaderProps {

}

export default function SpacesHeader(props: React.PropsWithChildren<SpacesHeaderProps>) {
    const routeMatch = useRouteMatch<{cluster: string}>();
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1];

    return <Header title={"Space Management"} selectedKey={selectedKey} items={[
        {
            key: "spaces",
            url: `/spaces`,
            content: <React.Fragment>
                <BlockOutlined />Spaces
            </React.Fragment>
        },
        {
            key: "spacetemplates",
            url: `/spacetemplates`,
            content: <React.Fragment>
                <CopyOutlined />Space Templates
            </React.Fragment>
        },
    ]}>
        {props.children}
    </Header>
}