import React from "react";
import useQuery from "../../../lib/Query/Query";
import {useUser} from "../../../contexts/UserContext/UserContext";
import AccessKeysTable from "../AccessKeysTable/AccessKeysTable";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import Description from "../../../components/Description/Description";

export default function AccessKeys() {
    const user = useUser();
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1UserAccessKeys).Get(user?.metadata?.name!));
    return <AccessKeysTable error={error} loading={loading} accessKeys={data ? data.accessKeys : undefined} user={user?.metadata?.name} top={<ProfileHeader>
        <Description.Table>
            Access Keys are a way to log into the Loft CLI without specifying a password. You also see all currently active access keys in this view created automatically by the Loft UI or CLI.
        </Description.Table>
    </ProfileHeader>} refetch={refetch} />
}