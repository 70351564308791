import React, {useState} from "react";
import {
    V1ClusterRole,
    V1ClusterRoleBinding,
    V1Role,
    V1RoleBinding
} from "@kubernetes/client-node";
import {Link, useRouteMatch} from "react-router-dom";
import Select from "../../../../components/Select/Select";
import {arr, removeDuplicateFilters, selectDefaultFilter} from "../../../../lib/helpers/renderhelper";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import useQuery from "../../../../lib/Query/Query";
import {ErrorTypeNotFound, Result, Return} from "../../../../lib/result";
import Table, {TableActions} from "../../../../components/Table/Table";
import {creationTimestampSorter, stringSorter} from "../../../../lib/helpers/sorthelper";
import FixedText from "../../../../components/FixedText/FixedText";
import styles from "./PermissionsTable.module.scss";
import {ManagementV1ClusterAccounts} from "../../../../../gen/models/managementV1ClusterAccounts";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined} from "@ant-design/icons";
import ShowYamlPopup, {ShowYamlModal, ShowYamlPopupProps} from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import {deleteConfirm} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import {ManagementV1ClusterRoleTemplate} from "../../../../../gen/models/managementV1ClusterRoleTemplate";
import constants from "../../../../constants/constants";
import {displayName, formatURIComponent} from "../../../../lib/helper";
import Description from "../../../../components/Description/Description";
const Option = Select.Option;

interface ExtendedShowYamlPopupProps<T> extends ShowYamlPopupProps<T> {
    text: string;
}

function ExtendedShowYamlPopup<T>(props: Omit<ExtendedShowYamlPopupProps<T>, "onClose">) {
    const [visible, setVisible] = useState<boolean>(false);

    return <React.Fragment>
        <FixedText className={"clickable-link color-primary"} text={props.text} onClick={() => setVisible(true)} />
        {visible && <ShowYamlModal {...props} onClose={() => setVisible(false)} />}
    </React.Fragment>
}

function filterTable(item: Match, value: string) {
    return !!(item.roleBinding?.metadata?.namespace?.includes(value) ||
        item.roleBinding?.metadata?.name?.includes(value) ||
        item.role?.metadata?.namespace?.includes(value) ||
        item.role?.metadata?.name?.includes(value) ||
        item.clusterRoleBinding?.metadata?.name?.includes(value) ||
        item.clusterRole?.metadata?.name?.includes(value) ||
        item.group?.includes(value) ||
        item.subject?.includes(value) ||
        item.displayName?.includes(value))
}

interface Match {
    key: string;
    namespace?: string;
    
    clusterRoleBinding?: V1ClusterRoleBinding,
    clusterRole?: V1ClusterRole,
    globalClusterRole?: ManagementV1ClusterRoleTemplate,
    displayName?: string;

    role?: V1Role,
    roleBinding?: V1RoleBinding,

    subject?: string;
    group?: string;
}

async function getPermissions(cluster: string | undefined, users: string[], groups: string[], globalClusterRoles: ManagementV1ClusterRoleTemplate[]): Promise<Result<Match[]>> {
    if (!cluster || arr(users).length + arr(groups).length === 0) {
        return Return.Value([]);
    }
    
    // find matching cluster role bindings & role bindings
    const clusterRoleBindings = await client.cluster(cluster, Resources.V1ClusterRoleBinding).List();
    if (clusterRoleBindings.err) {
        return clusterRoleBindings;
    }
    const roleBindings = await client.cluster(cluster, Resources.V1RoleBinding).List();
    if (roleBindings.err) {
        return roleBindings;
    }

    const matches: Match[] = [];

    // Cluster Role Bindings
    arr(clusterRoleBindings.val.items).forEach(clusterRoleBinding => {
        if (clusterRoleBinding.roleRef.kind !== "ClusterRole" || clusterRoleBinding.roleRef.apiGroup !== "rbac.authorization.k8s.io") {
            return;
        }

        arr(clusterRoleBinding.subjects).forEach(subject => {
            if (subject.kind === "Group" && subject.apiGroup === "rbac.authorization.k8s.io" && groups.includes(subject.name)) {
                matches.push({
                    key: clusterRoleBinding.metadata?.name!,
                    clusterRoleBinding,
                    group: subject.name,
                })
            } else if (subject.kind === "User" && subject.apiGroup === "rbac.authorization.k8s.io" && users.includes(subject.name)) {
                matches.push({
                    key: clusterRoleBinding.metadata?.name!,
                    clusterRoleBinding,
                    subject: subject.name,
                })
            }
        });
    });

    // Role Bindings
    arr(roleBindings.val.items).forEach(roleBinding => {
        if ((roleBinding.roleRef.kind !== "ClusterRole" && roleBinding.roleRef.kind !== "Role") || roleBinding.roleRef.apiGroup !== "rbac.authorization.k8s.io") {
            return;
        }

        arr(roleBinding.subjects).forEach(subject => {
            if (subject.kind === "Group" && subject.apiGroup === "rbac.authorization.k8s.io" && groups.includes(subject.name)) {
                matches.push({
                    key: roleBinding.metadata?.namespace! + "/" + roleBinding.metadata?.name!,
                    namespace: roleBinding.metadata?.namespace!,
                    roleBinding,
                    group: subject.name,
                })
            } else if (subject.kind === "User" && subject.apiGroup === "rbac.authorization.k8s.io" && users.includes(subject.name)) {
                matches.push({
                    key: roleBinding.metadata?.namespace! + "/" + roleBinding.metadata?.name!,
                    namespace: roleBinding.metadata?.namespace!,
                    roleBinding,
                    subject: subject.name,
                })
            }
        });
    });

    // Fill matched cluster roles & roles
    let clusterRoles: {[key: string]: Match[]} = {};
    let roles: {[key: string]: Match[]} = {};
    matches.forEach(match => {
        if (match.clusterRoleBinding) {
            if (!clusterRoles[match.clusterRoleBinding.roleRef.name]) {
                clusterRoles[match.clusterRoleBinding.roleRef.name] = [];
            }

            clusterRoles[match.clusterRoleBinding.roleRef.name].push(match);
        } else if (match.roleBinding) {
            if (match.roleBinding.roleRef.kind === "ClusterRole") {
                if (!clusterRoles[match.roleBinding.roleRef.name]) {
                    clusterRoles[match.roleBinding.roleRef.name] = [];
                }

                clusterRoles[match.roleBinding.roleRef.name].push(match);
            } else {
                const key = match.roleBinding?.metadata?.namespace + "/" + match.roleBinding?.roleRef.name;
                if (!roles[key]) {
                    roles[key] = [];
                }

                roles[key].push(match);
            }
        }
    })

    // Query the cluster roles & roles
    const clusterRoleKeys = Object.keys(clusterRoles);
    const clusterRoleList = await client.cluster(cluster, Resources.V1ClusterRole).List();
    if (clusterRoleList.err) {
        return clusterRoleList;
    }
    for (let i = 0; i < clusterRoleKeys.length; i++) {
        const clusterRole = arr(clusterRoleList.val.items).find(clusterRole => clusterRole.metadata?.name === clusterRoleKeys[i])
        if (clusterRole) {
            clusterRoles[clusterRoleKeys[i]].forEach(match => {
                match.clusterRole = clusterRole;
            })
        }
    }
    const roleKeys = Object.keys(roles);
    for (let i = 0; i < roleKeys.length; i++) {
        const splitted = roleKeys[i].split("/");
        const roleResult = await client.cluster(cluster, Resources.V1Role).Namespace(splitted[0]).Get(splitted[1]);
        if (roleResult.err) {
            if (roleResult.val.type !== ErrorTypeNotFound) {
                return roleResult;
            }

            continue
        }

        roles[roleKeys[i]].forEach(match => {
            match.role = roleResult.val;
        })
    }
    
    // fill in global cluster roles
    for (let i = 0; i < matches.length; i++) {
        if (matches[i].clusterRole?.metadata?.labels?.[constants.LoftClusterRoleCluster] || matches[i].clusterRole?.metadata?.labels?.[constants.LoftClusterRoleManagement]) {
            matches[i].globalClusterRole = globalClusterRoles.find(globalClusterRole => globalClusterRole.metadata?.name === matches[i].clusterRole?.metadata?.name);
        }
        
        matches[i].displayName = displayName(matches[i].globalClusterRole) || matches[i].role?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation] || matches[i].role?.metadata?.name || matches[i].clusterRole?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation] || matches[i].clusterRole?.metadata?.name;
    }

    return Return.Value(matches.sort((a, b) => {
        return stringSorter(a.displayName, b.displayName);
    }));
}

function getTableColumns(refetch: () => Promise<void>, cluster: string, matches: Match[]) {
    return [
        {
            title: '(Cluster) Role',
            sorter: (a: Match, b: Match) => {
                return stringSorter(a.role?.metadata?.name || a.clusterRole?.metadata?.name, b.role?.metadata?.name || b.clusterRole?.metadata?.name);
            },
            render: (match: Match) => {
                const name = match.role?.metadata?.name || match.clusterRole?.metadata?.name;
                const namespace = match.role?.metadata?.namespace;
                if (match.globalClusterRole) {
                    if (match.clusterRole?.metadata?.labels?.[constants.LoftClusterRoleCluster]) {
                        return <Link className={"clickable-link color-primary"} to={"/clusters/clusterroles#search="+formatURIComponent(match.displayName!)}>{match.displayName}</Link>
                    }

                    return <Link className={"clickable-link color-primary"} to={"/users/managementroles#search="+formatURIComponent(match.displayName!)}>{match.displayName}</Link>
                }
                
                return <ExtendedShowYamlPopup cluster={cluster} text={match.displayName!} namespace={namespace} name={name} object={match.role || match.clusterRole} resource={match.role ? Resources.V1Role : Resources.V1ClusterRole} refetch={refetch} />;
            }
        },
        {
            title: 'Description',
            render: (match: Match) => {
                if (match.clusterRole?.metadata?.name && ["system:discovery", "system:basic-user", "system:public-info-viewer"].includes(match.clusterRole?.metadata?.name)) {
                    return "Default Kubernetes permissions assigned to every connected user"
                }
                if (match.clusterRole?.metadata?.annotations?.[constants.LoftDescriptionAnnotation]) {
                    return <Description.Column>
                        {match.clusterRole?.metadata?.annotations?.[constants.LoftDescriptionAnnotation]}
                    </Description.Column>
                }
                if (match.role?.metadata?.annotations?.[constants.LoftDescriptionAnnotation]) {
                    return <Description.Column>
                        {match.role?.metadata?.annotations?.[constants.LoftDescriptionAnnotation]}
                    </Description.Column>
                }
                if (!match.globalClusterRole?.spec?.description) {
                    return "";
                }
                return <Description.Column>
                    {match.globalClusterRole?.spec?.description}
                </Description.Column>;
            }
        },
        {
            title: 'Namespace',
            sorter: (a: Match, b: Match) => {
                return stringSorter(a.namespace, b.namespace);
            },
            filters: removeDuplicateFilters(arr(matches).filter(match => !!match.namespace).map(match => ({value: match.namespace + "", text: match.namespace + ""}))),
            onFilter: (value: string | number | boolean, match: Match) => {
                return match.namespace === value;
            },
            render: (match: Match) => {
                return <FixedText text={match.namespace || ""} />
            }
        },
        {
            title: '(Cluster) Role Binding',
            sorter: (a: Match, b: Match) => {
                return stringSorter(a.roleBinding?.metadata?.name || a.clusterRoleBinding?.metadata?.name, b.roleBinding?.metadata?.name || b.clusterRoleBinding?.metadata?.name);
            },
            render: (match: Match) => {
                const name = match.roleBinding?.metadata?.name || match.clusterRoleBinding?.metadata?.name;
                const namespace =  match.roleBinding?.metadata?.namespace;
                return <ExtendedShowYamlPopup cluster={cluster} text={name!} namespace={namespace} name={name} object={match.roleBinding || match.clusterRoleBinding} resource={match.roleBinding ? Resources.V1RoleBinding : Resources.V1ClusterRoleBinding} refetch={refetch} />;
            }
        },
        {
            title: 'Assigned Through',
            sorter: (a: Match, b: Match) => {
                return stringSorter(a.subject || a.group, b.subject || b.group);
            },
            filters: removeDuplicateFilters(arr(matches).map(match => ({value: (match.subject || match.group) + "", text: (match.subject || match.group) + ""}))),
            onFilter: (value: string | number | boolean, match: Match) => {
                return match.subject === value || match.group === value;
            },
            render: (match: Match) => {
                return <FixedText text={match.subject || match.group + " (Group)"} />;
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: Match, b: Match) => creationTimestampSorter(a.roleBinding || a.clusterRoleBinding, b.roleBinding || b.clusterRoleBinding),
            render: (match: Match) => {
                return <DynamicTime timestamp={match.roleBinding?.metadata?.creationTimestamp || match.clusterRoleBinding?.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (match: Match) => {
                const name = match.roleBinding?.metadata?.name || match.clusterRoleBinding?.metadata?.name;
                const namespace = match.roleBinding?.metadata?.namespace;
                return <TableActions className={"actions-wrapper"}>
                    <ShowYamlPopup cluster={cluster} className={"blue-btn"} object={match.roleBinding || match.clusterRoleBinding} resource={match.roleBinding ? Resources.V1RoleBinding : Resources.V1ClusterRoleBinding} namespace={namespace} name={name} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={"actions-delete-btn"} onClick={() => {
                            deleteConfirm({
                                title: `Delete ${match.roleBinding ? "RoleBinding" : "ClusterRoleBinding"}: ${name}`,
                                content: `Are you sure you want to delete the ${match.roleBinding ? "RoleBinding" : "ClusterRoleBinding"} ${name}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading(cluster);
                                    const result = await client.cluster(cluster, match.roleBinding ? Resources.V1RoleBinding : Resources.V1ClusterRoleBinding).Namespace(namespace).Delete(name!);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

interface PermissionsTableProps {
    top: React.ReactNode;
}

export default function PermissionsTable(props: PermissionsTableProps) {
    const match: any = useRouteMatch();
    const [selectedCluster, setSelectedCluster] = useState<string | undefined>(undefined);
    const user = match.params?.user;
    const team = match.params?.team;
    const {error: clustersError, loading: clustersLoading, data: clusters} = useQuery(async () => {
        const clustersResult = await client.management(user ? Resources.ManagementV1UserClusters : Resources.ManagementV1TeamClusters).Get(user ? user : team);
        if (clustersResult.err) {
            return clustersResult;
        }

        const users: string[] = [];
        const groups: string[] = [];
        if (user) {
            // make request to find out groups and subject
            const selfResult = await client.management(Resources.ManagementV1Self).Get(user);
            if (selfResult.err) {
                return selfResult;
            }

            if (selfResult.val?.status?.subject) {
                users.push(selfResult.val?.status?.subject)
            }
            if (arr(selfResult.val?.status?.groups).length > 0) {
                groups.push(...arr(selfResult.val?.status?.groups));
            }
        } else if (team) {
            groups.push("system:authenticated")
            groups.push("loft:authenticated")
            groups.push("loft:team:"+team)
        } else {
            return Return.Failed(`No user or team specified`);
        }
        
        if (arr(clustersResult.val.clusters).length > 0) {
            setSelectedCluster(clustersResult.val.clusters?.[0].cluster?.metadata?.name);
        }

        const clusterRolesResult = await client.management(Resources.ManagementV1ClusterRoleTemplate).List();
        if (clusterRolesResult.err) {
            return clusterRolesResult;
        }
        
        return Return.Value({
            clusterRolesResult: arr(clusterRolesResult.val.items),
            clusters: arr(clustersResult.val.clusters),
            users: users.sort(),
            groups: groups.sort()
        })
    });

    // get rules
    const {loading, error, data, refetch} = useQuery(async () => await getPermissions(selectedCluster, arr(clusters?.users), arr(clusters?.groups), arr(clusters?.clusterRolesResult)), {refetch: [JSON.stringify({
            selected: selectedCluster,
            users: clusters?.users,
            groups: clusters?.groups
        })]});
    return <div>
        <Table loading={loading || clustersLoading} columns={getTableColumns(refetch, selectedCluster!, arr(data))} dataSource={data} error={error || clustersError} filter={filterTable} refetch={refetch} header={{
            top: <div>
                {props.top}
                <Description>This view shows all assigned cluster roles in the selected cluster the user or team has access to.</Description>
            </div>,
            left: clusters && <Select showSearch
                                      className={styles["select"]}
                                      placeholder={arr(clusters.clusters).length ? "Select a Cluster" : "No Access to a Cluster"}
                                      filterOption={selectDefaultFilter}
                                      value={selectedCluster}
                                      onChange={value => setSelectedCluster(value)}>
                {arr(clusters.clusters).sort((a: ManagementV1ClusterAccounts, b: ManagementV1ClusterAccounts) => {
                    return stringSorter(a.cluster?.metadata?.name, b.cluster?.metadata?.name);
                }).map(cluster => {
                    return <Option key={cluster.cluster?.metadata?.name!} value={cluster.cluster?.metadata?.name!}>{displayName(cluster.cluster)}</Option>
                })}
            </Select>
        }} />
    </div>
};