import React from "react";
import Label from "../../../../components/Label/Label";
import Description from "../../../../components/Description/Description";
import {ResultError, Return} from "../../../../lib/result";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {
    convertMinutesToSeconds,
    convertSecondsToMinutes,
    getDeleteAfter,
    getSleepAfter,
    setDeleteAfter,
    setSleepAfter,
    SleepModeSleepSchedule, SleepModeTimezone,
    SleepModeWakeupSchedule
} from "../../../../lib/helpers/sleepmodehelper";
import Input from "../../../../components/Input/Input";
import {ManagementV1SpaceTemplate} from "../../../../../gen/models/managementV1SpaceTemplate";
import {V1ObjectMeta} from "@kubernetes/client-node";
import SectionExpander from "../../SectionExpander/SectionExpander";
import { isValidCron } from 'cron-validator';
import Checkbox from "../../../Checkbox/Checkbox";
import constants from "../../../../constants/constants";
import {Link} from "react-router-dom";

interface SleepModeState {
    enableIngressAuthentication: boolean | undefined;

    sleepConfigSleepAfter: number | undefined;
    sleepConfigSleepAfterChanged: boolean;

    sleepConfigDeleteAfter: number | undefined;
    sleepConfigDeleteAfterChanged: boolean;

    sleepConfigSleepSchedule: string | undefined;
    sleepConfigSleepScheduleChanged: boolean;
    
    sleepConfigWakeUpSchedule: string | undefined;
    sleepConfigWakeUpScheduleChanged: boolean;
    
    sleepConfigTimezone: string | undefined;
    sleepConfigTimezoneChanged: boolean;
}

interface SleepModeObject {
    metadata?: V1ObjectMeta;
}

interface SleepModeProps extends SectionProps {
    obj?: SleepModeObject;
}

export default class SleepMode extends React.PureComponent<SleepModeProps, SleepModeState> {
    state: SleepModeState = {
        enableIngressAuthentication: this.props.obj?.metadata?.annotations?.[constants.LoftRequireIngressAuthentication] === "true",

        sleepConfigSleepAfter: this.props.obj ? getSleepAfter(this.props.obj?.metadata) : undefined,
        sleepConfigSleepAfterChanged: false,

        sleepConfigDeleteAfter: this.props.obj ? getDeleteAfter(this.props.obj?.metadata) : undefined,
        sleepConfigDeleteAfterChanged: false,

        sleepConfigSleepSchedule: this.props.obj?.metadata?.annotations?.[SleepModeSleepSchedule],
        sleepConfigSleepScheduleChanged: false,

        sleepConfigWakeUpSchedule: this.props.obj?.metadata?.annotations?.[SleepModeWakeupSchedule],
        sleepConfigWakeUpScheduleChanged: false,

        sleepConfigTimezone: this.props.obj?.metadata?.annotations?.[SleepModeTimezone],
        sleepConfigTimezoneChanged: false,
    };

    create = (obj: SleepModeObject): ResultError => {
        if (!obj.metadata) {
            obj.metadata = {};
        }
        if (!obj.metadata.annotations) {
            obj.metadata.annotations = {};
        }

        setSleepAfter(obj.metadata, this.state.sleepConfigSleepAfter);
        setDeleteAfter(obj.metadata, this.state.sleepConfigDeleteAfter);
        if (this.state.sleepConfigSleepSchedule) {
            if (!isValidCron(this.state.sleepConfigSleepSchedule)) {
                return Return.Failed(this.state.sleepConfigSleepSchedule + " is not a valid cron expression. Check https://crontab.guru/ for valid configurations");
            }
            obj.metadata.annotations[SleepModeSleepSchedule] = this.state.sleepConfigSleepSchedule
        }
        if (this.state.sleepConfigWakeUpSchedule) {
            if (!isValidCron(this.state.sleepConfigWakeUpSchedule)) {
                return Return.Failed(this.state.sleepConfigWakeUpSchedule + " is not a valid cron expression. Check https://crontab.guru/ for valid configurations");
            }
            obj.metadata.annotations[SleepModeWakeupSchedule] = this.state.sleepConfigWakeUpSchedule
        }
        if (this.state.sleepConfigTimezone) {
            obj.metadata.annotations[SleepModeTimezone] = this.state.sleepConfigTimezone
        }
        if (this.state.enableIngressAuthentication) {
            obj.metadata.annotations[constants.LoftRequireIngressAuthentication] = "true";
        }
        
        return Return.Ok();
    };

    update = (obj: SleepModeObject): ResultError => {
        if (!obj.metadata) {
            obj.metadata = {};
        }
        if (!obj.metadata.annotations) {
            obj.metadata.annotations = {};
        }
        
        // set sleep config sleep after
        if (this.state.sleepConfigSleepAfterChanged) {
            setSleepAfter(obj.metadata!, this.state.sleepConfigSleepAfter);
        }

        // set sleep config delete after
        if (this.state.sleepConfigDeleteAfterChanged) {
            setDeleteAfter(obj.metadata!, this.state.sleepConfigDeleteAfter);
        }
        
        if (this.state.sleepConfigSleepScheduleChanged) {
            if (this.state.sleepConfigSleepSchedule) {
                if (!isValidCron(this.state.sleepConfigSleepSchedule)) {
                    return Return.Failed(this.state.sleepConfigSleepSchedule + " is not a valid cron expression. Check https://crontab.guru/ for valid configurations");
                }
                obj.metadata.annotations[SleepModeSleepSchedule] = this.state.sleepConfigSleepSchedule;
            } else {
                delete(obj.metadata.annotations[SleepModeSleepSchedule]);
            }
        }

        if (this.state.sleepConfigWakeUpScheduleChanged) {
            if (this.state.sleepConfigWakeUpSchedule) {
                if (!isValidCron(this.state.sleepConfigWakeUpSchedule)) {
                    return Return.Failed(this.state.sleepConfigWakeUpSchedule + " is not a valid cron expression. Check https://crontab.guru/ for valid configurations");
                }
                obj.metadata.annotations[SleepModeWakeupSchedule] = this.state.sleepConfigWakeUpSchedule;
            } else {
                delete(obj.metadata.annotations[SleepModeWakeupSchedule]);
            }
        }
        
        if (this.state.sleepConfigTimezoneChanged) {
            if (this.state.sleepConfigTimezone) {
                obj.metadata.annotations[SleepModeTimezone] = this.state.sleepConfigTimezone;
            } else {
                delete(obj.metadata.annotations[SleepModeTimezone]);
            }
        }

        if (this.state.enableIngressAuthentication) {
            obj.metadata.annotations[constants.LoftRequireIngressAuthentication] = "true";
        } else {
            delete(obj.metadata.annotations[constants.LoftRequireIngressAuthentication]);
        }

        return Return.Ok();
    };

    batch = (objs: SleepModeObject[]): ResultError => {
        if (!objs) {
            return Return.Ok();
        }

        for(let i = 0; i < objs.length; i++) {
            const result = this.update(objs[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    };

    setSleepAfterValue = (val: number | undefined) => {
        this.setState({
            sleepConfigSleepAfter: convertMinutesToSeconds(val),
        })
    };

    setDeleteAfterValue = (val: number | undefined) => {
        this.setState({
            sleepConfigDeleteAfter: convertMinutesToSeconds(val),
        })
    };

    selectTemplate(spaceTemplate: ManagementV1SpaceTemplate | undefined) {
        if (!spaceTemplate) {
            return;
        }

        this.setState({
            enableIngressAuthentication: spaceTemplate?.spec?.template?.metadata?.annotations?.[constants.LoftRequireIngressAuthentication] === "true",
            sleepConfigSleepAfter: getSleepAfter(spaceTemplate?.spec?.template?.metadata),
            sleepConfigDeleteAfter: getDeleteAfter(spaceTemplate?.spec?.template?.metadata),
            sleepConfigSleepSchedule: spaceTemplate?.spec?.template?.metadata?.annotations?.[SleepModeSleepSchedule],
            sleepConfigWakeUpSchedule: spaceTemplate?.spec?.template?.metadata?.annotations?.[SleepModeWakeupSchedule],
            sleepConfigTimezone: spaceTemplate?.spec?.template?.metadata?.annotations?.[SleepModeTimezone],
        })
    }

    render() {
        return <div>
            <div className={"row"}>
                <div>
                    <Label>Sleep After Inactivity</Label>
                    <Input type={"number"}
                           resetable={this.props.mode !== "create"}
                           value={convertSecondsToMinutes(this.state.sleepConfigSleepAfter)}
                           onChangedStatus={changed => this.setState({sleepConfigSleepAfterChanged: changed})}
                           onChange={(e) => this.setSleepAfterValue(parseInt(e.target.value))}
                           placeholder={"Minutes before sleeping"} />
                    <Description>Leave empty for manual sleeping only.</Description>
                </div>
                <div>
                    <Label>Delete Space After Inactivity</Label>
                    <Input type={"number"}
                           resetable={this.props.mode !== "create"}
                           value={convertSecondsToMinutes(this.state.sleepConfigDeleteAfter)}
                           onChangedStatus={changed => this.setState({sleepConfigDeleteAfterChanged: changed})}
                           onChange={(e) => this.setDeleteAfterValue(parseInt(e.target.value))}
                           placeholder={"Minutes before deletion"} />
                    <Description>Delete the space if it is inactive for this period.</Description>
                </div>
            </div>
            <SectionExpander name={"Advanced Options"}>
                <SectionExpander name={"Sleep & WakeUp Schedule"}>
                    <Description>You can define schedules for which the space should start sleeping or should wakeup. A forced sleeping space will not wakeup through a schedule and a space that started sleeping through a schedule will not wakeup through activity.</Description>
                    <Label>Sleep Schedule</Label>
                    <Input resetable={this.props.mode !== "create"}
                           value={this.state.sleepConfigSleepSchedule}
                           onChangedStatus={changed => this.setState({sleepConfigSleepScheduleChanged: changed})}
                           onChange={(e) => this.setState({sleepConfigSleepSchedule: e.target.value})}
                           placeholder={"* * * * *"} />
                    <Description>Put the space to sleep at certain times. See <a href={"https://crontab.guru/"} target={"_blank"}>crontab.guru</a> for valid configurations. This might be useful if you want to set the space sleeping over the weekend for example.</Description>
                    <Label>Wake up Schedule</Label>
                    <Input resetable={this.props.mode !== "create"}
                           value={this.state.sleepConfigWakeUpSchedule}
                           onChangedStatus={changed => this.setState({sleepConfigWakeUpScheduleChanged: changed})}
                           onChange={(e) => this.setState({sleepConfigWakeUpSchedule: e.target.value})}
                           placeholder={"* * * * *"} />
                    <Description>Wake up the space at certain times. See <a href={"https://crontab.guru/"} target={"_blank"}>crontab.guru</a> for valid configurations. This might be useful if it started sleeping due to inactivity and you want to wake up the space on a regular basis.</Description>
                    <Label>Schedule Timezone</Label>
                    <Input resetable={this.props.mode !== "create"}
                           value={this.state.sleepConfigTimezone}
                           onChangedStatus={changed => this.setState({sleepConfigTimezoneChanged: changed})}
                           onChange={(e) => this.setState({sleepConfigTimezone: e.target.value})}
                           placeholder={Intl.DateTimeFormat().resolvedOptions().timeZone} />
                    <Description>Optional <a href={"https://en.wikipedia.org/wiki/List_of_tz_database_time_zones"} target={"_blank"}>TZ database timezone</a> for the schedules. Empty timezone means UTC.</Description>
                </SectionExpander>
                <SectionExpander name={"Automatic Ingress Authentication"}>
                    <Label>Automatic Ingress Authentication</Label>
                    <Checkbox checked={this.state.enableIngressAuthentication}
                              onChange={(val) => this.setState({enableIngressAuthentication: val.target.checked})}>Enable Ingress Authentication</Checkbox>
                    <Description>If enabled, Loft will automatically rewrite created ingresses within the space to require authentication through Loft before accessing. Only works for ingresses that use the <a href={"https://kubernetes.github.io/ingress-nginx/"} target={"_blank"}>nginx ingress controller</a> and requires <b>loftHost</b> to be set in the <Link to={"/admin/config"}>loft admin config</Link>.</Description>
                </SectionExpander>
            </SectionExpander>
        </div>
    }
}