import {Result, Return} from "./result";

type Callback = (value: Result<any>) => void;

export class SharedCache {
    private data: {[key: string]: {value: Result<any>}} = {};
    private loading: {[key: string]: Array<Callback>} = {};
    
    private notify(key: string, result: Result<any>) {
        if (!result) {
            this.data[key] = {value: Return.Failed("empty response")};
        } else {
            this.data[key] = {value: result};
        }

        const value = this.data[key].value;
        const callbacks = this.loading[key];
        delete this.loading[key];
        callbacks.forEach(cb => cb(value));
    }
    
    remove(key: string) {
        if (this.data[key]) {
            delete this.data[key];
        }
    }
    
    load<T>(key: string, load: () => Promise<Result<T>>, cb?: (value: Result<T>) => void): Promise<Result<T>> {
        return new Promise<Result<any>>(resolve => {
            const newCallback = (result: Result<any>) => {
                cb?.(result);
                resolve(result);
            };
            
            if (this.data[key]) {
                newCallback(this.data[key].value)
                return;
            } else if (this.loading[key]) {
                this.loading[key].push(newCallback);
                return;
            }

            this.loading[key] = [newCallback];
            load().then(result => this.notify(key, result)).catch(error => this.notify(key, Return.Failed(error?.message ? error.message : error)));
        });
    }
}