import React, {useState} from "react";
import * as Antd from "antd";
import * as AntdSelect from "antd/lib/select";
import {SelectValue} from "antd/lib/select";
import styles from "./Select.module.scss";
import {filterResetProps, ResetProps, useReset} from "../../lib/hooks/Reset/reset";
import {without} from "../../lib/helpers/renderhelper";

export interface SelectProps<T> extends AntdSelect.SelectProps<T>, Omit<ResetProps<T>, "value"> {

}

function Select<T extends SelectValue = SelectValue>(props: SelectProps<T>) {
    const {changed, onSelect, onReset} = useReset<T>(props, props.onChange as any);
    const classNames = [];
    if (props.mode === "multiple" || props.mode === "tags") {
        classNames.push(styles["multiple"]);
    }
    if (props.mode === "tags") {
        classNames.push(styles["tags"]);
    }
    if (props.className) {
        classNames.push(props.className);
    }
    if (changed) {
        classNames.push(styles["changed"]);
    }

    const dropdownClassNames = [];
    if (props.mode === "tags") {
        dropdownClassNames.push(styles["hidden"]);
    }
    if (props.dropdownClassName) {
        dropdownClassNames.push(props.dropdownClassName);
    }

    return <div className={classNames.join(" ")}>
        <Antd.Select {...filterResetProps(without(props, ["className"]))} onSelect={onSelect} onClick={onSelect} onChange={(val: any, option) => {
            if (props.onChange) {
                props.onChange(val, option);
            }

            onSelect();
        }} dropdownClassName={dropdownClassNames.join(" ")} />
        {changed && <span className={styles["text"]} onClick={(e) => onReset(e)}>Reset</span>}
    </div>
}

Select.Option = Antd.Select.Option;
Select.OptGroup = Antd.Select.OptGroup;

export default Select;