import React from "react";
import {TeamOutlined, UserOutlined} from "@ant-design/icons/lib";
import {getDisplayName} from "../../lib/helpers/renderhelper";
import styles from "./Owner.module.scss";

export interface OwnerProps {
    className?: string;
    hideIcon?: boolean;
    isTeam?: boolean;

    displayName?: string;
    username?: string;
    kubeName?: string;

    type?: "none" | "small" | "bold" | "dark" | "clickable";
}

export default function Owner(props: OwnerProps) {
    const classNames = [styles["owner"]];
    if (props.className) {
        classNames.push(props.className);
    }
    if (!props.type) {
        classNames.push(styles["bold"]);
    } else {
        classNames.push(styles[props.type]);
    }

    let hideIcon = props.hideIcon;
    let name = getDisplayName(props.displayName, props.username, props.kubeName);
    if (!name) {
        name = "none";
        hideIcon = true;
    }

    return <span className={classNames.join(" ")} title={name}>
        {!hideIcon && (props.isTeam ? <TeamOutlined /> : <UserOutlined />)}
        {name}
    </span>
}

