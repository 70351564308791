import React from "react";
import {LoadingOutlined} from "@ant-design/icons/lib";
import {Spin} from "antd";
import styles from "./Loading.module.scss";
import {SpinProps} from "antd/lib/spin";

function Loading(props: SpinProps) {
    const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
    return <Spin className={styles["loading"]} indicator={antIcon} {...props} />;
}

export default Loading;