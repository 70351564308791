import React from "react";
import {useRouteMatch} from "react-router-dom";
import VClusterHeader from "../VClusterHeader/VClusterHeader";
import PodsTable from "../../../Spaces/Spaces/Pods/PodsTable/PodsTable";

export default function Pods() {
    const match = useRouteMatch();
    const {cluster, namespace, vcluster} = match.params as any;
    const vCluster = {
        cluster: cluster,
        namespace: namespace,
        name: vcluster
    }

    return <PodsTable vCluster={vCluster}
                      top={<VClusterHeader />} />
}