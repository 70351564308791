import React from "react";
import {Resources} from "../../lib/resources";
import {QuotaTable} from "./QuotaTable/QuotaTable";
import Loading from "../../components/Loading/Loading";
import {useClusterQuery} from "../Spaces/Spaces/Spaces";
import Description from "../../components/Description/Description";
import {Link} from "react-router-dom";

export default function Quotas() {
    const {loading, error, data, clustersData, clustersLoading, clustersSelect, refetch} = useClusterQuery(Resources.ClusterV1ClusterQuota, "SELECTED_CLUSTERS_QUOTAS");
    if (clustersLoading) {
        return <Loading />;
    }
    
    return <QuotaTable error={error} 
                       top={<Description.Table>
                           Quotas define object limits for users or teams in a connected cluster. They behave similar to <a href={"https://kubernetes.io/docs/concepts/policy/resource-quotas/"} target={"_blank"}>resource quotas</a>, but limits are counted against all owned spaces and they can also define space limits. Quotas are managed through <Link to={"/clusters/clusteraccess"}>cluster access</Link> objects.
                       </Description.Table>}
                       loading={loading} 
                       clusters={clustersData?.items} 
                       quotas={data} 
                       left={clustersSelect} 
                       refetch={refetch} />
}