import React from "react";
import {formatTime, timeAgo} from "../../lib/helper";
import {Tooltip} from "../../components/Tooltip/Tooltip";
import Interval from "../../components/Interval/Interval";
import styles from "./DynamicTime.module.scss";

interface DynamicTimeProps {
  timestamp: Date | number | undefined;

  useTooltip?: boolean;
  renderTime?: (time: any) => any;
}

export default function DynamicTime(props: React.PropsWithChildren<DynamicTimeProps>) {
  const [duration, setDuration] = React.useState(props.renderTime ? props.renderTime(props.timestamp) : timeAgo(props.timestamp));
  return <Interval interval={5000} fn={() => setDuration(props.renderTime ? props.renderTime(props.timestamp) : timeAgo(props.timestamp))}>
    {props.useTooltip ? <Tooltip title={formatTime(props.timestamp)}>
      <span className={styles["time"]}>
         {duration}
      </span>
    </Tooltip> : <span className={styles["time"]}>
         {duration}
      </span>}
  </Interval>
}