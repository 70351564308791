import React from "react";
import AuthLayout from "../../../components/AuthLayout/AuthLayout";
import client from "../../../lib/client";
import {Button, Input} from "antd";
import Label from "../../../components/Label/Label";
import styles from "./CompleteProfile.module.scss";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import {Return} from "../../../lib/result";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {NewResource, Resources} from "../../../lib/resources";
import {useErrorLoading} from "../../../lib/messageHook";
import {useUser, useUserRefetch} from "../../../contexts/UserContext/UserContext";
import {useEffectOnce} from "../../../lib/helper";
export const LOFT_PROFILE_INFO = "loft_profile_info";

interface Props extends RouteComponentProps {
    showOrganization?: boolean;
}

export interface UserInfo {
    firstName: string;
    lastName: string;
    role: string;
    teamSize?: string;
    organization?: string;
    skipOrganization?: boolean;
}

function renderOrganization(organization: string, setOrganization: (organization: string) => void) {
    return <React.Fragment>
        <Label>Organization</Label>
        <Input placeholder={"Company Inc."} value={organization} onChange={(e) => setOrganization(e.target.value)} />
    </React.Fragment>
}

function CompleteProfile(props: Props) {
    const user = useUser();
    const userDispatch = useUserRefetch();
    const [firstName, setFirstName] = React.useState<string>(user?.spec?.displayName && user?.spec?.displayName.split(" ").length === 2 ? user?.spec?.displayName.split(" ")[0] : "");
    const [lastName, setLastName] = React.useState<string>(user?.spec?.displayName && user?.spec?.displayName.split(" ").length === 2 ? user?.spec?.displayName.split(" ")[1] : "");
    const [organization, setOrganization] = React.useState<string>("");
    const [errorLoading, setLoading, setError] = useErrorLoading();
    useEffectOnce(() => {
        try {
            if ((window as any).gagt) {
                (window as any).gagt("view_complete_profile", {
                  admin: (user?.metadata?.name == "admin"),
                })
            }
        } catch(e) {
            console.error(e)
        }
    })

    return <AuthLayout title="Complete your profile" description="to customize your experience" onSubmit={async () => {
        // validate inputs
        if (!firstName) {
            setError(Return.Failed("Please specify a first name"));
            return;
        }
        if (!lastName) {
            setError(Return.Failed("Please specify a last name"));
            return;
        }
        if (props.showOrganization) {
            if (!organization) {
                setError(Return.Failed("Please specify an organization"));
                return;
            }
        }

        // set loading
        setLoading(true);

        const customProfileInfo = btoa(JSON.stringify({
          firstName,
          lastName,
          organization,
        }))

        localStorage.setItem(LOFT_PROFILE_INFO, customProfileInfo)

        // create a new UserProfile resource
        const userProfile = NewResource(Resources.ManagementV1UserProfile, "profile", {
            "displayName": firstName + " " + lastName,
            "custom": customProfileInfo,
            "email": user?.spec?.email,       // DO NOT REMOVE THIS LINE
            "username": user?.spec?.username, // DO NOT REMOVE THIS LINE
        });

        // create the resource
        const response = await client.management(Resources.ManagementV1UserProfile).Name(user?.metadata?.name!).Create(userProfile);
        if (response.err) {
            setError(response);
            return;
        }

        try {
            if ((window as any).gagt) {
                (window as any).gagt("finish_complete_profile", {
                  admin: (user?.metadata?.name == "admin"),
                })
            }
        } catch(e) {
            console.error(e)
        }

        // refetch user
        userDispatch();
    }}>
        <div className={styles["first-last"]}>
            <div>
                <Label>First Name</Label>
                <Input placeholder={"First"} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </div>
            <div className={styles["spacer"]} />
            <div>
                <Label>Last Name</Label>
                <Input placeholder={"Last"} value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
        </div>
        <Input value={user?.metadata?.name} hidden={true} name="name" />  {/* DO NOT REMOVE THIS LINE*/}
        {props.showOrganization && renderOrganization(organization, setOrganization)}
        <ErrorMessage className={styles["error"]} error={errorLoading.error} />
        <div className={styles["finish-wrapper"]}>
            <Button loading={errorLoading.loading} type={"primary"} htmlType={"submit"}>Finish</Button>
        </div>
        <div className={styles["legal-hint"]}>
            By completing your profile and signing in, you agree to the <a href={"https://loft.sh/terms"}>Terms of Service</a> and the <a href={"https://loft.sh/privacy-policy"}>Privacy Policy</a> of Loft.
        </div>
    </AuthLayout>
}


export default withRouter(CompleteProfile);
