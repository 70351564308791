import React, {useState} from "react";
import AdminHeader from "../AdminHeader/AdminHeader";
import client, {getApiHost} from "../../../lib/client";
import useQuery, {QueryResult} from "../../../lib/Query/Query";
import styles from "./Upgrade.module.scss";
import {VersionV1Version} from "../../../lib/types";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../components/Loading/Loading";
import {Space} from "antd";
import Label from "../../../components/Label/Label";
import AsyncButton from "../../../components/AsyncButton/AsyncButton";
import Code from "../../../components/Code/Code";
import {confirm} from "../../../lib/Modal/Modal";
import Description from "../../../components/Description/Description";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {NewResource, Resources} from "../../../lib/resources";
import {Wait} from "../../../lib/helper";
import Input from "../../../components/Input/Input";

async function upgradeLoft(oldVersion: string, version: string) {
    confirm({
        title: `Upgrade Loft`,
        width: 700,
        content: <div>
            <p>
                By pressing the button below, loft will automatically upgrade itself to the version <b>{version}</b>. If you should experience any problems during upgrade, you can also manually upgrade loft via helm:
            </p>
            <p>
                <Code text={"helm upgrade loft loft --namespace loft --repo https://charts.loft.sh --version " + version} />
            </p>
            <p className={"color-warning"}>
                It is possible that the UI may lose connection to loft for a short period, however this has no effect on the upgrade process.
            </p>
        </div>,
        okText: "Upgrade",
        onOkAsync: async () => {
            const message = ClientMessage.Loading();

            // create a new upgrade object
            const loftUpgrade = NewResource(Resources.ManagementV1LoftUpgrade, "upgrade");
            loftUpgrade.spec = {
                version: version
            }

            const upgradeResult = await client.management(Resources.ManagementV1LoftUpgrade).Create(loftUpgrade);
            if (upgradeResult.err) {
                message.ErrorManagement(upgradeResult);
                return false;
            }

            // wait until the new version is updated
            while(true) {
                await Wait(2000);

                try {
                    const versionRequest = await fetch(getApiHost() + "/version");
                    const version = await versionRequest.json() as VersionV1Version;
                    if (oldVersion !== version.version) {
                        break;
                    }
                } catch(err) {
                    console.error(err);
                }
            }

            message.DoneManagement();
            await Wait(2000);

            window.location.reload();
        },
    });
}


function renderUpgrade(result: QueryResult<VersionV1Version>, version: string | undefined, setVersion: (v: string) => void, showVersion: boolean | undefined, setShowVersion: (v: boolean) => void) {
    if (result.error) {
        return <ErrorMessage error={result.error} />;
    } else if (result.loading) {
        return <Loading />;
    } else if (!result.data?.shouldUpgrade) {
        return <Space size={10} direction={"vertical"}>
            <Label.Bold>Upgrade Loft</Label.Bold>
            <div>No upgrade available, you are using the newest loft version. {
                !showVersion && <a href={"#"} onClick={() => setShowVersion(true)}>Upgrade loft to another version.</a>
            }</div>
            {
                showVersion && <React.Fragment>
                    <div style={{marginTop: "15px"}}>If you want to upgrade loft to another version, please enter a
                        version below.
                    </div>
                    <Input value={version} placeholder={result.data?.version}
                           onChange={e => setVersion(e.target.value)}/>
                    <div style={{marginTop: "10px"}}>
                        <AsyncButton type={"primary"}
                                     disabled={!version}
                                     onClickAsync={async () => upgradeLoft(result.data?.version!, version!)}>Upgrade</AsyncButton>
                        <Description>By pressing this button, loft will upgrade itself to the specified version.</Description>
                    </div>
                </React.Fragment>
            }
        </Space>
    }
    
    const targetVersion = version || result.data?.newerVersion!;
    return <Space size={10} direction={"vertical"}>
        <Label.Bold>Upgrade Loft</Label.Bold>
        <div style={{marginTop: "10px"}}>
            <AsyncButton type={"primary"} onClickAsync={async () => upgradeLoft(result.data?.version!, targetVersion)}>Upgrade</AsyncButton>
        </div>
        <Description>By pressing this button, loft will upgrade itself to version {result.data?.newerVersion!}. {
            !showVersion && <a href={"#"} onClick={() => setShowVersion(true)}>Change version</a>
        }</Description>
        {
            showVersion && <React.Fragment>
                <div>Please enter a loft version you want to upgrade to instead:</div>
                <Input value={version} placeholder={result.data?.newerVersion} onChange={e => setVersion(e.target.value)} />
            </React.Fragment>
        }
    </Space>
}

export default function Upgrade() {
    const [version, setVersion] = useState<string | undefined>(undefined);
    const result = useQuery<VersionV1Version>(async () => {
        const result = await client.loftVersion(true);
        if (result.err) {
            return result;
        }
        if (result.val?.newerVersion) {
            setVersion(result.val.newerVersion);
        }
        return result;
    });
    const [showVersion, setShowVersion] = useState(false);
    return <div className={styles["admin-wrapper"]}>
        <AdminHeader />
        {renderUpgrade(result, version, setVersion, showVersion, setShowVersion)}
    </div>
}
