import React, {useRef, useState} from "react";
import ItemDrawer, {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../../contexts/drawer/DrawerContext";
import AppSelector from "./Sections/AppSelector";
import HelmChartConfig from "./Sections/HelmChartConfig";
import {arr} from "../../../../../lib/helpers/renderhelper";
import ClientMessage from "../../../../../lib/Message/ClientMessage";
import {ErrorTypeNotFound, ResultError, Return} from "../../../../../lib/result";
import {NewResource, Resources} from "../../../../../lib/resources";
import client, {RequestOptionsVCluster} from "../../../../../lib/client";
import {Wait} from "../../../../../lib/helper";
import {StorageV1HelmChart} from "../../../../../../gen/models/storageV1HelmChart";
import {HelmRelease} from "../AppsTable/AppsTable";
import styles from "./AppsDrawer.module.scss";
import {Alert} from "antd";
import {ManagementV1App} from "../../../../../../gen/models/managementV1App";
import HelmChart from "./Sections/HelmChart";
import Manifests from "./Sections/Manifests";
import {isOutdated} from "../../Overview/AppsList/AppsList";
import {streamTask} from "../../../../Spaces/Spaces/SpaceDrawer/SpaceDrawer";
import {ProgressPopup} from "../../../../../components/ProgressPopup/ProgressPopup";
import {ClusterV1HelmRelease} from "../../../../../../gen/models/clusterV1HelmRelease";
import {useUser} from "../../../../../contexts/UserContext/UserContext";
import AppParametersPrompt from "./Sections/AppParametersPrompt";
import {ManagementV1User} from "../../../../../../gen/models/managementV1User";
import {StorageV1StreamContainer} from "../../../../../../gen/models/storageV1StreamContainer";
import AppReadme from "./Sections/AppReadme";
import Query from "../../../../../components/Query/Query";

export interface AppsDrawerProps extends SectionProps {
    release?: HelmRelease;
    cluster: string;
    namespace?: string;
    vCluster?: RequestOptionsVCluster;
    busy?: boolean;

    initialValues?: string;
    initialVersion?: string;
    selectedChart?: string;
    selectedApp?: ManagementV1App;
    clusterApp: boolean;
    
    apps?: Array<ManagementV1App>;
    charts?: Array<StorageV1HelmChart>;

    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<AppsDrawerProps, "mode"|"refetch"|"clusterApp"> & {
    user: ManagementV1User, 
    progressPopupRef: ProgressPopup | null, 
    manifestsRef: Manifests, 
    helmChartRef: HelmChart, 
    helmChartConfigRef: HelmChartConfig, 
    appParameterPromptRef: AppParametersPrompt,
    selectedApp: ManagementV1App | undefined,
};

async function onCreate({user, selectedApp, progressPopupRef, cluster, namespace, vCluster, manifestsRef, helmChartRef, helmChartConfigRef, appParameterPromptRef}: ChangeFunctionProps): Promise<ResultError> {
    // make sure we have an object
    const resource = vCluster ? Resources.VirtualclusterV1HelmRelease : Resources.ClusterV1HelmRelease;
    let release = NewResource(resource, undefined, {});

    // apply chart
    const applyChartResult = helmChartRef.create(release);
    if (applyChartResult.err) {
        return applyChartResult;
    }
    
    // apply parameters
    const parametersResult = appParameterPromptRef.create(release);
    if (parametersResult.err) {
        return parametersResult;
    }

    // apply chart config
    const applyConfig = helmChartConfigRef.create(release);
    if (applyConfig.err) {
        return applyConfig;
    }
    
    // apply manifests
    const applyManifests = manifestsRef.create(release);
    if (applyManifests.err) {
        return applyManifests;
    }
    
    // create namespace if it does not exist
    if (!namespace || vCluster) {
        const getNamespaceResult = await client.auto(cluster, vCluster, Resources.V1Namespace).Get(release.metadata?.namespace!);
        if (getNamespaceResult.err) {
            if (getNamespaceResult.val.type !== ErrorTypeNotFound) {
                return getNamespaceResult;
            }

            // create namespace
            const createNamespaceResult = await client.auto(cluster, vCluster, Resources.V1Namespace).Create(NewResource(Resources.V1Namespace, release.metadata?.namespace!));
            if (createNamespaceResult.err) {
                return createNamespaceResult;
            }
        }
    }

    // stream task
    const streamTaskResult = await streamTask(user, convertReleaseToHelmTask(release, "Install", getAppArgs(selectedApp), selectedApp?.spec?.streamContainer, cluster, vCluster), progressPopupRef, false);
    if (streamTaskResult.err) {
        return streamTaskResult;
    }

    return Return.Ok();
}

function getAppArgs(selectedApp: ManagementV1App | undefined) {
    const args: string[] = [];
    if (!!selectedApp?.spec?.wait) {
        args.push("--wait")
    }
    if (!!selectedApp?.spec?.timeout) {
        args.push("--timeout", selectedApp.spec.timeout)
    }
    return args;
}

export function convertReleaseToHelmTask(release: ClusterV1HelmRelease, type: string, extraArgs: string[] | undefined, streamContainer: StorageV1StreamContainer | undefined, cluster: string | undefined, vCluster?: RequestOptionsVCluster) {
    const task = NewResource(Resources.ManagementV1Task);
    task.spec = {
        displayName: type+" Helm Release "+release.metadata?.name,
        target: vCluster ? {virtualCluster: {name: vCluster.name!, namespace: vCluster.namespace!, cluster: vCluster.cluster!}} : {cluster: {cluster: cluster!}},
        task: {
            helm: {
                type,
                args: extraArgs,
                release: {
                    labels: release.metadata?.labels,
                    name: release.metadata?.name,
                    namespace: release.metadata?.namespace,
                    config: {
                        annotations: release.spec?.annotations,
                        chart: release.spec?.chart,
                        manifests: release.spec?.manifests,
                        parameters: release.spec?.parameters,
                        values: release.spec?.values
                    },
                },
                streamContainer: streamContainer,
            }
        },
    }
    return task;
}

async function onUpdate({user, selectedApp, progressPopupRef, release: originalRelease, cluster, namespace, manifestsRef, vCluster, helmChartRef, helmChartConfigRef, appParameterPromptRef}: ChangeFunctionProps): Promise<ResultError> {
    const resource = vCluster ? Resources.VirtualclusterV1HelmRelease : Resources.ClusterV1HelmRelease;
    if (!originalRelease) {
        return Return.Ok();
    }

    // make sure the object is up to date
    const getResult = await client.auto(cluster, vCluster, resource).Namespace(originalRelease.release.metadata?.namespace!).Get(originalRelease.release.metadata?.name!);
    if (getResult.err) {
        return getResult;
    }

    // set object
    let release = getResult.val;

    // apply chart
    const applyChartResult = helmChartRef.update(release);
    if (applyChartResult.err) {
        return applyChartResult;
    }

    // apply chart config
    const applyConfig = helmChartConfigRef.update(release);
    if (applyConfig.err) {
        return applyConfig;
    }

    // apply parameters
    const parametersResult = appParameterPromptRef.update(release);
    if (parametersResult.err) {
        return parametersResult;
    }
    
    // apply manifests
    const applyManifests = manifestsRef.update(release);
    if (applyManifests.err) {
        return applyManifests;
    }

    // stream task
    const streamTaskResult = await streamTask(user, convertReleaseToHelmTask(release, "Upgrade", getAppArgs(selectedApp), selectedApp?.spec?.streamContainer, cluster, vCluster), progressPopupRef, false,true);
    if (streamTaskResult.err) {
        return streamTaskResult;
    }

    return Return.Ok();
}

interface AppsDrawerState {
    customHelm?: boolean;
    customManifests?: boolean;
    
    selectedChart?: StorageV1HelmChart;
    selectedApp?: ManagementV1App;
}

const initialState = (props: AppsDrawerProps) => {
    if (props.release?.app) {
        return {
            selectedApp: props.release.app
        }
    } else if (props.release?.chart) {
        return {
            selectedChart: props.release.chart
        }
    } else if (props.selectedChart) {
        const selectedChart = props.charts?.find(chart => (chart.repository?.name + "/" + chart.metadata?.name) === props.selectedChart);
        if (!selectedChart) {
            return {
                customHelm: true
            }
        }
        
        return {
            selectedChart
        }
    } else if (props.selectedApp) {
        return {
            selectedApp: props.selectedApp
        }
    } else if (props.release?.release.spec?.manifests) {
        return {
            customManifests: true
        }
    }
    
    return {
        customHelm: true
    }
}

export default function AppsDrawer(props: AppsDrawerProps) {
    const user = useUser();
    const [state, setState] = useState<AppsDrawerState>(initialState(props));
    const [configUpdated, setConfigUpdated] = useState<boolean>(false);
    const drawer = useDrawerDispatcher();
    const appSelectorRef = useRef<AppSelector>(null);
    const helmChartRef = useRef<HelmChart>(null);
    const helmChartConfigRef = useRef<HelmChartConfig>(null);
    const manifestRef = useRef<Manifests>(null);
    const appParametersPromptRef = useRef<AppParametersPrompt>(null);
    const isHelm = !!(state.customHelm || state.selectedChart || state.selectedApp?.spec?.config?.chart?.name);
    const progressPopupRef = useRef<ProgressPopup>(null);
    
    return <Query query={async () => {
        const chartInfo = NewResource(Resources.ClusterV1ChartInfo, "", {spec: {}});
        chartInfo.spec = {}
        if (state.selectedApp?.spec?.config?.chart?.name) {
            chartInfo.spec = {
                chart: state.selectedApp?.spec?.config?.chart
            }
        } else if (state.selectedChart?.metadata?.name && state.selectedChart?.repository?.url) {
            chartInfo.spec = {
                chart: {
                    'insecureSkipTlsVerify': state.selectedChart.repository.insecure,
                    'name': state.selectedChart.metadata.name,
                    'password': state.selectedChart.repository.password,
                    'repoURL': state.selectedChart.repository.url,
                    'username': state.selectedChart.repository.username,
                    'version': state.selectedChart.metadata.version
                }
            }
        } else {
            return Return.Value(undefined);
        }
        
        const result = await client.cluster(props.cluster || props.vCluster?.cluster!, Resources.ClusterV1ChartInfo).Create(chartInfo);
        if (result.err) {
            return result;
        } else if (result.val.status?.values) {
            if (props.mode === "create" && !state.selectedApp?.spec?.config?.values) {
                helmChartConfigRef.current?.selectValues(result.val.status?.values.replace(/{{/g, '').replace(/}}/g, '').trim());
            }
        }
        
        return result;
    }} refetch={[state.selectedApp, state.selectedChart]}>
        {
            result => {
                return <React.Fragment>
                    {(state.selectedApp || (state.selectedChart?.metadata?.name && state.selectedChart?.repository?.url)) ? <AppReadme selectedChart={result.data}
                                                                                                                                       selectedApp={state.selectedApp}
                                                                                                                                       loading={result.loading}
                                                                                                                                       mode={props.mode} /> : undefined}
                    <ItemDrawer okButtonText={props.mode === "create" ? "Install" : "Upgrade"} onOkAsync={async () => {
                        const message = ClientMessage.Loading(props.cluster);

                        // execute the create / update / batch logic
                        let result: ResultError | undefined = undefined;
                        if (props.mode === "create") {
                            result = await onCreate({selectedApp: state.selectedApp, appParameterPromptRef: appParametersPromptRef.current!, user: user!, progressPopupRef: progressPopupRef.current!, cluster: props.cluster, namespace: props.namespace, vCluster: props.vCluster, helmChartRef: helmChartRef.current!, helmChartConfigRef: helmChartConfigRef.current!, manifestsRef: manifestRef.current!});
                        } else if (props.mode === "update") {
                            result = await onUpdate({selectedApp: state.selectedApp, appParameterPromptRef: appParametersPromptRef.current!, user: user!, progressPopupRef: progressPopupRef.current!, release: props.release, cluster: props.cluster, namespace: props.namespace, vCluster: props.vCluster, helmChartRef: helmChartRef.current!, helmChartConfigRef: helmChartConfigRef.current!, manifestsRef: manifestRef.current!});
                        }

                        // check if there was an error
                        if (result?.err) {
                            message.ErrorCluster(result, props.cluster);
                            return;
                        }

                        // give the caches some time to update
                        await Wait(2000);

                        // refetch
                        await props.refetch();

                        message.DoneCluster(props.cluster);

                        // close drawer
                        drawer({});
                    }}>
                        {props.mode === "create" && props.busy && <Alert className={styles["alert"]} message="loft is still parsing all app repositories. Please come back later to see all apps" type="warning" showIcon/>}
                        {
                            !configUpdated && props.release?.app && isOutdated(props.release) && <Alert className={styles["alert"]} message={<span>There is an update for this app. <span className={"clickable-link"} onClick={() => {
                                if (props.release?.app?.spec?.config?.chart?.name) {
                                    helmChartRef.current?.selectApp(props.release?.app);
                                    helmChartConfigRef.current?.selectApp(props.release?.app);
                                } else {
                                    manifestRef.current?.selectApp(props.release?.app);
                                }

                                setConfigUpdated(true);
                            }}>Click here to copy updated configuration.</span></span>} type="warning" showIcon/>
                        }
                        {
                            configUpdated && <Alert className={styles["alert"]} message={<span>New configuration copied. Click the Update button at the bottom to apply this updated configuration.</span>} type="warning" showIcon/>
                        }
                        <AppSelector mode={props.mode}
                                     charts={arr(props.charts)}
                                     apps={arr(props.apps)}
                                     selectedOption={props.selectedApp ? "app:"+props.selectedApp.metadata?.name : props.selectedChart ? "chart:"+props.selectedChart : "helm"}
                                     setSelectedApp={(app) => {
                                         setState({selectedApp: app});
                                         if (app?.spec?.config?.chart?.name) {
                                             helmChartRef.current?.selectApp(app);
                                             helmChartConfigRef.current?.selectApp(app);
                                         } else {
                                             manifestRef.current?.selectApp(app);
                                         }
                                         appParametersPromptRef.current?.selectApp(app);
                                     }}
                                     setSelectedChart={(chart) => {
                                         setState({selectedChart: chart});
                                         helmChartRef.current?.selectChart(chart);
                                         helmChartConfigRef.current?.selectChart(chart);
                                         appParametersPromptRef.current?.selectApp(undefined);
                                     }}
                                     setCustomManifest={() => {
                                         setState({customManifests: true});
                                         manifestRef.current?.selectCustomManifests();
                                         appParametersPromptRef.current?.selectApp(undefined);
                                     }}
                                     setCustomHelmChart={() => {
                                         setState({customHelm: true});
                                         helmChartRef.current?.selectCustomHelm();
                                         helmChartConfigRef.current?.selectCustomHelm();
                                         appParametersPromptRef.current?.selectApp(undefined);
                                     }}
                                     ref={appSelectorRef} />
                        <HelmChart show={isHelm}
                                   charts={arr(props.charts)}
                                   release={props.release}
                                   selectedChart={state.selectedChart}
                                   selectedApp={state.selectedApp}
                                   mode={props.mode}
                                   ref={helmChartRef} />
                        <HelmChartConfig show={isHelm}
                                         mode={props.mode}
                                         selectedChart={state.selectedChart || props.release?.chart}
                                         selectedApp={state.selectedApp}
                                         initialVersion={props.initialVersion}
                                         initialValues={props.initialValues}
                                         release={props.release}
                                         namespace={!props.vCluster ? props.namespace : undefined}
                                         clusterApp={props.clusterApp}
                                         ref={helmChartConfigRef} />
                        <Manifests show={!isHelm}
                                   mode={props.mode}
                                   selectedApp={state.selectedApp}
                                   release={props.release}
                                   namespace={!props.vCluster ? props.namespace : undefined}
                                   clusterApp={props.clusterApp}
                                   ref={manifestRef} />
                        <AppParametersPrompt selectedApp={state.selectedApp}
                                             mode={props.mode}
                                             release={props.release}
                                             ref={appParametersPromptRef} />
                        <ProgressPopup title={"Changing App"} ref={progressPopupRef} />
                    </ItemDrawer>
                </React.Fragment>
            }
        }
    </Query>
}