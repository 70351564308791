import React from "react";
import styles from './Announcement.module.scss';
import {useAnnouncement, useLicense} from "../../contexts/LicenseContext/LicenseContext";
import client from "../../lib/client";

export default function Announcement() {
    const announcement = useAnnouncement();
    const license = useLicense();
    if (!announcement) {
        return null;
    }
    
    const token = license?.token?.status?.token || "";
    return <div className={styles["announcement"]} dangerouslySetInnerHTML={{__html: announcement?.replace("{TOKEN}", token).replace("{INSTANCE}", license?.license?.status?.instance!).replace("{CALLBACK_URL}", encodeURIComponent(window.location.protocol + "//" + window.location.host + "/admin/license?refresh=true"))}} />
}
