import React, {useState} from "react";
import styles from "./AppsList.module.scss";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import AppCard from "./AppCard";
import {arr} from "../../../../../lib/helpers/renderhelper";
import {AppstoreAddOutlined, AppstoreOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons/lib";
import Loading from "../../../../../components/Loading/Loading";
import {displayNameSorter, stringCompare} from "../../../../../lib/helpers/sorthelper";
import {DrawerDispatch, useItemDrawer} from "../../../../../contexts/drawer/DrawerContext";
import AppsDrawer from "../../Apps/AppsDrawer/AppsDrawer";
import Actions from "../../../../../components/Icons/Actions";
import {alert} from "../../../../../lib/Modal/Modal";
import TextArea from "../../../../../components/TextArea/TextArea";
import {ClusterV1HelmRelease} from "../../../../../../gen/models/clusterV1HelmRelease";
import constants from "../../../../../constants/constants";
import {Result, Return} from "../../../../../lib/result";
import Query from "../../../../../components/Query/Query";
import {displayName, randomString} from "../../../../../lib/helper";
import {StorageV1HelmChart} from "../../../../../../gen/models/storageV1HelmChart";
import {HelmRelease, releaseStatusAlert, useApps, UseAppsResult} from "../../Apps/AppsTable/AppsTable";
import {Alert} from "antd";
import {RequestOptionsVCluster} from "../../../../../lib/client";
import {ManagementV1App} from "../../../../../../gen/models/managementV1App";
import drawerStyles from "../../Apps/AppsDrawer/AppsDrawer.module.scss";

interface AppsListProps {
    type: "space" | "cluster" | "virtualcluster";
    
    cluster: string;
    namespace?: string;
    vCluster?: RequestOptionsVCluster;
    className?: string;
    
    query: UseAppsResult;
}

export function chartName(chart?: StorageV1HelmChart) {
    if (chart?.repository?.name && chart?.metadata?.name) {
        return chart?.repository?.name + "/" + chart?.metadata?.name;
    }

    return undefined;
}

export function isSystemApp(release?: ClusterV1HelmRelease) {
    return release?.metadata?.labels?.[constants.LoftHelmReleaseSystemAppLabel] === "true";
}

export function isClusterApp(release?: ClusterV1HelmRelease) {
    return release?.metadata?.labels?.[constants.LoftHelmReleaseClusterAppLabel] === "true";
}

export function isApp(release?: ClusterV1HelmRelease) {
    return release?.metadata?.labels?.[constants.LoftHelmReleaseAppLabel] === "true";
}

function filterReleases(release: HelmRelease, props: AppsListProps, recommendedApps: Array<ManagementV1App>) {
    if (props.type !== "cluster") {
        return true;
    }
    
    const installed = !!recommendedApps?.find(recommendedApp => (release.app && release.app?.metadata?.name === recommendedApp.metadata?.name));
    if (installed) {
        return true;
    }

    return isClusterApp(release.release);
}

function renderRelease(release: HelmRelease, drawerDispatcher: DrawerDispatch, props: AppsListProps) {
    let status = release.release.status?.info?.status;
    if (isOutdated(release)) {
        status = "outdated";
    }

    return <AppCard key={release.key}
                    title={release.app ? displayName(release.app) : release.release.metadata?.name}
                    release={release}
                    status={status === "failed" ? "error" : status === "outdated" ? "warning" : undefined}
                    statusIcon={status === "failed" ? <Actions /> : status === "outdated" ? <UploadOutlined /> : undefined}
                    icon={release.release.status?.metadata?.icon ? <FallbackImage src={release.release.status?.metadata?.icon} /> : <AppstoreOutlined className={styles["app-icon-not-found"]} />}
                    onClick={() => {
                        if (status === "failed") {
                            alert({
                                title: <span>Failed Helm Release {release.release.metadata?.name}</span>,
                                content: <TextArea readOnly
                                                   value={release.release.status?.info?.description} />,
                                okText: "Close"
                            });
                        } else {
                            if (!isSystemApp(release.release)) {
                                drawerDispatcher({
                                    title: "Edit App: " + release.release.metadata?.name,
                                    content: <AppsDrawer mode={"update"} 
                                                         cluster={props.cluster} 
                                                         namespace={props.namespace} 
                                                         vCluster={props.vCluster} 
                                                         release={release} 
                                                         clusterApp={props.type === "cluster"}
                                                         refetch={props.query.refetch} />
                                });
                            } else {
                                releaseStatusAlert(release);
                            }
                        }
                    }} />
}

export function isOutdated(release: HelmRelease) {
    if (isSystemApp(release.release)) {
        return false;
    }
    
    if (release.app) {
        // compare resource versions first
        const appVersion = release.app.metadata?.generation || 0;
        const releaseVersion = parseInt(release.release.spec?.annotations?.[constants.LoftHelmReleaseAppGenerationAnnotation] || "0");
        if (appVersion && releaseVersion && appVersion > releaseVersion) {
            if (release.app.spec?.config?.manifests && release.app.spec.config?.manifests.trim() !== release.release.spec?.manifests?.trim()) {
                return true;
            } else if (release.app.spec?.config?.values && release.app.spec?.config?.values.trim() !== release.release.spec?.values?.trim()) {
                return true;
            } else if (release.app.spec?.config?.chart?.version && release.app.spec?.config?.chart?.version.trim() !== release.release.spec?.chart?.version?.trim()) {
                return true;
            }
        }
            
        return false;
    } else if (release.chart && release.chart.metadata?.version !== release.release.spec?.chart?.version)  {
        return true;
    }
    
    return false;
}

interface FallbackImageProps {
    src: string;
}

export function FallbackImage(props: FallbackImageProps) {
    const [isError, setError] = useState(false);
    if (isError) {
        return <AppstoreOutlined className={styles["app-icon-not-found"]} />
    }

    return <img onError={() => setError(true)} className={styles["app-icon"]} src={props.src} />
}

function renderRecommendedApps(type: string, cluster: string, namespace: string | undefined, vCluster: RequestOptionsVCluster | undefined, recommendedApps: Array<ManagementV1App>, releases: HelmRelease[], charts: Array<StorageV1HelmChart>, apps: Array<ManagementV1App>, refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch) {
    const retCards = [];
    for (let i = 0; i < recommendedApps.length; i++) {
        const recommendedApp = recommendedApps[i];
        const alreadyInstalled = releases?.find(release => release.app?.metadata?.name === recommendedApp.metadata?.name);
        if (alreadyInstalled) {
            continue;
        }

        retCards.push(<AppCard key={"app:"+recommendedApp.metadata?.name}
                               transparent={true}
                               status={"okay"}
                               statusIcon={<PlusOutlined />}
                               icon={recommendedApp?.spec?.icon ? <FallbackImage src={recommendedApp?.spec?.icon} /> : <AppstoreOutlined className={styles["app-icon-not-found"]} />}
                               title={displayName(recommendedApp)}
                               onClick={() => {
                                   drawerDispatcher({
                                       title: "Install App",
                                       content: <AppsDrawer mode={"create"}
                                                            cluster={cluster}
                                                            vCluster={vCluster}
                                                            namespace={namespace}
                                                            charts={charts}
                                                            apps={apps}
                                                            selectedApp={recommendedApp}
                                                            clusterApp={type === "cluster"}
                                                            refetch={refetch}/>
                                   });
                               }} />);
    }
    
    return retCards
}

export default function AppsList(props: AppsListProps) {
    const drawerDispatcher = useItemDrawer({width: 600, className: drawerStyles["apps-drawer"]});
    if (props.query.error) {
        return <ErrorMessage error={props.query.error} />;
    }

    const classNames = [styles["apps-list"]];
    if (props.className) {
        classNames.push(props.className);
    }
    
    const apps = arr(props.query.apps?.filter(app => app.spec?.recommendedApp?.find(r => r === props.type))).sort((a, b) => displayNameSorter(a, b));
    return <React.Fragment>
        <div className={classNames.join(" ")}>
            {arr(props.query.releases).filter(release => filterReleases(release, props, apps)).map(release => renderRelease(release, drawerDispatcher, props))}
            {renderRecommendedApps(props.type, props.cluster, props.namespace, props.vCluster, apps, arr(props.query.releases), props.query.charts, arr(props.query.apps), props.query.refetch, drawerDispatcher)}
            <AppCard key={"add-app"} transparent={true} status={"okay"} statusIcon={<PlusOutlined />} title={"Explore other apps..."} icon={<AppstoreAddOutlined />} onClick={() => {
                drawerDispatcher({
                    title: "Install App",
                    content: <AppsDrawer mode={"create"} 
                                         cluster={props.cluster} 
                                         vCluster={props.vCluster} 
                                         namespace={props.namespace} 
                                         charts={props.query.charts} 
                                         apps={props.query.apps}
                                         clusterApp={props.type === "cluster"}
                                         refetch={props.query.refetch} />
                });
            }} />
        </div>
    </React.Fragment>
}
