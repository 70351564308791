import React from "react";
import {useRouteMatch} from "react-router-dom";
import {ResourceTable} from "../../../../components/ResourceTable/ResourceTable";
import {Resources} from "../../../../lib/resources";
import VClusterHeader from "../VClusterHeader/VClusterHeader";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import Loading from "../../../../components/Loading/Loading";
import {ErrorTypeNotFound} from "../../../../lib/result";

export default function Ingresses() {
    const match = useRouteMatch();
    const {cluster, namespace, vcluster} = match.params as any;
    const {error, loading} = useQuery(async () => client.vCluster({
        cluster: cluster,
        namespace: namespace,
        name: vcluster
    }, Resources.NetworkingV1Ingress).List());
    if (loading) {
        return <Loading />;
    }
    
    return <ResourceTable vCluster={{
                            cluster: cluster,
                            namespace: namespace,
                            name: vcluster
                          }}
                          addNamespaceColumn={true}
                          top={<VClusterHeader />}
                          resource={error && error.val?.type === ErrorTypeNotFound ? Resources.NetworkingV1beta1Ingress : Resources.NetworkingV1Ingress} />
}