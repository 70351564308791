import React, {useState} from "react";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import UserDetailsHeader from "../UserDetailsHeader/UserDetailsHeader";
import AccessKeysTable from "../../../Profile/AccessKeysTable/AccessKeysTable";
import {useRouteMatch} from "react-router-dom";
import Description from "../../../../components/Description/Description";

export default function UserAccessKeys() {
    const match: any = useRouteMatch();
    const user = match.params?.user;
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1UserAccessKeys).Get(user!));
    return <AccessKeysTable error={error} 
                            loading={loading} 
                            accessKeys={data ? data.accessKeys : undefined} 
                            user={user} 
                            top={<UserDetailsHeader>
                                <Description.Table>
                                    Access Keys are a way to log into the Loft CLI without specifying a password. You also see all currently active access keys in this view created automatically by the Loft UI or CLI.
                                </Description.Table>
                            </UserDetailsHeader>} refetch={refetch} />
}