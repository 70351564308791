import React from "react";
import { useRouteMatch } from "react-router-dom";
import SpaceHeader from "../SpaceHeader/SpaceHeader";
import AppsTable, {useApps} from "../../../Clusters/Details/Apps/AppsTable/AppsTable";
import Label from "../../../../components/Label/Label";
import AppsList from "../../../Clusters/Details/Overview/AppsList/AppsList";
import styles from "../../../Clusters/Details/Overview/Overview.module.scss";

export default function Apps() {
    const match = useRouteMatch();
    const {cluster, space} = match.params as any;
    const appsResult = useApps(cluster, space);
    return <AppsTable query={appsResult} cluster={cluster} namespace={space} header={<SpaceHeader>
        <Label.Bold>Apps</Label.Bold>
        <div>Click on an app to install or upgrade it.</div>
        <AppsList type="space" query={appsResult} className={styles["apps-list"]} cluster={cluster} namespace={space} />
    </SpaceHeader>} />
};