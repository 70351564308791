import React from "react";
import styles from "./ClusterConnectStep2.module.scss";
import Code from "../../../components/Code/Code";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import {Return} from "../../../lib/result";
import {NewResource, Resources} from "../../../lib/resources";
import client from "../../../lib/client";
import Label from "../../../components/Label/Label";
import useQuery from "../../../lib/Query/Query";
import Loading from "../../../components/Loading/Loading";
import AsyncButton from "../../../components/AsyncButton/AsyncButton";
import ClientMessage from "../../../lib/Message/ClientMessage";
import TextArea from "../../../components/TextArea/TextArea";
import constants from "../../../constants/constants";
import {useFeatures} from "../../../contexts/LicenseContext/LicenseContext";
import {V1ObjectMeta} from "@kubernetes/client-node";
import {useUser} from "../../../contexts/UserContext/UserContext";

interface Props {
    clusterName: string;
    clusterDisplayName: string;
    labelsAnnotations: V1ObjectMeta;
    setCurrentStep: (step: number) => void;
}

export default function ClusterConnectStep2(props: Props) {
    const features = useFeatures();
    const user = useUser();
    const [clusterConfig, setClusterConfig] = React.useState<string>("");
    const {error, loading, data} = useQuery(async () => await client.management(Resources.ManagementV1Cluster).List());
    if (loading) {
        return <Loading />;
    } else if (error) {
        return <ErrorMessage error={error} />;
    }

    const localClusterConnected = data?.items.find(cluster => !!cluster.spec?.local) || features.find(feature => feature.metadata?.name === constants.ConnectLocalClusterFeature && !feature.status?.enabled);
    return <div className={styles["step-2"]}>
        {
            !localClusterConnected && <React.Fragment>
                <div>
                    <div className={"text-bold " + styles["option-a"]}>Option A: Connect the cluster you installed loft to</div>
                    <AsyncButton onClickAsync={async () => {
                        // build cluster object
                        const message = ClientMessage.Loading();
                        const cluster = NewResource(Resources.ManagementV1ClusterConnect);
                        cluster.metadata!.name = props.clusterName;
                        cluster.spec = {
                            config: "",
                            clusterTemplate: {
                                metadata: props.labelsAnnotations,
                                spec: {
                                    displayName: props.clusterDisplayName,
                                    access: [
                                        {
                                            subresources: ["*"],
                                            users: [user?.metadata?.name!],
                                            verbs: ["*"]
                                        }
                                    ]
                                }
                            },
                        };

                        // send it to server
                        const response = await client.management(Resources.ManagementV1ClusterConnect).Create(cluster);
                        if (response.err) {
                            message.ErrorManagement(response);
                            return;
                        }

                        // success we continue to the next step
                        message.DoneManagement();
                        props.setCurrentStep(2);
                    }} type="primary">Connect loft cluster</AsyncButton>
                </div>
                <div className={styles["or"]}>
                    <div className={styles["text"]}>OR</div>
                    <div className={styles["line"]} />
                </div>
                <div className={"text-bold"}>
                    Option B: Connect a different cluster
                </div>
            </React.Fragment>
        }
        <div>
            <Label>Create a kube-config for this cluster. The easiest option is</Label>
            <Code text="loft generate admin-kube-config" />
            <Label>Enter a valid kube-config for this cluster</Label>
            <TextArea style={{minHeight: "340px"}} value={clusterConfig} onChange={(e) => setClusterConfig(e.target.value)} placeholder={`apiVersion: v1
kind: Config
preferences: {}
current-context: demo_us-central1-c_cluster-1
contexts:
- context:
  cluster: demo_us-central1-c_cluster-1
  user: demo_us-central1-c_cluster-1
  name: demo_us-central1-c_cluster-1
clusters:
- cluster:
  certificate-authority-data: LS0tLS1CRUdJTiBDRVJUSUZ=
  server: https://10.197.235.173
  name: demo_us-central1-c_cluster-1
users:
- name: demo_us-central1-c_cluster-1
  token: LS0tLS1CRUdJTiBDRVJUSUZ=`} />
            <AsyncButton onClickAsync={async () => {
                const message = ClientMessage.Loading();
                if (!clusterConfig) {
                    message.ErrorManagement(Return.Failed("please enter a valid kube config"));
                    return;
                }

                // build cluster object
                const cluster = NewResource(Resources.ManagementV1ClusterConnect);
                cluster.metadata!.name = props.clusterName;
                cluster.spec = {
                    config: clusterConfig,
                    clusterTemplate: {
                        metadata: props.labelsAnnotations,
                        spec: {
                            displayName: props.clusterDisplayName,
                            access: [
                                {
                                    subresources: ["*"],
                                    users: [user?.metadata?.name!],
                                    verbs: ["*"]
                                }
                            ]
                        }
                    },
                };

                // send it to server
                const response = await client.management(Resources.ManagementV1ClusterConnect).Create(cluster);
                if (response.err) {
                    message.ErrorManagement(response);
                    return;
                }

                // success we continue to the next step
                message.DoneManagement();
                props.setCurrentStep(2);
            }} type="primary">Connect</AsyncButton>
        </div>
    </div>
}