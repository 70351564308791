import React from "react";
import {useRouteMatch} from "react-router-dom";
import {ResourceTable} from "../../../../components/ResourceTable/ResourceTable";
import {Resources} from "../../../../lib/resources";
import VClusterHeader from "../VClusterHeader/VClusterHeader";

export default function Secrets() {
    const match = useRouteMatch();
    const {cluster, namespace, vcluster} = match.params as any;
    return <ResourceTable vCluster={{cluster, namespace, name: vcluster}}
                          addNamespaceColumn={true}
                          top={<VClusterHeader />}
                          resource={Resources.V1Secret} />
}