import React from "react";
import styles from "./ClusterConnectStep4.module.scss";
import Code from "../../../components/Code/Code";
import {Button} from "antd";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Label from "../../../components/Label/Label";
import {useDrawerDispatcher} from "../../../contexts/drawer/DrawerContext";

interface Props extends RouteComponentProps {
    clusterName: string
}

function ClusterConnectStep4(props: Props) {
    const drawerDispatcher = useDrawerDispatcher();
    return <div className={styles["step-4"]}>
        <Label><a className={"text-small-bold"} href={"https://loft.sh/install-cli"} target={"_blank"}>Install loft CLI</a> and create a space in your cluster:</Label>
        <Code text={`loft create space my-space --cluster=${props.clusterName}`} />
        <Button className={styles["button"]} type="primary" onClick={() => {
            // navigate to the overview page
            props.history.push(`/clusters/details/${props.clusterName}/cluster`);

            // close the drawer
            drawerDispatcher({});
        }}>Finish</Button>
    </div>
}

export default withRouter(ClusterConnectStep4);