import React, {useEffect} from 'react';
import styles from "./DrawerContext.module.scss";
import {Drawer} from "antd";

export type DrawerDispatch = (state: DrawerState) => void
export type DrawerState = {
    title?: string,
    className?: string,
    content?: React.ReactNode,
    footer?: React.ReactNode,
    width?: string | number | undefined,
    noPadding?: boolean,
    
    update?: boolean,
}


const DrawerDispatchContext = React.createContext<DrawerDispatch | undefined>(undefined);

let setStateFunction: undefined | ((newState: DrawerState) => void) = undefined;

export function SetDrawer(state: DrawerState) {
    setStateFunction?.(state);
}

function DrawerProvider(props: React.PropsWithChildren<{}>) {
    const [state, setState] = React.useState<DrawerState>({});
    // we need this visible flag to solve the issue of drawer switches (e.g. selecting a different account
    // while another account drawer is already open). When a drawer switches while a drawer is already open
    // visible will be set to false and in the next render to true, this way we will force a complete unmount
    // of the current drawer components and all related state will be cleared.
    const [visible, setVisible] = React.useState<boolean>(false);

    useEffect(() => {
       if (!visible && state && state.content) {
           setVisible(true);
       }
    });

    const classNames = [styles["drawer"]];
    if (state.className) {
        classNames.push(state.className);
    }
    if (state.noPadding) {
        classNames.push(styles["no-padding"])
    }
    
    const newStateFunction = (newState: DrawerState) => {
        if (!newState.update) {
            if (visible) {
                setVisible(false);
            } else if (state && state.content) {
                setVisible(true);
            }

            setState(newState);
        } else {
            setState({...state, ...newState})
        }
    };

    // expose globally
    setStateFunction = newStateFunction;
    return (
        <DrawerDispatchContext.Provider value={newStateFunction}>
            {props.children}
            {visible && state && state.content && <Drawer
                title={state.title}
                placement="right"
                closable={true}
                visible={true}
                width={state.width ? state.width : 700}
                className={classNames.join(" ")}
                getContainer={false}
                footer={state.footer}
                onClose={() => setState({})}
                style={{ position: 'absolute' }}
            >
                {state.content}
            </Drawer>}
        </DrawerDispatchContext.Provider>
    )
}

export function useItemDrawer(extraOptions?: DrawerState) {
    const drawerDispatcher = useDrawerDispatcher();

    return (props: DrawerState) => {
        drawerDispatcher({...props, ...extraOptions, noPadding: true})
    }
}

export function useDrawerDispatcher() {
    let context = React.useContext(DrawerDispatchContext);
    if (!context) {
        throw new Error("useDrawerProvider has to be used below a DrawerProvider component");
    }

    return context;
}

export default DrawerProvider;