import React, {MouseEvent, useEffect, useState} from "react";
import {ButtonProps} from "antd/lib/button/button";
import {without} from "../../lib/helpers/renderhelper";
import Button from "../Button/Button";

export interface AsyncButtonProps extends ButtonProps {
    onClickAsync?: (e: React.MouseEvent<HTMLElement>) => Promise<any>;
}

interface AsyncButtonState {
    loading: boolean;
}

export default class AsyncButton extends React.PureComponent<AsyncButtonProps, AsyncButtonState> {
    mounted: boolean = true;
    state: AsyncButtonState = {
        loading: false
    };
    
    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const onClick = async (e: React.MouseEvent<HTMLElement>) => {
            this.setState({loading: true});

            try {
                if (this.props.onClickAsync) {
                    await this.props.onClickAsync(e);
                }
            } catch(err) {
                console.error(err);
            }

            window.setTimeout(() => {
                if (this.mounted) {
                    this.setState({loading: false});
                }
            }, 400);
        };

        return <Button {...without(this.props, ["onClickAsync"])} loading={this.state.loading} {...(this.props.onClickAsync ? {onClick} : {})} />
    }
}