import {useEffect} from 'react';
import constants from "../constants/constants";
import {DisplayNameObject} from "./helpers/sorthelper";

export function setDeepValue(obj: any, path: string, value: any) {
    let a = path.split('.')
    let o = obj
    while (a.length - 1) {
        let n = a.shift()
        if (!n) continue
        if (!(n in o)) o[n] = {}
        o = o[n]
    }
    o[a[0]] = value
}

export function getDeepValue(obj: any, path: string): any {
    if (!obj) return undefined;
    
    path = path.replace(/\[(\w+)\]/g, '.$1')
    path = path.replace(/^\./, '')
    let a = path.split('.')
    let o = obj
    while (a.length) {
        let n = a.shift()
        if (!n) continue
        if (!(n in o)) return undefined;
        o = o[n]
    }
    return o
}

export function displayName(obj?: DisplayNameObject): string {
    if (!obj) {
        return "";
    }
    if (obj.spec?.displayName) {
        return obj.spec?.displayName + "";
    }
    if (obj.metadata?.annotations?.[constants.LoftDisplayNameAnnotation]) {
        return obj.metadata?.annotations?.[constants.LoftDisplayNameAnnotation] + "";
    }
    if (obj.metadata?.name) {
        return obj.metadata?.name + "";
    }
    return "";
}  

export function safeParse<T>(str: string | undefined | null): T | undefined {
    if (!str) {
        return undefined;
    }
    
    try {
        return JSON.parse(str) as T;
    } catch(err) {
        return undefined;
    }
}

// returns a random number between from (inclusive) and to (inclusive)
export function randomNumber(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomString(length: number): string {
    return [...Array(length)].map(i=>(~~(Math.random()*36)).toString(36)).join('');
}

export function deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function useEffectOnce(fn: () => void) {
    useEffect(fn, [])
}

export function useEffectAsync(asyncFn: () => Promise<any>, onUnmount?: () => void) {
    useEffect(() => {
        asyncFn();
        return onUnmount;
    });
}

export function useEffectAsyncOnce(asyncFn: () => Promise<any>, onUnmount?: () => void) {
    useEffectOnce(() => {
        asyncFn();
        return onUnmount;
    });
}

export async function Wait(milliSeconds: number) : Promise<void> {
    return new Promise(resolve => {
       window.setTimeout(resolve, milliSeconds);
    });
}

export function setHashParameter(parameterName: string, value: string) {
    let tmp = [], result = [], found = false;
    let items = window.location.hash.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
        if (!items[index]) {
            continue;
        }
        
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) {
            found = true;
            if (value) {
                tmp[1] = encodeURIComponent(value.replace(/ /g, '+'));
            } else {
                continue;
            }
        }

        result.push(tmp.join("="));
    }
    if (value && !found) {
        result.push(parameterName+"="+encodeURIComponent(value.replace(/ /g, '+')))
    }
    
    window.location.hash = result.join("&");
}

export function formatURIComponent(s: string | undefined): string | undefined {
    return s?.replace(/ /g, '+')
}

export function findHashParameter(parameterName: string): string | undefined {
    let result = undefined,
        tmp = [];
    let items = window.location.hash.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) {
            return decodeURIComponent(tmp[1].replace(/\+/g, ' '));
        }
    }
    return result;
}

export function findGetParameter(parameterName: string): string | undefined {
    let result = undefined,
        tmp = [];
    let items = window.location.search.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) {
            return decodeURIComponent(tmp[1].replace(/\+/g, ' '));
        }
    }
    return result;
}

export function formatDate(time: any) {
    switch (typeof time) {
        case 'number':
            break;
        case 'string':
            time = +new Date(time);
            break;
        case 'object':
            if (time && time?.constructor === Date) {
                time = time.getTime();
            }
            break;
        default:
            time = +new Date();
    }

    return new Date(time).toDateString();
}

export function formatTime(time: any) {
    switch (typeof time) {
        case 'number':
            break;
        case 'string':
            time = +new Date(time);
            break;
        case 'object':
            if (time && time?.constructor === Date) {
                time = time.getTime();
            }
            break;
        default:
            time = +new Date();
    }

    return new Date(time).toLocaleString();
}

export function timeAgo(time: any) {
    switch (typeof time) {
        case 'number':
            break;
        case 'string':
            time = +new Date(time);
            break;
        case 'object':
            if (time && time?.constructor === Date) {
                time = time.getTime();
            }
            break;
        default:
            time = +new Date();
    }
    let time_formats = [
        [60, 'seconds', 1], // 60
        [120, '1 minute ago', '1 minute from now'], // 60*2
        [3600, 'minutes', 60], // 60*60, 60
        [7200, '1 hour ago', '1 hour from now'], // 60*60*2
        [86400, 'hours', 3600], // 60*60*24, 60*60
        [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
        [604800, 'days', 86400], // 60*60*24*7, 60*60*24
        [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
        [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
        [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
        [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
        [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    let seconds = (+new Date() - time) / 1000,
        token = 'ago',
        list_choice = 1;

    if (seconds == 0) {
        return 'Just now'
    }
    if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = 'from now';
        list_choice = 2;
    }
    let i = 0,
        format;
    while (format = time_formats[i++])
        if (seconds < format[0]) {
            if (typeof format[2] == 'string')
                return format[list_choice];
            else
                return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
        }
    return time;
}

export function readableTime(time: any) {
    switch (typeof time) {
        case 'number':
            break;
        case 'string':
            time = +new Date(time);
            break;
        case 'object':
            if (time && time?.constructor === Date) {
                time = time.getTime();
            }
            break;
        default:
            time = +new Date();
    }
    let time_formats = [
        [60, 'seconds', 1], // 60
        [120, '1 minute', '1 minute from now'], // 60*2
        [3600, 'minutes', 60], // 60*60, 60
        [7200, '1 hour', '1 hour from now'], // 60*60*2
        [86400, 'hours', 3600], // 60*60*24, 60*60
        [172800, '1 day', 'Tomorrow'], // 60*60*24*2
        [604800, 'days', 86400], // 60*60*24*7, 60*60*24
        [1209600, '1 week', 'Next week'], // 60*60*24*7*4*2
        [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, '1 month', 'Next month'], // 60*60*24*7*4*2
        [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, '1 year', 'Next year'], // 60*60*24*7*4*12*2
        [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, '1 century', 'Next century'], // 60*60*24*7*4*12*100*2
        [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    let seconds = (+new Date() - time) / 1000,
        token = '',
        list_choice = 1;

    if (seconds == 0) {
        return 'Just now'
    }
    if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = 'from now';
        list_choice = 2;
    }
    let i = 0,
        format;
    while (format = time_formats[i++])
        if (seconds < format[0]) {
            if (typeof format[2] == 'string')
                return format[list_choice];
            else
                return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
        }
    return time;
}

