import React, {useEffect} from "react";
import {Resources} from "../../../lib/resources";
import {VClusterTable} from "./VClusterTable/VClusterTable";
import {useClusterQuery} from "../../Spaces/Spaces/Spaces";
import Loading from "../../../components/Loading/Loading";
import VClustersHeader from "../VClustersHeader/VClustersHeader";
import Description from "../../../components/Description/Description";

export default function VClusters() {
    const {loading, error, data, clustersData, clustersLoading, clustersSelect, refetch} = useClusterQuery(Resources.ClusterV1VirtualCluster, "SELECTED_CLUSTERS_VCLUSTERS");
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 3000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);
    if (clustersLoading) {
        return <Loading />;
    }
    
    return <VClusterTable top={<VClustersHeader>
        <Description.Table>Virtual Clusters run inside a namespace of the underlying Kubernetes cluster. They are cheaper than creating separate full-blown clusters and they offer better multi-tenancy and isolation than regular namespaces.</Description.Table>
    </VClustersHeader>} 
                          error={error} 
                          loading={loading} 
                          vClusters={data} 
                          clusters={clustersData?.items}
                          left={clustersSelect} 
                          refetch={refetch} />
}