import React from "react";
import { SectionProps } from "../../../../../components/Drawer/ItemDrawer";
import Select from "../../../../../components/Select/Select";
import {ResultError, Return} from "../../../../../lib/result";
import Query from "../../../../../components/Query/Query";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../../components/Loading/Loading";
import Description from "../../../../../components/Description/Description";
import Section from "../../../../../components/Drawer/Section/Section";
import Label from "../../../../../components/Label/Label";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import {ManagementV1VirtualClusterTemplate} from "../../../../../../gen/models/managementV1VirtualClusterTemplate";
import {displayName} from "../../../../../lib/helper";
const { Option } = Select;

interface SpaceTemplateSectionState {
    spaceTemplate: string | undefined;
}

interface SpaceTemplateSectionProps extends SectionProps {
    virtualClusterTemplate?: ManagementV1VirtualClusterTemplate;
}

export default class SpaceTemplateSection extends React.PureComponent<SpaceTemplateSectionProps, SpaceTemplateSectionState> {
    state: SpaceTemplateSectionState = {
        spaceTemplate: this.props.virtualClusterTemplate?.spec?.spaceTemplateRef?.name
    };
    
    create = (template: ManagementV1VirtualClusterTemplate): ResultError => {
        return this.update(template);
    }

    update = (template: ManagementV1VirtualClusterTemplate): ResultError => {
        if (!template.spec) {
            template.spec = {};
        }

        if (this.state.spaceTemplate) {
            template.spec.spaceTemplateRef = {
                name: this.state.spaceTemplate
            }
        } else {
            delete template.spec.spaceTemplateRef
        }
        
        return Return.Ok();
    }

    render() {
        if (["batch"].includes(this.props.mode)) {
            return null;
        }

        return <Section title={`Space Template`} foldable={true} defaultFolded={true}>
            <Query query={async () => await client.management(Resources.ManagementV1SpaceTemplate).List()}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading) {
                            return <Loading />
                        }

                        return <React.Fragment>
                            <Label>Space Template for Virtual Cluster Creation</Label>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Select a Template"
                                style={{ width: '100%' }}
                                value={this.state.spaceTemplate}
                                onChange={value => {
                                    this.setState({
                                        spaceTemplate: value,
                                    });
                                }}
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {arr(result.data?.items).map(template => {
                                    return <Option key={template.metadata?.name} value={template.metadata?.name!}>
                                        {displayName(template)}
                                    </Option>
                                })}
                            </Select>
                            <Description>The space template that will be used to create a new space for the virtual cluster.</Description>
                        </React.Fragment>
                    }
                }
            </Query>
        </Section>
    }
}