import React from "react";
import {
    DollarCircleOutlined,
    FileProtectOutlined,
    ControlOutlined, ToTopOutlined
} from "@ant-design/icons/lib";
import { useRouteMatch } from "react-router-dom";
import Header from "../../../components/Header/Header";

export default function AdminHeader() {
    const routeMatch = useRouteMatch();
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1] === "admin" ? "config" : path[path.length - 1];

    return <Header title={"Admin Area"} selectedKey={selectedKey} items={[
        {
            key: "config",
            url: `/admin/config`,
            content: <React.Fragment>
                <ControlOutlined />Config
            </React.Fragment>
        },
        {
            key: "license",
            url: `/admin/license`,
            content: <React.Fragment>
                <FileProtectOutlined />License
            </React.Fragment>
        },
        {
            key: "billing",
            url: `/admin/billing`,
            content: <React.Fragment>
                <DollarCircleOutlined />Billing
            </React.Fragment>
        },
        {
            key: "upgrade",
            url: `/admin/upgrade`,
            content: <React.Fragment>
                <ToTopOutlined />Upgrade
            </React.Fragment>
        },
    ]} />
}