import React from "react";
import {displayName} from "../../../../lib/helper";
import {DrawerDispatch, useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import * as History from "history";
import {ToggleColumn, ToggleColumnContent} from "../../../../contexts/ToggleColumn/ToggleColumn";
import FixedText from "../../../../components/FixedText/FixedText";
import {useHistory} from "react-router-dom";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import Table, {TableActions} from "../../../../components/Table/Table";
import Button from "../../../../components/Button/Button";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons";
import {deleteConfirm} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {
    arr, Filter, getDisplayNameFromEntity, removeDuplicateFilters
} from "../../../../lib/helpers/renderhelper";
import {creationTimestampSorter, displayNameSorter, stringSorter} from "../../../../lib/helpers/sorthelper";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import RolesDrawer from "../RolesDrawer/RolesDrawer";
import {MultiItem} from "../../../../components/MultiItem/MultiItem";
import Description from "../../../../components/Description/Description";
import Query from "../../../../components/Query/Query";
import {ManagementV1ClusterRoleTemplate} from "../../../../../gen/models/managementV1ClusterRoleTemplate";

function getTableColumns(refetch: () => Promise<void>, 
                         drawerDispatcher: DrawerDispatch, 
                         management: boolean,
                         history: History.History<any>, 
                         allGlobalClusterRoles: ManagementV1ClusterRoleTemplate[]) {
    const editGlobalClusterRole = (clusterRole: ManagementV1ClusterRoleTemplate) => {
        drawerDispatcher({
            title: "Edit Cluster Role: " + displayName(clusterRole),
            content: <RolesDrawer mode={"update"} management={management} clusterRole={clusterRole} refetch={refetch} />
        })
    };

    const columns: any = [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            sorter: (a: ManagementV1ClusterRoleTemplate, b: ManagementV1ClusterRoleTemplate) => displayNameSorter(a, b),
            render: (clusterRole: ManagementV1ClusterRoleTemplate) => {
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editGlobalClusterRole(clusterRole)} text={displayName(clusterRole)} />;
                    },
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editGlobalClusterRole(clusterRole)} text={clusterRole.metadata?.name} />;
                    },
                ]}/>
            }
        },
        {
            title: "Description",
            render: (clusterRole: ManagementV1ClusterRoleTemplate) => {
                return <Description.Column>
                    {clusterRole.spec?.description || ""}
                </Description.Column>;
            }
        },
    ];
    
    if (!management) {
        columns.push({
            title: 'Clusters',
            filters: removeDuplicateFilters(arr(allGlobalClusterRoles).reduce((previous: Filter[], clusterAccess): Filter[] => {
                const clusters = clusterAccess.status?.clusters?.map(c => ({
                    text: getDisplayNameFromEntity(c),
                    value: getDisplayNameFromEntity(c) + ""
                }))
                return [...previous, ...arr(clusters)];
            }, [])),
            onFilter: (value: string | number | boolean, cluster: ManagementV1ClusterRoleTemplate) => {
                return !!cluster.status?.clusters?.find(c => getDisplayNameFromEntity(c) === value);
            },
            render: (cluster: ManagementV1ClusterRoleTemplate) => {
                const clusters = arr(cluster.status?.clusters);
                return <MultiItem items={clusters.map(c => ({key: c.name, children: getDisplayNameFromEntity(c)}))} maxItems={5} />;
            }
        })
    }
    
    columns.push({
        title: 'Rules',
        render: (cluster: ManagementV1ClusterRoleTemplate) => {
            if (cluster.spec?.localClusterRoleTemplate?.spec?.clusterRoleTemplate?.aggregationRule) {
                return <span>Aggregated Role</span>
            }

            return <span>{arr(cluster.spec?.localClusterRoleTemplate?.spec?.clusterRoleTemplate?.rules).length} Rules</span>
        }
    },
    {
        title: 'Created',
        sorter: (a: ManagementV1ClusterRoleTemplate, b: ManagementV1ClusterRoleTemplate) => creationTimestampSorter(a, b),
        render: (cluster: ManagementV1ClusterRoleTemplate) => {
            return <DynamicTime timestamp={cluster.metadata?.creationTimestamp} useTooltip={true}/>
        }
    },
    {
        title: 'Actions',
        width: "210px",
        render: (cluster: ManagementV1ClusterRoleTemplate) => {
            return <TableActions>
                <Tooltip title="edit">
                    <SettingOutlined className={"blue-btn"} onClick={() => editGlobalClusterRole(cluster)} />
                </Tooltip>
                <ShowYamlPopup className={"blue-btn"} object={cluster} resource={Resources.ManagementV1ClusterRoleTemplate} name={cluster.metadata?.name!} refetch={refetch} />
                <Tooltip title="delete">
                    <DeleteOutlined className={"actions-delete-btn"} onClick={() => {
                        deleteConfirm({
                            title: `Delete Cluster Role: ${displayName(cluster)}`,
                            content: `Are you sure you want to delete the cluster role ${displayName(cluster)}? This action CANNOT be reverted!`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                const result = await client.management(Resources.ManagementV1ClusterRoleTemplate).Delete(cluster?.metadata?.name as string);
                                message.Result(result);
                                await refetch();
                            },
                        });
                    }} />
                </Tooltip>
            </TableActions>;
        }
    })
    return columns;
}

function filter(item: ManagementV1ClusterRoleTemplate, value: string) {
    return item.metadata?.name?.includes(value) || item.spec?.displayName?.includes(value) || item.spec?.description?.includes(value) || !!item.status?.clusters?.find(cluster => cluster.name?.includes(value) || cluster.displayName?.includes(value));
}

interface Props {
    management: boolean;

    top?: React.ReactNode;
}

function RolesTable(props: Props) {
    const history = useHistory();
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1ClusterRoleTemplate).List());
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    const allGlobalClusterRoles = arr(data?.items).filter(globalClusterRole => props.management ? !!globalClusterRole.spec?.management : !globalClusterRole.spec?.management).map(cluster => { return {...cluster, key: cluster.metadata!.name!}}).sort((a, b) => displayNameSorter(a, b));
    return <div>
        <Table showSorterTooltip={false} loading={loading} columns={getTableColumns(refetch, drawerDispatcher, props.management, history, arr(data?.items))} dataSource={arr(allGlobalClusterRoles)} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: props.top,
            right: <Query query={async () => await client.management(Resources.ManagementV1ClusterRoleTemplate).CanI("create")}>
                {
                    result => {
                        if (result.loading || !result.data) {
                            return null;
                        }

                        return <Button type={"primary"} onClick={() => {
                            drawerDispatcher({
                                title: "Create Global Cluster Role",
                                content: <RolesDrawer mode={"create"} management={props.management} refetch={refetch} />
                            });
                        }}>Create Role</Button>
                    }
                }
            </Query>,
            selectedActions: <React.Fragment>
                <Tooltip title={"edit"}>
                    <SettingOutlined className={"blue-btn-batch"} onClick={() =>
                    {
                        const teams: ManagementV1ClusterRoleTemplate[] = [];
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                            const teamName = selectedRowKeys[i];
                            const team = data!.items!.find(team => team.metadata?.name === teamName);
                            if (!team) {
                                continue;
                            }

                            teams.push(team);
                        }

                        if (teams.length === 0) {
                            return;
                        }
                        if (teams.length === 1) {
                            const team = teams[0];
                            drawerDispatcher({
                                title: "Edit Cluster Role: " + team?.metadata?.name!,
                                content: <RolesDrawer mode={"update"} management={props.management} clusterRole={team} refetch={refetch} />
                            })
                        } else {
                            drawerDispatcher({
                                title: "Bulk Edit Selected Cluster Roles",
                                content: <RolesDrawer mode={"batch"} management={props.management} clusterRoles={teams} refetch={refetch} />
                            })
                        }

                        setSelectedRowKeys([]);
                    }} />
                </Tooltip>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={"actions-delete-btn-batch"} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Cluster Role`,
                            content: `Are you sure you want to delete the cluster role ${selectedRowKeys.join(", ")}?`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const clusterName = selectedRowKeys[i];
                                    const result = await client.management(Resources.ManagementV1ClusterRoleTemplate).Delete(clusterName as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneManagement();
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}

export default RolesTable;
