import React from "react";
import Label from "../../../../../components/Label/Label";
import Description from "../../../../../components/Description/Description";
import {Result, ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Query from "../../../../../components/Query/Query";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import {ManagementV1ClusterMembers} from "../../../../../../gen/models/managementV1ClusterMembers";
import {deepCopy, displayName} from "../../../../../lib/helper";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import {V1ClusterRole, V1RoleBinding} from "@kubernetes/client-node";
import styles from "./SpaceAccess.module.scss";
import Select from "../../../../../components/Select/Select";
import {selectDefaultFilter, selectOwnerFilter, arr} from "../../../../../lib/helpers/renderhelper";
import Owner from "../../../../../components/Owner/Owner";
import Section from "../../../../../components/Drawer/Section/Section";
import constants from "../../../../../constants/constants";
import {Alert} from "antd";
import {Link} from "react-router-dom";
import InfoAlert from "../../../../../components/InfoAlert/InfoAlert";
const { Option } = Select;

export function GetUserTeamFromKey(key: string | undefined): {user?: string | undefined, team?: string | undefined} {
    if (key) {
        const splitted = key.split(":");
        if (splitted.length !== 2) {
            return {};
        } else if (splitted[0] === "user") {
            return {
                user: splitted[1]
            }
        } else if (splitted[0] === "team") {
            return {
                team: splitted[1]
            }
        }

        return {}
    }

    return {};
}

export function GetKey(user: string | undefined, team?: string | undefined): string | undefined {
    if (user) {
        return "user:"+user;
    } else if (team) {
        return "team:"+team;
    }

    return undefined;
}

interface RoleSwitcherProps {
    clusterMembers: ManagementV1ClusterMembers;
    clusterRoles: V1ClusterRole[];
    user: UserClusterRole;

    onChange: (role: string) => void;
}

function RoleSwitcher(props: RoleSwitcherProps) {
    // search users
    const info = props.user.user ? props.clusterMembers.users?.find(user => user.info?.name === props.user.user) : props.user.team ? props.clusterMembers.teams?.find(team => team.info?.name === props.user.team) : undefined;
    if (!info) {
        return null;
    }

    return <div className={styles["role-switcher"]}>
        <span className={styles["user"]}>
            <Owner displayName={info ? info.info?.displayName : undefined} username={info ? info.info?.username : undefined} kubeName={info ? info.info?.name : props.user.user ? props.user.user : props.user.team} hideIcon={!info} isTeam={!!props.user.team} type={"none"} />
        </span>
        <Select showSearch
                filterOption={selectDefaultFilter}
                className={styles["cluster-roles-select"]}
                value={props.user.clusterRole}
                onChange={value => props.onChange(value)}>
            {props.clusterRoles.map(clusterRole => <Option key={clusterRole.metadata?.name!} value={clusterRole.metadata?.name!}>{displayName(clusterRole)}</Option>)}
        </Select>
    </div>
}

interface SpaceAccessState {
    error?: ResultError;

    users: UserClusterRole[];
    usersChanged: boolean;
}

interface SpaceAccessProps extends SectionProps {
    clusterMembers: ManagementV1ClusterMembers;
    cluster: string;

    defaultClusterRole: string;
    kind: string;

    // If the space is modified this will be non empty
    space?: string;

    hideSection?: boolean;
}

interface UserClusterRole {
    user: string | undefined;
    team: string | undefined;
    clusterRole: string;

    oldClusterRole?: string;
    roleBinding?: string;
    delete?: boolean;
}

export default class SpaceAccess extends React.PureComponent<SpaceAccessProps, SpaceAccessState> {
    state: SpaceAccessState = {
        users: [],
        usersChanged: false
    };

    createRoleBinding = async (space: string, user: UserClusterRole) => {
        // we don't need subjects here because kiosk will sync them for us
        const roleBinding: V1RoleBinding = {
            metadata: {
                generateName: "loft-"
            },
            roleRef: {
                apiGroup: Resources.V1RoleBinding.group,
                kind: "ClusterRole",
                name: user.clusterRole
            },
            subjects: [
                {
                    kind: "Group",
                    apiGroup: "rbac.authorization.k8s.io",
                    name: user.user ? "loft:user:"+user.user : "loft:team:"+user.team,
                }
            ]
        };

        // create the rolebinding
        return await client.cluster(this.props.cluster, Resources.V1RoleBinding).Namespace(space).Create(roleBinding);
    };

    create = async (space: string): Promise<ResultError> => {
        for (let i = 0; i < this.state.users.length; i++) {
            // create role binding object
            const user = this.state.users[i];
            const result = await this.createRoleBinding(space, user);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    };

    updateUsers = async (space: string, users: UserClusterRole[]): Promise<ResultError> => {
        if (!this.state.usersChanged) {
            return Return.Ok();
        }

        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            if (!user.delete && user.clusterRole === user.oldClusterRole) {
                continue;
            }

            // create
            if (!user.delete) {
                const result = await this.createRoleBinding(space, user);
                if (result.err) {
                    return result;
                }
            }

            // delete
            if (user.delete || user.oldClusterRole) {
                const result = await client.cluster(this.props.cluster, Resources.V1RoleBinding).Namespace(space).Delete(user.roleBinding!);
                if (result.err) {
                    return result;
                }
            }
        }

        return Return.Ok();
    };

    update = async (space: string): Promise<ResultError> => {
        return this.updateUsers(space, this.state.users);
    };

    batch = async (spaces: string[]): Promise<ResultError> => {
        if (!this.state.usersChanged) {
            return Return.Ok();
        }

        for (let i = 0; i < spaces.length; i++) {
            const usersResult = await this.getUsersInSpace(spaces[i]);
            if (usersResult.err) {
                return usersResult;
            }

            const newUsers = usersResult.val;
            this.diffUsers(this.state.users.map(user => GetKey(user.user, user.team)!), newUsers)

            // assign correct cluster roles
            for (let i = 0; i < newUsers.length; i++) {
                const user = this.state.users.find(u => GetKey(u.user, u.team) === GetKey(newUsers[i].user, newUsers[i].team));
                if (user) {
                    newUsers[i].clusterRole = user.clusterRole;
                }
            }

            // update
            const result = await this.updateUsers(spaces[i], newUsers);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    };

    diffUsers = (users: string[], newUsers: UserClusterRole[]) => {
        // add users that are now in the state already
        for (let i = 0; i < users.length; i++) {
            const user = newUsers.find(user => GetKey(user.user, user.team) === users[i]);
            if (!user) {
                const obj = GetUserTeamFromKey(users[i]);
                newUsers.push({
                    user: obj.user,
                    team: obj.team,
                    clusterRole: this.props.defaultClusterRole
                })
            } else if (user.delete) {
                user.delete = undefined;
            }
        }

        // remove users that are not selected anymore
        for (let i = newUsers.length - 1; i >= 0; i--) {
            if (!users.find(user => user === GetKey(newUsers[i].user, newUsers[i].team))) {
                if (newUsers[i].roleBinding) {
                    newUsers[i].delete = true;
                } else {
                    newUsers.splice(i, 1);
                }
            }
        }
    };

    async getUsersInSpace(space: string): Promise<Result<UserClusterRole[]>> {
        const result = await client.cluster(this.props.cluster!, Resources.V1RoleBinding).Namespace(space).List();
        if (result.err) {
            return result;
        }

        // parse from role bindings
        const users: UserClusterRole[] = result.val.items.filter(roleBinding => {
            if (roleBinding.roleRef.kind !== "ClusterRole" || arr(roleBinding.subjects).length !== 1) {
                return false;
            }

            return roleBinding.subjects?.[0].kind === "Group" && (roleBinding.subjects?.[0].name.startsWith("loft:user:") || roleBinding.subjects?.[0].name.startsWith("loft:team:"));
        }).map(roleBinding => {
            const user = roleBinding.subjects?.[0].name.startsWith("loft:user:") ? roleBinding.subjects?.[0].name.substring("loft:user:".length) : undefined;
            const team = roleBinding.subjects?.[0].name.startsWith("loft:team:") ? roleBinding.subjects?.[0].name.substring("loft:team:".length) : undefined;
            return {
                user,
                team,
                roleBinding: roleBinding.metadata?.name,
                clusterRole: roleBinding.roleRef.name,
                oldClusterRole: roleBinding.roleRef.name,
            }
        });

        return Return.Value(users);
    }

    async componentDidMount() {
        if (this.props.mode === "update") {
            const usersResult = await this.getUsersInSpace(this.props.space!);
            if (usersResult.err) {
                this.setState({
                    error: usersResult
                });
            } else {
                this.setState({
                    users: usersResult.val
                });
            }
        }
    }

    renderOptions() {
        const options: JSX.Element[] = [];

        // add other users
        this.props.clusterMembers.users?.forEach(user => {
            options.push(<Option key={"user:"+user?.info?.name} value={"user:"+user?.info?.name}>
                <Owner displayName={user.info?.displayName} username={user.info?.username} kubeName={user.info?.name} type={"small"} className={styles["option"]} />
            </Option>)
        });

        // add teams
        this.props.clusterMembers.teams?.forEach(team => {
            options.push(<Option key={"team:"+team?.info?.name} value={"team:"+team?.info?.name!}>
                <Owner displayName={team.info?.displayName} username={team.info?.username} kubeName={team.info?.name} isTeam={true} type={"small"} className={styles["option"]} />
            </Option>)
        });

        return options;
    }

    renderContents() {
        return <React.Fragment>
            <Label>Who should have access to this {this.props.kind}?</Label>
            <Select
                resetable
                className={styles["select"]}
                mode="multiple"
                style={{ width: '100%' }}
                onChangedStatus={changed => this.setState({usersChanged: changed})}
                placeholder={`Please select users that can access the ${this.props.kind}`}
                value={this.state.users.filter(user => !user.delete).map(user => GetKey(user.user, user.team)!)}
                onChange={(value) => {
                    const newUsers = deepCopy(this.state.users);

                    // diff the users
                    this.diffUsers(value, newUsers);

                    // update state
                    this.setState({
                        users: newUsers
                    });
                }}
                showSearch
                optionFilterProp="children"
                filterOption={selectOwnerFilter}
            >
                {this.renderOptions()}
            </Select>
            <Description>Users and Teams listed here can access the {this.props.kind}. You can configure their access rights below</Description>
            <InfoAlert message={<span>If you cannot find the desired user here, please make sure the user has appropriate <Link to={"/clusters/clusteraccess"}>cluster access</Link></span>} />
            <SectionExpander name={"Edit Roles for Users & Teams"}>
                <Query query={async () => await client.cluster(this.props.cluster, Resources.V1ClusterRole).List({labelSelector: constants.LoftClusterRoleCluster+"=true"})}>
                    {
                        (result) => {
                            if (result.error) {
                                return <ErrorMessage error={result.error} />
                            } else if (!result.data) {
                                return null;
                            }

                            const filteredUsers = this.state.users.filter(user => !user.delete);
                            if (!filteredUsers.length) {
                                return <Description>
                                    Please add a user or team via the input above.
                                </Description>
                            }

                            return <div className={styles["roles"]}>
                                {
                                    filteredUsers.map(user => <RoleSwitcher key={GetKey(user.user, user.team)}
                                                                            clusterMembers={this.props.clusterMembers}
                                                                            clusterRoles={result.data!.items.filter(clusterRole => clusterRole.metadata?.annotations?.[constants.LoftIgnoreAnnotation] !== "true")}
                                                                            user={user}
                                                                            onChange={(newClusterRole) => {
                                                                                const newUsers = deepCopy(this.state.users);
                                                                                newUsers.find(newUser => (newUser.user && newUser.user === user.user) || (newUser.team && newUser.team === user.team))!.clusterRole = newClusterRole;
                                                                                this.setState({
                                                                                    users: newUsers,
                                                                                    usersChanged: true,
                                                                                })
                                                                            }} />)
                                }
                            </div>;
                        }
                    }
                </Query>
            </SectionExpander>
        </React.Fragment>
    }

    render() {
        if (this.props.hideSection) {
            return this.renderContents();
        }

        return <Section title={"Access To " + this.props.kind} foldable={true} defaultFolded={true}>
            {this.renderContents()}
        </Section>
    }
}