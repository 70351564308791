import React from "react";
import client from "../../lib/client";
import {Resources} from "../../lib/resources";
import DynamicTime from "../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../components/Table/Table";
import useQuery from "../../lib/Query/Query";
import {
    DeleteOutlined,
    SettingOutlined,
} from "@ant-design/icons/lib";
import {deleteConfirm, confirm, alert} from "../../lib/Modal/Modal";
import ClientMessage from "../../lib/Message/ClientMessage";
import {Button} from "antd";
import {DrawerDispatch, useItemDrawer} from "../../contexts/drawer/DrawerContext";
import {arr} from "../../lib/helpers/renderhelper";
import {Tooltip} from "../../components/Tooltip/Tooltip";
import {
    creationTimestampSorter, displayNameSorter, nameSorter, stringSorter
} from "../../lib/helpers/sorthelper";
import ShowYamlPopup from "../../components/ShowYamlPopup/ShowYamlPopup";
import FixedText from "../../components/FixedText/FixedText";
import {ManagementV1App} from "../../../gen/models/managementV1App";
import AppDrawer from "./AppDrawer/AppDrawer";
import {ToggleColumn, ToggleColumnContent} from "../../contexts/ToggleColumn/ToggleColumn";
import {displayName} from "../../lib/helper";
import {MultiItem} from "../../components/MultiItem/MultiItem";
import Description from "../../components/Description/Description";

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, allApps: ManagementV1App[]) {
    const editApp = (app: ManagementV1App) => {
        drawerDispatcher({
            title: "Edit App: " + app.metadata?.name!,
            content: <AppDrawer mode={"update"} app={app} allApps={allApps} refetch={refetch} />
        })
    };

    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            sorter: (a: ManagementV1App, b: ManagementV1App) => stringSorter(displayName(a), displayName(b)),
            render: (app: ManagementV1App) => {
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editApp(app)} text={displayName(app)} />;
                    },
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editApp(app)} text={app.metadata?.name} />;
                    },
                ]}/>
            }
        },
        {
            title: 'Description',
            render: (app: ManagementV1App) => {
                return <Description.Column>
                    {app.spec?.description || ""}
                </Description.Column>;
            }
        },
        {
            title: 'Type',
            render: (app: ManagementV1App) => {
                if (app.spec?.streamContainer) {
                    return "Bash";
                } else if (app.spec?.config?.chart?.name) {
                    return "Chart";
                } else if (app.spec?.config?.manifests) {
                    return "Manifests";
                }
                
                return "Unknown";
            }
        },
        {
            title: 'Recommended',
            render: (app: ManagementV1App) => {
                if (!arr(app.spec?.recommendedApp).length) {
                    return "None";
                }

                return <MultiItem items={arr(app.spec?.recommendedApp).map(r => ({key: r, children: r+""}))} maxItems={5} />;
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ManagementV1App, b: ManagementV1App) => creationTimestampSorter(a, b),
            render: (app: ManagementV1App) => {
                return <DynamicTime timestamp={app.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "230px",
            render: (app: ManagementV1App) => {
                return <TableActions>
                    <Tooltip title="edit">
                        <SettingOutlined className={"blue-btn"} onClick={() => editApp(app)} />
                    </Tooltip>
                    <ShowYamlPopup className={"blue-btn"} object={app} resource={Resources.ManagementV1App} name={app.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={"actions-delete-btn"} onClick={() => {
                            deleteConfirm({
                                title: `Delete App: ${app?.metadata?.name}`,
                                content: `Are you sure you want to delete the app ${app?.metadata?.name}? This action CANNOT be reverted! Already deployed apps will stay unaffected.`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await client.management(Resources.ManagementV1App).Delete(app.metadata?.name!);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1App, value: string) {
    return !!(item.metadata?.name?.includes(value) || item.spec?.description?.includes(value) || item.spec?.displayName?.includes(value));
}

export default function Apps() {
    const drawerDispatcher = useItemDrawer({width: 900});
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1App).List());
    const {loading: permissionLoading, error: permissionError, data: permissionData} = useQuery(async () => await client.management(Resources.ManagementV1App).CanI("create"));
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    const apps = arr(data?.items).sort((a, b) => displayNameSorter(a, b))
    return <div>
        <Table loading={permissionLoading || loading} 
               columns={getTableColumns(refetch, drawerDispatcher, apps)} 
               dataSource={apps.map(user => { return {...user, key: user.metadata!.name!}})} 
               error={error || permissionError} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <Description.Table>
                Apps can be regular helm charts, Kubernetes manifests or bash scripts that setup an application in a space or virtual cluster. They are a powerful tool to setup predefined applications automatically and version them. Apps can also be parameterized and used in space or virtual cluster templates.
            </Description.Table>,
            right: permissionData ? <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Add App",
                    content: <AppDrawer mode={"create"} allApps={apps} refetch={refetch} />
                });
            }}>Add App</Button> : undefined,
            selectedActions: <React.Fragment>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={"actions-delete-btn-batch"} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Apps`,
                            content: `Are you sure you want to delete the apps ${selectedRowKeys.join(", ")}? This action CANNOT be reverted! Already deployed apps will not be deleted.`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const userName = selectedRowKeys[i];
                                    const result = await client.management(Resources.ManagementV1App).Delete(userName as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneManagement();
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}