import React from "react";
import {
    ApartmentOutlined, ApiOutlined, AppstoreAddOutlined, BlockOutlined,
    CloudServerOutlined, ClusterOutlined, DatabaseOutlined, FireOutlined, LockOutlined, SaveOutlined, SolutionOutlined
} from "@ant-design/icons/lib";
import {useRouteMatch, Link} from "react-router-dom";
import Header from "../../../../components/Header/Header";
import styles from "../../../Spaces/Spaces/SpaceHeader/SpaceHeader.module.scss";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {getActions} from "../VClusterTable/VClusterTable";
import {ClusterObject} from "../../../Spaces/Spaces/Spaces";
import {ClusterV1VirtualCluster} from "../../../../../gen/models/clusterV1VirtualCluster";

interface VClusterHeaderProps {

}

export default function VClusterHeader(props: React.PropsWithChildren<VClusterHeaderProps>) {
    const routeMatch = useRouteMatch<{cluster: string, namespace: string, vcluster: string}>();
    const cluster = routeMatch.params.cluster;
    const namespace = routeMatch.params.namespace;
    const vcluster = routeMatch.params.vcluster;
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1] === ":vcluster" ? "namespaces" : path[path.length -2] === "other" ? "other" : path[path.length - 1];
    const drawerDispatcher = useItemDrawer();
    const {data: templateData} = useQuery(async () => await client.management(Resources.ManagementV1VirtualClusterTemplate).List())
    // we refresh the object here, otherwise it can be possible that we get a conflict error if the space has changed meanwhile
    const {data:virtualClusterResult, refetch: vClusterRefetch} = useQuery(async () =>await client.cluster(cluster, Resources.ClusterV1VirtualCluster).Namespace(namespace).Get(vcluster));

    const vCluster : ClusterObject<ClusterV1VirtualCluster> = {
        cluster : cluster,
        object : virtualClusterResult?virtualClusterResult:{}
    }

    const refetch = () => {
        return vClusterRefetch();
    };

    const title = <span>
            {vcluster}
            <Tooltip title={namespace}>
                <Link className={styles["metrics"]} to={`/spaces/${cluster}/${namespace}`}>
                    Go to Space
                </Link>
            </Tooltip>
        </span>

    const actions: React.ReactNode = getActions(vCluster, templateData?.items, refetch, drawerDispatcher)

    return <Header title={title} actions={actions}  selectedKey={selectedKey} items={[
        {
            key: "namespaces",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/namespaces`,
            content: <React.Fragment>
                <CloudServerOutlined />Namespaces
            </React.Fragment>
        },
        {
            key: "pods",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/pods`,
            content: <React.Fragment>
                <CloudServerOutlined />Pods
            </React.Fragment>
        },
        {
            key: "apps",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/apps`,
            content: <React.Fragment>
                <AppstoreAddOutlined />Apps
            </React.Fragment>
        },
        {
            key: "deployments",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/deployments`,
            content: <React.Fragment>
                <ClusterOutlined />Deployments
            </React.Fragment>
        },
        {
            key: "statefulsets",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/statefulsets`,
            content: <React.Fragment>
                <DatabaseOutlined />StatefulSets
            </React.Fragment>
        },
        {
            key: "events",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/events`,
            content: <React.Fragment>
                <FireOutlined />Events
            </React.Fragment>
        },
        {
            key: "services",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/services`,
            content: <React.Fragment>
                <ApartmentOutlined />Services
            </React.Fragment>
        },
        {
            key: "ingresses",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/ingresses`,
            content: <React.Fragment>
                <ApiOutlined />Ingresses
            </React.Fragment>
        },
        {
            key: "secrets",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/secrets`,
            content: <React.Fragment>
                <LockOutlined />Secrets
            </React.Fragment>
        },
        {
            key: "configmaps",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/configmaps`,
            content: <React.Fragment>
                <SaveOutlined />ConfigMaps
            </React.Fragment>
        },
        {
            key: "activity",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/activity`,
            content: <React.Fragment>
                <SolutionOutlined />Activity
            </React.Fragment>
        },
        {
            key: "other",
            url: `/vclusters/${cluster}/${namespace}/${vcluster}/other`,
            content: <React.Fragment>
                <BlockOutlined />Other
            </React.Fragment>
        }
    ]}>
        {props.children}
    </Header>
}