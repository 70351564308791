import React from "react";
import {ManagementV1SharedSecret} from "../../../../../gen/models/managementV1SharedSecret";
import Section from "../../../../components/Drawer/Section/Section";
import Label from "../../../../components/Label/Label";
import YAMLEditor from "../../../../components/YAMLEditor/YAMLEditor";
import Description from "../../../../components/Description/Description";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {convertToMap, parseMap, YamlObject} from "../../../Users/ManagementRoles/RolesDrawer/Sections/RolesConfig";
import {Result, ResultError, Return} from "../../../../lib/result";
import jsyaml from "js-yaml";

function parseBase6Map(a?: YamlObject) {
    const newMap: YamlObject = {};
    if (a) {
        for (const [key] of Object.entries(a)) {
            try {
                newMap[key] = window.atob(a[key]);
            } catch(e) {
                console.error(e);
            }
        }
    }
    
    return parseMap(newMap);
}

function convertToBase64Map(str?: string): Result<YamlObject> {
    const map = convertToMap(str);
    if (map.err) {
        return map;
    } else if (map.val) {
        for (const [key] of Object.entries(map.val)) {
            try {
                map.val[key] = window.btoa(map.val[key]);
            } catch(e) {
                console.error(e);
            }
        }
    }
    
    return Return.Value(map.val);
}

interface SecretDataState {
    data: string;
}

interface SecretDataProps extends SectionProps {
    secret?: ManagementV1SharedSecret;
}

export default class SecretData extends React.PureComponent<SecretDataProps, SecretDataState> {
    state: SecretDataState = {
        data: this.props.secret?.spec?.data ? parseBase6Map(this.props.secret?.spec?.data) : "",
    };

    create = async (secret: ManagementV1SharedSecret): Promise<ResultError> => {
        return this.update(secret);
    };

    update = async (secret: ManagementV1SharedSecret): Promise<ResultError> => {
        if (!secret.spec) {
            secret.spec = {};
        }

        const parsed = convertToBase64Map(this.state.data);
        if (parsed.err) {
            return parsed;
        }

        secret.spec.data = parsed.val;
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={`Data`}>
                <Label>Secret Data</Label>
                <YAMLEditor value={this.state.data}
                            minLines={10}
                            maxLines={60}
                            onChange={(val) => this.setState({data: val})}
                            placeholder={`key: value`} />
                <Description>Key value data of the secret in YAML format.</Description>
        </Section>
    }
}