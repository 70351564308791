import React from "react";
import UserDetailsHeader from "../UserDetailsHeader/UserDetailsHeader";
import ActivityTable from "../../../Audit/Activity/ActivityTable";
import {useRouteMatch} from "react-router-dom";
import Description from "../../../../components/Description/Description";

export default function UserActivity() {
    const match: any = useRouteMatch();
    const user = match.params?.user;
    return <ActivityTable top={<UserDetailsHeader>
        <Description.Table>Shows the user activity across the management instance, connected clusters and virtual clusters depending on your audit configuration.</Description.Table>
    </UserDetailsHeader>} hideUser={true} user={"loft:user:"+user} />
}