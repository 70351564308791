import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {ResultError, Return} from "../../../../lib/result";
import {NewResource, Resources} from "../../../../lib/resources";
import client from "../../../../lib/client";
import AccessKeyName from "./Sections/AccessKeyName";
import {alert} from "../../../../lib/Modal/Modal";
import Input from "../../../../components/Input/Input";
import AccessKeyScope from "./Sections/AccessKeyScope";
import {ManagementV1OwnedAccessKey} from "../../../../../gen/models/managementV1OwnedAccessKey";

export interface AccessKeyDrawerProps extends SectionProps {
    user?: string;
    team?: string;
    
    accessKey?: ManagementV1OwnedAccessKey;
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<AccessKeyDrawerProps, "mode"|"refetch"> & {accessKeyScopeRef: AccessKeyScope, accessKeyNameRef: AccessKeyName, user: string | undefined, team: string | undefined};

async function onCreate({accessKeyNameRef, accessKeyScopeRef, user, team}: ChangeFunctionProps): Promise<ResultError> {
    const accessKey = NewResource(Resources.ManagementV1OwnedAccessKey, "my-access-key", {});
    const result = accessKeyNameRef.create(accessKey, user, team);
    if (result.err) {
        return result;
    }

    const scopeResult = accessKeyScopeRef.create(accessKey);
    if (scopeResult.err) {
        return scopeResult;
    }

    const createResult = await client.management(Resources.ManagementV1OwnedAccessKey).Create(accessKey);
    if (createResult.err) {
        return createResult;
    }

    alert({
        title: "Access Key Generated",
        content: <React.Fragment>
            <div>This is your newly generated access key</div>
            <Input readOnly value={createResult.val.spec?.key} />
            <div className={"color-error"} style={{marginTop: "5px"}}>
                You will only see this access key once. To generate a new one, use the "Create Access Key" button.
            </div>
        </React.Fragment>
    });

    return Return.Ok();
}

async function onUpdate({accessKey, accessKeyScopeRef}: ChangeFunctionProps) {
    if (!accessKey) {
        return Return.Ok();
    }

    // make sure the object is up to date
    const accessKeyResult = await client.management(Resources.ManagementV1OwnedAccessKey).Get(accessKey.metadata?.name!);
    if (accessKeyResult.err) {
        return accessKeyResult;
    }

    accessKey = accessKeyResult.val;

    // apply access key scope
    const result = await accessKeyScopeRef?.update(accessKey);
    if (result?.err) {
        return result;
    }

    // update object
    const updateResult = await client.management(Resources.ManagementV1OwnedAccessKey).Update(accessKey.metadata?.name!, accessKey);
    if (updateResult.err) {
        return updateResult;
    }

    return Return.Ok();
}

export default function AccessKeyDrawer(props: AccessKeyDrawerProps) {
    const drawer = useDrawerDispatcher();
    const accessKeyNameRef = useRef<AccessKeyName>(null);
    const accessKeyScopeRef = useRef<AccessKeyScope>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({accessKeyScopeRef: accessKeyScopeRef.current!, accessKeyNameRef: accessKeyNameRef.current!, user: props.user, team: props.team});
        } else if (props.mode === "update") {
            result = await onUpdate({accessKey: props.accessKey, accessKeyScopeRef: accessKeyScopeRef.current!, accessKeyNameRef: accessKeyNameRef.current!, user: props.user, team: props.team});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorManagement(result);
            return;
        }

        // refetch & close
        await props.refetch();
        message.DoneManagement();
        drawer({});
    }}>
        <AccessKeyName mode={props.mode} ref={accessKeyNameRef} />
        <AccessKeyScope mode={props.mode} accessKey={props.accessKey} ref={accessKeyScopeRef} />
    </ItemDrawer>
}