import React, {useRef} from "react";
import {Button, Input} from "antd";
import styles from "./ClusterConnectStep1.module.scss";
import {ValidateKubeName} from "../../../lib/validation";
import ClientMessage from "../../../lib/Message/ClientMessage";
import LabelsAnnotationsSection from "../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import {V1ObjectMeta} from "@kubernetes/client-node";
import Label from "../../../components/Label/Label";
import {randomString} from "../../../lib/helper";
import {displayNameToID} from "../../../components/Drawer/Sections/Metadata/Metadata";

interface Props {
    clusterName: string,
    clusterNameChanged: boolean,
    setCurrentStep: (step: number) => void,
    setClusterName: (clusterName: string) => void,
    setClusterNameChanged: (changed: boolean) => void,
    clusterDisplayName: string,
    setClusterDisplayName: (clusterDisplayName: string) => void,
    setLabelsAnnotations: (metadata: V1ObjectMeta) => void,
}

export default function ClusterConnectStep1(props: Props) {
    const labelsAnnotationsSectionRef = useRef<LabelsAnnotationsSection>(null);
    const onSubmit = () => {
        if (props.clusterName) {
            const err = ValidateKubeName(props.clusterName);
            if (err.err) {
                ClientMessage.Error(err);
                return;
            }
        } else {
            props.setClusterName("cluster-"+randomString(5))
        }
        
        // set labels and annotations
        if (labelsAnnotationsSectionRef.current) {
            let metadata = {};
            const annotationsErr = labelsAnnotationsSectionRef.current.create(metadata);
            if (annotationsErr.err) {
                ClientMessage.Error(annotationsErr);
                return;
            }
            
            props.setLabelsAnnotations(metadata);
        }

        props.setCurrentStep(1);
    };

    return <div className={styles["step-1"]}>
        <div>
            <div className={"row"}>
                <div>
                    <Label>Display Name</Label>
                    <Input className={styles["cluster-name"]}
                           placeholder="My Cluster" value={props.clusterDisplayName}
                           onChange={(e) => {
                               props.setClusterDisplayName(e.target.value);
                               if (!props.clusterNameChanged) {
                                   props.setClusterName(displayNameToID(e.target.value)+"")
                               }
                           }}
                           onKeyUp={e => {
                        if (e.keyCode === 13) {
                            onSubmit();
                        }
                    }} />
                </div>
                <div>
                    <Label>Kubernetes Name (ID)</Label>
                    <Input className={styles["cluster-name"]}
                           placeholder="Leave empty for generated name"
                           value={props.clusterName}
                           onChange={(e) => {
                               props.setClusterName(e.target.value);
                               props.setClusterNameChanged(true);
                           }}
                           onKeyUp={e => {
                        if (e.keyCode === 13) {
                            onSubmit();
                        }
                    }} />
                </div>
            </div>
            <LabelsAnnotationsSection mode={"create"} ref={labelsAnnotationsSectionRef} />
        </div>
        <Button className={styles["next"]} onClick={onSubmit} type="primary">Next</Button>
    </div>
}