import React, {RefObject} from "react";
import Section from "../../../../../components/Drawer/Section/Section";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import LabelsAnnotationsSection, {LabelsAnnotationsSectionProps} from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import Label from "../../../../../components/Label/Label";
import Description from "../../../../../components/Description/Description";
import {Resources} from "../../../../../lib/resources";
import Query from "../../../../../components/Query/Query";
import client from "../../../../../lib/client";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import Select from "../../../../../components/Select/Select";
import {ResultError, Return} from "../../../../../lib/result";
import {displayName} from "../../../../../lib/helper";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import SleepMode from "../../../../../components/Drawer/Sections/SleepMode/SleepMode";
import constants from "../../../../../constants/constants";
import {ManagementV1SpaceConstraint} from "../../../../../../gen/models/managementV1SpaceConstraint";
const { Option } = Select;

interface SpaceConstraintConfigProps extends SectionProps, LabelsAnnotationsSectionProps {
    spaceConstraint?: ManagementV1SpaceConstraint;
}

interface SpaceConstraintConfigState {
    spaceClusterRole: string | undefined;
    spaceClusterRoleChanged: boolean;
    
    syncTemplate: boolean | undefined;
}

export default class SpaceConstraintConfig extends React.PureComponent<SpaceConstraintConfigProps, SpaceConstraintConfigState> {
    labelsSectionRef?: RefObject<LabelsAnnotationsSection>;
    sleepModeRef?: RefObject<SleepMode>;
    
    state: SpaceConstraintConfigState = {
        spaceClusterRole: this.props.spaceConstraint?.spec?.localSpaceConstraintTemplate?.spec?.spaceTemplate?.clusterRole || "loft-cluster-space-admin",
        spaceClusterRoleChanged: false,
        
        syncTemplate: this.props.spaceConstraint ? !!this.props.spaceConstraint?.spec?.localSpaceConstraintTemplate?.spec?.sync : true,
    };

    constructor(props: SpaceConstraintConfigProps) {
        super(props);

        this.labelsSectionRef = React.createRef<LabelsAnnotationsSection>();
        this.sleepModeRef = React.createRef<SleepMode>();
    }

    create = (spaceConstraint: ManagementV1SpaceConstraint): ResultError => {
        if (!spaceConstraint.spec) {
            spaceConstraint.spec = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate) {
            spaceConstraint.spec.localSpaceConstraintTemplate = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate.spec) {
            spaceConstraint.spec.localSpaceConstraintTemplate.spec = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate) {
            spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate.metadata) {
            spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate.metadata = {};
        }

        // set annotations & labels
        const annotationsResult = this.labelsSectionRef!.current!.create(spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate.metadata);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        // set sleep mode config
        const sleepModeResultResult = this.sleepModeRef!.current!.create(spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate);
        if (sleepModeResultResult.err) {
            return sleepModeResultResult;
        }
        
        // set sync & cluster role
        spaceConstraint.spec.localSpaceConstraintTemplate.spec.sync = this.state.syncTemplate;
        spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate.clusterRole = this.state.spaceClusterRole;
        return Return.Ok();
    }

    update = (spaceConstraint: ManagementV1SpaceConstraint): ResultError => {
        if (!spaceConstraint.spec) {
            spaceConstraint.spec = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate) {
            spaceConstraint.spec.localSpaceConstraintTemplate = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate.spec) {
            spaceConstraint.spec.localSpaceConstraintTemplate.spec = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate) {
            spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate = {};
        }
        if (!spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate.metadata) {
            spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate.metadata = {};
        }

        // set annotations & labels
        const annotationsResult = this.labelsSectionRef!.current!.update(spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate.metadata);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        // set sleep mode config
        const sleepModeResultResult = this.sleepModeRef!.current!.update(spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate);
        if (sleepModeResultResult.err) {
            return sleepModeResultResult;
        }

        // set sync & cluster role
        spaceConstraint.spec.localSpaceConstraintTemplate.spec.sync = this.state.syncTemplate;
        if (this.state.spaceClusterRoleChanged) {
            spaceConstraint.spec.localSpaceConstraintTemplate.spec.spaceTemplate.clusterRole = this.state.spaceClusterRole
        }
        return Return.Ok();
    }

    render() {
        return <React.Fragment>
            <Section title={"Enforce Space Settings"} foldable={true} defaultFolded={true}>
                <SleepMode mode={this.props.mode} obj={this.props.spaceConstraint ? this.props.spaceConstraint.spec?.localSpaceConstraintTemplate?.spec?.spaceTemplate : undefined} ref={this.sleepModeRef} />
                <LabelsAnnotationsSection {...this.props} name={"Enforce Space Labels & Annotations"} metadata={this.props.spaceConstraint ? this.props.spaceConstraint.spec?.localSpaceConstraintTemplate?.spec?.spaceTemplate?.metadata : undefined} ref={this.labelsSectionRef} />
            </Section>
            <Section title={"Advanced Options"} foldable={true} defaultFolded={true}>
                <Label>Sync Space Constraints Changes</Label>
                <Checkbox onChange={e => this.setState({syncTemplate: e.target.checked})} checked={this.state.syncTemplate}>Sync Space Constraints Changes</Checkbox>
                <Description>If enabled, any change to the space constraints object, such as enforced space metadata, sleep configuration, default cluster role or space objects, will be synced and reapplied to all spaces that have this space constraints applied.</Description>
                <Label>Space Owner's Cluster Role</Label>
                <Query query={async () => await client.management(Resources.ManagementV1ClusterRoleTemplate).List()}>
                    {
                        result => {
                            if (result.error) {
                                return <span className={"color-error"}>{result.error.val?.message!}</span>
                            }

                            const clusterRoles = arr(result.data?.items).filter(globalClusterRole => !globalClusterRole.spec?.management && globalClusterRole.metadata?.annotations?.[constants.LoftIgnoreAnnotation] !== "true")
                            return <Select showSearch
                                        style={{width: "100%"}}
                                        resetable={this.props.mode !== "create"}
                                        changed={this.state.spaceClusterRoleChanged}
                                        onChangedStatus={status => this.setState({spaceClusterRoleChanged: status})}
                                        filterOption={selectDefaultFilter}
                                        placeholder={"Default Space Cluster Role"}
                                        value={this.state.spaceClusterRole}
                                        onChange={value => this.setState({spaceClusterRole: value})}>
                                {clusterRoles.map(clusterRole => <Option key={clusterRole.metadata?.name!} value={clusterRole.metadata?.name!}>{displayName(clusterRole)}</Option>)}
                            </Select>
                        }
                    }
                </Query>
                <Description>The cluster role that is assigned to the space owner in each space where this constraint is applied.</Description>
            </Section>
        </React.Fragment>
    }
}