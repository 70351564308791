import React from "react";
import {
    BlockOutlined,
    CopyOutlined,
} from "@ant-design/icons/lib";
import { useRouteMatch, useHistory } from "react-router-dom";
import Header from "../../../components/Header/Header";
import VCluster from "../../../components/Icons/VCluster";

interface VClustersHeaderProps {

}

export default function VClustersHeader(props: React.PropsWithChildren<VClustersHeaderProps>) {
    const routeMatch = useRouteMatch<{cluster: string}>();
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1];

    return <Header title={"Virtual Cluster Management"} selectedKey={selectedKey} items={[
        {
            key: "vclusters",
            url: `/vclusters`,
            content: <React.Fragment>
                <VCluster />Virtual Clusters
            </React.Fragment>
        },
        {
            key: "vclustertemplates",
            url: `/vclustertemplates`,
            content: <React.Fragment>
                <CopyOutlined />Virtual Cluster Templates
            </React.Fragment>
        },
    ]}>
        {props.children}
    </Header>
}