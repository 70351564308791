import React from "react";
import { SectionProps } from "../../../../components/Drawer/ItemDrawer";
import {ResultError, Return} from "../../../../lib/result";
import Section from "../../../../components/Drawer/Section/Section";
import Description from "../../../../components/Description/Description";
import Label from "../../../../components/Label/Label";
import {arr, selectDefaultFilter} from "../../../../lib/helpers/renderhelper";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import Loading from "../../../Loading/Loading";
import {ErrorMessage} from "../../../ErrorMessage/ErrorMessage";
import Select from "../../../Select/Select";
import styles from "../../../../views/Spaces/Spaces/SpaceDrawer/Sections/SpaceAccess.module.scss";
import Query from "../../../Query/Query";
import {displayName} from "../../../../lib/helper";
const { Option } = Select;

interface ClustersState {
    clusters: Array<string>;
    clustersChanged: boolean;
}

interface ClustersObject {
    spec?: {
        clusters?: Array<string>;
    }
}

interface ClustersProps extends SectionProps {
    obj?: ClustersObject;
    
    description: string;
}

export default class Clusters extends React.PureComponent<ClustersProps, ClustersState> {
    state: ClustersState = {
        clusters: arr(this.props.obj?.spec?.clusters).length > 0 ? arr(this.props.obj?.spec?.clusters) : this.props.mode === "create" ? ["*"] : [],
        clustersChanged: false,
    };

    create = async (obj: ClustersObject): Promise<ResultError> => {
        if (!obj.spec) {
            obj.spec = {};
        }

        obj.spec.clusters = this.state.clusters;
        return Return.Ok();
    };

    update = async (obj: ClustersObject): Promise<ResultError> => {
        if (!obj.spec) {
            obj.spec = {};
        }
        if (this.state.clustersChanged) {
            obj.spec.clusters = this.state.clusters;
        }
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={`Clusters`} {...this.props}>
            <Label>Select Clusters</Label>
            <Query query={async () => await client.management(Resources.ManagementV1Cluster).List()}>
                {
                    result => {
                        if (result.loading) {
                            return <Loading />
                        } else if (result.error) {
                            return <ErrorMessage error={result.error} />
                        }

                        return <Select
                            resetable={this.props.mode !== "create"}
                            className={styles["select"]}
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select a cluster"
                            value={this.state.clusters}
                            onChangedStatus={changed => this.setState({clustersChanged: changed})}
                            onChange={(value) => {
                                const allBefore = !!this.state.clusters.find(c => c === "*");
                                const allAfter = !!value.find(c => c === "*");

                                if (allBefore && allAfter && value.length > 1) {
                                    this.setState({clusters: value.filter(c => c !== "*")});
                                } else if (allAfter) {
                                    this.setState({clusters: ["*"]});
                                } else {
                                    this.setState({clusters: value})
                                }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={selectDefaultFilter}
                        >
                            <Option key={"*"} value={"*"}>All Clusters</Option>
                            {arr(result.data?.items).map(cluster => <Option key={cluster.metadata?.name} value={cluster.metadata?.name!}>
                                {displayName(cluster)}
                            </Option>)}
                        </Select>
                    }
                }
            </Query>
            <Description>{this.props.description}</Description>
        </Section>
    }
}