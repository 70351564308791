import React from "react";
import * as Ant from "antd";
import styles from "./Button.module.scss";
import {ButtonProps} from "antd/lib/button";

export default function Button(props: ButtonProps) {
    const classNames = [styles["button"]];
    if (props.className) {
        classNames.push(props.className);
    }

    // type
    if (!props.type) {
        classNames.push(styles["type-default"]);
    } else {
        classNames.push(styles["type-"+props.type])
    }

    return <Ant.Button {...props} className={classNames.join(" ")} />
}
