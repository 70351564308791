import React, {MouseEventHandler} from 'react';
import styles from "./FixedText.module.scss";
import Text from "antd/es/typography/Text";

export interface FixedTextProps {
    className?: string;
    text: string | undefined;
    
    onClick?: MouseEventHandler<any>;
    maxWidth?: number;
}

export default function FixedText(props: FixedTextProps) {
    let maxWidth = "300px";
    if (props.maxWidth) {
        maxWidth = props.maxWidth + "px";
    }
    
    const classNames = [styles["text"]];
    if (props.className) {
        classNames.push(props.className);
    }
    
    return <Text style={{maxWidth}} ellipsis={true} className={classNames.join(" ")} title={props.text} onClick={props.onClick as any}>{props.text}</Text>
}