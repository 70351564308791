import React from "react";
import {Button, message, Modal} from "antd";
import {MessageType} from "antd/es/message";
import styles from "./Message.module.scss";
import {CheckCircleTwoTone, CloseOutlined, InfoCircleOutlined} from "@ant-design/icons/lib";
import {Failed} from "../result";
import {alert} from "../Modal/Modal";
import TextArea from "../../components/TextArea/TextArea";

class Message {
    public static Loading(props: React.ReactNode): Message {
        let message = new Message();
        message.Loading(props);
        return message;
    }

    public static Error(props: React.ReactNode, err: Failed, title?: React.ReactNode): Message {
        let message = new Message();
        message.Error(props, err, title);
        return message;
    }

    private readonly key: number;
    constructor(key = Math.random() * 1000000) {
        this.key = key;
    }

    public Loading(props: React.ReactNode): MessageType {
        return message.loading({
            content: <span className={styles["loading"]}>{props}</span>,
            key: this.key,
            duration: 0,
        });
    }

    public Info(props: React.ReactNode) {
        return message.info({
            content: <span className={styles["info"]}>{props}</span>,
            key: this.key,
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            duration: 2,
        });
    }

    public Error(props: React.ReactNode, err: Failed, title: React.ReactNode = 'An Error Occured') {
        let errMessage = err.message;
        if (typeof err.message === "string") {
            errMessage = "Error: " + err.message + (err.reason ? " (" + err.reason + ")" : "");
            if (errMessage.length <= 128) {
                const hide = message.info({
                    content: <span className={styles["error"]}>{errMessage}<Button icon={<CloseOutlined />} type={"link"} size={"small"} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        hide();
                    }} /></span>,
                    key: this.key,
                    icon: <InfoCircleOutlined style={{"color": "#C21B1B"}} twoToneColor="#C21B1B" />,
                    duration: 120,
                });
                return hide;
            }
        }

        const hide = message.info({
            content: <span className={styles["error"] + " " + styles["clickable"]} onClick={() => {
                alert({
                    title: <span>{title}</span>,
                    content: (typeof errMessage === "string") ? <TextArea readOnly
                                                                          style={{minHeight: "98px"}}
                                                                          value={errMessage} /> : <span>{errMessage}</span>,
                    okText: "Close"
                });
            }}>{props}<Button icon={<CloseOutlined />} type={"link"} size={"small"} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                hide();
            }} /></span>,
            key: this.key,
            icon: <InfoCircleOutlined style={{"color": "#C21B1B"}} twoToneColor="#C21B1B" />,
            duration: 120,
        });
        return hide;
    }
}

export default Message;