import React from "react";
import AuthLayout from "../../../components/AuthLayout/AuthLayout";
import client from "../../../lib/client";
import {Button, Input} from "antd";
import Label from "../../../components/Label/Label";
import styles from "./ResetPassword.module.scss";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import {Return} from "../../../lib/result";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {useErrorLoading} from "../../../lib/messageHook";
import {useUser} from "../../../contexts/UserContext/UserContext";
import {NewResource, Resources} from "../../../lib/resources";
import {deleteResetAccessKey} from "../CreateAccount/CreateAccount";

interface Props extends RouteComponentProps {

}

function ResetPassword(props: Props) {
    const user = useUser();
    const [password, setPassword] = React.useState<string>("");
    const [passwordRepeated, setPasswordRepeated] = React.useState<string>("");
    const [errorLoading, setLoading, setError] = useErrorLoading();

    // reroute if not logged in
    if (!client.isLoggedIn()) {
        props.history.push("/");
        return null;
    }

    return <AuthLayout title="Set a new password" description="to reclaim access to your account" onSubmit={async () => {
        if (!password) {
            setError(Return.Failed("Please specify a password"));
            return;
        } else if (password !== passwordRepeated) {
            setError(Return.Failed("Please make sure the passwords match"));
            return;
        }

        // set loading
        setLoading(true);

        // create a new UserProfile resource
        const userProfile = NewResource(Resources.ManagementV1UserProfile, "profile", {
            password
        });

        // create the resource
        const response = await client.management(Resources.ManagementV1UserProfile).Name(user?.metadata?.name!).Create(userProfile);
        if (response.err) {
            setError(response);
            return;
        }

        // login to gain a new access key
        const loginResult = await client.login(user?.spec?.username!, password);
        if (loginResult.err) {
            setError(loginResult);
            return;
        }

        // now delete the current access key
        const result = await deleteResetAccessKey();
        if (result.err) {
            setError(result);
            return;
        }

        // redirect
        window.location.href = "/spaces";
    }}>
        <Label>Choose a new password</Label>
        <Input.Password className={styles["password"]} placeholder={"*************"} value={password} onChange={(e) => setPassword(e.target.value)} />
        <Label>Repeat new password</Label>
        <Input.Password placeholder={"*************"} value={passwordRepeated} onChange={(e) => setPasswordRepeated(e.target.value)} />
        <ErrorMessage className={styles["error"]} error={errorLoading.error} />
        <div className={styles["set-password-wrapper"]}>
            <Button type={"primary"} loading={errorLoading.loading} htmlType={"submit"}>Set Password</Button>
        </div>
    </AuthLayout>
}

export default withRouter(ResetPassword);