import React, { createContext, Dispatch, PropsWithChildren, Reducer, useReducer } from 'react';

import { RequestOptionsVCluster } from '../../lib/client';

export enum DockTabActions {
    OPEN,
    CLOSE,
    FOCUS,
}

export enum DockTabKind {
    EXEC = 'exec',
    LOGS = 'logs',
}

export interface DockTab {
    id: string;
    kind: DockTabKind;
    cluster?: string;
    vCluster?: RequestOptionsVCluster;
    namespace?: string;
    pod?: string;
    task?: string;
    container?: string;
}

export interface DockState {
    tabs: DockTab[];
    focusedTab?: string;
}

export interface DockTabPayload {
    kind: DockTabKind;
    cluster?: string;
    vCluster?: RequestOptionsVCluster;
    namespace?: string;
    pod?: string;
    task?: string;
    container?: string;
}

export interface DockTabAction {
    type: DockTabActions;
    payload?: DockTabPayload;
    id?: string;
}

const initialDockState: DockState = {
    tabs: [],
    focusedTab: undefined,
};

function tabKey(
    cluster: string | undefined,
    vCluster: RequestOptionsVCluster | undefined,
    namespace: string | undefined,
    name: string,
    tabKind: DockTabKind
): string {
    const tokens = [
        cluster,
        vCluster?.cluster,
        vCluster?.namespace,
        vCluster?.name,
        namespace,
        name,
        tabKind,
    ].filter((token: string | undefined) => {
        return token != null && token !== ''
    })
    return tokens.join('/');
}

function tabById(id: string) {
    return (tab: DockTab) => {
        return tab.id == id;
    }
}

export const DockContext = createContext<{
    state: DockState,
    dispatch: Dispatch<DockTabAction>,
}>({
    state: initialDockState,
    dispatch: () => null,
});

const reducer: Reducer<DockState, DockTabAction> = (state, action): DockState => {
    const { tabs, focusedTab } = state;

    switch (action.type) {
        case DockTabActions.FOCUS:
            return {
                tabs,
                focusedTab: action.id
            }
        case DockTabActions.CLOSE:
            const closedTabIndex = tabs.findIndex(tabById(action.id!))
            if (closedTabIndex == -1) {
                return { tabs, focusedTab };
            }

            const closedTab = tabs[closedTabIndex];
            const newTabs = [
                ...tabs.slice(0, closedTabIndex),
                ...tabs.slice(closedTabIndex + 1),
            ];

            if (focusedTab === closedTab.id) {
                const newLastIndex = newTabs.length - 1;
                const newFocusTab = newTabs[Math.min(closedTabIndex, newLastIndex)]
                return {
                    tabs: newTabs,
                    focusedTab: newFocusTab?.id,
                }
            } else {
                return { tabs: newTabs, focusedTab }
            }
        case DockTabActions.OPEN:
            const { payload } = action;
            const { kind, namespace, cluster, vCluster, pod, task, container } = payload!;
            const name = pod != null ? pod! : task!;
            const id = tabKey(cluster, vCluster, namespace, name, kind);

            const found = tabs.find(tabById(id))
            if (!found) {
                return {
                    tabs: [...tabs, {
                        id,
                        kind,
                        cluster,
                        vCluster,
                        namespace,
                        pod,
                        task,
                        container
                    }],
                    focusedTab: id,
                }
            } else {
                return {
                    tabs,
                    focusedTab: id,
                }
            }
    }
}

function DockContextProvider(props: PropsWithChildren<{}>) {
    const [state, dispatch] = useReducer(reducer, initialDockState);

    return <DockContext.Provider value={{ state, dispatch }}>
        {props.children}
    </DockContext.Provider>
}

export default DockContextProvider;