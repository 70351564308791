import React, {useState} from "react";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined} from "@ant-design/icons/lib";
import styles from "./TemplateInstanceDeletePopup.module.scss";
import {DeleteModal} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {ConfigV1alpha1TemplateInstance} from "../../../../../gen/models/configV1alpha1TemplateInstance";

interface Props {
    templateinstances: ConfigV1alpha1TemplateInstance[];
    cluster: string;
    refetch: () => Promise<void>;
    className?: string;
}

export default function TemplateInstanceDeletePopup(props: Props) {
    const [visible, setVisible] = useState<boolean>(false);

    return <React.Fragment>
        <Tooltip title="delete">
            <DeleteOutlined className={props.className ? props.className : styles["delete"]} onClick={() => setVisible(true)} />
        </Tooltip>
        <DeleteModal title={`Delete Template Instance${props.templateinstances.length === 1 ? ": " + props.templateinstances[0].metadata?.name! : "s"}`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
            const message = ClientMessage.Loading(props.cluster);

            for(let ti of props.templateinstances) {
                // delete instance
                const result = await client.cluster(props.cluster, Resources.ConfigV1alpha1TemplateInstance).Namespace(ti.metadata?.namespace!).Delete(ti.metadata?.name!);
                if (result.err) {
                    message.ErrorManagement(result);
                    setVisible(false);
                    return;
                }
            }

            message.DoneManagement();
            await props.refetch();
            setVisible(false);
        }}>

            <div>
                Are you sure you want to delete the Template Instance{props.templateinstances.length === 1 ? "" : "s"} {props.templateinstances.map(ti => ti.metadata?.name!).join(", ")}?
            </div>
        </DeleteModal>
    </React.Fragment>
}