import React from "react";
import * as antd from "antd";
import {ModalFuncProps} from "antd/lib/modal/Modal";
import styles from "./Modal.module.scss";
import {DeleteOutlined} from "@ant-design/icons/lib";
import AsyncButton from "../../components/AsyncButton/AsyncButton";
import FixedText from "../../components/FixedText/FixedText";
const { confirm: antConfirm } = antd.Modal;

export const MODAL_ZINDEX = 1100;
export const MODAL_TOOLTIP_ZINDEX = MODAL_ZINDEX + 1;

export function DeleteModal(props: React.PropsWithChildren<ConfirmProps>) {
    return <Modal {...props} okButtonProps={{className: styles["delete-btn"]}} okText={<React.Fragment><DeleteOutlined className={styles["delete-icon"]} />{props.okText ? props.okText : "Delete"}</React.Fragment>} />
}

export function Modal(props: React.PropsWithChildren<ConfirmProps>) {
    let footer: React.ReactNode = null
    if (!props.hideButtons) {
      footer = <div className={styles["buttons"]}>
          <div>
              {props.leftButton}
          </div>
          <div>
              {!props.hideCancel && <AsyncButton {...props.cancelButtonProps} onClick={props.onCancel}>{props.cancelText ? props.cancelText : "Cancel"}</AsyncButton>}
              <AsyncButton {...props.okButtonProps} type={"primary"} onClickAsync={props.onOkAsync}>{props.okText ? props.okText : "Ok"}</AsyncButton>  
          </div>
      </div>
    }

    return <antd.Modal {...props}
                       className={styles["modal"] + " " + styles["modal-component"]}
                       maskClosable={props.maskClosable !== undefined ? props.maskClosable : true}
                       title={<div className={styles["title"]}>
                           {props.title}
                       </div>}
                       zIndex={MODAL_ZINDEX}
                       width={props.width ? props.width : 600}
                       style={props.style ? {...props.style, maxWidth: "70vw"} : {maxWidth: "70vw"}}
                       footer={footer}
                       >
        <div className={styles["content"]}>
            {props.children}
        </div>
    </antd.Modal>
}

export interface ConfirmProps extends ModalFuncProps {
    leftButton?: React.ReactNode;
    
    hideButtons?: boolean;
    hideCancel?: boolean;

    onOkAsync?: (e: React.MouseEvent<HTMLElement>) => Promise<any>;
}

export function confirm(props: ConfirmProps) {
    const result = antConfirm({
        ...props,
        maskClosable: props.maskClosable !== undefined ? props.maskClosable : true,
        className: styles["modal"],
        title: <div className={styles["title"]}>
            {props.title}
        </div>,
        width: props.width ? props.width : 600,
        style: props.style ? {...props.style, maxWidth: "70vw"} : {maxWidth: "70vw"},
        zIndex: MODAL_ZINDEX,
        content: <div>
            <div className={styles["content"]}>
                {props.content}
            </div>
            {!props.hideButtons && <div className={styles["buttons"]}>
                <div>
                    {props.leftButton}
                </div>
                <div>
                    {!props.hideCancel && <AsyncButton {...props.cancelButtonProps} onClick={() => {
                        if (props.onCancel) {
                            props.onCancel();
                        }

                        result.destroy();
                    }}>{props.cancelText ? props.cancelText : "Cancel"}</AsyncButton>}
                    <AsyncButton {...props.okButtonProps} type={"primary"} onClickAsync={props.onOkAsync ? async (e) => {
                        const resp = await props?.onOkAsync!(e);
                        if (resp !== false) {
                            result.destroy();
                        }
                    } : async () => result.destroy()}>{props.okText ? props.okText : "Ok"}</AsyncButton>
                </div>
            </div>}
        </div>
    });
    return result;
}

export interface AlertProps extends ConfirmProps {

}

export function alert(props: AlertProps) {
    return confirm({
        ...props,
        hideCancel: true
    })
}

export interface DeleteConfirmProps extends ConfirmProps {

}

export function deleteConfirm(props: DeleteConfirmProps) {
    return confirm({
        ...props,
        title: <FixedText maxWidth={450} text={props.title as string} />,
        okButtonProps: {
            className: styles["delete-btn"]
        },
        okText: <React.Fragment>
            <DeleteOutlined className={styles["delete-icon"]} />{props.okText ? props.okText : "Delete"}
        </React.Fragment>
    });
}