import React from "react";
import {ColumnFilterItem} from "antd/es/table/interface";
import {stringSorter} from "./sorthelper";
import {ClusterV1EntityInfo} from "../../../gen/models/clusterV1EntityInfo";

export function wrapIf(outer: (inner: React.ReactNode) => React.ReactNode, inner: React.ReactNode, ifTrue: boolean) {
    return (ifTrue) ? outer(inner) : inner;
}

export interface Filter {value: string, text: string | undefined}

export function removeDuplicateFilters(a: Array<Filter>): Array<Filter> {
    return a.reduce((self: any, c) => {
        if (arr(self as ColumnFilterItem[]).find(s => s.value === c.value)) {
            return self;
        }

        return [...self, c];
    }, []).sort((a: any, b: any) => {
        return stringSorter(a.value + "", b.value + "");
    });
}

export function getDisplayNameFromEntity(entity?: ClusterV1EntityInfo) {
    return getDisplayName(entity?.displayName, entity?.username, entity?.name)
}

export function getDisplayName(displayName: string | undefined, username: string | undefined, kubeName: string | undefined) {
    return displayName ? displayName : username ? username : kubeName === "*" ? "All" : kubeName;
}

export function without(props: any, keys: string[]) {
    if (!props) {
        return props;
    }

    return Object.keys(props).filter(key => keys.indexOf(key) === -1).reduce((retVal: any, key) => {
        retVal[key] = props[key];
        return retVal;
    }, {});
}

export function filterObj<T extends any>(obj: T, predicate: (obj: T, key: string) => any): T {
    const o = obj as any;
    let result: any = {}, key;
    for (key in obj) {
        if (o.hasOwnProperty(key) && predicate(o[key], key)) {
            result[key] = o[key];
        }
    }

    return result as any;
}

export function arr<T>(array: T[] | undefined): T[] {
    return !array ? [] : array;
}

export function selectOwnerFilter(input: any, option: any) {
    if (option && option.children && option.children.props) {
        const props = option.children.props;
        let filter = "";
        if (props.displayName) {
            filter += props.displayName;
        } else if (props.username) {
            filter += props.username
        } else if (props.kubeName) {
            filter += props.kubeName;
        }

        return filter.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    }

    return false;
}

export function selectDefaultFilter(input: any, option: any) {
    if (option && option.children) {
         return childrenToString(option.children).toLowerCase().indexOf(input.toLowerCase()) !== -1;
    }

    return false;
}

export function childrenToString(children: React.ReactNode): string {
    if (!children) {
        return "";
    }

    if (Array.isArray(children)) {
        let retStr = "";
        for (let i = 0; i < children.length; i++) {
            retStr += childrenToString(children[0]);
        }
        return retStr;
    }

    if (typeof children === "string") {
        return children;
    } else if (typeof children === "number") {
        return children.toString();
    } else if (typeof children === "object" && (children as any).children) {
        return childrenToString((children as any).children);
    }

    return "";
}
