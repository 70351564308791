import React from "react";
import {Tooltip} from "../Tooltip/Tooltip";
import {WarningOutlined} from "@ant-design/icons";
import styles from "./Warning.module.scss";

interface WarningProps {
    tooltip: React.ReactNode;
    text: React.ReactNode;
    
    className?: string;
}

export default function Warning(props: WarningProps) {
    const classNames = [styles["warning"]]
    if (props.className) {
        classNames.push(props.className);
    }
    
    return <Tooltip title={props.tooltip}>
        <span className={classNames.join(" ")}><WarningOutlined />{props.text}</span>
    </Tooltip>
}
